import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle } from "react-native"

export interface titleStyles extends Styles {
    text: TextStyle
}

export const titleStyles: StyleFunction<titleStyles> = () => {
    return {
        text: {
            marginVertical: 5,
        },
        WEB: {
            text: {
                fontKerning: "none",
            },
        },
    }
}
