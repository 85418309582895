/* eslint-disable react/prop-types */
import React from "react"
import { getValidationMessage } from "@helpers/validationMessage"
import { Platform, TextInput, TextStyle } from "react-native"
import { BaseInput } from "@components"
import { BaseInputProps } from "@components/BaseInput/BaseInput"

import { ValidationError } from "yup"
import Yup from "../../yup-extended"
import { captureMessage } from "@services/sentry"
import { getUrlsFromString } from "@helpers/getUrlsFromString"

type ValidateResponse = {
    isValid: boolean
    error?: ValidationError | undefined
}

interface InputProps extends BaseInputProps {
    onInvalidChange?: (error: ValidationError | undefined) => void
    shrinkText?: boolean
    editable?: boolean
    minLength?: number
    maxLength?: number
}

const Input = React.forwardRef<TextInput, InputProps>((props, ref) => {
    const {
        variant = "plain",
        placeholder = "",
        value = undefined,
        textAlign,
        style,
        inputStyle,
        keyboardType,
        multiline,
        numberOfLines,
        minLength,
        maxLength,
        autoCapitalize,
        autoCorrect,
        editable,
        shrinkText = false,
        importantForAutofill,
        onChange,
        onBlur,
        onFocus,
        onInvalidChange,
        onPressIn,
        onPressOut,
    } = props
    // prep for content size change handling
    const contentHeightRef = React.useRef(0)
    const initialContentHeightRef = React.useRef(0)
    const fontSize = (inputStyle as TextStyle)?.fontSize
    const initialFontSize = Platform.select({ web: fontSize }) || fontSize || 15
    const fontSizeRef = React.useRef<number>(initialFontSize)
    const fontReductionScale = Platform.select({ web: 0.95 }) || 0.985
    const fontIncreaseScale = 1.15

    const handleContentSizeChange = (height: number) => {
        if (!shrinkText || fontSize) return
        if (!initialContentHeightRef.current) {
            initialContentHeightRef.current = height
        }
        if (
            height > initialContentHeightRef.current &&
            initialContentHeightRef.current > 0
        ) {
            fontSizeRef.current = fontSizeRef.current * fontReductionScale
        } else if (height < initialContentHeightRef.current) {
            fontSizeRef.current = fontSizeRef.current * fontIncreaseScale
        }
        contentHeightRef.current = height
    }

    const validate = async (value: string): Promise<ValidateResponse> => {
        const schema = Yup.object({
            value: Yup.string()
                .required()
                .minMultibyte(
                    minLength || 0,
                    getValidationMessage("STRING_TOO_SHORT"),
                )
                .max(maxLength || 31, getValidationMessage("STRING_TOO_LONG")),
        })

        try {
            await schema.validate({ value })
            return { isValid: true }
        } catch (e: any) {
            captureMessage(e)
            return { isValid: false, error: e as ValidationError }
        }
    }

    const trimSpaces = (text: string) =>
        text.replace(/[\r\n\t]/g, " ").replace(/\s\s+/g, " ")

    const handleOnChange = async (value: string) => {
        value = value.trimStart()
        const hasLinks = getUrlsFromString(value)

        if (hasLinks) {
            onChange && onChange(trimSpaces(value))
            return
        }

        if (value !== " ") {
            onChange && onChange(trimSpaces(value).slice(0, maxLength))
        }
        const { error } = await validate(value)
        onInvalidChange && onInvalidChange(error)
    }

    return (
        <BaseInput
            ref={ref}
            style={style}
            inputStyle={inputStyle}
            variant={variant}
            editable={editable}
            placeholder={placeholder}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            value={value}
            textAlign={textAlign}
            multiline={multiline}
            numberOfLines={numberOfLines}
            keyboardType={keyboardType}
            onChange={handleOnChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onPressIn={onPressIn}
            onPressOut={onPressOut}
            onContentSizeChange={handleContentSizeChange}
            fontSize={shrinkText ? fontSizeRef.current : undefined}
            importantForAutofill={importantForAutofill}
        />
    )
})

Input.displayName = "Input"

export default Input
