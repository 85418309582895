import { BackButton, NavHeader } from "@components"
import { AnimatedView } from "@components/AnimatedView"
import { AnimatedType } from "@components/AnimatedView/AnimatedView"
import { NavigationContext } from "@contexts/NavigationProvider/NavigationReducer"
import useStyles from "@helpers/hooks/useStyles"
import { useVisualViewport } from "@helpers/hooks/useVisualViewport"
import React, { useContext } from "react"
import { View } from "react-native"
import { webScreenCardStyles } from "./WebScreenCard.styles"

interface WebScreenCardProps {
    children: React.ReactNode
}

const WebScreenCard = ({ children }: WebScreenCardProps) => {
    const { isMobileView } = useVisualViewport()
    const { canGoBack, goBack, screenTransition } =
        useContext(NavigationContext)

    const { styles } = useStyles(webScreenCardStyles)

    const handleBack = () => {
        goBack()
    }

    const getAnimation = () => {
        switch (screenTransition) {
            case "initial":
                return {
                    type: AnimatedType.FADE,
                    from: 0,
                    to: 1,
                    duration: 500,
                }
            case "back":
                return {
                    type: AnimatedType.SLIDE_H,
                    from: 0,
                    to: 500,
                    speed: 20,
                    bounciness: 0,
                }
            case "forward":
                return {
                    type: AnimatedType.SLIDE_H,
                    from: 500,
                    to: 0,
                    speed: 15,
                    bounciness: 0,
                }
            case "bringBack":
                return {
                    type: AnimatedType.SLIDE_H,
                    from: -400,
                    to: 0,
                    speed: 15,
                    bounciness: 0,
                }
            default:
                return {
                    type: AnimatedType.FADE,
                    from: 0,
                    to: 1,
                    duration: 500,
                }
        }
    }

    return (
        <View style={styles.outerContainer}>
            {isMobileView && <NavHeader />}
            <View style={styles.innerContainer}>
                <AnimatedView
                    id="web_card"
                    style={styles.animationContainer}
                    animProps={getAnimation()}
                >
                    {children}
                </AnimatedView>
            </View>
            {canGoBack && (
                <View style={styles.backBtn}>
                    <BackButton align="left" onPress={handleBack} />
                </View>
            )}
        </View>
    )
}

const isEqual = (
    prevProps: Readonly<WebScreenCardProps>,
    nextProps: Readonly<WebScreenCardProps>,
) => {
    return prevProps.children === nextProps.children
}
export default React.memo(WebScreenCard, isEqual)
