import { Image, Linking, Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { PoweredByPollsStyles } from "./PoweredByPolls.styles"
import { Title } from "@components"
import { useTranslation } from "react-i18next"
import links from "@config/links"
import { AnalyticsEvent } from "@services/analytics"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"

const PoweredByPolls = () => {
    const { t } = useTranslation()
    const { styles } = useStyles(PoweredByPollsStyles)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const redirect = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapVotePoweredByPolls,
            },
        })

        Linking.openURL(links.pollsUrl)
    }

    return (
        <Pressable style={styles.container} onPress={redirect}>
            <View>
                <Image
                    source={require("@assets/images/Icon-1.png")}
                    style={styles.logo}
                    resizeMode={"contain"}
                />
            </View>
            <View>
                <Title style={styles.subtitle} title={t("poweredBy")} />
                <Title style={styles.title} title={t("thePolls")} />
            </View>
        </Pressable>
    )
}

export default PoweredByPolls
