import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    ScrollView,
    View,
} from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { Dispatch, useContext, useEffect, useState } from "react"
import { PollSelectListStyles } from "./PollSelectList.styles"
import { PollSelectListHeader } from "./components/PollSelectListHeader"
import { AnalyticsActionType, AnalyticsContext, PollContext } from "@contexts"
import { PollSelectItem } from "@components/PollSelectList/components/PollSelectItem"
import { PollOption } from "@types"
import { PollSelectListSkeleton } from "@components/PollSelectList/components/PollSelectListSkeleton"
import { AnalyticsEvent } from "@services/analytics"

interface PollSelectListProps {
    selected: PollOption["id"][]
    setSelected: Dispatch<PollOption["id"][]>
    loading: boolean
}

const PollSelectList = ({
    setSelected,
    selected,
    loading,
}: PollSelectListProps) => {
    const { styles } = useStyles(PollSelectListStyles)
    const {
        pollState: { data },
    } = useContext(PollContext)
    const [isScrollTop, setIsScrollTop] = useState<boolean>(false)
    const [preselectedIndex, setPreselectedIndex] = useState(0)

    const { analyticsDispatch } = useContext(AnalyticsContext)

    const handleCheck = (id: string) => {
        let selectedCopy = [...selected]
        const isChecked = selectedCopy.some(item => item === id)

        const event = isChecked
            ? AnalyticsEvent.tapDraftFilterUnselect
            : AnalyticsEvent.tapDraftFilterSelect

        if (isChecked) {
            selectedCopy = selectedCopy.filter(item => item !== id)
        } else {
            selectedCopy.push(id)
        }

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: event,
            },
        })

        setSelected(selectedCopy)
    }

    const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        setIsScrollTop(e.nativeEvent.contentOffset.y > 0)
    }

    const checkAll = () => {
        const optionIds = data.options.map(item => item.id)

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapDraftFilterAll,
            },
        })

        setSelected(optionIds)
    }

    const resetSelected = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapDraftFilterNone,
            },
        })

        setSelected([])
    }

    const preselectOptions = () => {
        const selectedCopy = [...selected]

        if (preselectedIndex >= 3 || !data.options[preselectedIndex]) return

        selectedCopy.push(data.options[preselectedIndex].id)

        setSelected(selectedCopy)
        setPreselectedIndex(preselectedIndex + 1)
    }

    useEffect(() => {
        if (data.options.length === 0) return

        const timeoutHandler = setTimeout(preselectOptions, 200)

        return () => {
            clearTimeout(timeoutHandler)
        }
    }, [data.options.length, preselectedIndex])

    const renderList = () => (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={styles.scrollContainer}
            onScroll={onScroll}
        >
            {data.options.map(item => {
                const isChecked = selected.some(id => id === item.id)

                if (item.type !== "defaultTemplate") return null

                return (
                    <PollSelectItem
                        key={item.id}
                        data={item}
                        checked={isChecked}
                        onCheck={handleCheck}
                    />
                )
            })}
        </ScrollView>
    )

    const renderSkeleton = () => (
        <View style={styles.scrollContainer}>
            <PollSelectListSkeleton />
            <PollSelectListSkeleton />
            <PollSelectListSkeleton />
            <PollSelectListSkeleton />
        </View>
    )

    return (
        <View style={styles.container}>
            <PollSelectListHeader
                counter={selected.length}
                shadowVisible={isScrollTop}
                handleFilterAll={checkAll}
                handleFilterNone={resetSelected}
            />
            {loading ? renderSkeleton() : renderList()}
        </View>
    )
}

export default PollSelectList
