import { Auth } from "aws-amplify"
import Logger from "@services/logger"
import { captureMessage } from "@services/sentry"

const isSessionValid = async (): Promise<boolean> => {
    try {
        const session = await Auth.currentSession()
        const isValid = session.isValid()
        return isValid
    } catch (e) {
        captureMessage(e)
        Logger.info(`SESSION INVALID ${e}`)
        return false
    }
}

export default isSessionValid
