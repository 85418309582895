import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface SignInScreenStyles extends Styles {
    center: ViewStyle
    phoneContainer: ViewStyle
}

export const signInScreenStyles: StyleFunction<SignInScreenStyles> = () => {
    return {
        center: {
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        phoneContainer: {
            // grab from theme
            marginTop: 50,
            marginBottom: 200,
        },
    }
}
