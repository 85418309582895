import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle } from "react-native"

export interface HeaderLogoStyles extends Styles {
    image: ImageStyle
}

export const HeaderLogoStyles: StyleFunction<HeaderLogoStyles> = () => {
    return {
        image: {},
        NATIVE: {
            image: {
                marginRight: 10,
            },
        },
    }
}
