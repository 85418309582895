import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ButtonStyles extends Styles {
    ctn: ViewStyle
    container: ViewStyle
    primary: ViewStyle
    secondary: ViewStyle
    text: ViewStyle
    btnContainer: ViewStyle
    btnTitle: TextStyle
    btnIcon: TextStyle
    spinner: ViewStyle
}

export const buttonStyles: StyleFunction<ButtonStyles> = ({ theme }) => {
    return {
        ctn: { marginVertical: 5 },
        container: {
            flex: 1,
            minHeight: 33,
            maxHeight: 33,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingHorizontal: 20,
            overflow: "hidden",
        },
        primary: {
            borderRadius: theme.cornerRadius.cta,
        },
        secondary: {
            borderRadius: theme.cornerRadius.cta,
        },
        text: {},
        btnContainer: {
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        },
        btnTitle: {
            ...theme.font.ctaPrimaryText,
        },
        btnIcon: {
            ...theme.font.ctaIcon,
            paddingHorizontal: 8,
        },
        spinner: {
            height: 25,
            width: 25,
            padding: 0,
            overflow: "hidden",
        },
        WEB: {
            btnTitle: {
                fontKerning: "none",
                userSelect: "none",
            },
            btnIcon: {
                userSelect: "none",
            },
            primary: {
                ...theme.shadow.cta.web,
            },
            secondary: {
                ...theme.shadow.cta.web,
            },
        },
        MOBILE: {
            btnTitle: {
                fontKerning: "none",
                userSelect: "none",
            },
            btnIcon: {
                userSelect: "none",
            },
            primary: {
                ...theme.shadow.cta.web,
            },
            secondary: {
                ...theme.shadow.cta.web,
            },
        },
        NATIVE: {
            container: {
                //backgroundColor: "#ff0000",
                marginLeft: 40,
                marginRight: 40,
                minHeight: 40,
                maxHeight: 40,
            },
            primary: {
                backgroundColor: theme.color.ctaPrimaryActiveBackground,
                ...theme.shadow.cta.native,
            },
            secondary: {
                backgroundColor: theme.color.ctaSecondaryActiveBackground,
                ...theme.shadow.cta.native,
            },
        },
    }
}
