import { UserContext } from "@contexts/UserDataProvider/UserReducer"
import {
    DrawerActionType,
    DrawerContext,
    PollActionType,
    ScreenShotContext,
} from "@contexts"
import { getPoll } from "@services/poll/getPoll"
import { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { PollAction } from "@contexts/PollContext/PollReducer"
import Logger from "@services/logger"
import { ThemeContext } from "@contexts/ThemeContext"
import { ModalName } from "@components/Drawer/content"

const LIMIT_OF_TRIES = 15

export const useGetPollFlow = (
    pollPending: boolean,
    pollDispatch: PollAction,
) => {
    const {
        userState: { deviceId },
    } = useContext(UserContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { screenShotDispatch } = useContext(ScreenShotContext)

    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()

    const refreshPollData = useCallback(() => {
        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
        pollDispatch({ type: PollActionType.REFRESH_POLL_DATA })
    }, [pollDispatch])

    const execute = useCallback(
        async (pollId: string, screenshotOnRefresh: boolean, repeats = 0) => {
            Logger.info(`FLOW: getPoll initialized id: ${pollId}`)

            const { isSuccess, data } = await getPoll({
                pollId,
                deviceId,
            })

            if (isSuccess && data) {
                pollDispatch({
                    type: PollActionType.SET_DATA,
                    payload: { data: data.poll, pollTitle: data.poll.title },
                })

                if (screenshotOnRefresh)
                    pollDispatch({
                        type: PollActionType.CREATE_PREVIEW_IMAGE,
                    })
            } else {
                if (pollPending) {
                    if (repeats < LIMIT_OF_TRIES) {
                        setTimeout(
                            execute,
                            500,
                            pollId,
                            screenshotOnRefresh,
                            repeats + 1,
                        )
                        return
                    } else {
                        drawerDispatch({
                            type: DrawerActionType.SHOW_MODAL,
                            payload: {
                                title: t("failedToCreate"),
                                message: t("pleaseReturn"),
                                image: theme.image.loadPollFailedGraphic,
                                imageSize: {
                                    height: 105,
                                    width: 131,
                                },
                                dismissible: false,
                                hasCloseButton: false,
                                identifier: ModalName.FAILED_TO_LOAD_POLL,
                            },
                        })
                        return
                    }
                }

                drawerDispatch({
                    type: DrawerActionType.SHOW_MODAL,
                    payload: {
                        title: t("oops"),
                        message:
                            t("bottomNavigation.couldntLoadThePoll") +
                            " " +
                            t("bottomNavigation.checkConnection"),
                        image: theme.image.loadPollFailedGraphic,
                        imageSize: {
                            height: 105,
                            width: 131,
                        },
                        buttonTitle: t("retry"),
                        dismissible: false,
                        onAction: refreshPollData,
                        identifier: ModalName.FAILED_TO_LOAD_POLL,
                    },
                })
            }

            pollDispatch({ type: PollActionType.POLL_DATA_REFRESHED })
        },
        [
            deviceId,
            drawerDispatch,
            pollDispatch,
            refreshPollData,
            screenShotDispatch,
            t,
        ],
    )

    return execute
}
