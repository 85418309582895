import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, PixelRatio, TextStyle } from "react-native"

export interface TextCardTemplateStyles extends Styles {
    image: ImageStyle
    title: TextStyle
}

export const TextCardTemplateStyles: StyleFunction<TextCardTemplateStyles> = ({
    theme,
}) => {
    const ratio = PixelRatio.get()

    return {
        image: {
            height: 100 / ratio,
            width: 165 / ratio,
            borderRadius: 10,
            marginRight: 10,
        },
        title: {
            ...theme.font.previewImageOptionTitle,
            fontSize: theme.font.previewImageOptionTitle.fontSize / ratio,
            lineHeight: theme.font.previewImageOptionTitle.lineHeight / ratio,
            flexWrap: "wrap",
            marginBottom: "auto",
        },
    }
}
