import {
    UserAction,
    UserActionType,
} from "@contexts/UserDataProvider/UserReducer"
import Logger from "@services/logger"
import { storageService } from "@services/storage"
import { CookieKeys, StorageKeys } from "@types"
import { Auth } from "aws-amplify"
import { setCookie } from "@services/cookies"
import { captureMessage } from "@services/sentry"

interface LogoutData {
    userDispatch: UserAction
}

const logoutUser = async ({ userDispatch }: LogoutData) => {
    try {
        userDispatch({
            type: UserActionType.LOG_OUT,
        })

        userDispatch({
            type: UserActionType.SET_IS_PHONE_NUMBER_ENTERED,
            payload: {
                isPhoneNumberEntered: false,
            },
        })

        userDispatch({
            type: UserActionType.GET_FINGERPRINT_DATA,
            payload: {
                forceCreateNew: true,
            },
        })

        await storageService.setItem(StorageKeys.USER_NAME, "")
        setCookie({ name: CookieKeys.IS_AUTHENTICATED, value: "false" })
        setCookie({ name: CookieKeys.USER_NAME, value: "" })

        await Auth.signOut()

        Logger.info("LOGGED OUT")
    } catch (error) {
        captureMessage(JSON.stringify(error))
        Logger.error(`LOGOUT ERROR ${error}`)
    }
}

export default logoutUser
