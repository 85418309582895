import Logger from "@services/logger"
import axios, { AxiosRequestConfig, HeadersDefaults } from "axios"
import { getAppConfig } from "@services/appConfig"
import { Auth } from "aws-amplify"
import { captureMessage } from "@services/sentry"

interface ExtendedHeaderProps extends HeadersDefaults {
    Authorization: string
}

const { apiUrl } = getAppConfig()

export const httpService = axios.create({
    baseURL: apiUrl,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    },
})

httpService.defaults.headers.common["Content-Type"] = "application/json"

httpService.interceptors.request.use(
    async (config): Promise<AxiosRequestConfig> => {
        try {
            try {
                const session = await Auth.currentSession()
                ;(config.headers as unknown as ExtendedHeaderProps)[
                    "Authorization"
                ] = `Bearer ${session.getIdToken().getJwtToken()}`
            } catch (e) {
                captureMessage(JSON.stringify(e))
                Logger.info(`INTERCEPTOR: ${JSON.stringify(e)}`)
            }
            return config
        } catch (e) {
            const message = (e as Error).message

            captureMessage(message)
            Logger.info(message)

            return Promise.reject(e)
        }
    },

    error => Promise.reject(error),
)
