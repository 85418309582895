import React, { useEffect, useMemo, useRef } from "react"
import { Animated, GestureResponderHandlers, TextInput } from "react-native"
import { PollOptionLink } from "@types"
import { useFocus } from "@helpers/hooks/useFocus"
import { PollsCardWrapper } from "@components/PollList/Items/PollsCardWrapper"
import ListCardTemplateContent from "@components/PollList/Items/LinkCardTemplate/ListCardTemplateContent"
import { getHostnameFromUrl } from "@helpers/getHostnameFromUrl"
import TextCardSkeleton from "@components/PollList/Items/TextCardTemplate/TextCardSkeleton"

interface LinkCardTemplateProps {
    isLoadingLink: boolean
    optionID: string
    data: PollOptionLink
    index: number
    itemEdit: {
        on: boolean
        index: number
    }
    itemEditMode: boolean
    editMode: boolean
    editVoteMode: boolean
    draftMode: boolean
    totalVoteCount: number
    checked: boolean
    doneTransition: boolean
    canUserRemove: boolean
    onPress: (index: number) => void
    onItemCreated: (index: number) => void
    onVote: (index: number) => void
    onValueChanged: (id: string) => void
    onSeeWhoVoted: () => void
    onDelete: () => void
    onOpenOptionLink: (id: string, index: number, url: string) => void
    panResponderCallbacks: GestureResponderHandlers
}

const LinkCardTemplate = ({
    isLoadingLink,
    data,
    index,
    itemEdit,
    itemEditMode,
    editMode,
    editVoteMode,
    draftMode,
    checked,
    totalVoteCount,
    doneTransition,
    canUserRemove,
    onPress,
    onItemCreated,
    onVote,
    onSeeWhoVoted,
    onDelete,
    onOpenOptionLink,
    panResponderCallbacks,
}: LinkCardTemplateProps) => {
    const { voteCount, url, siteName } = data
    const inputRef = useRef<TextInput>(null)

    const { setFocusTarget } = useFocus()

    const votesCount = voteCount[0]?.count || 0

    useEffect(() => {
        if (inputRef.current && itemEdit.index === index) {
            setFocusTarget("option", inputRef.current, "default")
            onItemCreated?.(index)
        }
    }, [inputRef, itemEdit.index])

    const handleOnVote = () => {
        if (!draftMode && !editMode) {
            onVote(index)
        }
    }

    const handleOnPress = () => {
        if (inputRef.current && !itemEdit.on) {
            setFocusTarget("option", inputRef.current, "default")
            onPress?.(index)
        } else {
            handleOnVote()
        }
    }

    const onLinkPress = () => {
        if (!url) return

        onOpenOptionLink(data.id, index, url)
    }

    const hostname = useMemo(() => {
        if (!url) return ""

        return getHostnameFromUrl(url)
    }, [url])

    const anim = useRef(new Animated.Value(0)).current

    Animated.timing(anim, {
        toValue: doneTransition ? 0.9 : 0,
        duration: 100,
        useNativeDriver: false,
    }).start()

    return (
        <PollsCardWrapper
            optionID={data.id}
            index={index}
            itemEdit={itemEdit}
            itemEditMode={itemEditMode}
            editMode={editMode}
            editVoteMode={editVoteMode}
            draftMode={draftMode}
            checked={checked}
            doneTransition={doneTransition}
            canUserRemove={canUserRemove}
            handleOnPress={handleOnPress}
            onItemCreated={onItemCreated}
            onDelete={onDelete}
            onVote={onVote}
            panResponderCallbacks={panResponderCallbacks}
        >
            {isLoadingLink ? (
                <TextCardSkeleton />
            ) : (
                <ListCardTemplateContent
                    data={data}
                    checked={checked}
                    totalVoteCount={totalVoteCount}
                    hostname={siteName ?? hostname}
                    votesCount={votesCount}
                    onSeeWhoVoted={onSeeWhoVoted}
                    draftMode={draftMode}
                    onLinkPress={onLinkPress}
                />
            )}
        </PollsCardWrapper>
    )
}

export default LinkCardTemplate
