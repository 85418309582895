import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface SuccessModalStyles extends Styles {
    container: ViewStyle
    animation: ViewStyle
    title: TextStyle
    message: TextStyle
}

export const SuccessModalStyles: StyleFunction<SuccessModalStyles> = ({
    theme,
}) => {
    return {
        container: {
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 10,
            paddingVertical: 10,
            width: 249,
            height: 249,
        },
        animation: {
            width: "100%",
            height: 91,
            margin: 0,
            marginBottom: 20,
        },
        title: {
            ...theme.font.notificationPrimaryText,
        },
        message: {
            ...theme.font.notificationSecondaryText,
            marginTop: 10,
        },
    }
}
