import React, { useContext } from "react"
import { Image } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { shareImageStyles } from "./ShareImage.styles"
import { ThemeContext } from "@contexts/ThemeContext"

export const ShareImage = () => {
    const { styles } = useStyles(shareImageStyles)
    const { theme } = useContext(ThemeContext)
    return (
        <Image
            source={{
                uri: theme.image.shareVoteGraphic,
            }}
            style={styles.container}
        />
    )
}
