import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface OptionCardStyles extends Styles {
    card: ViewStyle
}

export const CardStyles: StyleFunction<OptionCardStyles> = ({ theme }) => {
    return {
        card: {
            flex: 1,
            flexGrow: 1,
            flexDirection: "row",
            margin: 5,
            backgroundColor: theme.color.optionCardBackground,
            borderRadius: theme.cornerRadius.optionCard,
        },

        MOBILE: {
            card: {
                ...theme.shadow.voteCard.web,
            },
        },
        WEB: {
            card: {
                ...theme.shadow.voteCard.web,
            },
        },
        NATIVE: {
            card: {
                ...theme.shadow.voteCard.native,
            },
        },
    }
}
