import { httpService } from "@services/http"
import Logger from "@services/logger"
import { PollSettings } from "@types"
import { AxiosError, AxiosResponse } from "axios"
import { captureMessage } from "@services/sentry"

export type PropsToUpdate = {
    name?: string
    userDefaults?: {
        pollSettings: {
            multipleVotes: boolean
            editPoll: boolean
            seeWhoVoted: boolean
        }
    }
}

type UpdatePayload = { user: PropsToUpdate }

type UpdatedUser = {
    name: string
    id: string
    phoneNumber?: string
    signInMethod?: string
    cognitoId?: string
    userDefaults: { pollSettings: PollSettings }
}
export interface UpdateUserProps {
    isAuthenticated: boolean
    propsToUpdate: PropsToUpdate
    deviceId: string
}

interface EndpointResponse {
    user: UpdatedUser
}

export const updateUser = async ({
    isAuthenticated,
    propsToUpdate,
    deviceId,
}: UpdateUserProps) => {
    try {
        const payload = {
            user: propsToUpdate,
        }

        const {
            status,
            data: { user },
        } = await httpService.put<
            UpdatePayload,
            AxiosResponse<EndpointResponse>
        >(`/${isAuthenticated ? "me" : "voter"}`, payload, {
            headers: { "polls-device-id": deviceId },
        })

        return { isSuccess: status >= 200 && status < 300, data: user }
    } catch (e) {
        Logger.error("could not update user")
        captureMessage(e)
        return { isSuccess: false, error: e as AxiosError }
    }
}
