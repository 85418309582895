import { Checkbox } from "@components"
import { UserContext } from "@contexts"
import { PollContext } from "@contexts/PollContext"
import useStyles from "@helpers/hooks/useStyles"
import { PollRecentVotes } from "@types"
import React, { useContext, useMemo } from "react"
import { Pressable, View } from "react-native"
import { UserVoteIcon } from "./"
import { SeeWhoVotedIconsStyle } from "./SeeWhoVotedIcons.styles"

interface SeeWhoVotedIconsProps {
    showSeeWhoVotedDrawer: () => void
    recentVotes: PollRecentVotes[] | []
    checked: boolean
}

const SeeWhoVotedIcons = ({
    recentVotes,
    checked,
    showSeeWhoVotedDrawer,
}: SeeWhoVotedIconsProps) => {
    const {
        pollState: { data },
    } = useContext(PollContext)
    const { styles } = useStyles(SeeWhoVotedIconsStyle)
    const {
        userState: { userId },
    } = useContext(UserContext)

    const otherUserVotes = useMemo(() => {
        const RECENT_VOTERS_LIMIT = 3

        let otherUserVotes: PollRecentVotes[] = recentVotes.filter(
            vote => vote.voter.id !== userId,
        )

        const recentVotesIncludesUser = recentVotes.some(
            vote => vote.voter.id === userId,
        )
        const currentUserHasVoted = checked || recentVotesIncludesUser

        if (
            currentUserHasVoted &&
            otherUserVotes.length > RECENT_VOTERS_LIMIT
        ) {
            otherUserVotes = otherUserVotes.slice(0, 2)
        }
        if (!currentUserHasVoted && recentVotes.length > RECENT_VOTERS_LIMIT) {
            otherUserVotes = recentVotes.slice(0, 3)
        }

        otherUserVotes = otherUserVotes.reverse()

        return otherUserVotes
    }, [recentVotes, userId, checked])

    if (!data.settings?.seeWhoVoted || (!checked && !otherUserVotes.length))
        return null

    return (
        <Pressable style={styles.container} onPress={showSeeWhoVotedDrawer}>
            {otherUserVotes &&
                otherUserVotes.map(item => {
                    return <UserVoteIcon key={item.id} item={item} />
                })}
            {checked && (
                <View style={styles.avatar}>
                    <Checkbox
                        containerStyle={styles.checkboxContainer}
                        size={18}
                        checked={true}
                        onPress={showSeeWhoVotedDrawer}
                    />
                </View>
            )}
        </Pressable>
    )
}

export default SeeWhoVotedIcons
