import { Text, View } from "react-native"
import React from "react"
import { Icon } from "@assets/icons"
import useStyles from "@helpers/hooks/useStyles"
import { PhoneNumberTextStyles } from "./PhoneNumberText.styles"
import formatPhoneNumber from "@helpers/phoneNumberFormatter"

interface PhoneNumberTextProps {
    phoneNumber: string
}

const PhoneNumberText = ({ phoneNumber }: PhoneNumberTextProps) => {
    const { styles, theme } = useStyles(PhoneNumberTextStyles)

    return (
        <View style={styles.container}>
            <Icon
                style={styles.icon}
                name={theme.image.profilePhoneNumberIcon}
                size={14}
            />
            <Text style={styles.number}>{formatPhoneNumber(phoneNumber)}</Text>
        </View>
    )
}

export default PhoneNumberText
