import React, { useCallback, useEffect, useState } from "react"
import { Pressable, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { cardButtonStyles } from "./ToggleCardButton.styles"
import { Icon } from "@assets/icons"
import { CheckboxAnimated } from "@components"

interface CardProps {
    title: string
    subtitle?: string
    icon: string
    isChecked: boolean
    onPress?: (checked: boolean) => void
}

const ToggleCardButton = ({
    title,
    subtitle,
    icon,
    isChecked,
    onPress,
}: CardProps) => {
    const { styles } = useStyles(cardButtonStyles)
    const [checked, setChecked] = useState(isChecked)

    const handleCheckBoxPress = useCallback(() => {
        setChecked(!checked)
        onPress && onPress(!checked)
    }, [checked, onPress])

    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked])

    return (
        <Pressable onPress={handleCheckBoxPress}>
            <View style={styles.card}>
                <View style={styles.cardContent}>
                    <View style={styles.cardTitleContainer}>
                        <Icon
                            style={styles.cardTitleIcon}
                            name={icon}
                            size={18}
                        />
                        <Text style={styles.cardTitle}>{title}</Text>
                    </View>
                    {subtitle && (
                        <Text style={styles.cardSubTitle}>{subtitle}</Text>
                    )}
                </View>
                <View style={styles.cardActions}>
                    <View style={styles.checkboxContainer}>
                        <CheckboxAnimated
                            checked={checked}
                            onPress={handleCheckBoxPress}
                            size={27.4}
                        />
                    </View>
                </View>
            </View>
        </Pressable>
    )
}

export default ToggleCardButton
