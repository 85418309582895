import { Image, Linking, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { CreateOwnPollsStyles } from "./CreateOwnPolls.styles"
import { EncodedImages } from "@assets/images/encodedImages"
import { Button, Title } from "@components"
import links from "@config/links"
import { t } from "i18next"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"
import { AnalyticsEvent } from "@services/analytics"

const CreateOwnPolls = () => {
    const { styles } = useStyles(CreateOwnPollsStyles)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const getPolls = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapPostVoteDownloadPollsDownload,
            },
        })

        Linking.openURL(links.getPolls)
    }

    return (
        <View style={styles.container}>
            <Image
                source={{ uri: EncodedImages.pollsLogoBig }}
                style={styles.image}
            />
            <Title title={t("createOwnPolls")} style={styles.title} />
            <Title title={t("availableOn")} style={styles.subtitle} />
            <Button
                title={t("download")}
                onPress={getPolls}
                style={styles.button}
            />
        </View>
    )
}

export default CreateOwnPolls
