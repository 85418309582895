import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle } from "react-native"

export interface SplashScreenStyles extends Styles {
    container: ImageStyle
}

export const SplashScreenStyles: StyleFunction<SplashScreenStyles> = () => {
    return {
        container: {
            width: "100%",
            height: "100%",
        },
    }
}
