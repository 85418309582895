import { httpService } from "@services/http"
import { captureMessage } from "@services/sentry"
import { AxiosRequestConfig } from "axios"
import { Buffer } from "buffer"

interface Response {
    status: number
    data: Blob
}

interface proxyProps {
    url: string
}

export const proxy = async ({ url }: proxyProps) => {
    const requestConfig: AxiosRequestConfig = {
        params: {
            urlBase64: Buffer.from(url, "utf-8").toString("base64"),
        },
        headers: { accept: "image/*" },
        responseType: "blob",
    }

    try {
        const response = await httpService.get<proxyProps, Response>(
            "proxy",
            requestConfig,
        )

        return {
            isSuccess: response.status >= 200 && response.status < 300,
            data: response.data,
        }
    } catch (e) {
        captureMessage(e)
        return { isSuccess: false, data: null }
    }
}
