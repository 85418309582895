export interface AnalyticsConfig {
    maxEventNameLength: number
    convertEventNameToSnake: boolean

    maxParameterCount: number
    maxParameterLength: number
    maxValueLength: number
    convertParameterToString: boolean

    maxUserPropertyCount: number
    maxUserPropertyKeyLength: number
    maxUserPropertyValueLength: number
    convertUserPropertyToString: boolean
    convertUserPropertyKeyToSnake: boolean
}

// we need to make sure we conform to them or the events won't fire
// these limits are enforced by firebase
export const firebaseAnalyticsConfig: AnalyticsConfig = {
    maxEventNameLength: 40,
    convertEventNameToSnake: false,

    maxParameterCount: 25,
    maxParameterLength: 40,
    maxValueLength: 100,
    convertParameterToString: false,

    maxUserPropertyCount: 25,
    maxUserPropertyKeyLength: 24,
    maxUserPropertyValueLength: 36,
    convertUserPropertyToString: true,
    convertUserPropertyKeyToSnake: false,
}

export const amplitudeAnalyticsConfig: AnalyticsConfig = {
    maxEventNameLength: 1024,
    convertEventNameToSnake: false,

    maxParameterCount: 2000,
    maxParameterLength: 1024,
    maxValueLength: 1024,
    convertParameterToString: false,

    maxUserPropertyCount: 1000,
    maxUserPropertyKeyLength: 1024,
    maxUserPropertyValueLength: 1024,
    convertUserPropertyToString: false,
    convertUserPropertyKeyToSnake: false,
}

// amplitude allows JSON String to be 2048 characters
// { "key": "value" }
// 2 + keys*(2+length) + values*(2+length) = 2048

export const airbridgeAnalyticsConfig: AnalyticsConfig = {
    maxEventNameLength: 128,
    convertEventNameToSnake: false,

    maxParameterCount: 20,
    maxParameterLength: 40,
    maxValueLength: 55,
    convertParameterToString: true,

    maxUserPropertyCount: 100,
    maxUserPropertyKeyLength: 128,
    maxUserPropertyValueLength: 1024,
    convertUserPropertyToString: false,
    convertUserPropertyKeyToSnake: true,
}
