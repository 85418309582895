import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface VoterAddNameDrawerStyles extends Styles {
    container: ViewStyle
    buttonsContainer: ViewStyle
    image: ImageStyle
    title: TextStyle
    subTitle: TextStyle
    input: ViewStyle
}

export const voterAddNameDrawerStyles: StyleFunction<
    VoterAddNameDrawerStyles
> = ({ theme }) => {
    return {
        container: {
            alignItems: "center",
            paddingTop: 20,
            paddingBottom: 15,
        },
        image: {
            width: 140,
            height: 110,
            marginBottom: 20,
        },
        buttonsContainer: {
            marginTop: 10,
        },
        title: {
            ...theme.font.voterAddNameTitle,
            marginBottom: 10,
        },
        subTitle: {
            ...theme.font.voterAddNameSubtitle,
            marginBottom: 15,
        },
        input: {
            minWidth: 200,
        },
        WEB: {
            container: {
                paddingHorizontal: 35,
            },
            image: {
                width: 140,
                height: 130,
            },
        },
    }
}
