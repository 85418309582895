import React from "react"
import { StyleProp, TextStyle, Text } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { titleStyles } from "./Title.styles"

interface TitleProps {
    title: string
    numberOfLines?: number
    style?: StyleProp<TextStyle>
    color?: string
}

const Title = ({ title, style, color, numberOfLines = 1 }: TitleProps) => {
    const { styles } = useStyles(titleStyles)
    const customColor = color ? { color } : null
    return (
        <Text
            style={[styles.text, style, customColor]}
            numberOfLines={numberOfLines}
            ellipsizeMode={"tail"}
        >
            {title}
        </Text>
    )
}

export default Title
