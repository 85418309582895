import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface FormErrorStyles extends Styles {
    innerContainer: ViewStyle
    container: ViewStyle
    shadow: ViewStyle
    text: TextStyle
}

export const formErrorStyles: StyleFunction<FormErrorStyles> = ({ theme }) => {
    return {
        WEB: {
            innerContainer: {
                width: 206,
                height: 35,
                alignSelf: "center",
                justifyContent: "center",
                borderRadius: 8,
            },
            shadow: {
                ...theme.shadow.errorBoxShadow.web,
            },
        },
        innerContainer: {},
        shadow: {},
        container: {
            textAlign: "center",
            alignItems: "center",
            height: 35,
            marginVertical: 5,
            marginBottom: 30,
        },
        NATIVE: {
            container: {
                height: 20,
            },
        },
        text: {
            ...theme.font.errorText,
        },
    }
}
