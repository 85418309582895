import { Animated, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useEffect, useRef } from "react"
import { SuccessModalStyles } from "./SuccessModal.styles"
import { LottieAnimation } from "@components"

interface SuccessModalProps {
    title: string
    message?: string
    animation?: any
    animationStart?: number
    animationEnd?: number
    animationScale?: number
    duration?: number
}

const SuccessModal = ({
    title,
    message,
    animation,
    animationStart = 0,
    animationEnd = 1,
    animationScale = 1,
    duration = 300,
}: SuccessModalProps) => {
    const { styles, theme } = useStyles(SuccessModalStyles)

    const anim = useRef(new Animated.Value(animationStart)).current

    const runAnimation = () => {
        Animated.timing(anim, {
            toValue: animationEnd,
            duration: duration,
            useNativeDriver: false,
        }).start()
    }

    useEffect(() => {
        runAnimation()
    }, [])

    return (
        <View style={styles.container}>
            <LottieAnimation
                progress={anim}
                source={animation ?? theme.lottie.success}
                style={[
                    styles.animation,
                    {
                        transform: [{ scale: animationScale }],
                    },
                ]}
                autoPlay
                autoSize={true}
                resizeMode={"cover"}
                loop={false}
            />
            <Text style={styles.title}>{title}</Text>
            {message && <Text style={styles.message}>{message}</Text>}
        </View>
    )
}

export default SuccessModal
