import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ProfileAvatarStyles extends Styles {
    container: ViewStyle
    avatar: ViewStyle
    smallAvatar: ViewStyle
    smallAvatarIcon: TextStyle
    avatarIcon: TextStyle
    smallAvatarLetter: TextStyle
    avatarLetter: TextStyle
    userNameContainer: ViewStyle
    userName: TextStyle
    userNameIcon: TextStyle
}

export const profileStyles: StyleFunction<ProfileAvatarStyles> = ({
    theme,
}) => {
    return {
        container: {
            flex: 1,
            margin: 15,
            alignItems: "center",
            justifyContent: "center",
        },
        avatar: {
            width: theme.layout.profileAvatarCircle,
            height: theme.layout.profileAvatarCircle,
            borderRadius: theme.layout.profileAvatarCircle,
            marginTop: 10,
            marginBottom: 10,
            backgroundColor: theme.color.profileAvatarBackground,
            alignItems: "center",
            justifyContent: "center",
        },
        smallAvatar: {
            width: theme.layout.smallProfileAvatarCircle,
            height: theme.layout.smallProfileAvatarCircle,
            borderRadius: 50,
            marginTop: 10,
            marginBottom: 10,
            backgroundColor: theme.color.profileAvatarBackground,
            alignItems: "center",
            justifyContent: "center",
        },
        avatarIcon: {
            ...theme.font.profileAvatarIcon,
            paddingLeft: 2,
        },
        smallAvatarIcon: {
            ...theme.font.profileAvatarIconSmall,
            paddingLeft: 2,
        },
        avatarLetter: {
            ...theme.font.profileAvatarLetter,
        },
        smallAvatarLetter: {
            ...theme.font.profileAvatarLetterSmall,
        },
        userNameContainer: {
            flex: 1,
            flexDirection: "row",
            maxWidth: "100%",
            margin: 0,
        },
        userName: {
            ...theme.font.profileUserNameText,
        },
        userNameIcon: {
            margin: 2,
            marginLeft: 8,
            ...theme.font.profileUserNameIcon,
        },
    }
}
