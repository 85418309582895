import { UserContext } from "@contexts/UserDataProvider/UserReducer"
import { PollActionType } from "@contexts"
import { useCallback, useContext } from "react"
import { PollAction } from "@contexts/PollContext/PollReducer"
import Logger from "@services/logger"
import { getPollsForUser } from "@services/poll/getPollsForUser"

export const useGetPollHistoryFlow = (pollDispatch: PollAction) => {
    const {
        userState: { deviceId },
    } = useContext(UserContext)

    return useCallback(async () => {
        Logger.info("FLOW: getPollHistory initialized")

        const { isSuccess, data } = await getPollsForUser({
            deviceId,
        })

        if (isSuccess && data) {
            pollDispatch({
                type: PollActionType.POLL_HISTORY_REFRESHED,
                payload: data.polls,
            })
        }
    }, [deviceId, pollDispatch])
}
