import { View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { BottomNavigatorWrapperStyles } from "./BottomNavigatorWrapper.styles"
import { SaveChangesBanner } from "../SaveChangesBanner"
import { BottomErrorMessage } from "../BottomErrorMessage"
import { HintModal } from "@components/BottomNavigator/HintModal"

interface BottomNavigatorWrapperProps {
    children: React.ReactNode
    saveChangesActive?: boolean
}

const BottomNavigatorWrapper = ({
    children,
    saveChangesActive,
}: BottomNavigatorWrapperProps) => {
    const { styles } = useStyles(BottomNavigatorWrapperStyles)

    return (
        <>
            <HintModal />
            <View style={styles.outerContainer}>
                <BottomErrorMessage />
                <SaveChangesBanner active={saveChangesActive} />
                <View style={styles.container} pointerEvents={"auto"}>
                    {children}
                </View>
            </View>
        </>
    )
}

export default BottomNavigatorWrapper
