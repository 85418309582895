import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface CustomerInfoModalStyles extends Styles {
    container: ViewStyle
    header: TextStyle
    divider: ViewStyle
    image: ImageStyle
}

export const CustomerInfoModalStyles: StyleFunction<
    CustomerInfoModalStyles
> = ({ theme }) => {
    return {
        container: {
            padding: 25,
            marginLeft: 6,
        },
        header: {
            ...theme.font.customerInfoModalHeader,
            marginVertical: 10,
            textAlign: "center",
        },
        divider: {
            maxWidth: "100%",
            height: 3,
            borderRadius: 3,
            backgroundColor: theme.color.customerInfoModalDivider,
            marginBottom: 30,
            marginHorizontal: 20,
        },
        image: {
            width: 355,
            height: 134,
        },
    }
}
