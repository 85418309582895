import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface SettingsOptionCardStyles extends Styles {
    optionCard: ViewStyle
    optionTitle: TextStyle
    optionContainer: ViewStyle
}

export const SettingsOptionCardStyles: StyleFunction<
    SettingsOptionCardStyles
> = ({ theme }) => {
    return {
        optionCard: {
            minWidth: 165,
            maxWidth: 165,
            height: 45,
        },
        optionTitle: {
            ...theme.font.createPollSendSettingsOption,
        },
        optionContainer: {
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingHorizontal: 10,
        },
        NATIVE: {
            optionCard: {
                minWidth: 155,
            },
        },
    }
}
