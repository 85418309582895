import React from "react"
import { Checkbox } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { listSkeletonItemStyles } from "./ListSkeletonItem.styles"
import { PollOptionCard } from "@components"
import SkeletonLoader from "expo-skeleton-loader"

interface skeletonItem {
    checked: boolean
}

const ListSkeletonItem = ({ checked }: skeletonItem) => {
    const { styles, theme } = useStyles(listSkeletonItemStyles)

    return (
        <PollOptionCard
            iconContent={
                <Checkbox checked={checked} onPress={() => null} size={40} />
            }
        >
            <SkeletonLoader
                boneColor={theme.color.skeletonLight}
                highlightColor={theme.color.skeletonHighlight}
                duration={800}
            >
                <SkeletonLoader.Container style={styles.skeletonContent}>
                    <SkeletonLoader.Item
                        style={{
                            width: 250,
                            height: 11,
                            borderRadius: 5.5,
                            marginBottom: 10,
                        }}
                    ></SkeletonLoader.Item>
                    <SkeletonLoader.Item
                        style={{
                            width: 130,
                            height: 11,
                            borderRadius: 5.5,
                        }}
                    ></SkeletonLoader.Item>
                </SkeletonLoader.Container>
            </SkeletonLoader>
        </PollOptionCard>
    )
}

export default ListSkeletonItem
