import * as yup from "yup"
import { AnyObject, Maybe } from "yup/lib/types"

yup.addMethod<yup.StringSchema>(
    yup.string,
    "minMultibyte",
    function (length: number, message: string) {
        return this.test(
            "test-min-multibyte-length",
            function (value: string | undefined) {
                const { path, createError } = this

                if (!value) return true

                return value.length < length
                    ? createError({ path, message: message })
                    : true
            },
        )
    },
)

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType,
    > extends yup.BaseSchema<TType, TContext, TOut> {
        minMultibyte(
            length: number,
            message: string,
        ): StringSchema<TType, TContext>
    }
}

export default yup
