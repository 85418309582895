import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface InfoLinkStyles extends Styles {
    container: ViewStyle
    icon: TextStyle
    iconContainer: ViewStyle
    titleContainer: ViewStyle
    title: TextStyle
    subTitle: TextStyle
}

export const InfoLinkStyles: StyleFunction<InfoLinkStyles> = ({ theme }) => {
    return {
        container: {
            flexDirection: "row",
            marginBottom: 16,
        },
        iconContainer: {
            width: 44,
            height: 44,
            backgroundColor: theme.color.customerInfoModalItemIconContainer,
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
        },
        icon: {
            color: theme.color.customerInfoModalItemIcon,
        },
        titleContainer: {
            alignItems: "flex-start",
            justifyContent: "center",
            marginLeft: 10,
        },
        title: {
            ...theme.font.customerInfoModalItemTitle,
            marginBottom: 1,
        },
        subTitle: {
            ...theme.font.customerInfoModalItemSubtitle,
            marginTop: 1,
        },
    }
}
