import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface DefaultCardTemplateStyles extends Styles {
    container: ViewStyle
    topRow: ViewStyle
    imageTextRow: ViewStyle
    bottomRow: ViewStyle
    textColumn: ViewStyle
    row: ViewStyle
    image: ImageStyle
    title: TextStyle
    subtitleContainer: ViewStyle
    subtitle: TextStyle
    details: TextStyle
    bottomTextRow: ViewStyle
    counterText: TextStyle
    link: TextStyle
    linkIcon: TextStyle
}

export const DefaultCardTemplateStyles: StyleFunction<
    DefaultCardTemplateStyles
> = ({ theme }) => {
    return {
        container: {
            width: "100%",
            paddingHorizontal: 15,
            paddingVertical: 13,
        },
        topRow: {
            flex: 1,
            width: "100%",
            flexDirection: "row",
        },
        imageTextRow: {
            flexDirection: "row",
        },
        bottomRow: {
            paddingTop: 7,
        },
        textColumn: {
            flex: 3,
            justifyContent: "space-between",
            marginRight: 8,
        },
        row: {
            flexDirection: "row",
            alignItems: "center",
        },
        image: {
            width: 102,
            height: 70,
            borderRadius: 6,
            marginRight: 8,
        },
        title: {
            ...theme.font.defaultCardTemplateTitle,
            flexWrap: "wrap",
            flex: 2,
            marginVertical: 0,
        },
        subtitleContainer: {
            flex: 2,
            justifyContent: "space-evenly",
        },
        subtitle: {
            ...theme.font.defaultCardTemplateSubtitle,
            flexWrap: "nowrap",
        },
        details: {
            ...theme.font.defaultCardTemplateDescription,
        },
        bottomTextRow: {
            justifyContent: "space-between",
            marginTop: 6,
        },
        counterText: {
            ...theme.font.optionCardTextVoteCount,
        },
        link: {
            ...theme.font.defaultCardTemplateLink,
            marginVertical: 0,
            textAlignVertical: "bottom",
        },
        linkIcon: {
            color: theme.font.defaultCardTemplateLink.color,
            marginLeft: 5,
        },
    }
}
