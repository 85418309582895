import { httpService } from "@services/http"
import { PollData } from "@types"
import { captureMessage } from "@services/sentry"
import { RequestPollOption } from "@helpers/getRequestPoll"

interface Poll {
    poll: PollData
}

interface PublishPollProps {
    deviceId: string
    poll: PublishPollData
}

interface PublishPollData {
    id: PollData["id"]
    version: PollData["version"]
    minCompatibleVersion: PollData["minCompatibleVersion"]
    timeZoneId: PollData["timeZoneId"]
    settings: PollData["settings"]
    title: PollData["title"]
    options: RequestPollOption[]
}

export const publishPoll = async ({ deviceId, poll }: PublishPollProps) => {
    const requestConfig = {
        headers: {
            "polls-device-id": deviceId || "",
        },
    }

    try {
        const pollResponse = await httpService.put<Poll>(
            `polls/${poll.id}/publish`,
            { poll },
            requestConfig,
        )

        return {
            isSuccess: pollResponse.status >= 200 && pollResponse.status < 300,
            data: pollResponse.data.poll,
        }
    } catch (e) {
        const error = e as Error
        captureMessage(error.message)
        return { isSuccess: false }
    }
}
