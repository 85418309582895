import React, { ReactNode, useContext } from "react"
import { Button } from "@components"
import { NavigationContext } from "@contexts"
import useStyles from "@helpers/hooks/useStyles"
import { BottomNavigatorWrapper } from "./BottomNavigatorWrapper"
import { bottomNavigatorStyles } from "./BottomNavigator.styles"
import {
    BottomBarColumnsProps,
    BottomBarOption,
} from "@components/BottomNavigator/config"
import { AnalyticsEvent } from "@services/analytics"
import { useCanEditPoll } from "@helpers/hooks/useCanEditPoll"
import { ScreenName } from "@screens"

interface BottomNavigatorProps {
    config: BottomBarColumnsProps
    canSubmitPoll?: boolean
    onAction?: (id: BottomBarOption) => void
    hideIcons?: boolean
    children: ReactNode
}

const BottomNavigator: React.FC<BottomNavigatorProps> = ({
    config,
    canSubmitPoll,
    onAction,
    hideIcons,
    children,
}) => {
    const { styles, theme } = useStyles(bottomNavigatorStyles)
    const { canEdit } = useCanEditPoll()

    const { currentScreen } = useContext(NavigationContext)

    const handleOnPress = (id: BottomBarOption) => () => {
        onAction?.(id)
    }

    const renderButton = (id: BottomBarOption) => {
        if (hideIcons) return null

        switch (id) {
            case BottomBarOption.DISCARD:
                return (
                    <Button
                        key={id}
                        variant="text"
                        style={styles.button}
                        iconStyle={styles.errorIcon}
                        iconLeft={theme.image.navBarTrash}
                        onPress={handleOnPress(id)}
                        analyticsEvent={AnalyticsEvent.tapDraftDiscard}
                    />
                )
            case BottomBarOption.EDIT:
                return (
                    <Button
                        key={id}
                        variant="text"
                        style={styles.button}
                        iconLeft={theme.image.navBarEdit}
                        onPress={handleOnPress(id)}
                        disabled={!canEdit}
                        analyticsEvent={AnalyticsEvent.tapVoteEdit}
                    />
                )
            case BottomBarOption.SHARE:
                return (
                    <Button
                        key={id}
                        variant="text"
                        style={styles.button}
                        iconLeft={theme.image.navBarShare}
                        onPress={handleOnPress(id)}
                        analyticsEvent={AnalyticsEvent.tapVoteSharePoll}
                    />
                )
            case BottomBarOption.HOME:
                return (
                    <Button
                        key={id}
                        variant="text"
                        style={styles.button}
                        iconLeft={theme.image.navBarHome}
                        onPress={handleOnPress(id)}
                        analyticsEvent={AnalyticsEvent.tapVoteHome}
                    />
                )
            case BottomBarOption.PROFILE:
                return (
                    <Button
                        key={id}
                        variant="text"
                        style={styles.button}
                        iconLeft={theme.image.navBarProfile}
                        onPress={handleOnPress(id)}
                        analyticsEvent={
                            currentScreen === ScreenName.CREATE_POLL_SCREEN
                                ? AnalyticsEvent.tapDraftProfile
                                : AnalyticsEvent.tapVoteProfile
                        }
                    />
                )
            case BottomBarOption.INFO:
                return (
                    <Button
                        key={id}
                        variant="text"
                        style={styles.button}
                        iconLeft={theme.image.navBarInfo}
                        onPress={handleOnPress(id)}
                        analyticsEvent={AnalyticsEvent.tapVoteInfo}
                    />
                )
        }
    }

    return (
        <BottomNavigatorWrapper saveChangesActive={canSubmitPoll}>
            {Object.entries(config.leftColumn).map(([, item]) =>
                renderButton(item),
            )}
            {children}
            {Object.entries(config.rightColumn).map(([, item]) =>
                renderButton(item),
            )}
        </BottomNavigatorWrapper>
    )
}

export default BottomNavigator
