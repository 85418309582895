import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface DiscardModalStyles extends Styles {
    container: ViewStyle
    subheader: TextStyle
    card: ViewStyle
    buttons: ViewStyle
    title: TextStyle
    buttonDiscard: ViewStyle
    buttonDiscardText: TextStyle
    buttonCancel: ViewStyle
    buttonCancelText: TextStyle
}

export const discardModalStyles: StyleFunction<DiscardModalStyles> = ({
    theme,
}) => {
    return {
        container: {
            alignItems: "center",
            paddingHorizontal: 10,
            paddingVertical: 10,
        },
        buttons: {
            flex: 1,
            justifyContent: "space-evenly",
        },
        title: {
            ...theme.font.discardModalTitle, //perhaps notificationPrimaryText
            marginBottom: 10,
        },
        buttonDiscard: {},
        buttonDiscardText: {
            ...theme.font.discardModalLogoutButtonText,
        },
        buttonCancel: {},
        buttonCancelText: {
            ...theme.font.discardModalCancelButtonText,
        },
        subheader: {
            ...theme.font.discardModalSubtile,
            paddingBottom: 10,
        },
        card: {
            width: "100%",
            padding: 20,
            borderRadius: theme.cornerRadius.modal,
        },
        NATIVE: {
            container: {
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
            },
            card: {
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 20,
                padding: 10,
                backgroundColor: theme.color.modalBackground,
                ...theme.shadow.modal.native,
            },
        },
        MOBILE: {
            container: {
                flex: 1,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
            },
            card: {
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                margin: 10,
                padding: 10,
                backgroundColor: theme.color.modalBackground,
                ...theme.shadow.modal.web,
            },
            buttonDiscard: { marginTop: 10 },
        },
        WEB: {
            container: {
                padding: 0,
            },
            card: {
                backgroundColor: theme.color.modalBackground,
                ...theme.shadow.modal.native,
                padding: 20,
            },
            subheader: {
                ...theme.font.discardModalSubtileDesktop,
                marginBottom: 40,
            },
            buttons: {
                flexDirection: "row-reverse",
            },
            buttonDiscard: {
                marginLeft: 10,
                marginRight: 10,
            },
            buttonDiscardText: {
                ...theme.font.discardModalSmallLogoutButtonText,
            },
            buttonCancel: {
                marginLeft: 10,
                marginRight: 10,
            },
            buttonCancelText: {
                ...theme.font.discardModalSmallCancelButtonText,
            },
        },
    }
}
