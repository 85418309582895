import React, { ReactNode, useRef } from "react"
import {
    Animated,
    GestureResponderHandlers,
    Pressable,
    View,
} from "react-native"
import { CheckboxAnimated } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { PollsCardWrapperStyles } from "./PollsCardWrapper.styles"
import { Icon } from "@assets/icons"
import { LottieAnimation } from "@components/LottieAnimation"
import { commonStyles } from "@styles/Main.styles"

interface PollsCardTemplateProps {
    children: ReactNode
    optionID: string
    index: number
    itemEdit: {
        on: boolean
        index: number
    }
    itemEditMode: boolean
    editMode: boolean
    editVoteMode: boolean
    draftMode: boolean
    checked: boolean
    doneTransition: boolean
    canUserRemove: boolean
    handleOnPress: () => void
    onItemCreated: (index: number) => void
    onVote: (index: number) => void
    onDelete: () => void
    panResponderCallbacks: GestureResponderHandlers
}

const PollsCardWrapper = ({
    children,
    optionID,
    index,
    itemEdit,
    itemEditMode,
    editMode,
    editVoteMode,
    draftMode,
    checked,
    doneTransition,
    canUserRemove,
    handleOnPress,
    onVote,
    onDelete,
    panResponderCallbacks,
}: PollsCardTemplateProps) => {
    const anim = useRef(new Animated.Value(0)).current

    const { styles, theme } = useStyles(PollsCardWrapperStyles)
    const { styles: defaultStyles } = useStyles(commonStyles)

    const handleOnVote = () => {
        if (!draftMode && !editMode && !itemEditMode) {
            onVote(index)
        }
    }

    const handleOnDelete = () => {
        if (canUserRemove) onDelete()
    }

    Animated.timing(anim, {
        toValue: doneTransition ? 0.9 : 0,
        duration: 100,
        useNativeDriver: false,
    }).start()

    return (
        <View style={styles.container}>
            {draftMode ? (
                <View
                    style={styles.dragIconContainer}
                    {...panResponderCallbacks}
                >
                    {doneTransition ? (
                        <LottieAnimation
                            progress={anim}
                            source={theme.lottie.checkmarkDone}
                            style={styles.doneAnim}
                        />
                    ) : (
                        <Icon
                            name={theme.image.optionCardDrag}
                            style={styles.dragIcon}
                            size={21}
                        />
                    )}
                </View>
            ) : editMode && !editVoteMode ? (
                <View style={styles.iconContainer}>
                    <Pressable
                        testID={`item-${optionID}-remove`}
                        onPress={handleOnDelete}
                        renderToHardwareTextureAndroid={true}
                    >
                        <Icon
                            name={
                                canUserRemove
                                    ? theme.image.optionCardRemove
                                    : theme.image.optionCardLocked
                            }
                            color={
                                canUserRemove
                                    ? theme.color.optionCardRemoveIcon
                                    : theme.color.optionCardLockedIcon
                            }
                            size={40}
                        />
                    </Pressable>
                </View>
            ) : (
                <View style={styles.iconContainer}>
                    <CheckboxAnimated
                        checked={checked}
                        onPress={handleOnVote}
                        size={40}
                    />
                </View>
            )}
            <Pressable
                testID={`item-${optionID}-edit-pressable`}
                style={defaultStyles.rowFlex}
                onPress={handleOnPress}
            >
                {children}
            </Pressable>
            {(draftMode || (editVoteMode && itemEdit.index === index)) && (
                <Pressable
                    testID={`item-${optionID}-remove`}
                    style={styles.iconClose}
                    onPress={onDelete}
                >
                    <Icon
                        name={theme.image.closeX}
                        style={styles.deleteIcon}
                        size={13}
                    />
                </Pressable>
            )}
        </View>
    )
}

export default PollsCardWrapper
