import { TFunction } from "react-i18next"
import {
    getBrowser,
    getDeviceModel,
    getDeviceOS,
} from "@services/deviceInfo"
import Logger from "@services/logger"
import { captureMessage } from "@services/sentry"
import { jsVersion } from "./manifest"

export const configureFeedbackMessage = async (
    mailAddress: string,
    t: TFunction<"translation", undefined>,
    deviceId: string | null,
): Promise<string> => {
    let message = ""

    try {
        const encodedAddress = encodeURIComponent(mailAddress)
        const encodedSubject = encodeURIComponent(
            t("shareFeedback.messageTitle") as string,
        )
        const encodedBodyPlaceholder = encodeURIComponent(
            (t("shareFeedback.messageBodyPlaceholder") as string) +
                "\n\n\n\n\n",
        )
        const encodedBodyRest = encodeURIComponent(
            t("shareFeedback.supportInfoHeading") +
                "\n" +
                getAppVersionLine() +
                getDeviceModelLine() +
                getBrowserLine() +
                getDeviceOSLine() +
                getDeviceIdLine(deviceId),
        )

        message =
            "mailto:" +
            encodedAddress +
            "?subject=" +
            encodedSubject +
            "&body=" +
            encodedBodyPlaceholder +
            encodedBodyRest
    } catch (e) {
        const error = e as Error
        captureMessage(error.message)
        Logger.error("error configuring feedback message: " + error.message)
    }
    return message
}

const getAppVersionLine = () => {
    const version = jsVersion()
    return version ? "Version: " + version + "\n" : ""
}

const getDeviceModelLine = () => {
    const model = getDeviceModel()
    return model ? "Model: " + model + "\n" : ""
}

const getBrowserLine = () => {
    const browser = getBrowser()
    return browser ? "Browser: " + browser + "\n" : ""
}

const getDeviceOSLine = () => {
    const OS = getDeviceOS()
    return OS ? "OS: " + OS + "\n" : ""
}

const getDeviceIdLine = (deviceId: string | null): string => {
    // check if cond below necessary
    return deviceId ? "DID: " + deviceId + "\n" : ""
}
