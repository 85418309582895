import Constants from "expo-constants"
import * as Application from "expo-application"
import { Platform } from "react-native"

const manifest = Constants.manifest2 ?? Constants.manifest

const getManifestValueWithFallback = (name: string, fallback: string) => {
    const value =
        Constants.manifest2?.extra?.expoClient?.extra?.eas?.[name] ??
        Constants.manifest?.extra?.eas?.[name]

    return value ?? fallback
}

export const appEnv = () => {
    return getManifestValueWithFallback("app_env", "dev")
}

export const jsVersion = () => {
    return getManifestValueWithFallback("jsVersion", "##")
}

export const tenant = () => {
    return getManifestValueWithFallback("tenant", "polls")
}

export const isQaTenant = () => {
    const isQaTenantString = getManifestValueWithFallback("isQaTenant", "")
    return isQaTenantString === "TRUE"
}

export const appVersion = () => {
    return (
        Platform.select({
            native: Application.nativeApplicationVersion,
            web: "W",
        }) ?? "##"
    )
}

export const runtimeVersion = () => {
    const runtimeVersionObject = manifest?.runtimeVersion

    let runtimeVersionString: string | undefined = undefined

    if (
        typeof runtimeVersionObject === "string" ||
        runtimeVersionObject instanceof String
    ) {
        runtimeVersionString = `R${runtimeVersionObject}`
    } else if (runtimeVersionObject?.policy === "sdkVersion") {
        runtimeVersionString = "SDK"
    } else if (runtimeVersionObject?.policy === "nativeVersion") {
        runtimeVersionString = `N${appVersion}`
    } else {
        runtimeVersionString = "##"
    }

    return runtimeVersionString
}
