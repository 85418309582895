import { Image, Pressable, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { PollOptionDefault, PollOptionLink } from "@types"
import { LinkCardTemplateStyles } from "@components/PollList/Items/LinkCardTemplate/LinkCardTemplate.styles"
import { commonStyles } from "@styles/Main.styles"

interface ListCardTemplateBodyProps {
    data: PollOptionLink | PollOptionDefault
    onLinkPress: () => void
}

const ListCardTemplateBody = ({
    data,
    onLinkPress,
}: ListCardTemplateBodyProps) => {
    const { styles } = useStyles(LinkCardTemplateStyles)
    const { styles: defaultStyles } = useStyles(commonStyles)

    const { title, subtitle, imageUrl } = data

    return (
        <View style={[styles.topRow, !imageUrl ? defaultStyles.rowFlex : null]}>
            {imageUrl && (
                <Pressable onPress={onLinkPress}>
                    <Image source={{ uri: imageUrl }} style={styles.rowImage} />
                </Pressable>
            )}
            <View
                style={[
                    styles.rowTextColumn,
                    !imageUrl ? styles.textStart : null,
                ]}
            >
                <Text
                    style={styles.itemTitle}
                    numberOfLines={2}
                    ellipsizeMode={"tail"}
                >
                    {title}
                </Text>
                <Text
                    style={styles.itemSubtitle}
                    numberOfLines={1}
                    ellipsizeMode={"tail"}
                >
                    {subtitle}
                </Text>
            </View>
        </View>
    )
}

export default ListCardTemplateBody
