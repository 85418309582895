import { getAppConfig } from "@services/appConfig"
import { UserState } from "@contexts/UserDataProvider/UserReducer"
import * as Sentry from "sentry-expo"
import { Platform } from "react-native"
import * as Manifest from "@helpers/manifest"
import { isPlatformNative } from "@helpers/isPlatformNative"
interface Sentry {
    user: UserState
}

export const initializeSentry = () => {
    const config = getAppConfig()

    Sentry.init({
        dsn: config.sentry.dsn,
        enableInExpoDevelopment: true,
        environment: Manifest.appEnv(),
        debug: config.sentryDebug,
        release: Manifest.jsVersion(),
    })

    const tags = {
        version: Manifest.appVersion(),
        runtimeVersion: Manifest.runtimeVersion(),
        jsVersion: Manifest.jsVersion(),
        tenant: config.tenantConfig.analyticsTenantName,
    }

    if (Platform.OS === "web") {
        Sentry.Browser.setTags(tags)
    } else {
        Sentry.Native.setTags(tags)
    }
}

export const updateUser = (userId: string, deviceId: string) => {
    if (Platform.OS === "web") {
        Sentry.Browser.setUser({
            id: userId,
            deviceId: deviceId,
        })
    } else {
        Sentry.Native.setUser({
            id: userId,
            deviceId: deviceId,
        })
    }
}

export const captureMessage = (message: string) => {
    const isNative = isPlatformNative()

    isNative
        ? Sentry.Native.captureMessage(message)
        : Sentry.Browser.captureMessage(message)
}
