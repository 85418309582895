import React, { useContext, useState } from "react"
import { Button, PollSelectList, ScreenLayout } from "@components"
import { ListBanner } from "@components/ListBanner"
import { Icons } from "@assets/icons"
import { t } from "i18next"
import { BottomNavigator } from "@components/BottomNavigator"
import { getAppConfig } from "@services/appConfig"
import { PollOption } from "@types"
import {
    AnalyticsActionType,
    AnalyticsContext,
    PollActionType,
    PollContext,
} from "@contexts"
import useStyles from "@helpers/hooks/useStyles"
import { PollSelectListStyles } from "@components/PollSelectList/PollSelectList.styles"
import { AnalyticsEvent } from "@services/analytics"

const SelectPollScreen = () => {
    const [selected, setSelected] = useState<PollOption["id"][]>([])

    const { styles } = useStyles(PollSelectListStyles)

    const {
        pollState: { loading, data, updating },
        pollDispatch,
    } = useContext(PollContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const {
        tenantConfig: { bottomBarConfig: cfg },
    } = getAppConfig()

    const handleOnSubmit = () => {
        const options = selected.map(id =>
            data.options.find(stateOption => stateOption.id === id),
        ) as PollOption[]

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapDraftFilterLaunch,
            },
        })

        pollDispatch({
            type: PollActionType.PUBLISH_POLL,
            payload: {
                options,
            },
        })
    }

    return (
        <ScreenLayout>
            <ListBanner
                icon={Icons.list}
                title={t("createPoll")}
                subtitle={t("decideWhichItemInclude")}
                containerStyle={styles.listBanner}
            />
            <PollSelectList
                selected={selected}
                setSelected={setSelected}
                loading={loading}
            />
            <BottomNavigator config={cfg.selectPoll}>
                <Button
                    title={t("launch")}
                    disabled={selected.length < 2}
                    onPress={handleOnSubmit}
                    isLoading={updating}
                />
            </BottomNavigator>
        </ScreenLayout>
    )
}

export default SelectPollScreen
