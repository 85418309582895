import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { ProgressBar } from "@components"
import { Pressable, Text, View } from "react-native"
import { SeeWhoVotedIcons } from "@components/PollList/Items/SeeWhoVotedIcons"
import formatNumber from "@helpers/compactNumberFormatter"
import { TextCardTemplateStyles } from "@components/PollList/Items/TextCardTemplate/TextCardTemplate.styles"
import { PollRecentVotes } from "@types"

interface TextCardContentProps {
    title: string
    votesCount: number
    totalVoteCount: number
    onSeeWhoVoted: () => void
    recentVotes: PollRecentVotes[]
    checked: boolean
}

const TextCardContent = ({
    title,
    votesCount,
    totalVoteCount,
    onSeeWhoVoted,
    recentVotes,
    checked,
}: TextCardContentProps) => {
    const { styles } = useStyles(TextCardTemplateStyles)

    return (
        <View style={styles.contentColumn}>
            <Text
                style={styles.itemText}
                numberOfLines={3}
                ellipsizeMode={"tail"}
            >
                {title}
            </Text>
            <ProgressBar votes={votesCount} totalVotes={totalVoteCount} />
            <Pressable style={styles.votesContainer} onPress={onSeeWhoVoted}>
                <SeeWhoVotedIcons
                    showSeeWhoVotedDrawer={onSeeWhoVoted}
                    recentVotes={recentVotes}
                    checked={checked}
                />
                <Text style={styles.counterText}>
                    {votesCount === 1
                        ? `${votesCount} vote`
                        : `${formatNumber(votesCount)} votes`}
                </Text>
            </Pressable>
        </View>
    )
}

export default TextCardContent
