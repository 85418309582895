import React from "react"
import { View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { PreviewImageProgressBarStyles } from "./PreviewImageProgressbar.styles"

interface ProgressBarProps {
    votes: number
    totalVotes: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ votes, totalVotes }) => {
    const { styles } = useStyles(PreviewImageProgressBarStyles)

    let progress = Math.round((votes === 0 ? 0 : votes / totalVotes) * 100)

    if (!totalVotes || !votes) {
        progress = 0
    }

    return (
        <View style={styles.container}>
            <View
                style={[styles.internalContainer, { width: `${progress}%` }]}
            ></View>
        </View>
    )
}

export default ProgressBar
