import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ListBannerStyles extends Styles {
    outerContainer: ViewStyle
    container: ViewStyle
    iconContainer: ViewStyle
    icon: TextStyle
    title: TextStyle
    subtitle: TextStyle
}

export const ListBannerStyles: StyleFunction<ListBannerStyles> = ({
    theme,
}) => {
    return {
        outerContainer: {
            width: "100%",
            height: 67,
        },
        container: {
            flex: 1,
            paddingVertical: 13,
            paddingHorizontal: 15,
            backgroundColor: theme.color.pollListCopyBannerBackground,
            borderRadius: 8,
            flexDirection: "row",
        },
        iconContainer: {
            height: 41,
            width: 41,
            backgroundColor: theme.color.pollListCopyBannerIconBackground,
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
            marginRight: 21,
            marginLeft: 11,
        },
        icon: {
            color: theme.color.pollListCopyBannerIcon,
            fontSize: 18,
        },
        title: {
            ...theme.font.pollListCopyBannerTitle,
            marginBottom: 0,
        },
        subtitle: {
            ...theme.font.pollListCopyBannerSubtitle,
        },
        WEB: {
            container: {
                marginHorizontal: 120,
            },
            outerContainer: {
                marginBottom: 20,
            },
        },
        MOBILE: {
            container: {
                marginHorizontal: 25,
            },
            outerContainer: {
                marginBottom: 20,
            },
        },
    }
}
