import { Button, Title } from "@components"
import { DrawerActionType, DrawerContext } from "@contexts/DrawerProvider"
import { UserContext } from "@contexts/UserDataProvider"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import useStyles from "@helpers/hooks/useStyles"
import { logoutUser } from "@services/amplify"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Text, View } from "react-native"
import { ConfirmLogoutModalStyles } from "./ConfirmLogoutModal.styles"
import { AnalyticsEvent } from "@services/analytics"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"

const ConfirmLogoutModal = () => {
    const { t } = useTranslation()
    const { styles } = useStyles(ConfirmLogoutModalStyles)
    const { drawerDispatch } = useContext(DrawerContext)
    const { userDispatch } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const { isMobileView } = useScreenDimensions()

    const handleDismiss = () => {
        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
    }

    const handleLogout = async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapProfileLogoutConfirm,
            },
        })

        handleDismiss()

        await logoutUser({
            userDispatch,
        })
    }

    return isMobileView ? (
        <View style={styles.container}>
            <View style={styles.card}>
                <Text style={styles.subheader}>
                    {t("profileScreen.areYouSureLogout")}
                </Text>
                <View style={styles.divider} />
                <Button
                    style={styles.buttonLogout}
                    titleStyle={styles.buttonLogoutText}
                    variant="text"
                    onPress={handleLogout}
                    title={t("logout")}
                />
            </View>
            <View style={styles.card}>
                <Button
                    style={styles.buttonCancel}
                    titleStyle={styles.buttonCancelText}
                    variant="text"
                    onPress={handleDismiss}
                    title={t("cancel")}
                />
            </View>
        </View>
    ) : (
        <View style={styles.container}>
            <View style={styles.card}>
                <Title style={styles.title} title={"Confirm"} />
                <Text style={styles.subheader}>
                    {t("profileScreen.areYouSureLogout")}
                </Text>
                <View style={styles.buttons}>
                    <Button
                        style={styles.buttonLogout}
                        titleStyle={styles.buttonLogoutText}
                        variant="secondary"
                        onPress={handleLogout}
                        title={t("logout")}
                    />
                    <Button
                        style={styles.buttonCancel}
                        titleStyle={styles.buttonCancelText}
                        variant="secondary"
                        onPress={handleDismiss}
                        title={t("cancel")}
                    />
                </View>
            </View>
        </View>
    )
}

export default ConfirmLogoutModal
