import React from "react"
import useStyles from "@helpers/hooks/useStyles"
import { phoneInputStyles } from "./PhoneButton.styles"
import { Button } from "@components"
import { AnalyticsEvent } from "@services/analytics"

interface PhoneInputProps {
    title: string
    onPress: () => void
}

const PhoneInput = ({ title, onPress }: PhoneInputProps) => {
    const { styles, theme } = useStyles(phoneInputStyles)

    const handleOnPress = () => {
        onPress()
    }

    return (
        <Button
            variant={"secondary"}
            title={title}
            style={styles.container}
            titleStyle={styles.title}
            iconStyle={styles.icon}
            iconLeft={theme.image.profileAuthenticateButtonIcon}
            onPress={handleOnPress}
            analyticsEvent={AnalyticsEvent.tapProfileSignIn}
        />
    )
}

export default PhoneInput
