import React from "react"
import { View } from "react-native"
import { LottieAnimation } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { FlatListLoaderStyles } from "./Loader.styles"

const FlatListLoader: React.FC = () => {
    const { styles, theme } = useStyles(FlatListLoaderStyles)
    return (
        <View style={styles.lottieContainer}>
            <LottieAnimation
                source={theme.lottie.spinner}
                style={styles.loader}
                autoPlay
                loop
            />
        </View>
    )
}

export default FlatListLoader
