import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ShareVoteModalStyles extends Styles {
    container: ViewStyle
    title: TextStyle
    step: TextStyle
    shareVote: TextStyle
}

export const shareVoteTitleStyles: StyleFunction<ShareVoteModalStyles> = ({
    width,
    theme,
}) => {
    return {
        container: {
            flexDirection: "row",
            justifyContent: "center",
        },

        title: {
            ...theme.font.voteShareTitle,
        },

        step: {
            ...theme.font.voteShareStepText,
        },

        shareVote: {
            ...theme.font.voteShareShareVoteText,
            paddingHorizontal: 8,
        },
    }
}
