import {
    AuthenticationState,
    UserContext,
} from "@contexts/UserDataProvider/UserReducer"
import {
    AppContext,
    DrawerActionType,
    DrawerContext,
    PollActionType,
    PollContext,
    ScreenShotActionType,
    ScreenShotContext,
} from "@contexts"
import { ReactNode, useCallback, useContext } from "react"
import { Image } from "react-native"
import { t } from "i18next"
import { LogEntry, updatePoll } from "@services/poll/updatePoll"
import { PollAction } from "@contexts/PollContext"
import Logger from "@services/logger"
import { ThemeContext } from "@contexts/ThemeContext"
import { Vote } from "@contexts/PollContext/PollReducer"
import { AnalyticsEvent } from "@services/analytics"
import { ModalName } from "@components/Drawer/content"
import { PostVoteAction, PostVoteActionType } from "@types"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { useLogAnalytic } from "@helpers/hooks/useAnalyticsActions"
import {
    getPostVoteAction,
    getPostVoteActionImagePayload,
} from "@helpers/postVoteAction"
import { getAppConfig } from "@services/appConfig"

interface callbackProps {
    pollId: string
    sequentialUpdates: LogEntry[]
    resultingVotes: Vote[]
}

interface useUpdatePollFlowProp {
    pollDispatch: PollAction
    drawerContent: {
        [PostVoteActionType.Values.shareVote]: ReactNode
        [PostVoteActionType.Values.downloadPollsApp]: ReactNode
        [PostVoteActionType.Values.image]: ReactNode
    }
}

export const useUpdatePollFlow = ({
    pollDispatch,
    drawerContent,
}: useUpdatePollFlowProp) => {
    const {
        userState: { deviceId, authStatus, userName },
    } = useContext(UserContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { screenShotDispatch } = useContext(ScreenShotContext)
    const { theme } = useContext(ThemeContext)
    const {
        pollState: { originalOptionsLength },
    } = useContext(PollContext)
    const {
        appState: { embeddedState, tenantOverrideKey },
    } = useContext(AppContext)

    // only pass post vote image override for demo 
    const { tenantConfig } = getAppConfig()
    let postVoteImageTenantOverrideKey: string | undefined = undefined
    if (tenantConfig.backendTenantName === "demo") {
        postVoteImageTenantOverrideKey = tenantOverrideKey
    }

    const isNative = isPlatformNative()
    const logAnalytic = useLogAnalytic()

    const refreshPollData = useCallback(() => {
        pollDispatch({ type: PollActionType.REFRESH_POLL_DATA })
    }, [pollDispatch])

    const clearShareLink = useCallback(() => {
        screenShotDispatch({
            type: ScreenShotActionType.SET_SHARE_LINK,
            payload: {
                shareLink: "",
            },
        })
    }, [screenShotDispatch])

    const onUpdateSuccessDismiss = useCallback(
        (postVoteAction: PostVoteAction) => {
            switch (postVoteAction.type) {
                case PostVoteActionType.Values.shareVote: {
                    drawerDispatch({
                        type: DrawerActionType.SHOW_DRAWER,
                        payload: {
                            identifier: ModalName.SHARE_VOTE,
                            content: drawerContent.shareVote,
                            dismissible: true,
                            hasCloseButton: true,
                            hasBackground: true,
                            onDismiss: clearShareLink,
                        },
                    })
                    break
                }
                case PostVoteActionType.Values.downloadPollsApp: {
                    if (isNative) break

                    drawerDispatch({
                        type: DrawerActionType.SHOW_DRAWER,
                        payload: {
                            identifier: ModalName.POST_VOTE_DOWNLOAD_POLLS,
                            content: drawerContent.downloadPollsApp,
                            dismissible: true,
                            hasCloseButton: true,
                            hasBackground: true,
                            isModal: true,
                            onDismiss: clearShareLink,
                        },
                    })
                    break
                }
                case PostVoteActionType.Values.image: {
                    drawerDispatch({
                        type: DrawerActionType.SHOW_DRAWER,
                        payload: {
                            identifier: ModalName.POST_VOTE_IMAGE_MODAL,
                            content: drawerContent.image,
                            dismissible: true,
                            hasCloseButton: true,
                            hasBackground: true,
                            onDismiss: clearShareLink,
                        },
                    })
                    break
                }
            }
        },
        [
            clearShareLink,
            drawerContent.downloadPollsApp,
            drawerContent.image,
            drawerContent.shareVote,
            drawerDispatch,
            isNative,
        ],
    )

    return useCallback(
        async ({
            pollId,
            sequentialUpdates,
            resultingVotes,
        }: callbackProps) => {
            Logger.info("FLOW: updatePoll initialized")

            const { isSuccess, data, error } = await updatePoll({
                userName,
                pollId,
                deviceId,
                sequentialUpdates,
                resultingVotes,
                isAuthenticated:
                    authStatus === AuthenticationState.AUTHENTICATED,
            })

            if (isSuccess && data) {
                const postVoteAction = getPostVoteAction({
                    pollSettings: data.settings,
                    embeddedState,
                })

                const { imageUrl: postVoteImageUrl } =
                    getPostVoteActionImagePayload({
                        postVoteAction,
                        tenantOverrideKey: postVoteImageTenantOverrideKey,
                    })

                if (postVoteImageUrl) {
                    Image.prefetch(postVoteImageUrl)
                }

                const handleUpdateSuccessDismiss = () =>
                    onUpdateSuccessDismiss(postVoteAction)

                const event = sequentialUpdates.length
                    ? AnalyticsEvent.updatePollSuccess
                    : AnalyticsEvent.voteSuccess

                logAnalytic(event)

                pollDispatch({
                    type: PollActionType.SET_DATA,
                    payload: { data, pollTitle: "" },
                })

                drawerDispatch({
                    type: DrawerActionType.SHOW_SUCCESS_MODAL,
                    payload: {
                        title: t("youVoted"),
                        message: t("shareWithFriends"),
                        animation: theme.lottie.voteSuccess,
                        duration: 1500,
                        onDismiss: handleUpdateSuccessDismiss,
                    },
                })

                pollDispatch({
                    type: PollActionType.CREATE_PREVIEW_IMAGE,
                })
            } else if (error) {
                if (error.response?.status === 409) {
                    drawerDispatch({
                        type: DrawerActionType.SHOW_MODAL,
                        payload: {
                            title: t("updateFailed"),
                            message: t("bottomNavigation.someoneElseChanged"),
                            image: theme.image.updatePollCrossErrorGraphic,
                            imageSize: {
                                height: 89,
                                width: 248,
                            },
                            buttonTitle: t("reloadPoll"),
                            onDismiss: refreshPollData,
                            dismissible: false,
                            identifier: ModalName.WIRES_CROSSED,
                        },
                    })
                } else {
                    drawerDispatch({
                        type: DrawerActionType.SHOW_MODAL,
                        payload: {
                            title: t("updateFailed"),
                            message:
                                t("bottomNavigation.couldntUpdateThePoll") +
                                " " +
                                t("bottomNavigation.checkConnection"),
                            image: theme.image.updatePollFailedGraphic,
                            imageSize: {
                                height: 105,
                                width: 131,
                            },
                            buttonTitle: t("okay"),
                            identifier: ModalName.FAILED_TO_SUBMIT_VOTE,
                        },
                    })
                }
            }

            pollDispatch({
                type: PollActionType.POLL_DATA_UPDATED,
            })
        },
        [
            authStatus,
            deviceId,
            drawerDispatch,
            userName,
            pollDispatch,
            refreshPollData,
            screenShotDispatch,
            originalOptionsLength,
            t,
            embeddedState,
            postVoteImageTenantOverrideKey,
        ],
    )
}
