import { httpService } from "@services/http"
import Logger from "@services/logger"
import { PollRecentVotes } from "@types"
import { captureMessage } from "@services/sentry"

interface PaginationParams {
    currentPage: number
    pageCount: number
    pageSize: number
    total: number
}

interface EndpointResponse {
    votes: PollRecentVotes[]
    meta: {
        pagination: PaginationParams
    }
}

type VotesForOptionReturn =
    | { isSuccess: true; data: EndpointResponse }
    | { isSuccess: false; data: undefined }

export const getVotesForOption = async (
    pollId: string,
    deviceId: string,
    optionId: string,
    offset: number,
    pageSize: number,
): Promise<VotesForOptionReturn> => {
    const requestUrl = `polls/${pollId}/option/${optionId}/votes`
    const requestConfig = {
        headers: {
            "polls-device-id": deviceId || "",
        },
        params: {
            offset,
            pageSize,
        },
    }

    try {
        const response = await httpService.get<EndpointResponse>(
            requestUrl,
            requestConfig,
        )
        if (response.status >= 200 && response.status < 300) {
            return { isSuccess: true, data: response.data }
        } else throw new Error("response error")
    } catch (e) {
        const error = e as Error
        captureMessage(error.message)
        Logger.error(error.message)
        return { isSuccess: false, data: undefined }
    }
}
