import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ShareVoteModalStyles extends Styles {
    content: ViewStyle
    copy: TextStyle
    spinner: ViewStyle
    spinnerContainer: ViewStyle
}

export const shareVoteModalStyles: StyleFunction<ShareVoteModalStyles> = ({
    theme,
}) => {
    return {
        content: {
            marginHorizontal: 20,
            marginVertical: 40,
            alignItems: "center",
        },
        copy: {
            ...theme.font.voteShareCopyText,
            textAlign: "center",
            marginTop: 16,
            marginBottom: 5,
            width: 214,
        },
        spinnerContainer: {
            justifyContent: "center",
            width: "100%",
        },
        spinner: {
            width: 30,
            height: 30,
            alignSelf: "center",
        },
    }
}
