import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface PollListStyles extends Styles {
    container: ViewStyle
    wrapper: ViewStyle
    doneButton: ViewStyle
    addOneButton: ViewStyle
    toolbarButtons: ViewStyle
    toolbarContainer: ViewStyle
    listOverlay: ViewStyle
    keyboardAvoidingContainer: ViewStyle
}

export const pollListStyles: StyleFunction<PollListStyles> = () => {
    return {
        container: {
            flex: 1,
            width: "100%",
        },
        wrapper: {
            flex: 1,
            overflow: "hidden",
        },
        listOverlay: {
            position: "absolute",
            zIndex: 5,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
        },
        doneButton: {
            flex: 1,
            paddingHorizontal: 40,
        },
        addOneButton: {
            flex: 1,
        },
        toolbarContainer: {
            position: "absolute",
            width: "100%",
            height: 50,
        },
        toolbarButtons: {
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
        },
        keyboardAvoidingContainer: {
            width: "100%",
            position: "absolute",
            top: 0,
            zIndex: 5,
            justifyContent: "flex-end",
            alignSelf: "center",
        },
        NATIVE: {
            wrapper: {
                marginBottom: -20,
            },
        },
        WEB: {
            wrapper: {
                paddingHorizontal: 110,
                marginBottom: -20,
            },
            toolbarContainer: {
                paddingHorizontal: 110,
            },
        },
        MOBILE: {
            wrapper: {
                left: 0,
                paddingHorizontal: 12,
            },
            toolbarContainer: {
                paddingHorizontal: 12,
            },
        },
    }
}
