import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface FlatListItemStyles extends Styles {
    itemContainer: ViewStyle
    title: TextStyle
    orderNumberText: TextStyle
    avatar: ViewStyle
    avatarIcon: TextStyle
}

export const FlatListItemStyles: StyleFunction<FlatListItemStyles> = ({
    theme,
}) => {
    return {
        itemContainer: {
            flexDirection: "row",
            height: 30,
        },
        orderNumberText: {
            textAlign: "center",
            minWidth: 40,
        },
        title: {
            ...theme.font.voterListNamesText,
            marginRight: 10,
            marginBottom: 10,
        },
        avatar: {
            marginRight: 5,
            width: 21,
            height: 21,
            borderRadius: 21,
            alignItems: "center",
            justifyContent: "center",
        },
        avatarIcon: {
            ...theme.font.profileAvatarLetterTiny,
        },
    }
}
