import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface SeeWhoVotedIconsStyle extends Styles {
    container: ViewStyle
    checkboxContainer: ViewStyle
    avatar: ViewStyle
    avatarIcon: TextStyle
}

export const SeeWhoVotedIconsStyle: StyleFunction<SeeWhoVotedIconsStyle> = ({
    theme,
}) => {
    return {
        container: {
            flexDirection: "row-reverse",
            marginRight: 10,
        },
        avatar: {
            width: 18,
            height: 17,
            borderRadius: 17,
            borderRightWidth: 1,
            borderRightColor: "#fff",
            marginRight: -3,
            alignItems: "center",
            justifyContent: "center",
        },
        avatarIcon: {
            margin: 0,
            ...theme.font.profileAvatarLetterTiny,
        },
        checkboxContainer: {},
        NATIVE: {
            container: {
                marginRight: 0,
                marginLeft: 10,
            },
            checkboxContainer: {
                marginBottom: -1,
                marginRight: -2,
                borderRightWidth: 1,
                borderRightColor: "#fff",
                borderRadius: 18,
            },
        },
        WEB: {
            checkboxContainer: {
                userSelect: "none",
            },
            avatarIcon: {
                userSelect: "none",
            },
        },
        MOBILE: {
            checkboxContainer: {
                userSelect: "none",
            },
            avatarIcon: {
                userSelect: "none",
            },
        },
    }
}
