import { UserAction, UserActionType } from "@contexts/UserDataProvider"
import { httpService } from "@services/http"
import Logger from "@services/logger"
import { storageService } from "@services/storage"
import { CookieKeys, PollSettings, StorageKeys } from "@types"
import uuid from "react-native-uuid"
import { setCookie } from "@services/cookies"
import { captureMessage } from "@services/sentry"
import { getAppConfig } from "@services/appConfig"

type FingerprintRequestData = {
    fingerprints: {
        value: string
        type: string
    }[]
}

type FingerprintResponse = {
    user: {
        name: string
        id: string
        userDefaults: { pollSettings: PollSettings }
    }
    deviceId: string
}
interface FingerprintService {
    userDispatch: UserAction
    isForceCreateNew: boolean
}

export const fingerprintService = async ({
    isForceCreateNew,
    userDispatch,
}: FingerprintService) => {
    try {
        const cookieFingerprint = await getCookieFingerprint()

        // for now we are requiring android users to log in,
        // so this super sticky OS build fingerprint is not necessary
        // and it is causing an issue if the user signs in, uninstalls
        // (and even clears data), and then tries to sign in with new phone number

        // const devID = getOSBuildFingerprint()

        const requestData: FingerprintRequestData = {
            fingerprints: [
                // {
                //     value: firebaseUID,
                //     type: "firebase",
                // },
                // {
                //     value: devID,
                //     type: "device",
                // },
                {
                    value: cookieFingerprint,
                    type: "cookie-based",
                },
            ].filter(fingerprint => fingerprint.value !== ""),
        }

        const backendTenantName = getAppConfig().tenantConfig.backendTenantName

        const {
            data: {
                user: {
                    name,
                    id,
                    userDefaults: { pollSettings },
                },
                deviceId,
            },
            status,
        } = await httpService.post<FingerprintResponse>(
            "users/searchByFingerprints",
            !isForceCreateNew
                ? requestData
                : { ...requestData, forceCreateNew: true },
            {
                headers: { "polls-tenant": backendTenantName },
            },
        )

        if (status >= 200 && status < 300) {
            userDispatch({
                type: UserActionType.SET_UNAUTHENTICATED_USER,
                payload: {
                    userName: name ?? "",
                    deviceId,
                    userId: id,
                    isLogout: isForceCreateNew,
                },
            })

            userDispatch({
                type: UserActionType.SET_POLL_SETTINGS,
                payload: {
                    pollSettings,
                },
            })
            storageService.setItem(StorageKeys.DEVICE_ID, deviceId)
            storageService.setItem(StorageKeys.POLL_SETTINGS, pollSettings)
            storageService.setItem(StorageKeys.USER_NAME, name ?? "")
            storageService.setItem(StorageKeys.USER_ID, id)
            setCookie({ name: CookieKeys.USER_NAME, value: name })
        }
    } catch (err) {
        const errorMessage = (err as Error).message
        captureMessage(errorMessage)
        Logger.error(errorMessage)
    }
}

const getCookieFingerprint = async (): Promise<string> => {
    const cookieFromStorage = await storageService.getItem<string | null>(
        StorageKeys.COOKIE,
    )
    if (!cookieFromStorage) {
        Logger.info("Creating new cookie and setting value in storage")
        const freshCookie = uuid.v4().toString()
        await storageService.setItem(StorageKeys.COOKIE, freshCookie)
        return freshCookie
    }
    return cookieFromStorage
}
