import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface titleStyles extends Styles {
    container: ViewStyle
    plain: ViewStyle
    box: ViewStyle
    inputBox: ViewStyle
    inputText: TextStyle
    noEdit: TextStyle
}

export const inputStyles: StyleFunction<titleStyles> = ({ theme }) => {
    return {
        container: {},
        inputBox: {
            margin: 5,
            backgroundColor: theme.color.inputBoxColor,
            borderWidth: 0,
            borderColor: theme.color.inputBoxBorderColor,
            borderRadius: theme.cornerRadius.input,
        },
        plain: {
            marginHorizontal: 0,
        },
        box: {
            marginHorizontal: 8,
        },
        inputText: {
            padding: 5,
            minWidth: 100,
            ...theme.font.input,
        },
        noEdit: {},
        MOBILE: {
            inputBox: {
                ...theme.shadow.input.web,
            },
            inputText: {
                outlineStyle: "none",
            },
            noEdit: {
                userSelect: "none",
                pointerEvents: "none",
            },
        },
        WEB: {
            inputBox: {
                ...theme.shadow.input.web,
            },
            inputText: {
                outlineStyle: "none",
                fontKerning: "none",
            },
            noEdit: {
                userSelect: "none",
                pointerEvents: "none",
            },
        },
        NATIVE: {
            container: {
                margin: 2,
                alignItems: "flex-start",
                justifyContent: "space-around",
            },
            inputBox: {
                flex: 1,
                margin: 2,
                ...theme.shadow.input.native,
            },
            inputText: {
                margin: 0,
            },
        },
    }
}
