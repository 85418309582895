import { LayoutChangeEvent, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { webScreenCardStyles } from "@components/WebScreenCard/WebScreenCard.styles"
import { Drawer } from "@components"
import { OuterLayoutStyles } from "./OuterLayout.styles"
import { AppActionType } from "@contexts/AppProvider/AppReducer"
import { AppContext } from "@contexts"

const OuterLayout = () => {
    const { styles } = useStyles(webScreenCardStyles)
    const { styles: containerStyles } = useStyles(OuterLayoutStyles)

    const {
        appState: { containerHeight },
        appDispatch,
    } = useContext(AppContext)

    const handleOnLayout = (e: LayoutChangeEvent) => {
        const currentHeight = e.nativeEvent.layout.height
        if (containerHeight !== currentHeight) {
            appDispatch({
                type: AppActionType.SET_CONTAINER_HEIGHT,
                payload: { containerHeight: e.nativeEvent.layout.height },
            })
        }
    }

    return (
        <View
            style={[
                styles.outerContainer,
                containerStyles.backgroundTransparent,
            ]}
            pointerEvents={"none"}
        >
            <View style={styles.innerContainer} onLayout={handleOnLayout}>
                <Drawer />
            </View>
        </View>
    )
}

export default OuterLayout
