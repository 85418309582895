import { UserActionType } from "@contexts/UserDataProvider/UserReducer"
import { useCallback } from "react"
import Logger from "@services/logger"
import { UserAction } from "@contexts/UserDataProvider"
import { storageService } from "@services/storage"
import { CookieKeys, StorageKeys } from "@types"
import { setCookie } from "@services/cookies"
import { updateUser, UpdateUserProps } from "@services/user/updateUser"

export const useUpdateUserFlow = (userDispatch: UserAction) => {
    return useCallback(
        async ({
            isAuthenticated,
            propsToUpdate,
            deviceId,
        }: UpdateUserProps) => {
            Logger.info("FLOW: updateUser initialized")

            const { isSuccess, data } = await updateUser({
                isAuthenticated,
                propsToUpdate,
                deviceId,
            })

            if (isSuccess && data) {
                const {
                    name,
                    id,
                    cognitoId,
                    phoneNumber,
                    signInMethod,
                    userDefaults: { pollSettings },
                } = data

                userDispatch({
                    type: UserActionType.SET_POLL_SETTINGS,
                    payload: { pollSettings },
                })

                if (isAuthenticated) {
                    userDispatch({
                        type: UserActionType.SET_AUTHENTICATED_USER_INFO,
                        payload: {
                            authenticatedUserInfo: {
                                signInMethod: signInMethod ?? "",
                                cognitoId: cognitoId ?? "",
                            },
                            phoneNumber,
                            userId: id,
                            userName: name,
                        },
                    })
                } else {
                    // for the unauthenticated user we have only a name
                    userDispatch({
                        type: UserActionType.SET_NAME,
                        payload: { userName: name },
                    })
                }

                // update user in storage
                await storageService.setItem(StorageKeys.USER_NAME, name ?? "")
                await storageService.setItem(
                    StorageKeys.POLL_SETTINGS,
                    pollSettings,
                )
                setCookie({ name: CookieKeys.USER_NAME, value: name })
            }

            userDispatch({ type: UserActionType.USER_DATA_UPDATED })
        },
        [userDispatch],
    )
}
