import React from "react"
import {
    LinkCardTemplate,
    DefaultCardTemplate,
    TextCardTemplate,
} from "@components/PreviewImage/templates"
import { PollOption } from "@types"

interface PreviewImageCardTemplateProps {
    seeWhoVoted: boolean
    option: PollOption
    totalCounter: number
    onLoaded: (id: PollOption["id"]) => void
    isChecked: boolean
}

const PreviewImageCardTemplate = (props: PreviewImageCardTemplateProps) => {
    switch (props.option.type) {
        case "defaultTemplate":
            return (
                <DefaultCardTemplate
                    seeWhoVoted={props.seeWhoVoted}
                    option={props.option}
                    onLoaded={props.onLoaded}
                    isChecked={props.isChecked}
                />
            )
        case "link":
            return (
                <LinkCardTemplate
                    seeWhoVoted={props.seeWhoVoted}
                    option={props.option}
                    totalCounter={props.totalCounter}
                    onLoaded={props.onLoaded}
                    isChecked={props.isChecked}
                />
            )
        case "text":
            return (
                <TextCardTemplate
                    seeWhoVoted={props.seeWhoVoted}
                    option={props.option}
                    totalCounter={props.totalCounter}
                    onLoaded={props.onLoaded}
                    isChecked={props.isChecked}
                />
            )
        default:
            return null
    }
}

export default PreviewImageCardTemplate
