import { getAppConfig } from "@services/appConfig"
import Logger from "@services/logger"
import AWS, { S3 } from "aws-sdk"
import { decode } from "base64-arraybuffer"
import React, {
    createContext,
    Dispatch,
    ReactNode,
    Reducer,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from "react"
import { PollActionType, PollContext } from "@contexts"
import { captureMessage } from "@services/sentry"

enum ScreenShotActionType {
    SHOW_PREVIEW_IMAGE = "showPreviewImage",
    SAVE_PREVIEW_IMAGE = "savePreviewImage",
    DISMISS_PREVIEW_IMAGE = "dismissPreviewImage",
    SET_AWS_BUCKET = "setAWSBucket",
    SET_SHARE_LINK = "setShareLink",
}

type Action =
    | {
          type: ScreenShotActionType.SHOW_PREVIEW_IMAGE
      }
    | {
          type: ScreenShotActionType.SAVE_PREVIEW_IMAGE
          payload: {
              previewImage: string
          }
      }
    | {
          type: ScreenShotActionType.DISMISS_PREVIEW_IMAGE
      }
    | {
          type: ScreenShotActionType.SET_AWS_BUCKET
          payload: {
              awsBucket: S3.Types
          }
      }
    | {
          type: ScreenShotActionType.SET_SHARE_LINK
          payload: {
              shareLink: string
          }
      }

type ScreenShotState = {
    available: boolean
    previewImage: string | undefined // base64 string
    awsBucket: S3.Types | undefined
    awsBucketConfigured: boolean
    shareLink: string
}

const initialState: ScreenShotState = {
    available: false,
    previewImage: undefined,
    awsBucket: undefined,
    awsBucketConfigured: false,
    shareLink: "",
}

interface ScreenShotProviderProps {
    children: ReactNode
}

type ScreenShotAction = Dispatch<Action>

interface ScreenShotContext {
    state: ScreenShotState
    screenShotDispatch: ScreenShotAction
}

const ScreenShotContext = createContext<ScreenShotContext>({
    state: initialState,
    screenShotDispatch: () => initialState,
})

// reducer
const screenShotReducer: Reducer<ScreenShotState, Action> = (state, action) => {
    Logger.info(`Action: ${action.type}`)
    switch (action.type) {
        case ScreenShotActionType.SHOW_PREVIEW_IMAGE: {
            return {
                ...state,
                available: true,
            }
        }
        case ScreenShotActionType.SAVE_PREVIEW_IMAGE: {
            const image = action.payload.previewImage
            return {
                ...state,
                previewImage: image,
            }
        }
        case ScreenShotActionType.DISMISS_PREVIEW_IMAGE: {
            return {
                ...state,
                available: false,
                previewImage: "",
            }
        }

        case ScreenShotActionType.SET_AWS_BUCKET: {
            const bucket = action.payload.awsBucket
            return {
                ...state,
                awsBucket: bucket,
                awsBucketConfigured: true,
            }
        }
        case ScreenShotActionType.SET_SHARE_LINK: {
            const { shareLink } = action.payload
            return {
                ...state,
                shareLink,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}

// provider
const ScreenShotProvider = ({ children }: ScreenShotProviderProps) => {
    const [state, screenShotDispatch] = useReducer(
        screenShotReducer,
        initialState,
    )

    const {
        pollState: {
            data: { previewImageFileUuid, shortLink },
            pollId,
            isLaunched,
        },
        pollDispatch,
    } = useContext(PollContext)

    const uploadImage = useCallback(() => {
        const { awsS3 } = getAppConfig()

        if (state.previewImage && state.awsBucket && previewImageFileUuid) {
            const fileUuid = isLaunched ? pollId : previewImageFileUuid
            const fileName = `${fileUuid}.jpeg`
            const fileToUpload: S3.Types.PutObjectRequest = {
                Key: fileName,
                ContentType: "image/jpeg",
                Body: decode(state.previewImage),
                Bucket: awsS3.bucket,
            }

            if (shortLink)
                screenShotDispatch({
                    type: ScreenShotActionType.SET_SHARE_LINK,
                    payload: {
                        shareLink: shortLink,
                    },
                })

            state.awsBucket
                .upload(fileToUpload)
                .promise()
                .then(data => {
                    Logger.info(`Uploaded file path: ${data.Location}`)
                    pollDispatch({
                        type: PollActionType.PREVIEW_IMAGE_CREATED,
                    })
                    screenShotDispatch({
                        type: ScreenShotActionType.DISMISS_PREVIEW_IMAGE,
                    })
                })
                .catch(error => {
                    if (state.shareLink)
                        screenShotDispatch({
                            type: ScreenShotActionType.SET_SHARE_LINK,
                            payload: {
                                shareLink: "",
                            },
                        })
                    captureMessage(error)
                    Logger.error(`Error during uploading: ${error}`)
                })
        }
    }, [
        state.previewImage,
        state.awsBucket,
        state.shareLink,
        previewImageFileUuid,
        isLaunched,
        pollId,
        shortLink,
        pollDispatch,
    ])

    const configureAWS = useCallback(() => {
        Logger.info("AWS BUCKET CONFIGURATION")

        const { awsS3 } = getAppConfig()

        AWS.config.update({
            accessKeyId: awsS3.accessKeyId,
            secretAccessKey: awsS3.secretAccessKey,
        })

        const bucket = new AWS.S3({
            params: { Bucket: awsS3.bucket },
            region: awsS3.region,
        })

        screenShotDispatch({
            type: ScreenShotActionType.SET_AWS_BUCKET,
            payload: {
                awsBucket: bucket,
            },
        })
    }, [])

    useEffect(() => {
        configureAWS()
    }, [])

    useEffect(() => {
        if (state.awsBucket && state.previewImage) {
            uploadImage()
        }
    }, [uploadImage])

    return (
        <ScreenShotContext.Provider value={{ state, screenShotDispatch }}>
            {children}
        </ScreenShotContext.Provider>
    )
}

export {
    ScreenShotContext,
    ScreenShotProvider,
    ScreenShotAction,
    ScreenShotActionType,
}

