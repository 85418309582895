import React, {
    ReactElement,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react"
import { Theme } from "@assets/theme/Theme"
import { TenantTheme } from "@theme/Theme"
import * as Font from "expo-font"

interface ThemeContent {
    theme: Theme
    changeTheme: (val: SetStateAction<Theme>) => void
}

const initialState = {
    theme: TenantTheme,
    changeTheme: () => {
        // noop
    },
}

export const ThemeContext = React.createContext<ThemeContent>(initialState)

export function ThemeContextProvider({
    children,
}: {
    children: React.ReactNode
}): ReactElement {
    const [activeTheme, setActiveTheme] = useState<Theme>(TenantTheme)

    // reload font files if theme changes
    // this is important because the icons are in a font file
    useEffect(() => {
        Font.loadAsync(activeTheme.fontFiles)
    }, [activeTheme])

    const value = useMemo(() => {
        return {
            theme: activeTheme,
            changeTheme: (val: SetStateAction<Theme>) => setActiveTheme(val),
        }
    }, [activeTheme])

    return (
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    )
}
