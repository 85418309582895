import { Pressable, Text } from "react-native"
import React from "react"
import { Card, CheckboxAnimated } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { SettingsOptionCardStyles } from "./SettingsOptionCard.styles"

interface SettingsOptionCardProps {
    title: string
    checked: boolean
    onPress: () => void
}

const SettingsOptionCard = ({
    title,
    checked,
    onPress,
}: SettingsOptionCardProps) => {
    const { styles } = useStyles(SettingsOptionCardStyles)

    return (
        <Card style={styles.optionCard}>
            <Pressable onPress={onPress} style={styles.optionContainer}>
                <Text style={styles.optionTitle}>{title}</Text>
                <CheckboxAnimated
                    checked={checked}
                    size={28}
                    onPress={onPress}
                />
            </Pressable>
        </Card>
    )
}

export default SettingsOptionCard
