import { httpService } from "@services/http"
import Logger from "@services/logger"
import { captureMessage } from "@services/sentry"
import { AxiosError } from "axios"

interface TextMeTheApp {
    phoneNumber: string
}

export const textMeTheApp = async ({ phoneNumber }: TextMeTheApp) => {
    try {
        const pollResponse = await httpService.post("textMeTheApp", {
            phoneNumber,
        })

        return {
            isSuccess: pollResponse.status >= 200 && pollResponse.status < 300,
        }
    } catch (e) {
        captureMessage(e)
        Logger.error(`Text Me the App: ${e}`)
        return { isSuccess: false, error: e as AxiosError }
    }
}
