import { Animated, View, Text, ViewStyle, TextStyle } from "react-native"
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react"
import useStyles from "@helpers/hooks/useStyles"
import { formErrorStyles } from "./FormError.styles"

interface FormErrorProps {
    message: string | undefined
    disableShadow?: boolean
    setErrorMessage?: Dispatch<SetStateAction<string>>
    onErrorDismissed?: () => void
    styles?: ViewStyle
    textStyles?: TextStyle
}

const FormError = ({
    message,
    disableShadow,
    setErrorMessage,
    onErrorDismissed,
    styles: propsStyles,
    textStyles,
}: FormErrorProps) => {
    const { styles } = useStyles(formErrorStyles)
    const opacity = useRef(new Animated.Value(0)).current
    const [isAnimating, setIsAnimating] = useState(false)

    const setVisible = (visible: 1 | 0) => {
        return Animated.timing(opacity, {
            toValue: visible,
            duration: 300,
            useNativeDriver: false,
        })
    }

    const clearErrorMessage = () => {
        if (setErrorMessage) setErrorMessage("")

        setIsAnimating(false)

        onErrorDismissed?.()
    }

    useEffect(() => {
        if (!message) return

        setIsAnimating(true)

        Animated.sequence([
            setVisible(1),
            Animated.delay(3000),
            setVisible(0),
        ]).start(clearErrorMessage)
    }, [message])

    return (
        <Animated.View
            style={[
                styles.container,
                propsStyles,
                !disableShadow && styles.shadow,
                {
                    opacity,
                },
            ]}
            renderToHardwareTextureAndroid={isAnimating}
        >
            <View style={styles.innerContainer}>
                <Text style={[styles.text, textStyles]}>{message}</Text>
            </View>
        </Animated.View>
    )
}

export default FormError
