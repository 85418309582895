interface HashParams {
    pollId: string
    userId: string
    colors: string[]
}

export const hash = ({ pollId, userId, colors }: HashParams): string => {
    const id = pollId.concat(userId)
    let hash = 0
    if (id.length == 0) return colors[0] // return default color
    for (let i = 0; i < id.length; i++) {
        hash += id.charCodeAt(i)
    }
    const calculatedHash = Math.abs(Math.pow(hash, 2))
    return colors[calculatedHash % 10]
}
