import { Image, Pressable, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { DefaultCardTemplateStyles } from "./DefaultCardTemplate.styles"
import { CheckboxAnimated, ProgressBar, Title } from "@components"
import { Icon, Icons } from "@assets/icons"
import { PollOptionDefault } from "@types"
import { t } from "i18next"
import { commonStyles } from "@styles/Main.styles"
import formatNumber from "@helpers/compactNumberFormatter"

interface DefaultCardTemplateProps {
    index: number
    data: PollOptionDefault
    totalVoteCount: number
    checked: boolean
    onVote: (index: number) => void
    onOpenOptionLink: (id: string, index: number, url: string) => void
}

const DefaultCardTemplate = ({
    index,
    data,
    totalVoteCount,
    checked,
    onVote,
    onOpenOptionLink,
}: DefaultCardTemplateProps) => {
    const { styles, theme } = useStyles(DefaultCardTemplateStyles)
    const { styles: defaultStyles } = useStyles(commonStyles)

    const votesCount = data.voteCount[0]?.count || 0

    const handleOnOpenOptionLink = () => {
        const url = data.url

        onOpenOptionLink(data.id, index, url)
    }

    const handleOnVote = () => {
        onVote(index)
    }

    return (
        <View style={styles.container}>
            <View style={styles.topRow}>
                <Pressable
                    onPress={handleOnOpenOptionLink}
                    style={styles.imageTextRow}
                >
                    <Image
                        source={{
                            uri: data.imageUrl,
                        }}
                        defaultSource={{ uri: theme.image.imagePlaceholder }}
                        style={styles.image}
                    />
                </Pressable>
                <Pressable style={defaultStyles.rowFlex} onPress={handleOnVote}>
                    <View style={styles.textColumn}>
                        <Title
                            title={data.title}
                            style={styles.title}
                            numberOfLines={2}
                        />
                        <View style={styles.subtitleContainer}>
                            <Text
                                style={styles.subtitle}
                                ellipsizeMode={"tail"}
                                numberOfLines={1}
                            >
                                {data.subtitle || ""}
                            </Text>
                            <Text
                                style={styles.details}
                                ellipsizeMode={"tail"}
                                numberOfLines={1}
                            >
                                {data.details || ""}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <CheckboxAnimated
                            checked={checked}
                            onPress={handleOnVote}
                            size={31}
                        />
                    </View>
                </Pressable>
            </View>
            <View style={styles.bottomRow}>
                <ProgressBar votes={votesCount} totalVotes={totalVoteCount} />
                <View style={[styles.row, styles.bottomTextRow]}>
                    <Pressable
                        onPress={handleOnVote}
                        style={defaultStyles.rowFlex}
                    >
                        <Text style={styles.counterText}>
                            {votesCount === 1
                                ? `${votesCount} vote`
                                : `${formatNumber(votesCount)} votes`}
                        </Text>
                    </Pressable>
                    <Pressable
                        onPress={handleOnOpenOptionLink}
                        style={styles.row}
                    >
                        <Title title={t("viewDetails")} style={styles.link} />
                        <Icon
                            name={Icons.linkOut}
                            style={styles.linkIcon}
                            size={10}
                        />
                    </Pressable>
                </View>
            </View>
        </View>
    )
}

export default DefaultCardTemplate
