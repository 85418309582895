import { isPlatformNative } from "@helpers/isPlatformNative"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { Animated, StyleProp, ViewStyle } from "react-native"

export enum AnimatedType {
    ZOOM = "zoom",
    SLIDE_V = "slideVertical",
    SLIDE_H = "slideHorizontal",
    FADE = "fade",
}
export interface AnimatedProps {
    type: AnimatedType
    bounciness?: number
    speed?: number
    duration?: number
    from: number
    to: number
    cashed?: boolean
}

interface AnimatedViewProps {
    id?: string
    children?: ReactNode | null
    style?: StyleProp<ViewStyle>
    animProps: AnimatedProps
    onAnimationStarted?: () => void
    onAnimationCompleted?: () => void
}

const AnimatedView = ({
    id,
    children = null,
    style,
    animProps,
    onAnimationCompleted,
    onAnimationStarted,
}: AnimatedViewProps) => {
    const {
        type = AnimatedType.FADE,
        speed = 100,
        bounciness = 0,
        duration = 100,
        from,
        to,
    } = animProps
    const value = useRef(new Animated.Value(from)).current
    const isNative = isPlatformNative()
    const [renderToTexture, setRenderToTexture] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)

    const animate = () => {
        if (type === "fade") {
            setRenderToTexture(true)
            Animated.timing(value, {
                toValue: to,
                duration: duration,
                useNativeDriver: isNative,
            }).start(() => {
                setRenderToTexture(false)
                setIsCompleted(true)
            })
        } else {
            Animated.spring(value, {
                toValue: to,
                speed: speed,
                bounciness: bounciness,
                useNativeDriver: isNative,
            }).start(() => {
                setIsCompleted(true)
            })
        }
    }

    const pickTransformAnimation = () => {
        switch (type) {
            case AnimatedType.ZOOM:
                return { transform: [{ scale: value }] }
            case AnimatedType.SLIDE_V:
                return { transform: [{ translateY: value }] }
            case AnimatedType.SLIDE_H:
                return { transform: [{ translateX: value }] }
            case AnimatedType.FADE:
                return { opacity: value }
        }
    }

    useEffect(() => {
        if (isCompleted && onAnimationCompleted) {
            onAnimationCompleted()
        }
    }, [isCompleted])

    useEffect(() => {
        setIsCompleted(false)
        value.setValue(from)
        onAnimationStarted && onAnimationStarted()
        animate()
    }, [from, to])

    return (
        <Animated.View
            style={[style, pickTransformAnimation()]}
            renderToHardwareTextureAndroid={renderToTexture}
            needsOffscreenAlphaCompositing={true}
        >
            {children}
        </Animated.View>
    )
}

const isEqual = (
    prevProps: Readonly<AnimatedViewProps>,
    nextProps: Readonly<AnimatedViewProps>,
) => {
    if (!prevProps.animProps.cashed) return false
    return prevProps.animProps.to === nextProps.animProps.to
}

export default React.memo(AnimatedView, isEqual)
