import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, ViewStyle } from "react-native"

export interface PollSelectListSkeletonStyles extends Styles {
    container: ViewStyle
    checkboxColumn: ViewStyle
    card: ViewStyle
    flexStart: ViewStyle
    skeletonImage: ImageStyle
    skeletonContentContainer: ViewStyle
}

export const PollSelectListSkeletonStyles: StyleFunction<
    PollSelectListSkeletonStyles
> = () => {
    return {
        container: { flexDirection: "row", marginTop: 10 },
        checkboxColumn: { justifyContent: "center", marginRight: 5 },
        card: { padding: 16, opacity: 0.7, marginRight: 0 },
        flexStart: {
            alignItems: "flex-start",
            justifyContent: "flex-start",
        },
        skeletonImage: {
            height: 70,
            width: 102,
            marginRight: 10,
        },
        skeletonContentContainer: {
            flex: 1,
            height: 70,
            justifyContent: "center",
        },
    }
}
