import { Input } from "@components"
import { PollContext, PollActionType } from "@contexts"
import { useInputConfig } from "@helpers/hooks/useInputConfig"
import useStyles from "@helpers/hooks/useStyles"
import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react"
import { StyleProp, TextInput, TextStyle, ViewStyle } from "react-native"
import { ValidationError } from "yup"
import { CreatePollScreenTitleInputStyles } from "./TitleInput.styles"
import { useDebounce } from "@helpers/hooks/useDebounce"
import { useFocus } from "@helpers/hooks/useFocus"
import { isPlatformAndroid } from "@helpers/isPlatformAndroid"

export interface TitleInputProps {
    placeholder: string
    style?: StyleProp<ViewStyle>
    inputStyle?: StyleProp<TextStyle>
    title: string
    isDataRestored: boolean
    editable: boolean
    autoFocus: boolean
    onInvalidChange: (error: ValidationError | undefined) => void
    setInputButtonPressed: Dispatch<SetStateAction<boolean>>
}

const TitleInput = ({
    placeholder,
    style,
    inputStyle,
    title,
    isDataRestored,
    editable,
    autoFocus,
    onInvalidChange,
    setInputButtonPressed,
}: TitleInputProps) => {
    const { styles } = useStyles(CreatePollScreenTitleInputStyles)
    const {
        pollState: { isCreateDraftMode },
        pollDispatch,
    } = useContext(PollContext)
    const { setFocus, setFocusTarget } = useFocus()
    const titleFocusRef = useRef<TextInput>()

    const [localTitle, setLocalTitle] = useState<string>("")
    const inputConfig = useInputConfig("pollTitleInput")

    useEffect(() => {
        if (titleFocusRef.current && !isPlatformAndroid()) {
            setFocusTarget("pollTitle", titleFocusRef.current)
        }
    }, [titleFocusRef])

    useEffect(() => {
        if (titleFocusRef.current) {
            const shouldFocus =
                autoFocus && title.length === 0 && isDataRestored
            if (shouldFocus) {
                if (isPlatformAndroid()) {
                    titleFocusRef.current?.focus()
                } else {
                    setFocus("pollTitle")
                }
            }
        }
    }, [titleFocusRef, autoFocus, isDataRestored, title])

    useEffect(() => {
        setLocalTitle(title)
    }, [title])

    const handleFocus = () => {
        pollDispatch({
            type: PollActionType.SET_POLL_TITLE_EDIT,
            payload: true,
        })
    }

    const handleBlur = () => {
        setInputButtonPressed(false)
    }

    const changeTitle = () => {
        pollDispatch({
            type: PollActionType.CHANGE_POLL_TITLE,
            payload: { title: localTitle },
        })
    }

    useDebounce(changeTitle, 200, [localTitle])

    const handleOnChange = (value: string) => {
        setLocalTitle(value)
    }

    return (
        <Input
            {...inputConfig}
            onFocus={handleFocus}
            onBlur={handleBlur}
            editable={editable}
            style={[styles.inputContainer, style]}
            onChange={handleOnChange}
            onInvalidChange={onInvalidChange}
            textAlign={"center"}
            inputStyle={[
                isCreateDraftMode ? styles.title : styles.titleActive,
                styles.input,
                inputStyle,
            ]}
            value={localTitle}
            placeholder={placeholder}
            fontSize={16}
            ref={titleFocusRef as any}
        />
    )
}

export default TitleInput
