import React, { useContext } from "react"
import { Text, View } from "react-native"
import { useTranslation } from "react-i18next"
import {
    AnalyticsActionType,
    AnalyticsContext,
    ScreenShotContext,
} from "@contexts"
import { shareVoteModalStyles } from "./ShareVoteModal.styles"
import useStyles from "@helpers/hooks/useStyles"
import { ShareVoteTitle } from "./ShareVoteTitle"
import { ShareImage } from "./ShareImage"
import { ShareLinkBar } from "./ShareLinkBar"
import * as Clipboard from "expo-clipboard"
import { AnalyticsEvent } from "@services/analytics"
import { LottieAnimation } from "@components"

const ShareVoteModal = () => {
    const {
        state: { shareLink },
    } = useContext(ScreenShotContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const { styles, theme } = useStyles(shareVoteModalStyles)
    const { t } = useTranslation()

    const handlePress = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapShareVoteCopy,
            },
        })

        Clipboard.setStringAsync(shareLink)
    }

    return (
        <View style={styles.content}>
            <ShareVoteTitle />
            <ShareImage />
            {shareLink !== "" ? (
                <ShareLinkBar onPress={handlePress} shareLink={shareLink} />
            ) : (
                <View style={styles.spinnerContainer}>
                    <LottieAnimation
                        source={theme.lottie.spinner}
                        style={styles.spinner}
                        autoPlay
                        loop
                    />
                </View>
            )}
            <Text style={styles.copy}>{t("voteScreen.copyShareLink")}</Text>
        </View>
    )
}

export default ShareVoteModal
