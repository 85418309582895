import React, { useEffect, useRef } from "react"
import { View, Animated } from "react-native"
import useStyles from "@helpers/hooks/useStyles"

import { ProgressBarStyles } from "./ProgressBar.styles"

interface ProgressBarProps {
    votes: number
    totalVotes: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ votes, totalVotes }) => {
    const { styles } = useStyles(ProgressBarStyles)

    const progressAnim = useRef(new Animated.Value(0)).current

    useEffect(() => {
        let progress = Math.round((votes === 0 ? 0 : votes / totalVotes) * 100)

        if (!totalVotes || !votes) {
            progress = 0
        }

        Animated.timing(progressAnim, {
            toValue: progress,
            duration: 200,
            useNativeDriver: false,
        }).start()
    }, [votes, totalVotes, progressAnim])

    const widthInterpolate = progressAnim.interpolate({
        inputRange: [0, 100],
        outputRange: ["0%", "100%"],
    })

    return (
        <View style={styles.container}>
            <Animated.View
                style={[
                    styles.internalContainer,
                    {
                        width: widthInterpolate,
                    },
                ]}
            />
        </View>
    )
}

export default ProgressBar
