import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface CreateOwnPollsStyles extends Styles {
    container: ViewStyle
    image: ImageStyle
    title: TextStyle
    subtitle: TextStyle
    button: ViewStyle
}

export const CreateOwnPollsStyles: StyleFunction<CreateOwnPollsStyles> = ({
    theme,
}) => {
    return {
        container: {
            alignItems: "center",
            marginHorizontal: 43,
            marginBottom: 30,
        },
        image: {
            width: 162,
            height: 162,
        },
        title: {
            ...theme.font.postVoteDownloadTitleText,
            marginBottom: 0,
        },
        subtitle: {
            ...theme.font.postVoteDownloadSubtitleText,
        },
        button: {
            marginTop: 10,
        },
    }
}
