import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface ListSkeletonStyles extends Styles {
    container: ViewStyle
    contentContainer: ViewStyle
    spinner: ViewStyle
}

export const ListSkeletonStyles: StyleFunction<ListSkeletonStyles> = () => {
    return {
        container: {
            flex: 1,
            width: "100%",
        },
        contentContainer: {
            alignItems: "center",
            paddingHorizontal: 16,
            paddingVertical: 30,
        },
        spinner: {
            width: 90,
            height: 90,
            alignSelf: "center",
            marginTop: 20,
        },
        NATIVE: {
            spinner: {
                marginTop: 0,
            },
        },
        MOBILE: {
            container: {
                paddingHorizontal: 16,
            },
        },
        WEB: {
            container: {
                paddingHorizontal: 110,
            },
            spinner: {
                marginTop: 0,
            },
        },
    }
}
