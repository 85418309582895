import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle } from "react-native"

export interface CheckboxStyles extends Styles {
    checkBoxIcon: TextStyle
}

export const checkboxStyles: StyleFunction<CheckboxStyles> = ({ theme }) => {
    return {
        checkBoxIcon: {
            color: theme.color.optionCardCheckbox,
        },
    }
}
