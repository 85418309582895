import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    Animated,
    Keyboard,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    ScrollView,
    View,
} from "react-native"
import { useCanEditPoll } from "@helpers/hooks/useCanEditPoll"
import useStyles from "@helpers/hooks/useStyles"
import { pollListStyles } from "./PollList.styles"
import {
    ListItem,
    PollData,
    PollOption,
    PollOptionLink,
    PollOptionType,
    PollOptionValueType,
} from "@types"
import { t } from "i18next"
import PollListToolBar from "./PollListToolBar"
import { CARD_HEIGHT, EDIT_ITEM_OFFSET, TOOLBAR_OFFSET } from "@config/pollList"
import { AnalyticsEvent } from "@services/analytics"
import { PollListSubmitButton } from "@components/PollList/PollListSubmitButton"
import { sortList } from "@helpers/pollList"
import { Vote } from "@contexts/PollContext/PollReducer"
import { PollListHeader } from "./PollListHeader"
import { UserState } from "@contexts/UserDataProvider/UserReducer"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { useFocus } from "@helpers/hooks/useFocus"
import { AnalyticsListActions } from "@contexts/AnalyticsProvider/AnalyticsReducer"
import { ListBanner } from "@components/ListBanner"
import { Icons } from "@assets/icons"
import PollListKeyboardAvoidingContainer from "@components/PollList/PollListKeyboardAvoidingContainer"
import PollListItemsRender from "@components/PollList/PollListItemsRender"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { isPlatformMobile } from "@helpers/isPlatformMobile"
import PollListAvoidingContainer from "@components/PollList/PollListAvoidingContainer"

export interface DoneTransition {
    transition: boolean
    index: number
}

export interface ItemEdit {
    on: boolean
    index: number
}

interface LinkQueue {
    linkData: any
    id: PollOption["id"]
}

interface PollListViewProps {
    testID?: string
    isTitleSet: boolean
    pollTitle: string
    pollTitleEdit: boolean
    pollOwnerId: PollData["ownerId"]
    userId: UserState["userId"]
    userName: UserState["userName"]
    currentSessionVotesByUser: Vote[]
    options: PollOption[]
    editMode: boolean
    editVoteMode: boolean
    exitEditMode: boolean
    draftMode: boolean
    totalVoteCount: number
    optionsAdded: boolean
    setFocus: (id: string) => void
    setEditMode: (enabled: boolean) => void
    setEditVoteMode: (enabled: boolean) => void
    addOption: (type: PollOptionType, valueType: PollOptionValueType) => void
    removeOption: (id: string) => void
    setOptions: (options: PollOption[]) => void
    onVote: (id: string, valueType: PollOptionValueType) => void
    onOpenOptionLink: (id: string, index: number, url: string) => void
    onLinkOptionParsed: (option: PollOptionLink) => void
    setScrollTop: (top: boolean) => void
    seeWhoVoted: (data: PollOption, checked: boolean) => void
    setTitle: () => void
    logListAnalytic: (listActionId: AnalyticsListActions) => void
    logAnalytic: (analyticId: AnalyticsEvent) => void
}

const PollListView = ({
    testID,
    isTitleSet,
    pollTitle,
    pollTitleEdit,
    pollOwnerId,
    userId,
    userName,
    currentSessionVotesByUser,
    options,
    editMode,
    editVoteMode,
    exitEditMode,
    draftMode,
    totalVoteCount,
    setFocus,
    setEditMode,
    setEditVoteMode,
    addOption,
    removeOption,
    setOptions,
    onVote,
    onOpenOptionLink,
    onLinkOptionParsed,
    setScrollTop,
    seeWhoVoted,
    setTitle,
    logListAnalytic,
    logAnalytic,
}: PollListViewProps) => {
    const { styles } = useStyles(pollListStyles)

    const useNativeDriver = Platform.OS !== "web"
    const overlayAlpha = useRef(new Animated.Value(0)).current

    const [localData, setData] = useState<ListItem[]>([])
    const max = useMemo(() => localData.length - 1, [localData])
    const [itemEdit, setEditItem] = useState({ on: false, index: -1 })
    const [doneTransition, setDoneTransition] = useState<DoneTransition>({
        transition: false,
        index: -1,
    })
    const [dropIndex, setDropIndex] = useState(-1)
    const [scrollRef, setRef] = useState<ScrollView | null>()
    const scrollOffset = useRef(0)
    const [scrollTop, setIsScrollTop] = useState(true)
    const [drag, setDrag] = useState({ dragging: false, index: -1 })
    const [itemUpdated, setItemUpdated] = useState(false)
    const containerY = useRef(new Animated.Value(0)).current
    const [isHelpTextVisible, setHelpVisible] = useState(false)
    const [isAddButtonVisible, setAddButtonVisible] = useState(true)
    const [isAnimating, setAnimating] = useState(false)
    const [headerHeight, setHeaderHeight] = useState<number>(0)
    const [handledLinksQueue, setHandledLinksQueue] = useState<LinkQueue[]>([])

    const { canEdit: canEditPoll } = useCanEditPoll()
    const { setFocusTop } = useFocus()

    const isNative = isPlatformNative()
    const isMobile = isPlatformMobile()

    // this useEffect keeps track on provider's global and component options local state
    useEffect(() => {
        const data = [...localData]
        options.map(option => {
            const dataIndex = data.findIndex(i => i.id === option.id)
            if (dataIndex >= 0) {
                // item exist
                data[dataIndex].data.voteCount = option.voteCount
            } else {
                const item = {
                    index: data.length,
                    id: option.id,
                    type: option.type,
                    data: option,
                }
                data.push(item)
            }
        })

        const syncData = data.filter(item =>
            options.find(i => item.id === i.id),
        )

        const sortData = sortList(syncData)
        sortData.map((e, i) => (e.index = i))
        setData(sortData)
    }, [options, options.length])

    useEffect(() => {
        if (exitEditMode) {
            handleOnDone()
        }
    }, [exitEditMode])

    useEffect(() => {
        if (itemEdit.on) {
            setHelpVisible(false)
            setAddButtonVisible(false)
        } else {
            setHelpVisible(localData.length < 2 || localData.length === 25)
            setAddButtonVisible(localData.length < 25)
        }
    }, [localData.length, itemEdit.on])

    useEffect(() => {
        setScrollTop(scrollTop)
    }, [scrollTop])

    useEffect(() => {
        if (itemEdit.on) {
            let relativeY = -CARD_HEIGHT * itemEdit.index

            if (!isNative) relativeY += scrollOffset.current

            if (relativeY > 0) {
                scrollTo(scrollOffset.current - relativeY)
            } else {
                const finalOffset =
                    itemEdit.index > 0
                        ? relativeY + EDIT_ITEM_OFFSET
                        : relativeY

                editModeOnTransition(finalOffset)
            }
        } else {
            Keyboard.dismiss()
        }
    }, [containerY, itemEdit, scrollOffset.current])

    const onEditModeReady = () => {
        setTimeout(setFocus, 600, "option")
        setTimeout(setAnimating, 1000, false)
    }

    const editModeOnTransition = (positionY: number) => {
        setScrollTop(positionY >= 0)
        Animated.timing(containerY, {
            toValue: positionY,
            duration: 200,
            delay: 100,
            useNativeDriver,
        }).start(onEditModeReady)
    }

    const onDoneReady = () => {
        setDoneTransition({ transition: false, index: -1 })
    }

    const editModeOffTransition = () => {
        setScrollTop(scrollTop)
        setDoneTransition({ transition: true, index: itemEdit.index })
        setEditItem({ on: false, index: -1 })
        Animated.timing(containerY, {
            toValue: 0,
            duration: 300,
            delay: 300,
            useNativeDriver,
        }).start(onDoneReady)
    }

    const scrollTo = (positionY: number) => {
        if (!scrollRef) return
        scrollRef.scrollTo({ y: positionY, animated: true })
    }

    const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        scrollOffset.current = e.nativeEvent.contentOffset.y

        if (itemEdit.on && !isAnimating) {
            handleOnDone()
        } else {
            setIsScrollTop(scrollOffset.current <= 0)
        }
    }

    const handleOnVote = (index: number) => {
        handleVote(index)
    }

    const handleOnItemPress = (index: number) => {
        if (!draftMode) return

        setAnimating(true)
        if (Platform.OS !== "android") {
            setFocusTop()
        }

        setEditItem({ on: true, index })
    }

    const handleVote = (index: number) => {
        if (editMode) return
        const { id } = localData[index]
        onVote(id, "true")
    }

    const onItemStartDrag = (index: number) => {
        setDrag({ dragging: true, index })
    }

    const onItemStopDrag = (id: string) => {
        if (dropIndex >= 0) {
            const items = [...localData]
            const i = items.findIndex(item => item.id === id)
            if (i >= 0) {
                items[i].index = dropIndex
                const list = localData.sort((a, b) => a.index - b.index)

                setData(list)
                setOptions(list.map(o => o.data))
            }
            setDropIndex(-1)
            logAnalytic(AnalyticsEvent.draftOptionReorder)
        }

        setDropIndex(-1)
        setDrag({ dragging: false, index: -1 })
    }

    const handleOnDone = () => {
        if (!draftMode) {
            setEditVoteMode(false)
        }

        if (itemEdit.on) {
            editModeOffTransition()
        }
    }

    const handleAddOne = () => {
        const step = localData.length
        if (step > 24) return

        setAnimating(true)
        setFocusTop()
        addOption("text", "true")
        setDoneTransition({ transition: true, index: itemEdit.index })

        setTimeout(() => {
            setEditItem({ on: true, index: step })
        }, 500)
    }

    const handleRemove = (id: string) => {
        const items: ListItem[] = []
        let shift = false
        const list = localData.sort((a, b) => a.index - b.index)
        list.map(item => {
            if (item.id === id) {
                shift = true
                removeOption(id)
            } else if (shift) {
                item.index = item.index - 1
                items.push(item)
            } else {
                items.push(item)
            }
        })
        setData(items)
        setOptions(items.map(o => o.data))

        if (itemEdit.on) {
            handleOnDone()
        }
    }

    const handleSwapIndex = useCallback(
        (callerId: string, from: number, to: number) => {
            const items = [...localData]
            const i = items.findIndex(
                item => item.index === from && item.id !== callerId,
            )

            if (i >= 0) {
                setDropIndex(from > max ? max : from)
                items[i].index = to
                setData(items)
            }
        },
        [localData, max],
    )

    const onItemChanged = () => {
        setItemUpdated(!itemUpdated)
    }

    const handleSubmitPress = () => {
        if (editMode) {
            setEditMode(false)
        }

        if (!isTitleSet) {
            handleAddOne()
        } else {
            Keyboard.dismiss()
        }

        setTitle()

        const analyticsEvent = isTitleSet
            ? AnalyticsEvent.tapDraftEditTitleNext
            : AnalyticsEvent.tapDraftSetTitleNext

        logAnalytic(analyticsEvent)
    }

    const handleLinkOptionParsed = (linkData: any, id: PollOption["id"]) => {
        const items = [...localData]

        const index = items.findIndex(item => item.id === id)

        if (index === itemEdit.index) {
            updateLinkItemData(items, index, linkData)

            setData(items)
            setOptions(items.map(o => o.data))
        } else {
            const list = [...handledLinksQueue]

            list.push({ linkData, id })

            setHandledLinksQueue(list)
        }
    }

    const updateLinkItemData = (
        items: ListItem[],
        index: number,
        linkData: any,
    ) => {
        if (index < 0 || !items[index]) return false

        items[index] = {
            id: items[index].id,
            index,
            type: "link",
            data: {
                ...items[index].data,
                type: "link",
                title: linkData.title,
                subtitle: linkData.subtitle,
                faviconUrl: linkData.faviconUrl,
                url: linkData.url,
                imageUrl: linkData.imageUrl,
                siteName: linkData.siteName,
            },
        }
        const data = items[index].data

        if (data.type === "link") {
            onLinkOptionParsed(data)
        }
    }

    const updateQueuedLinkItems = () => {
        const queueCopy = [...handledLinksQueue]
        const items = [...localData]

        const filtered = queueCopy.filter(({ linkData, id }) => {
            const index = items.findIndex(item => item.id === id)

            updateLinkItemData(items, index, linkData)

            return false
        })

        setData(items)
        setOptions(items.map(o => o.data))

        setHandledLinksQueue(filtered)
    }

    useEffect(() => {
        if (!itemEdit.on && handledLinksQueue.length) {
            updateQueuedLinkItems()
        }
    }, [itemEdit.on, handledLinksQueue.length])

    const displayAddOption = useMemo(() => {
        return draftMode ? isTitleSet : canEditPoll
    }, [canEditPoll, draftMode, isTitleSet])

    const linkBannerVisible = useMemo(() => {
        return itemEdit.on && !localData[itemEdit.index]?.data.title.length
    }, [itemEdit.on, itemEdit.index, localData])

    const toolBarVisible = useMemo(() => {
        if (linkBannerVisible) return true

        if (itemEdit.on) {
            return !!localData[itemEdit.index]?.data.title.length || false
        }
        return false
    }, [itemEdit.on, itemUpdated, itemEdit.index, linkBannerVisible])

    const submitButtonVisible = useMemo(() => {
        return !!(pollTitleEdit && pollTitle.length)
    }, [pollTitle, pollTitleEdit])

    const submitButtonTitle = useMemo(() => {
        return isTitleSet ? t("done") : t("next")
    }, [isTitleSet])

    const listButtonsVisible = useMemo(() => {
        return !!localData[itemEdit.index]?.data.title.length
    }, [itemEdit.index, localData])

    useEffect(() => {
        Animated.timing(overlayAlpha, {
            toValue: pollTitleEdit && isTitleSet ? 0.7 : 0,
            duration: 100,
            useNativeDriver,
        }).start()
    }, [pollTitleEdit, isTitleSet])

    useEffect(() => {
        if (!isTitleSet && !pollTitle && !pollTitleEdit) handleOnDone()
    }, [isTitleSet, pollTitle, pollTitleEdit])

    return (
        <View style={[styles.container]}>
            <PollListHeader
                autoFocus={!options.length}
                setHeaderHeight={setHeaderHeight}
            />
            <KeyboardAwareScrollView
                enableOnAndroid={true}
                enableAutomaticScroll={false}
                extraHeight={1200}
                innerRef={ref => setRef(ref as any)}
                onScroll={onScroll}
                scrollEnabled={!drag.dragging}
                style={[styles.wrapper]}
                scrollEventThrottle={8}
                scrollToOverflowEnabled={true}
                keyboardDismissMode="on-drag"
                keyboardShouldPersistTaps="handled"
                showsVerticalScrollIndicator={false}
            >
                <Animated.View
                    style={[
                        {
                            transform: [{ translateY: containerY }],
                        },
                    ]}
                >
                    <PollListItemsRender
                        localData={localData}
                        testID={testID}
                        itemEdit={itemEdit}
                        currentSessionVotesByUser={currentSessionVotesByUser}
                        userId={userId}
                        userName={userName}
                        pollOwnerId={pollOwnerId}
                        editMode={editMode}
                        editVoteMode={editVoteMode}
                        draftMode={draftMode}
                        totalVoteCount={totalVoteCount}
                        doneTransition={doneTransition}
                        handleOnItemPress={handleOnItemPress}
                        handleOnVote={handleOnVote}
                        onOpenOptionLink={onOpenOptionLink}
                        handleSwapIndex={handleSwapIndex}
                        handleRemove={handleRemove}
                        onItemStartDrag={onItemStartDrag}
                        onItemStopDrag={onItemStopDrag}
                        handleSeeWhoVoted={seeWhoVoted}
                        handleLinkOptionParsed={handleLinkOptionParsed}
                        logListAnalytic={logListAnalytic}
                        isAddButtonVisible={isAddButtonVisible}
                        isHelpTextVisible={isHelpTextVisible}
                        handleAddOne={handleAddOne}
                        displayAddOption={displayAddOption}
                        onItemChanged={onItemChanged}
                    />
                </Animated.View>
            </KeyboardAwareScrollView>

            <PollListAvoidingContainer
                visible={toolBarVisible}
                offsetY={
                    headerHeight + (itemEdit.index > 0 ? TOOLBAR_OFFSET : 0)
                }
            >
                {listButtonsVisible && (
                    <PollListToolBar
                        onDone={handleOnDone}
                        onAddOne={handleAddOne}
                        isAddOneVisible={localData.length < 25}
                        logListEvent={logListAnalytic}
                    />
                )}

                {linkBannerVisible && isMobile && (
                    <ListBanner
                        title={t("addLinks")}
                        subtitle={t("pasteUrlToOption")}
                        icon={Icons.copy}
                        delayShow={true}
                    />
                )}
            </PollListAvoidingContainer>

            {pollTitleEdit && isTitleSet && (
                <Animated.View
                    style={[
                        styles.listOverlay,
                        {
                            opacity: overlayAlpha,
                        },
                    ]}
                />
            )}

            <PollListKeyboardAvoidingContainer>
                <PollListSubmitButton
                    isVisible={submitButtonVisible}
                    buttonTitle={submitButtonTitle}
                    onPress={handleSubmitPress}
                />
                {linkBannerVisible && !isMobile && (
                    <ListBanner
                        title={t("addLinks")}
                        subtitle={t("pasteUrlToOption")}
                        icon={Icons.copy}
                        delayShow={true}
                    />
                )}
            </PollListKeyboardAvoidingContainer>
        </View>
    )
}

export default PollListView
