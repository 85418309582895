import { Image, Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { PollHistoryStyles } from "./PollHistory.styles"
import { Card, Title } from "@components"
import { Icon } from "@assets/icons"
import {
    AnalyticsActionType,
    AnalyticsContext,
    NavigationContext,
    PollContext,
} from "@contexts"
import { ScreenName } from "@screens"
import { t } from "i18next"
import { AnalyticsEvent } from "@services/analytics"

const PollHistory = () => {
    const { styles, theme } = useStyles(PollHistoryStyles)
    const { navigate } = useContext(NavigationContext)
    const {
        pollState: { pollHistory },
    } = useContext(PollContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const redirect = (pollId: string) => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapHomePollHistoryPoll,
                additionalData: {
                    pollId,
                },
            },
        })

        navigate({ screen: ScreenName.VOTE_SCREEN, params: { pollId } })
    }

    return (
        <View>
            <Card style={styles.container}>
                <View style={styles.headerContainer}>
                    <Image
                        source={theme.image.pollHistoryHeader}
                        style={styles.headerImage}
                    />
                    <Title
                        title={t("homeScreen.pollHistory")}
                        style={styles.headerTitle}
                    />
                </View>
                <View style={styles.cardBody}>
                    {pollHistory.length ? (
                        pollHistory.map(item => (
                            <Pressable
                                key={item.id}
                                style={styles.itemContainer}
                                onPress={() => redirect(item.id)}
                            >
                                <View style={styles.itemDivider}></View>
                                <Title
                                    title={item.title}
                                    style={styles.itemTitle}
                                />
                                <Icon
                                    name={theme.image.backIcon}
                                    style={styles.itemIcon}
                                />
                            </Pressable>
                        ))
                    ) : (
                        <>
                            <Image
                                source={theme.image.pollHistoryEmpty}
                                style={styles.emptyImage}
                                resizeMode={"contain"}
                            />
                            <Title
                                title={t("homeScreen.createYourFirstPoll")}
                                style={styles.emptyText}
                                numberOfLines={2}
                            />
                        </>
                    )}
                </View>
            </Card>
        </View>
    )
}

export default PollHistory
