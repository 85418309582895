import {
    DrawerAction,
    DrawerActionType,
} from "@contexts/DrawerProvider/DrawerProvider"
import { NavigationHandlerProps } from "@contexts/NavigationProvider/NavigationReducer"
import { UserAction, UserActionType } from "@contexts/UserDataProvider"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { ScreenParamList } from "@navigation/LinkingConfiguration"
import { ScreenName } from "@screens"
import { CognitoUser, SignInFlow } from "@types"
import { Auth } from "aws-amplify"
import { TFunction } from "i18next"
import { captureMessage } from "@services/sentry"

interface ConfirmUserProps {
    cognitoUser: CognitoUser
    smsCode: string
    drawerDispatch: DrawerAction
    userDispatch: UserAction
    setErrorMessage: (message: string) => void
    t: TFunction
    navigate: <T extends keyof ScreenParamList>(
        props: NavigationHandlerProps<T>,
    ) => void
    signInFlow: SignInFlow
    successAnimation: string
}

const confirmUser = async ({
    cognitoUser,
    smsCode,
    drawerDispatch,
    userDispatch,
    setErrorMessage,
    t,
    navigate,
    signInFlow,
    successAnimation,
}: ConfirmUserProps) => {
    const isNative = isPlatformNative()
    const sendChallengeResponse = await sendChallengeAnswer({
        cognitoUserData: cognitoUser,
        verificationCode: smsCode,
    })

    if (
        sendChallengeResponse.cognitoUser?.signInUserSession &&
        sendChallengeResponse.isSuccess
    ) {
        userDispatch({
            type: UserActionType.SET_COGNITO_USER,
            payload: {
                cognitoUser: sendChallengeResponse.cognitoUser,
                isUserConfirmed: true,
            },
        })
        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
        userDispatch({ type: UserActionType.LOG_IN, payload: { signInFlow } })
        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: {
                title: t("Success"),
                message: t("verificationCodeModal.youreSignedIn"),
                animation: successAnimation,
                duration: 1500,
                animationStart: 0.38,
                animationScale: isNative ? 1.5 : 2,
            },
        })

        // change to use nav context
        if (isPlatformNative()) navigate({ screen: ScreenName.HOME_SCREEN })
    } else {
        captureMessage(JSON.stringify(sendChallengeResponse))

        setErrorMessage(t("verificationCodeModal.invalidCode"))
    }
}

interface sendChallengeAnswerProps {
    cognitoUserData: CognitoUser
    verificationCode: string
}

const sendChallengeAnswer = async ({
    cognitoUserData,
    verificationCode,
}: sendChallengeAnswerProps) => {
    try {
        const user = await Auth.sendCustomChallengeAnswer(
            cognitoUserData,
            verificationCode,
        )

        const cognitoUser = user as CognitoUser

        return { isSuccess: true, cognitoUser }
    } catch (error) {
        captureMessage(error)
        return { isSuccess: false, errorMessage: error }
    }
}

export default confirmUser
