import { useContext } from "react"
import { PollActionType, PollContext } from "@contexts"
import { storageService } from "@services/storage"
import { StorageKeys } from "@types"

export const useToggleHintModal = () => {
    const {
        pollState: { isHintVisible },
        pollDispatch,
    } = useContext(PollContext)

    const hintModalShown = storageService.getItem(
        StorageKeys.REMEMBER_SUBMIT_SHOWN,
    )

    return async (visible: boolean) => {
        if ((await hintModalShown) && !isHintVisible) return

        pollDispatch({
            type: PollActionType.TOGGLE_HINT_MODAL,
            payload: { visible },
        })
    }
}
