import { Vote } from "@contexts/PollContext/PollReducer"
import { LogEntry } from "@services/poll/updatePoll"
import { ListItem } from "@types"
import orderBy from "lodash/orderBy"

export const sortList = (list: ListItem[]) => {
    return orderBy(list, [item => item.data.voteCount[0]?.count ?? 0], ["desc"])
}

export const getVoteArrayDifference = (array1: Vote[], array2: Vote[]) => {
    return array1.filter(object1 => {
        return !array2.some(object2 => {
            return object1.optionId === object2.optionId
        })
    })
}

export const checkIfLogExist = (array: LogEntry[], id: string) => {
    const index = array
        .map(entry => entry.type === "addOption" && entry.option.id)
        .findIndex(e => e === id)

    return index >= 0
}
