import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface PollSelectItemStyles extends Styles {
    container: ViewStyle
    checkboxContainer: ViewStyle
    innerContainer: ViewStyle
    card: ViewStyle
}

export const PollSelectItemStyles: StyleFunction<PollSelectItemStyles> = () => {
    return {
        container: {
            flexDirection: "row",
            marginTop: 10,
        },
        checkboxContainer: {
            justifyContent: "center",
            marginRight: 5,
        },
        innerContainer: {
            flex: 1,
            padding: 16,
            margin: 5,
            opacity: 0.7,
        },
        card: {
            padding: 16,
        },
    }
}
