import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface EditSectionStyles extends Styles {
    section: ViewStyle
    buttonContent: ViewStyle
    textContent: ViewStyle
    helpTextPrimary: TextStyle
    helpTextSecondary: TextStyle
}

export const editSectionStyles: StyleFunction<EditSectionStyles> = ({
    theme,
}) => {
    return {
        section: {
            height: 0,
        },
        buttonContent: {
            height: 50,
            alignItems: "center",
            marginTop: 20,
            marginBottom: 20,
        },
        textContent: {
            alignItems: "center",
            height: 80,
        },
        helpTextPrimary: {
            ...theme.font.helpTextPrimary,
            paddingBottom: 0,
        },
        helpTextSecondary: {
            ...theme.font.helpTextSecondary,
            marginTop: 0,
            paddingBottom: 0,
            textAlign: "center",
        },
    }
}
