import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
    Animated,
    GestureResponderHandlers,
    Pressable,
    TextInput,
} from "react-native"
import { Input } from "@components"
import { PollActionType, PollContext } from "@contexts"
import { getNumberWithOrdinal } from "@helpers/getNumberWithOrdinal"
import { useDebounce } from "@helpers/hooks/useDebounce"
import { useInputConfig } from "@helpers/hooks/useInputConfig"
import useStyles from "@helpers/hooks/useStyles"
import { getValidationMessage } from "@helpers/validationMessage"
import * as Yup from "yup"
import { TextCardTemplateStyles } from "./TextCardTemplate.styles"
import { PollOption } from "@types"
import { useFocus } from "@helpers/hooks/useFocus"
import { PollsCardWrapper } from "@components/PollList/Items/PollsCardWrapper"
import TextCardContent from "@components/PollList/Items/TextCardTemplate/TextCardContent"
import TextCardSkeleton from "@components/PollList/Items/TextCardTemplate/TextCardSkeleton"
import { getUrlsFromString } from "@helpers/getUrlsFromString"
import { isPlatformNative } from "@helpers/isPlatformNative"

interface TextCardTemplateProps {
    isLoadingLink: boolean
    data: PollOption
    index: number
    itemEdit: {
        on: boolean
        index: number
    }
    itemEditMode: boolean
    editMode: boolean
    editVoteMode: boolean
    draftMode: boolean
    totalVoteCount: number
    checked: boolean
    doneTransition: boolean
    canUserRemove: boolean
    handleLinkParsed: (link: string) => void
    onPress: (index: number) => void
    onItemCreated: (index: number) => void
    onVote: (index: number) => void
    onValueChanged: (id: string) => void
    onSeeWhoVoted: () => void
    onDelete: () => void
    panResponderCallbacks: GestureResponderHandlers
}

const TextCardTemplate = ({
    isLoadingLink,
    data,
    index,
    itemEdit,
    itemEditMode,
    editMode,
    editVoteMode,
    draftMode,
    checked,
    totalVoteCount,
    doneTransition,
    canUserRemove,
    handleLinkParsed,
    onPress,
    onItemCreated,
    onVote,
    onValueChanged,
    onSeeWhoVoted,
    onDelete,
    panResponderCallbacks,
}: TextCardTemplateProps) => {
    const { title, voteCount, recentVotes } = data
    const inputRef = useRef<TextInput>(null)
    const inputConfig = useInputConfig("pollOptionInput")

    const { styles } = useStyles(TextCardTemplateStyles)

    const [validationSchema, setValidationSchema] = useState({})
    const [localValue, setValue] = useState<string>("")

    const { pollDispatch } = useContext(PollContext)
    const { setFocusTarget } = useFocus()
    const isNative = isPlatformNative()

    const optionFormSchema = Yup.object().shape(validationSchema)
    const votesCount = voteCount[0]?.count || 0

    useEffect(() => {
        if (title && localValue !== title) setValue(title)
    }, [title])

    useEffect(() => {
        if (inputRef.current && itemEdit.index === index) {
            setFocusTarget("option", inputRef.current, "default")
            onItemCreated?.(index)
        }
    }, [inputRef, itemEdit.index])

    useEffect(() => {
        setValidationSchema((prevState: Record<string, unknown>) => ({
            ...prevState,
            ["pollOptionInput"]: Yup.string()
                .required(getValidationMessage("STRING_TOO_SHORT"))
                .min(
                    inputConfig.minLength ?? 1,
                    getValidationMessage("STRING_TOO_SHORT"),
                )
                .minMultibyte(
                    inputConfig.minLength ?? 0,
                    getValidationMessage("STRING_TOO_SHORT"),
                )
                .max(
                    (inputConfig.maxLength && inputConfig.maxLength - 1) ?? 31,
                    getValidationMessage("STRING_TOO_LONG"),
                ),
        }))
    }, [])

    const setTitle = () => {
        if (itemEdit.on)
            pollDispatch({
                type: PollActionType.SET_OPTION_TEXT,
                payload: { text: localValue, id: data.id },
            })
        onValueChanged(data.id)
    }

    const parseTitleLinks = async () => {
        const parsedUrls = getUrlsFromString(localValue)

        if (parsedUrls && parsedUrls.length) {
            const [first] = parsedUrls

            handleLinkParsed(first)
        }
    }

    useDebounce(setTitle, 100, [localValue])
    useDebounce(parseTitleLinks, 300, [localValue])

    const handleInputChange = async (val: string) => {
        const hasLinks = getUrlsFromString(val)

        if (!hasLinks)
            optionFormSchema
                .validate({ pollOptionInput: val })
                .catch((reason: Error) => {
                    console.log("error", reason.message)
                })

        setValue(val)
    }

    const handleOnVote = () => {
        if (!draftMode && !editMode && !itemEditMode) {
            onVote(index)
        }
    }

    const handleOnPress = () => {
        if (
            inputRef.current &&
            (!itemEdit.on || (!draftMode && itemEditMode))
        ) {
            setFocusTarget("option", inputRef.current, "default")
            onPress?.(index)
        } else {
            handleOnVote()
        }
    }

    const anim = useRef(new Animated.Value(0)).current
    Animated.timing(anim, {
        toValue: doneTransition ? 0.9 : 0,
        duration: 100,
        useNativeDriver: false,
    }).start()

    const cardBody = useMemo(() => {
        if (isLoadingLink) {
            return <TextCardSkeleton />
        }

        if (draftMode || itemEditMode) {
            const pressEvent = isNative
                ? { onFocus: handleOnPress }
                : { onPressIn: handleOnPress }
            return (
                <Input
                    ref={inputRef}
                    shrinkText={false}
                    value={localValue}
                    inputStyle={[
                        styles.input,
                        { fontSize: inputConfig.fontSize },
                    ]}
                    placeholder={`Add your ${getNumberWithOrdinal(
                        index,
                    )} option here`}
                    style={styles.inputContainer}
                    autoCorrect={inputConfig.autoCorrect}
                    onChange={handleInputChange}
                    multiline={true}
                    {...pressEvent}
                    {...inputConfig}
                />
            )
        }

        return (
            <TextCardContent
                title={localValue}
                votesCount={votesCount}
                totalVoteCount={totalVoteCount}
                onSeeWhoVoted={onSeeWhoVoted}
                recentVotes={recentVotes}
                checked={checked}
            />
        )
    }, [
        checked,
        draftMode,
        index,
        inputConfig,
        itemEditMode,
        localValue,
        onSeeWhoVoted,
        recentVotes,
        totalVoteCount,
        votesCount,
        isLoadingLink,
    ])

    return (
        <PollsCardWrapper
            optionID={data.id}
            index={index}
            itemEdit={itemEdit}
            itemEditMode={itemEditMode}
            editMode={editMode}
            editVoteMode={editVoteMode}
            draftMode={draftMode}
            checked={checked}
            doneTransition={doneTransition}
            canUserRemove={canUserRemove}
            handleOnPress={handleOnPress}
            onItemCreated={onItemCreated}
            onDelete={onDelete}
            onVote={onVote}
            panResponderCallbacks={panResponderCallbacks}
        >
            {cardBody}
        </PollsCardWrapper>
    )
}

export default TextCardTemplate
