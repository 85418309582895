import { Inputs } from "@config/inputs"
import { KeyboardTypeOptions } from "react-native"

export interface InputConfig {
    keyboardType?: KeyboardTypeOptions
    placeholder?: string
    textAlign?: "center" | "left" | "right"
    maxLength?: number
    minLength?: number
    numberOfLines?: number
    autoCapitalize?: "none" | "sentences" | "words" | "characters"
    autoCorrect?: boolean
    fontSize?: number
    shrunkenFontSize?: number
    multiline?: boolean
    autoFocus?: boolean
}

export const useInputConfig = (inputName: keyof typeof Inputs): InputConfig =>
    Inputs[inputName] ?? {}
