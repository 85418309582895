import React, { useCallback, useContext, useEffect } from "react"
import {
    AuthModal,
    Button,
    PollList,
    ScreenLayout,
    SharePollModal,
} from "@components"
import useStyles from "@helpers/hooks/useStyles"
import {
    AnalyticsActionType,
    AnalyticsContext,
    DrawerActionType,
    DrawerContext,
    NavigationContext,
    PollActionType,
    PollContext,
    ScreenShotActionType,
    ScreenShotContext,
    UserContext,
} from "@contexts"
import { vibrate } from "@services/vibration"
import { ListSkeleton } from "./components/ListSkeleton"
import { BottomNavigator } from "@components/BottomNavigator"
import links from "@config/links"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { ScreenName } from "@screens"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import { SignInFlow } from "@types"
import { ScreenParamList } from "@navigation/LinkingConfiguration"
import { CustomerInfoModal, ModalName } from "@components/Drawer/content"
import { usePollPermissionsCheck } from "@helpers/hooks/usePollPermissionsCheck"
import { getAppConfig } from "@services/appConfig"
import { BottomBarOption } from "@components/BottomNavigator/config"
import { bottomNavigatorStyles } from "@components/BottomNavigator/BottomNavigator.styles"
import { useTranslation } from "react-i18next"
import { AnalyticsEvent } from "@services/analytics"
import { useToggleHintModal } from "@helpers/hooks/useToggleHintModal"

const VoteScreen = () => {
    const { t } = useTranslation()
    const { styles: bottomBarStyles, theme } = useStyles(bottomNavigatorStyles)

    const {
        pollState: {
            loading,
            updating,
            data: { options },
            editMode,
            editVoteMode,
            sequentialUpdates,
            needUpdating,
            customerSharePoll,
        },
        pollDispatch,
    } = useContext(PollContext)

    const {
        userState: { deviceId, authStatus, isAuthenticating },
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const isAuthenticated = authStatus === AuthenticationState.AUTHENTICATED
    const { drawerDispatch } = useContext(DrawerContext)
    const { screenShotDispatch } = useContext(ScreenShotContext)
    const { navigate } = useContext(NavigationContext)
    const toggleHintModal = useToggleHintModal()

    const {
        tenantConfig: { bottomBarConfig: cfg },
    } = getAppConfig()

    const checkPermissions = usePollPermissionsCheck({
        authModalContent: <AuthModal signInFlow={SignInFlow.editPoll} />,
    })

    const redirect = (screenName: keyof ScreenParamList) => {
        navigate({ screen: screenName })
        vibrate("normalButtonPress")
    }

    const handlePressHome = useCallback(async (): Promise<void> => {
        const nativeNavTarget = isAuthenticated
            ? ScreenName.HOME_SCREEN
            : ScreenName.SIGN_IN_SCREEN

        isPlatformNative()
            ? redirect(nativeNavTarget)
            : window.open(links.pollsUrl, "_blank")
    }, [])

    const handleEditPoll = () => {
        if (checkPermissions()) {
            pollDispatch({
                type: PollActionType.SET_EDIT_MODE,
                payload: {
                    enabled: true,
                },
            })
        }
    }

    const clearShareLink = useCallback(() => {
        screenShotDispatch({
            type: ScreenShotActionType.SET_SHARE_LINK,
            payload: {
                shareLink: "",
            },
        })
    }, [screenShotDispatch])

    const handleShareLink = useCallback(() => {
        if (customerSharePoll) {
            setTimeout(() => {
                pollDispatch({
                    type: PollActionType.SET_CUSTOMER_SHARE_POLL,
                    payload: false,
                })
            }, 500)
        }

        pollDispatch({
            type: PollActionType.REFRESH_POLL_DATA,
            screenShotOnRefresh: true,
        })

        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <SharePollModal />,
                dismissible: true,
                hasCloseButton: true,
                hasBackground: true,
                onDismiss: clearShareLink,
                identifier: ModalName.SHARE_POLL,
            },
        })
    }, [clearShareLink, drawerDispatch, pollDispatch, customerSharePoll])

    const handleInfo = useCallback(() => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                identifier: ModalName.PARTNER_INFO_MODAL,
                content: <CustomerInfoModal />,
                dismissible: true,
                hasCloseButton: true,
                hasBackground: true,
            },
        })
    }, [])

    const filterValidateOptionList = useCallback((): boolean => {
        if (options.filter(item => item.title.length).length >= 2) {
            pollDispatch({ type: PollActionType.FILTER_EMPTY_POLL_OPTIONS })
        }

        return options.filter(item => item.title.length).length >= 2
    }, [options, pollDispatch])

    const submitVote = async () => {
        const event = sequentialUpdates.length
            ? AnalyticsEvent.tapVoteSubmitUpdate
            : AnalyticsEvent.tapVoteSubmitVote

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: event,
            },
        })

        pollDispatch({ type: PollActionType.EXIT_EDIT_MODE })

        const isValid = filterValidateOptionList()

        if (!isValid) {
            drawerDispatch({
                type: DrawerActionType.SHOW_MODAL,
                payload: {
                    title: t("createPollScreen.invalidPollModal.almostThere"),
                    message: t(
                        "createPollScreen.invalidPollModal.pollNeedsTwo",
                    ),
                    buttonTitle: t("okay"),
                    image: theme.image.missingOptionsGraphic,
                    imageSize: {
                        width: 160,
                        height: 160,
                    },
                    identifier: ModalName.ALMOST_THERE,
                },
            })
            return
        }

        vibrate("significantButtonPress")
        if (!deviceId) return

        pollDispatch({
            type: PollActionType.UPDATE_POLL_DATA,
        })
    }

    const disableEditMode = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapEditDone,
            },
        })

        pollDispatch({
            type: PollActionType.SET_EDIT_MODE,
            payload: {
                enabled: false,
            },
        })
    }

    const handleOnSubmit = () => {
        toggleHintModal(false)

        isEditing ? disableEditMode() : submitVote()
    }

    const handleOnAction = (id: BottomBarOption) => {
        toggleHintModal(false)

        switch (id) {
            case BottomBarOption.HOME:
                handlePressHome()
                break
            case BottomBarOption.EDIT:
                handleEditPoll()
                break
            case BottomBarOption.SHARE:
                handleShareLink()
                break
            case BottomBarOption.PROFILE:
                redirect(ScreenName.PROFILE_SCREEN)
                break
            case BottomBarOption.INFO:
                handleInfo()
                break
        }
    }

    useEffect(() => {
        pollDispatch({
            type: PollActionType.UNSET_CREATE_DRAFT_MODE,
        })
    }, [])

    const handleShareAction = () => handleOnAction(BottomBarOption.SHARE)

    const isLoading = loading || isAuthenticating
    const isEditing = editMode && !editVoteMode

    return (
        <ScreenLayout>
            {isLoading ? (
                <ListSkeleton />
            ) : (
                <>
                    <PollList />
                    {customerSharePoll ? (
                        <BottomNavigator config={cfg.sharePoll}>
                            <Button
                                title={t("sharePollText")}
                                onPress={handleShareAction}
                                style={bottomBarStyles.shareSubmitButton}
                            />
                        </BottomNavigator>
                    ) : (
                        <BottomNavigator
                            config={cfg.vote}
                            canSubmitPoll={needUpdating}
                            onAction={handleOnAction}
                            hideIcons={editMode && !editVoteMode}
                        >
                            <Button
                                title={isEditing ? t("done") : t("submit")}
                                disabled={isEditing ? false : !needUpdating}
                                onPress={handleOnSubmit}
                                isLoading={updating}
                                style={bottomBarStyles.submitButton}
                            />
                        </BottomNavigator>
                    )}
                </>
            )}
        </ScreenLayout>
    )
}

export default VoteScreen
