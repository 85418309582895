import { Button, Title } from "@components"
import Avatar from "@components/ProfileAvatar/Avatar"
import { ScreenLayout } from "@components/ScreenLayout"
import {
    AnalyticsActionType,
    AnalyticsContext,
    AppContext,
    PollActionType,
    PollContext,
    UserContext,
} from "@contexts"
import {
    NavigationContext,
    NavigationHandlerProps,
} from "@contexts/NavigationProvider/NavigationReducer"
import useStyles from "@helpers/hooks/useStyles"
import { ScreenParamList } from "@navigation/LinkingConfiguration"
import { ScreenName } from "@screens"
import { AnalyticsEvent } from "@services/analytics"
import React, { useCallback, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Image, Platform, Pressable, ScrollView, View } from "react-native"
import { homeScreenStyles } from "./HomeScreen.styles"
import { PollHistory } from "@screens/HomeScreen/components/PollHistory"
import { useIsFocused } from "@react-navigation/native"

interface HomeScreenView {
    userName: string
    navigate: <T extends keyof ScreenParamList>(
        props: NavigationHandlerProps<T>,
    ) => void
}

const HomeScreenView = React.memo(
    ({ userName, navigate }: HomeScreenView) => {
        const { styles, theme } = useStyles(homeScreenStyles)
        const { t } = useTranslation()
        const { pollDispatch } = useContext(PollContext)
        const {
            appState: { appState },
        } = useContext(AppContext)
        const { analyticsDispatch } = useContext(AnalyticsContext)

        const isFocused = useIsFocused()

        const handlePressAvatar = useCallback(() => {
            analyticsDispatch({
                type: AnalyticsActionType.LOG_ANALYTIC,
                payload: {
                    analyticId: AnalyticsEvent.tapHomeProfile,
                },
            })

            navigate({ screen: ScreenName.PROFILE_SCREEN })
        }, [navigate])

        const handlePressCreatePoll = useCallback(() => {
            navigate({ screen: ScreenName.CREATE_POLL_SCREEN })
        }, [navigate])

        useEffect(() => {
            if (appState === "active" && isFocused)
                pollDispatch({
                    type: PollActionType.REFRESH_POLL_HISTORY,
                })
        }, [appState, isFocused])

        return (
            <ScreenLayout>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.container}>
                        <View style={styles.fixedContainerUpper}>
                            <View style={styles.avatarContainer}>
                                <Pressable onPress={handlePressAvatar}>
                                    <Avatar
                                        letter={userName
                                            .charAt(0)
                                            .toUpperCase()}
                                        variant="small"
                                    />
                                </Pressable>
                            </View>
                            <View style={styles.center}>
                                <Title
                                    title={t(
                                        `homeScreen.pollsTitle.${Platform.OS}`,
                                    )}
                                    style={styles.pageTitle}
                                />
                                <Title
                                    title={t("homeScreen.pollsSubtitle")}
                                    style={styles.pageSubtitle}
                                />
                            </View>
                        </View>
                        <View style={[styles.center, styles.imageContainer]}>
                            <Image
                                source={theme.image.homeScreenImage}
                                style={styles.image}
                            />
                        </View>
                        <View style={styles.fixedContainerLower}>
                            <View style={styles.center}>
                                <Title
                                    title={t("homeScreen.welcomeBack")}
                                    style={styles.greeting}
                                />
                                <Title title={userName} style={styles.name} />
                            </View>
                            <Button
                                variant="primary"
                                title={t("homeScreen.createPoll")}
                                onPress={handlePressCreatePoll}
                                style={styles.createPollButton}
                                analyticsEvent={
                                    AnalyticsEvent.tapHomeCreatePoll
                                }
                            />
                        </View>
                    </View>
                    <PollHistory />
                </ScrollView>
            </ScreenLayout>
        )
    },
    (prev, next) => {
        return (
            prev.userName === next.userName && prev.navigate === next.navigate
        )
    },
)

HomeScreenView.displayName = "HomeScreenView"

const HomeScreen = () => {
    const { navigate } = useContext(NavigationContext)
    const {
        userState: { userName },
    } = useContext(UserContext)
    return <HomeScreenView userName={userName || ""} navigate={navigate} />
}

export default HomeScreen
