import {
    PollOption,
    PollOptionDefault,
    PollOptionLink,
    PollSettings,
} from "@types"

export type RequestPollOption =
    | {
          type: "text"
          id: PollOption["id"]
          title: PollOption["title"]
      }
    | {
          type: "link"
          id: PollOption["id"]
          title: PollOption["title"]
          subtitle: PollOptionLink["subtitle"]
          url: PollOptionLink["url"]
          imageUrl: PollOptionLink["imageUrl"]
          faviconUrl: PollOptionLink["faviconUrl"]
          siteName: PollOptionLink["siteName"]
      }
    | {
          type: "datetime"
          id: PollOption["id"]
          title: PollOption["title"]
          subtitle: string
          startDate: string
          endDate: string
      }
    | {
          type: "defaultTemplate"
          id: PollOptionDefault["id"]
          title: PollOptionDefault["title"]
          subtitle: PollOptionDefault["subtitle"]
          details: PollOptionDefault["details"]
          imageUrl: PollOptionDefault["imageUrl"]
          resourceId: PollOptionDefault["resourceId"]
          url: PollOptionDefault["url"]
      }

export type RequestPollSettings = {
    sendType: string
    seeWhoVoted: boolean
    multipleVotes: boolean
    declareWinner: boolean
    editPoll: boolean
}

const truncateText = (val?: string) => {
    if (!val) return ""

    return val.slice(0, 500)
}

const getOption = (item: PollOption): RequestPollOption => {
    switch (item.type) {
        case "text":
            return {
                id: item.id,
                title: item.title,
                type: item.type,
            }
        case "link":
            return {
                id: item.id,
                title: truncateText(item.title),
                type: item.type,
                subtitle: truncateText(item.subtitle),
                url: item.url,
                imageUrl: item.imageUrl,
                faviconUrl: item.faviconUrl,
                siteName: truncateText(item.siteName),
            }
        case "defaultTemplate":
            return {
                id: item.id,
                title: truncateText(item.title),
                type: item.type,
                subtitle: truncateText(item.subtitle),
                details: truncateText(item.details),
                imageUrl: item.imageUrl,
                resourceId: item.resourceId,
                url: item.url,
            }
        default:
            return {
                id: item.id,
                title: item.title,
                type: "text",
            }
    }
}

export const getRequestPollOptions = (
    optionsList: PollOption[],
): RequestPollOption[] => {
    const requestOptionsList: RequestPollOption[] = []

    optionsList.forEach(item => {
        requestOptionsList.push(getOption(item))
    })

    return requestOptionsList
}

export const getRequestPollSettings = (
    settings: PollSettings,
): RequestPollSettings => {
    return {
        sendType: settings.sendType ?? "",
        seeWhoVoted: settings.seeWhoVoted,
        multipleVotes: settings.multipleVotes,
        declareWinner: !!settings.declareWinner,
        editPoll: settings.editPoll,
    }
}
