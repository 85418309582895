import { Reducer } from "react"
import Logger from "@services/logger"
import { AppStateStatus } from "react-native"

export enum AppActionType {
    APP_STATE = "appState",
    NAV_READY = "navigationReady",
    SET_CONTAINER_HEIGHT = "setContainerHeight",
    SET_EMBEDDED_STATE = "setEmbeddedState",
    SET_TENANT_OVERRIDE = "setTenantOverride",
    SET_OPEN_POLL_FLOW = "setOpenPollFlow",
    SET_STORE_ID = "setStoreId",
}

export enum OpenPollFlow {
    createPoll = "createPoll",
    openPoll = "openPoll",
}

export enum EmbeddedState {
    NONE = "none",
    SDK_MOBILE_APP_IOS = "sdk-mobile-app-ios",
    SDK_MOBILE_APP_ANDROID = "sdk-mobile-app-android",
    SDK_WEB_DESKTOP = "sdk-web-desktop",
    SDK_WEB_MOBILE = "sdk-web-mobile",
}

export type Action =
    | { type: AppActionType.APP_STATE; payload: { appState: AppStateStatus } }
    | { type: AppActionType.NAV_READY; payload: { navReady: boolean } }
    | {
          type: AppActionType.SET_CONTAINER_HEIGHT
          payload: { containerHeight: number }
      }
    | {
          type: AppActionType.SET_EMBEDDED_STATE
          payload: { embeddedState: EmbeddedState }
      }
    | {
          type: AppActionType.SET_TENANT_OVERRIDE
          payload: { tenantKey: string }
      }
    | {
          type: AppActionType.SET_OPEN_POLL_FLOW
          payload: {
              openPollFlow: OpenPollFlow
              openPollId: string
          }
      }
    | {
          type: AppActionType.SET_STORE_ID
          payload: { storeId: string }
      }

export type AppState = {
    appState: AppStateStatus
    navReady: boolean
    keypad: boolean
    containerHeight: number
    embeddedState?: EmbeddedState
    tenantOverrideKey?: string
    openPollFlow: {
        flow: OpenPollFlow
        openPollId?: string
    }
    storeId?: string
}

export const initialState: AppState = {
    appState: "active",
    navReady: false,
    keypad: false,
    containerHeight: 400,
    embeddedState: undefined,
    tenantOverrideKey: undefined,
    openPollFlow: {
        flow: OpenPollFlow.openPoll,
        openPollId: undefined,
    },
    storeId: undefined,
}

export const appReducer: Reducer<AppState, Action> = (state, action) => {
    Logger.info(`ACTION: ${action.type}`)
    switch (action.type) {
        case AppActionType.APP_STATE: {
            const { appState } = action.payload
            return {
                ...state,
                appState,
            }
        }
        case AppActionType.NAV_READY: {
            const { navReady } = action.payload
            return {
                ...state,
                navReady,
            }
        }
        case AppActionType.SET_CONTAINER_HEIGHT: {
            const { containerHeight } = action.payload
            return {
                ...state,
                containerHeight,
            }
        }
        case AppActionType.SET_EMBEDDED_STATE: {
            const { embeddedState } = action.payload
            return {
                ...state,
                embeddedState,
            }
        }
        case AppActionType.SET_TENANT_OVERRIDE: {
            const { tenantKey } = action.payload
            return {
                ...state,
                tenantOverrideKey: tenantKey,
            }
        }
        case AppActionType.SET_OPEN_POLL_FLOW: {
            const { openPollFlow, openPollId } = action.payload
            return {
                ...state,
                openPollFlow: {
                    flow: openPollFlow,
                    openPollId,
                },
            }
        }
        case AppActionType.SET_STORE_ID: {
            const { storeId } = action.payload
            return {
                ...state,
                storeId,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}
