import React, { ReactNode, useContext, useEffect, useReducer } from "react"
import {
    AnalyticsContext,
    analyticsReducer,
    initialState,
} from "./AnalyticsReducer"
import { AnalyticsEvent } from "@services/analytics"
import { DrawerContext, NavigationContext, UserContext } from "@contexts"
import {
    useLogAnalytic,
    useLogListAnalytics,
    useScreenAnalytic,
    useSignInAnalytic,
} from "@helpers/hooks/useAnalyticsActions"

interface AnalyticsContextProviderProps {
    children: ReactNode
}

export const AnalyticsContextProvider = ({
    children,
}: AnalyticsContextProviderProps) => {
    const [analyticsState, analyticsDispatch] = useReducer(
        analyticsReducer,
        initialState,
    )
    const {
        drawerState: { visible: drawerVisible, dismissed: drawerDismissed },
    } = useContext(DrawerContext)
    const { currentScreen } = useContext(NavigationContext)
    const {
        userState: { signInFlow },
    } = useContext(UserContext)

    const {
        analyticId,
        listActionId,
        additionalData,
        listActionTrigger,
        analyticTrigger,
    } = analyticsState

    const logListAnalytics = useLogListAnalytics()
    const logAnalytic = useLogAnalytic(additionalData)
    const logSignInAnalytic = useSignInAnalytic()
    const logScreenAnalytic = useScreenAnalytic()

    useEffect(() => {
        if (!listActionId) return

        logListAnalytics(listActionId)
    }, [listActionTrigger])

    useEffect(() => {
        if (!analyticId) return

        logAnalytic(analyticId)
    }, [analyticTrigger])

    useEffect(() => {
        if (!drawerVisible) return

        const event = drawerDismissed
            ? AnalyticsEvent.dismissModal
            : AnalyticsEvent.viewModal

        logAnalytic(event)
    }, [drawerVisible, drawerDismissed])

    useEffect(() => {
        if (!currentScreen) return

        logScreenAnalytic(currentScreen)
    }, [currentScreen])

    useEffect(() => {
        if (signInFlow === undefined) return

        logSignInAnalytic(signInFlow)
    }, [signInFlow])

    return (
        <AnalyticsContext.Provider
            value={{ analyticsState, analyticsDispatch }}
        >
            {children}
        </AnalyticsContext.Provider>
    )
}
