import React, { useContext, useEffect, useRef, useState } from "react"
import useStyles from "@helpers/hooks/useStyles"
import { PreviewImageStyles } from "./PreviewImage.styles"
// @ts-ignore
import ViewShot from "react-native-view-shot-with-web-support"
import { PreviewImage } from "./PreviewImage"
import { PollContext, ScreenShotContext } from "@contexts"
import { PollOption } from "@types"
import { ScreenShotAction } from "@contexts/ScreenShotProvider/ScreenShotReducer"
import { View } from "react-native"
import { getAppConfig } from "@services/appConfig"
import { Vote } from "@contexts/PollContext/PollReducer"
import orderBy from "lodash/orderBy"

interface ScreenShotContainerViewProps {
    available: boolean
    title: string
    optionsList: PollOption[]
    totalCounter: number
    seeWhoVoted: boolean
    screenShotDispatch: ScreenShotAction
    currentVotes: Vote[]
}

const ScreenShotContainerView = React.memo(
    ({
        available,
        title,
        optionsList,
        totalCounter,
        seeWhoVoted,
        screenShotDispatch,
        currentVotes,
    }: ScreenShotContainerViewProps) => {
        const { styles } = useStyles(PreviewImageStyles)
        const previewRef = useRef<any>()

        const sortList = () => {
            return orderBy(
                optionsList,
                [item => item.voteCount[0]?.count ?? 0],
                ["desc"],
            )
        }

        return (
            <View style={styles.wrapper}>
                <ViewShot style={styles.screenShotContainer} ref={previewRef}>
                    {available && !!optionsList.length && (
                        <PreviewImage
                            sortedList={sortList()}
                            title={title}
                            previewRef={previewRef}
                            totalCounter={totalCounter}
                            seeWhoVoted={seeWhoVoted}
                            screenShotDispatch={screenShotDispatch}
                            currentVotes={currentVotes}
                        />
                    )}
                </ViewShot>
            </View>
        )
    },
    (prev, next) => {
        return (
            prev.available === next.available &&
            prev.optionsList.length === next.optionsList.length
        )
    },
)

ScreenShotContainerView.displayName = "ScreenShotContainerView"

const ScreenShotContainer = () => {
    const { tenantConfig } = getAppConfig()

    const [showPreview, setShowPreview] = useState(false)

    const { screenShotDispatch } = useContext(ScreenShotContext)

    const {
        pollState: {
            data: {
                title,
                options,
                totalVoteCount,
                settings: { seeWhoVoted },
            },
            createPreviewImage,
            currentSessionVotesByUser,
        },
    } = useContext(PollContext)

    useEffect(() => {
        if (createPreviewImage && title.length > 0) {
            setShowPreview(true)
        }
    }, [createPreviewImage, title])

    useEffect(() => {
        if (!createPreviewImage) {
            setShowPreview(false)
        }
    }, [createPreviewImage])

    return (
        <ScreenShotContainerView
            screenShotDispatch={screenShotDispatch}
            available={showPreview}
            title={title}
            optionsList={options}
            totalCounter={totalVoteCount}
            seeWhoVoted={seeWhoVoted || tenantConfig.type === "customer"}
            currentVotes={currentSessionVotesByUser}
        />
    )
}

export default ScreenShotContainer
