import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface ImageWithTextStyles extends Styles {
    content: ViewStyle
    image: ImageStyle
    text: TextStyle
    imageBackground: ViewStyle
    imageBackgroundShadow: ViewStyle
}

export const imageWithTextStyles: StyleFunction<ImageWithTextStyles> = ({
    theme,
}) => {
    return {
        content: {
            justifyContent: "center",
            alignItems: "center",
        },
        imageBackground: {
            borderRadius: 180,
            backgroundColor: theme.color.shareViaAppButtonBackgroundColor,
            width: 65,
            height: 65,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
        },
        imageBackgroundShadow: {},
        image: {
            height: 35,
            width: 35,
        },
        text: {
            paddingTop: 10,
            ...theme.font.textUnderImage,
        },
        WEB: {
            imageBackground: {
                ...theme.shadow.shareViaAppButton.web,
            },
        },
        MOBILE: {
            imageBackground: {
                ...theme.shadow.shareViaAppButton.web,
            },
        },
        NATIVE: {
            imageBackgroundShadow: {
                borderRadius: 180,
                backgroundColor: "transparent",
                ...theme.shadow.shareViaAppButton.native,
            },
        },
    }
}
