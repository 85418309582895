import React, { useEffect, useRef } from "react"
import { Animated, Pressable, View } from "react-native"
import { LottieAnimation } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { checkboxStyles } from "./CheckboxAnimated.styles"

interface CheckboxAnimatedProps {
    checked: boolean
    size?: number
    onPress?: () => void
    square?: boolean
}

const CheckboxAnimated = ({
    checked,
    onPress,
    size = 20,
    square,
}: CheckboxAnimatedProps) => {
    const { styles, theme } = useStyles(checkboxStyles)

    const animStart = square ? 0 : 0.35
    const animEnd = square ? 1 : 0.69
    const anim = useRef(new Animated.Value(animStart)).current

    useEffect(() => {
        toggleCheckbox()
    }, [checked])

    const handleOnPress = () => {
        onPress && onPress()
    }

    const toggleCheckbox = () => {
        Animated.timing(anim, {
            toValue: checked ? animEnd : animStart,
            duration: 300,
            useNativeDriver: false,
        }).start()
    }

    const borderRadius = square ? 7 : size * 0.5

    return (
        <Pressable onPress={handleOnPress}>
            <View style={[styles.circle, { borderRadius }]} />
            <LottieAnimation
                progress={anim}
                source={square ? theme.lottie.checkBox : theme.lottie.checkmark}
                style={{ width: size, height: size }}
            />
        </Pressable>
    )
}

export default CheckboxAnimated
