import useStyles from "@helpers/hooks/useStyles"
import { commonStyles } from "@styles/Main.styles"
import React, { ReactNode, useEffect, useReducer, useRef } from "react"
import { TextInput } from "react-native"
import { FocusContext } from "."
import { initialState, FocusActionType, focusReducer } from "./FocusReducer"

interface FocusContextProviderProps {
    children: ReactNode
}

export const FocusContextProvider = ({
    children,
}: FocusContextProviderProps) => {
    const { styles: defaultStyles } = useStyles(commonStyles)
    const [focusState, focusDispatch] = useReducer(focusReducer, initialState)
    const topInputRef = useRef<TextInput>(null)
    const bottomInputRef = useRef<TextInput>(null)
    const { current, keyboardType } = focusState

    useEffect(() => {
        if (topInputRef.current) {
            focusDispatch({
                type: FocusActionType.SET_FOCUS_TARGET,
                payload: { id: "top", input: topInputRef.current },
            })
        }
    }, [topInputRef])

    useEffect(() => {
        if (bottomInputRef.current) {
            focusDispatch({
                type: FocusActionType.SET_FOCUS_TARGET,
                payload: { id: "bottom", input: bottomInputRef.current },
            })
        }
    }, [bottomInputRef])

    useEffect(() => {
        if (current) {
            current.focus()
        }
    }, [current])

    return (
        <FocusContext.Provider value={{ focusState, focusDispatch }}>
            <TextInput
                style={[defaultStyles.fakeInputTop]}
                ref={topInputRef}
                keyboardType={keyboardType}
            />
            {children}
            <TextInput
                style={[defaultStyles.fakeInputBottom]}
                ref={bottomInputRef}
                keyboardType={keyboardType}
            />
        </FocusContext.Provider>
    )
}
