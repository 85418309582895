import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface PollSelectListHeaderStyles extends Styles {
    outerContainer: ViewStyle
    container: ViewStyle
    containerShadow: ViewStyle
    leftColumn: ViewStyle
    filterButton: ViewStyle
    filterTextAll: TextStyle
    filterTextNone: TextStyle
    divider: ViewStyle
    rightColumn: ViewStyle
    counter: TextStyle
    counterError: TextStyle
    counterText: TextStyle
}

export const PollSelectListHeaderStyles: StyleFunction<
    PollSelectListHeaderStyles
> = ({ theme }) => {
    return {
        outerContainer: {
            width: "100%",
            overflow: "hidden",
            paddingBottom: 10,
            marginBottom: -4,
        },
        container: {
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: -4,
            paddingBottom: 5,
            paddingHorizontal: 20,
        },
        containerShadow: {},
        leftColumn: {
            flexDirection: "row",
        },
        filterButton: {
            justifyContent: "flex-end",
        },
        filterTextAll: {
            ...theme.font.step2FilterAll,
            marginBottom: 4,
        },
        filterTextNone: {
            ...theme.font.step2FilterNone,
            marginBottom: 4,
        },
        divider: {
            width: 2,
            height: 17,
            marginHorizontal: 5,
            marginBottom: 4,
            backgroundColor: theme.color.step2FilterDivider,
            alignSelf: "flex-end",
        },
        rightColumn: {
            flexDirection: "row",
        },
        counter: {
            ...theme.font.step2OptionsSelectedCounterValid,
        },
        counterError: {
            ...theme.font.step2OptionsSelectedCounterInvalid,
        },
        counterText: {
            ...theme.font.step2OptionsCounterText,
            marginBottom: 4,
            marginLeft: 5,
        },
        WEB: {
            container: {
                paddingHorizontal: 120,
            },
            containerShadow: {
                ...theme.shadow.pollHeader.web,
            },
        },
        MOBILE: {
            containerShadow: {
                ...theme.shadow.pollHeader.web,
            },
        },
    }
}
