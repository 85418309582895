import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface PollListSubmitButtonStyles extends Styles {
    button: ViewStyle
}

export const PollListSubmitButtonStyles: StyleFunction<
    PollListSubmitButtonStyles
> = () => {
    return {
        button: {
            alignContent: "flex-end",
            justifyContent: "flex-end",
            bottom: 20,
        },
        MOBILE: {
            button: {
                bottom: 20,
            },
        },
        NATIVE: {
            button: {
                bottom: 40,
            },
        },
    }
}
