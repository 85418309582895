import airbridge from "airbridge-web-sdk-loader"
import { InitOption } from "airbridge-web-sdk-loader/build/airbridge_api"
import {
    AirbridgeServiceType,
    EventProperties,
    UserProperties,
    ValidUserPropertyType,
} from "./AirbridgeTypes"

// init

const init = (options: {
    appName: string
    webToken: string
    deviceId: string
}) => {
    const { appName, webToken, deviceId } = options

    const airbridgeOptions: InitOption = {
        app: appName,
        webToken: webToken,
        user: {
            alias: {
                amplitude_device_id: deviceId,
            },
        },
    }

    airbridge.init(airbridgeOptions)
}

// deep link

const getLatestDeepLink = () => {
    // do nothing on web
    return null
}

const addDeeplinkListener = (listener: (url: string | null) => void) => {
    // do nothing on web
}

// user id

const setUserId = (userId: string) => {
    airbridge.setUserId(userId)
}

const logout = () => {
    airbridge.clearUser()
}

// user property

const setUserProperty = (property: string, value: ValidUserPropertyType) => {
    airbridge.setUserAttributes({ [property]: value })
}

const setUserProperties = (properties: UserProperties) => {
    airbridge.setUserAttributes(properties)
}

// log event

const logEvent = (event: string, eventProperties?: EventProperties) => {
    airbridge.events.send(event, { customAttributes: eventProperties })
}

const AirbridgeService: AirbridgeServiceType | null = {
    // initialization

    init,

    // deep links

    getLatestDeepLink,
    addDeeplinkListener,

    // user id

    setUserId,
    logout,

    // user properties

    setUserProperty,
    setUserProperties,
    // unsetUserPropery,

    // events

    logEvent,
}

export default AirbridgeService
