import React from "react"
import { Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { profileStyles } from "./ProfileAvatar.styles"
import { Icon } from "@assets/icons"

interface AvatarProps {
    letter: string
    variant?: "normal" | "small"
}

const Avatar = ({ letter, variant = "normal" }: AvatarProps) => {
    const { styles, theme } = useStyles(profileStyles)

    return (
        <View style={variant === "small" ? styles.smallAvatar : styles.avatar}>
            {letter ? (
                <Text
                    style={
                        variant === "small"
                            ? styles.smallAvatarLetter
                            : styles.avatarLetter
                    }
                >
                    {letter}
                </Text>
            ) : (
                <Icon
                    style={
                        variant === "small"
                            ? styles.smallAvatarIcon
                            : styles.avatarIcon
                    }
                    name={theme.image.profileAvatar}
                />
            )}
        </View>
    )
}

export default Avatar
