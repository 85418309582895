import { View, ViewStyle } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useEffect, useMemo, useState } from "react"
import { ListBannerStyles } from "./ListBanner.styles"
import { Icon } from "@assets/icons"
import { AnimatedType, AnimatedView, Title } from "@components"

interface ListBannerProps {
    icon: string
    title: string
    subtitle: string
    containerStyle?: ViewStyle
    delayShow?: boolean
}

const ListBanner = ({
    icon,
    title,
    subtitle,
    containerStyle,
    delayShow,
}: ListBannerProps) => {
    const { styles } = useStyles(ListBannerStyles)

    const [visible, setVisible] = useState<boolean>(false)

    const overlayAnim = useMemo(() => {
        return {
            type: AnimatedType.FADE,
            from: 0,
            to: 1,
            duration: delayShow ? 200 : 0,
        }
    }, [visible])

    useEffect(() => {
        setTimeout(setVisible, delayShow ? 500 : 0, true)

        return () => {
            setVisible(false)
        }
    }, [])

    if (!visible) return null

    return (
        <AnimatedView
            animProps={overlayAnim}
            style={[styles.outerContainer, containerStyle]}
        >
            <View style={styles.container}>
                <View style={styles.iconContainer}>
                    <Icon name={icon} style={styles.icon} />
                </View>
                <View>
                    <Title title={title} style={styles.title} />
                    <Title title={subtitle} style={styles.subtitle} />
                </View>
            </View>
        </AnimatedView>
    )
}

export default ListBanner
