import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface CheckboxStyles extends Styles {
    circle: ViewStyle
}

export const checkboxStyles: StyleFunction<CheckboxStyles> = ({ theme }) => {
    return {
        circle: {
            position: "absolute",
            margin: 2,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderWidth: 2.5,
            borderColor: theme.color.checkBox,
        },
    }
}
