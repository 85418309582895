import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface PhoneInputStyles extends Styles {
    container: ViewStyle
    title: TextStyle
    icon: TextStyle
}

export const phoneInputStyles: StyleFunction<PhoneInputStyles> = ({
    theme,
}) => {
    return {
        container: {
            paddingHorizontal: theme.layout.padding.phoneButton,
            marginBottom: 20,
            minHeight: 38,
            maxHeight: 38,
        },
        title: { ...theme.font.phoneButtonText },
        icon: { ...theme.font.phoneButtonIcon },
    }
}
