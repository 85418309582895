import { AppContext } from "@contexts"
import { EmbeddedState } from "@contexts/AppProvider/AppReducer"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import {
    EmbeddedMessageEvent,
    sendWrapperMessage,
} from "@helpers/sendWrapperMessage"
import { useCallback, useContext } from "react"
import { Linking } from "react-native"

export const useRedirect = () => {
    const { isMobileView } = useScreenDimensions()

    // app state

    const {
        appState: { embeddedState },
    } = useContext(AppContext)

    // On mobile web specific we want urls to open in the same tab
    return useCallback(
        (url: string) => {
            if (
                embeddedState === EmbeddedState.SDK_WEB_DESKTOP ||
                embeddedState === EmbeddedState.SDK_WEB_MOBILE
            ) {
                sendWrapperMessage({
                    event: EmbeddedMessageEvent.OPEN_LINK,
                    payload: {
                        url: url,
                        newTab: !isMobileView,
                    },
                })
            } else {
                isMobileView
                    ? (window.location.href = url)
                    : Linking.openURL(url)
            }
        },
        [isMobileView, embeddedState],
    )
}
