import React, { useCallback, useEffect, useMemo, useState } from "react"
import { PixelRatio, Text, View } from "react-native"
import { ScreenShotActionType } from "@contexts"
import { PreviewImageStyles } from "./PreviewImage.styles"
import useStyles from "@helpers/hooks/useStyles"
// @ts-ignore
import { captureRef } from "react-native-view-shot-with-web-support"
import Logger from "@services/logger"
import { PollOption } from "@types"
import { ScreenShotAction } from "@contexts/ScreenShotProvider/ScreenShotReducer"
import PreviewImageCardTemplate from "@components/PreviewImage/PreviewImageCardTemplate"
import { captureMessage } from "@services/sentry"
import { Vote } from "@contexts/PollContext/PollReducer"
import ScreenshotImage from "./ScreenshotImage"

interface PreviewImageProps {
    previewRef: any
    sortedList: PollOption[]
    totalCounter: number
    title: string
    seeWhoVoted: boolean
    screenShotDispatch: ScreenShotAction
    currentVotes: Vote[]
}

export const LAST_CARD_INDEX = 3

const PreviewImage: React.FC<PreviewImageProps> = ({
    previewRef,
    sortedList,
    totalCounter,
    title,
    seeWhoVoted,
    screenShotDispatch,
    currentVotes,
}) => {
    const { styles, theme } = useStyles(PreviewImageStyles)

    const [backgroundCompleted, setBackgroundCompleted] =
        useState<boolean>(false)
    const [cardsLoaded, setCardsLoaded] = useState<boolean>(false)
    const [numCards, setNumCards] = useState<PollOption["id"][] | []>([])

    useEffect(() => {
        if (
            cardsLoaded &&
            (theme.image.previewImageBackground ? backgroundCompleted : true)
        ) {
            setTimeout(() => {
                saveImage()
            }, 100) // EXTRA DELAY - SUPER NEEDED FOR MAKE SCREENSHOT CORRECTLY
        }
    }, [cardsLoaded, backgroundCompleted])

    const saveImage = useCallback(async () => {
        try {
            const result = await captureRef(previewRef, {
                result: "base64",
                format: "jpg",
                quality: 0.9,
            })
            //not empty preview image - more characters than just prefix
            if (result.length > 6) {
                screenShotDispatch({
                    type: ScreenShotActionType.SAVE_PREVIEW_IMAGE,
                    payload: {
                        previewImage: result,
                    },
                })
            }
        } catch (e) {
            captureMessage(JSON.stringify(e))
            Logger.info(JSON.stringify(e))
        }
    }, [])

    const onBackgroundCompleted = () => setBackgroundCompleted(true)

    const handleCardLoaded = useCallback(
        (id: PollOption["id"]) => {
            const loadedCards = [...numCards]
            const endLength =
                sortedList.length <= 3 ? sortedList.length : LAST_CARD_INDEX

            if (loadedCards.some(item => item === id)) return

            loadedCards.push(id)

            setNumCards(loadedCards)

            if (loadedCards.length === endLength) {
                setCardsLoaded(true)
                return
            }
        },
        [numCards],
    )

    // calculations duplicated in styles
    const ratio = PixelRatio.get()
    const previewImageWidth = 932 / ratio
    const listMargin = (theme.image.previewImageBackground ? 25 : 0) / ratio
    const cardHeight = 200 / ratio
    const headerHeight =
        (theme.image.previewImageBackground ? 150 : 130) / ratio
    const previewImageHeight = cardHeight * 3 + listMargin + headerHeight

    const body = useMemo(
        () => (
            <>
                <View style={styles.logoContainer}>
                    <ScreenshotImage
                        src={theme.image.previewImageLogo as string}
                        style={{
                            width: 100 / ratio,
                            height: 100 / ratio,
                        }}
                        targetAspectRatio={{
                            width: 100,
                            height: 100,
                        }}
                    />
                </View>
                <View style={styles.headerContainer}>
                    <Text
                        style={styles.header}
                        numberOfLines={2}
                        ellipsizeMode={"tail"}
                    >
                        {title}
                    </Text>
                </View>
                <View style={styles.listContainer}>
                    {sortedList.slice(0, LAST_CARD_INDEX).map(elem => {
                        const votedOn = currentVotes.some(
                            vote => vote.optionId === elem.id,
                        )

                        return (
                            <PreviewImageCardTemplate
                                key={elem.id}
                                seeWhoVoted={seeWhoVoted}
                                option={elem}
                                totalCounter={totalCounter}
                                onLoaded={handleCardLoaded}
                                isChecked={votedOn}
                            />
                        )
                    })}
                </View>
            </>
        ),
        [currentVotes.length, handleCardLoaded, seeWhoVoted, sortedList.length],
    )

    return (
        <View style={styles.container}>
            {theme.image.previewImageBackground ? (
                <View style={styles.imageBackground}>
                    <ScreenshotImage
                        src={theme.image.previewImageBackground as string}
                        // resizeMode="stretch"
                        onLoad={onBackgroundCompleted}
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            bottom: 0,
                            right: 0,
                        }}
                        targetAspectRatio={{
                            width: previewImageWidth,
                            height: previewImageHeight,
                        }}
                    />
                    {body}
                </View>
            ) : (
                body
            )}
        </View>
    )
}

export { PreviewImage }
