import React, { Dispatch, Reducer } from "react"
import Logger from "@services/logger"
import { KeyboardTypeOptions, TextInput } from "react-native"

export enum FocusActionType {
    SET_FOCUS = "setFocus",
    SET_FOCUS_TARGET = "setFocusTarget",
}

export type Action =
    | {
          type: FocusActionType.SET_FOCUS
          payload: { id: string }
      }
    | {
          type: FocusActionType.SET_FOCUS_TARGET
          payload: {
              id: string
              input: TextInput
              overwrite?: boolean
              keyboardType?: KeyboardTypeOptions
          }
      }

interface FocusTarget {
    input: TextInput
    keyboardType: KeyboardTypeOptions
}
interface FocusState {
    list: { [key: string]: FocusTarget }
    current: TextInput | null
    keyboardType: KeyboardTypeOptions
}

interface FocusContextContent {
    focusState: FocusState
    focusDispatch: FocusAction
}

type FocusAction = Dispatch<Action>

const initialState: FocusState = {
    list: {},
    current: null,
    keyboardType: "default",
}

const FocusContext = React.createContext<FocusContextContent>({
    focusState: initialState,
    focusDispatch: () => initialState,
})

const FocusReducer: Reducer<FocusState, Action> = (
    state: FocusState,
    action: Action,
) => {
    Logger.info("ACTION FocusManager:" + action.type)

    switch (action.type) {
        case FocusActionType.SET_FOCUS: {
            const { id } = action.payload
            const current = state.list[id]?.input || null

            if (!current) {
                console.log("no such focus target registered", id)
            }
            return {
                ...state,
                current,
                keyboardType: state.list[id]?.keyboardType || "default",
            }
        }
        case FocusActionType.SET_FOCUS_TARGET: {
            const { id, input, overwrite, keyboardType } = action.payload
            const shouldOverwrite = overwrite ? overwrite : true
            const data = { ...state.list }
            if (!input) {
                return state
            }
            if (data[id] && !shouldOverwrite) {
                console.log("Target already registered", id)
                return state
            }
            data[id] = {
                input,
                keyboardType: keyboardType || "default",
            }
            return {
                ...state,
                list: data,
            }
        }

        default:
            return state
    }
}

export {
    FocusAction,
    FocusContext,
    FocusReducer as focusReducer,
    FocusState,
    initialState,
}
