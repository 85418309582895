import { Checkbox } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { PollRecentVotes } from "@types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Text, View } from "react-native"
import { hash } from "../hashColors"
import { FlatListItemStyles } from "./FlatListItem.styles"

interface FlatListItemProps {
    item: PollRecentVotes
    index: number
    authenticatedUserId: string | undefined
    pollID: string
}
const FlatListItem: React.FC<FlatListItemProps> = ({
    item,
    index,
    authenticatedUserId,
    pollID,
}) => {
    const { styles, theme } = useStyles(FlatListItemStyles)
    const { t } = useTranslation()

    const isOwner = (): boolean => {
        if (authenticatedUserId && item.voter.id === authenticatedUserId)
            return true
        return false
    }

    const userName = item.voter.name ? item.voter.name : "Anonymous "

    return (
        <View style={styles.itemContainer}>
            <Text style={[styles.title, styles.orderNumberText]}>
                {index + 1}
            </Text>
            <View
                style={[
                    styles.avatar,
                    !isOwner() && {
                        backgroundColor: hash({
                            pollId: pollID,
                            userId: item.voter.id,
                            colors: theme.layout.palette,
                        }),
                    },
                ]}
            >
                {isOwner() ? (
                    <Checkbox size={21} checked={true} onPress={() => null} />
                ) : (
                    <Text style={styles.avatarIcon}>
                        {userName.charAt(0).toLocaleUpperCase()}
                    </Text>
                )}
            </View>
            <Text style={styles.title} numberOfLines={1} ellipsizeMode={"tail"}>
                {userName} {isOwner() && `(${t("me")})`}
            </Text>
        </View>
    )
}

export default FlatListItem
