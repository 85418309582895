import React, {
    CSSProperties,
    ReactEventHandler,
    useEffect,
    useRef,
    useState,
} from "react"

type ScreenshotImageProps = {
    src: string
    style: CSSProperties
    targetAspectRatio: {
        width: number
        height: number
    }
    onLoad?: () => void
}

const ScreenshotImage: React.FC<ScreenshotImageProps> = ({
    src,
    style,
    targetAspectRatio,
    onLoad: delegateOnLoad,
}) => {
    const [imgDimensions, setImageDimensions] = useState<{
        width: number
        height: number
    } | null>(null)

    const hasLoaded = useRef<boolean>(false)

    const isTooWide =
        imgDimensions !== null &&
        imgDimensions.width / imgDimensions.height <=
            targetAspectRatio.width / targetAspectRatio.height

    useEffect(() => {
        if (hasLoaded.current === true) {
            return
        }
        hasLoaded.current = true
        delegateOnLoad?.()
    }, [imgDimensions])

    const constraint = isTooWide
        ? {
              width: "100%",
          }
        : {
              height: "100%",
          }

    const imageDidLoad: ReactEventHandler<HTMLImageElement> = event => {
        const { currentTarget: img } = event
        setImageDimensions({
            width: img.naturalWidth,
            height: img.naturalHeight,
        })
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                ...style,
            }}
        >
            <img
                src={src}
                onLoad={imageDidLoad}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...constraint,
                }}
            />
        </div>
    )
}

export default ScreenshotImage
