import { Icon } from "@assets/icons"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { Pressable, ViewStyle } from "react-native"
import { checkboxStyles } from "./Checkbox.styles"

interface CheckboxProps {
    checked: boolean
    size?: number
    containerStyle?: ViewStyle
    onPress: () => void
}

const Checkbox = ({
    checked,
    size,
    containerStyle,
    onPress,
}: CheckboxProps) => {
    const { styles, theme } = useStyles(checkboxStyles)

    return (
        <Pressable style={containerStyle} onPress={onPress}>
            <Icon
                name={
                    checked
                        ? theme.image.checkboxChecked
                        : theme.image.checkboxUnchecked
                }
                style={styles.checkBoxIcon}
                size={size ?? 36}
            />
        </Pressable>
    )
}

export default Checkbox
