import React, { useContext, useMemo, useState } from "react"
import { Button, TitleInput } from "@components"
import { PollActionType, PollContext } from "@contexts"
import useStyles from "@helpers/hooks/useStyles"
import { useVisualViewport } from "@helpers/hooks/useVisualViewport"
import { AnalyticsEvent } from "@services/analytics"
import { useTranslation } from "react-i18next"
import { LayoutChangeEvent, Text, View } from "react-native"
import { ValidationError } from "yup"
import { pollListHeaderStyles } from "./PollListHeader.styles"
import { useFocus } from "@helpers/hooks/useFocus"

interface PollListHeaderProps {
    autoFocus: boolean
    setHeaderHeight: (val: number) => void
}

export const PollListHeader = ({
    autoFocus,
    setHeaderHeight,
}: PollListHeaderProps) => {
    const { t } = useTranslation()
    const { styles } = useStyles(pollListHeaderStyles)
    const {
        pollState: {
            pollTitle,
            isTitleSet,
            isDataRestored,
            isScrollTop,
            isCreateDraftMode,
        },
        pollDispatch,
    } = useContext(PollContext)
    const { setFocusTop } = useFocus()

    const [inputButtonPressed, setInputButtonPressed] = useState(false)
    const { isMobileWeb } = useVisualViewport()

    const shouldButtonRender = useMemo(() => {
        return isMobileWeb && !inputButtonPressed && !pollTitle && !isTitleSet
    }, [isMobileWeb, inputButtonPressed, pollTitle, isTitleSet])

    const handleInvalidChange = (error: ValidationError | undefined) => {
        if (error?.type === "max") {
            pollDispatch({
                type: PollActionType.SET_ERROR_MESSAGE,
                payload: { message: t("maxCharacters") },
            })
        }
    }

    const handleTapHereButton = () => {
        setFocusTop()
        setInputButtonPressed(true)
    }

    const handleHeaderLayout = (e: LayoutChangeEvent) => {
        setHeaderHeight(e.nativeEvent.layout.height)
    }

    return (
        <View style={styles.container} onLayout={handleHeaderLayout}>
            <View style={styles.bottomShadowContainer}>
                <View style={!isScrollTop && styles.containerShadow}>
                    {shouldButtonRender ? (
                        <Button
                            style={styles.tapToTypeButtonContainer}
                            titleStyle={styles.tapToTypeButtonTitle}
                            title={t("createPollScreen.tapHere")}
                            onPress={handleTapHereButton}
                            analyticsEvent={AnalyticsEvent.tapDraftSetTitle}
                        />
                    ) : (
                        <TitleInput
                            editable={isCreateDraftMode}
                            autoFocus={autoFocus}
                            onInvalidChange={handleInvalidChange}
                            placeholder={t("pollTitle")}
                            title={pollTitle}
                            isDataRestored={isDataRestored}
                            setInputButtonPressed={setInputButtonPressed}
                        />
                    )}
                    {isScrollTop && <View style={styles.divider} />}
                </View>
            </View>
            {!isTitleSet && isCreateDraftMode && (
                <View style={styles.subTitleContainer}>
                    <Text style={styles.subTitle}>{t("setPollTitle")}</Text>
                    <Text style={styles.subTitle2}>
                        {t("whatAreWeDeciding")}
                    </Text>
                </View>
            )}
        </View>
    )
}

export default PollListHeader
