import { httpService } from "@services/http"
import Logger from "@services/logger"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"
import { captureMessage } from "@services/sentry"

interface SendPollData {
    deviceId: string
    pollId: string
    phoneNumber: string
    setIsSmsLoading: Dispatch<SetStateAction<boolean>>
    setSmsSuccess: Dispatch<SetStateAction<boolean>>
    setSmsError: Dispatch<SetStateAction<string>>
    t: TFunction
}

export const sendPollViaSms = async ({
    deviceId,
    pollId,
    phoneNumber,
    setIsSmsLoading,
    setSmsSuccess,
    setSmsError,
    t,
}: SendPollData) => {
    const requestConfig = {
        headers: {
            "polls-device-id": deviceId || "",
        },
    }
    try {
        const pollResponse = await httpService.post(
            `polls/${pollId}/sendViaSms`,
            { phoneNumber },
            requestConfig,
        )
        if (pollResponse.status >= 200 && pollResponse.status < 300) {
            setIsSmsLoading(false)
            setSmsSuccess(true)
            setTimeout(() => {
                setSmsSuccess(false)
            }, 3000)
        }
    } catch (e) {
        setIsSmsLoading(false)
        setSmsError(t("sharePoll.smsError"))
        captureMessage(e)
        Logger.error(`SEND POLL VIA SMS: ${e}`)
    }
}
