import { Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { PollSelectItemStyles } from "./PollSelectItem.styles"
import ListCardTemplateBody from "@components/PollList/Items/LinkCardTemplate/ListCardTemplateBody"
import { PollOption, PollOptionDefault, PollOptionLink } from "@types"
import { Card, CheckboxAnimated } from "@components"

interface PollSelectItemProps {
    data: PollOptionLink | PollOptionDefault
    checked: boolean
    onCheck: (id: PollOption["id"]) => void
}

const PollSelectItem = ({ data, checked, onCheck }: PollSelectItemProps) => {
    const { styles } = useStyles(PollSelectItemStyles)

    const handleCheck = () => onCheck(data.id)

    return (
        <Pressable style={styles.container} onPress={handleCheck}>
            <View style={styles.checkboxContainer}>
                <CheckboxAnimated
                    checked={checked}
                    size={30}
                    onPress={handleCheck}
                    square={true}
                />
            </View>
            {checked ? (
                <Card style={styles.card}>
                    <ListCardTemplateBody
                        data={data}
                        onLinkPress={handleCheck}
                    />
                </Card>
            ) : (
                <View style={styles.innerContainer}>
                    <ListCardTemplateBody
                        data={data}
                        onLinkPress={handleCheck}
                    />
                </View>
            )}
        </Pressable>
    )
}

export default PollSelectItem
