import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface BottomNavigatorWrapperStyles extends Styles {
    outerContainer: ViewStyle
    container: ViewStyle
}

export const BottomNavigatorWrapperStyles: StyleFunction<
    BottomNavigatorWrapperStyles
> = ({ theme }) => {
    return {
        outerContainer: {
            width: "100%",
            marginBottom: 15,
            zIndex: 10,
            bottom: 0,
        },
        container: {
            width: "100%",
            height: 62,
            flexDirection: "row",
            paddingVertical: 2,
            paddingHorizontal: 0,
            alignItems: "center",
            justifyContent: "space-evenly",
            zIndex: 10,
            backgroundColor: theme.color.bottomNavigationBackgroundColor,
            borderRadius: theme.cornerRadius.bottomNavigationCard,
        },
        WEB: {
            outerContainer: {
                paddingHorizontal: 110,
            },
            container: {
                ...theme.shadow.bottomNavigationCard.web,
            },
        },
        MOBILE: {
            outerContainer: {
                paddingHorizontal: 16,
            },
            container: {
                ...theme.shadow.bottomNavigationCard.web,
            },
        },
        NATIVE: {
            container: {
                ...theme.shadow.bottomNavigationCard.native,
            },
        },
    }
}
