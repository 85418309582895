import { Image, Pressable, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { ProgressBar } from "@components"
import { SeeWhoVotedIcons } from "@components/PollList/Items/SeeWhoVotedIcons"
import formatNumber from "@helpers/compactNumberFormatter"
import { Icon, Icons } from "@assets/icons"
import { LinkCardTemplateStyles } from "@components/PollList/Items/LinkCardTemplate/LinkCardTemplate.styles"
import { PollOptionLink } from "@types"
import ListCardTemplateBody from "@components/PollList/Items/LinkCardTemplate/ListCardTemplateBody"

interface ListCardTemplateContentProps {
    data: PollOptionLink
    checked: boolean
    totalVoteCount: number
    hostname: string
    votesCount: number
    onSeeWhoVoted: () => void
    draftMode: boolean
    onLinkPress: () => void
}

const ListCardTemplateContent = ({
    data,
    checked,
    totalVoteCount,
    hostname,
    votesCount,
    onSeeWhoVoted,
    draftMode,
    onLinkPress,
}: ListCardTemplateContentProps) => {
    const { styles, theme } = useStyles(LinkCardTemplateStyles)

    const { recentVotes, faviconUrl } = data

    return (
        <View style={styles.container}>
            <ListCardTemplateBody data={data} onLinkPress={onLinkPress} />
            {!draftMode ? (
                <>
                    <View style={{ marginTop: 7 }}>
                        <ProgressBar
                            votes={votesCount}
                            totalVotes={totalVoteCount}
                        />
                    </View>
                    <View style={styles.bottomRow}>
                        <Pressable
                            style={styles.rowCenter}
                            onPress={onSeeWhoVoted}
                        >
                            <SeeWhoVotedIcons
                                showSeeWhoVotedDrawer={onSeeWhoVoted}
                                recentVotes={recentVotes}
                                checked={checked}
                            />
                            <Text style={styles.counterText}>
                                {votesCount === 1
                                    ? `${votesCount} vote`
                                    : `${formatNumber(votesCount)} votes`}
                            </Text>
                        </Pressable>
                        <Pressable
                            onPress={onLinkPress}
                            style={styles.rowCenter}
                        >
                            {faviconUrl && (
                                <Image
                                    source={{ uri: faviconUrl }}
                                    style={styles.favicon}
                                    resizeMode={"contain"}
                                />
                            )}
                            <Text style={styles.linkText}>{hostname}</Text>
                            <Icon
                                name={Icons.linkOut}
                                color={theme.color.optionCardLinkText}
                                style={styles.linkIcon}
                            />
                        </Pressable>
                    </View>
                </>
            ) : (
                <View style={styles.draftSpacing} />
            )}
        </View>
    )
}

export default ListCardTemplateContent
