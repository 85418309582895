import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { PixelRatio, TextStyle, ViewStyle } from "react-native"

export interface PollsCardWrapperStyles extends Styles {
    container: ViewStyle
    imageBackground: ViewStyle
    contentContainer: ViewStyle
    checkboxContainer: ViewStyle
    voteCountText: TextStyle
    textContainer: ViewStyle
}

export const PollsCardWrapperStyles: StyleFunction<PollsCardWrapperStyles> = ({
    theme,
}) => {
    const ratio = PixelRatio.get()

    return {
        container: {
            height: 200 / ratio,
        },
        imageBackground: {
            width: 932 / ratio,
            height: 200 / ratio,
        },
        contentContainer: {
            flex: 1,
            flexDirection: "row",
            margin: 20 / ratio,
            marginTop: 2 / ratio,
            alignItems: "center",
        },
        checkboxContainer: {
            width: 130 / ratio,
            paddingHorizontal: 10 / ratio,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        voteCountText: {
            ...theme.font.previewImageVotesCountText,
            fontSize: theme.font.previewImageVotesCountText.fontSize / ratio,
            lineHeight: theme.font.previewImageVotesCountText.lineHeight / ratio,
            marginTop: 10 / ratio,
        },
        textContainer: {
            paddingVertical: 25 / ratio,
            paddingLeft: 20 / ratio,
            paddingRight: 30 / ratio,
            width: 700 / ratio,
            flex: 1,
            height: "100%",
        },
    }
}
