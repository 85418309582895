const formatNumber = (number: number) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find(item => {
            return number >= item.value
        })

    return item
        ? Number((number / item.value).toString().match(/^\d+(?:\.\d{0,1})?/))
              .toString()
              .replace(rx, "$1") + item.symbol
        : "0"
}

export default formatNumber
