import { UserContext } from "@contexts/UserDataProvider/UserReducer"
import {
    DrawerActionType,
    DrawerContext,
    PollActionType,
    ScreenShotContext,
} from "@contexts"
import { useCallback, useContext } from "react"
import { t } from "i18next"
import { PollAction } from "@contexts/PollContext/PollReducer"
import { launchPoll } from "@services/poll/launchPoll"
import { storageService } from "@services/storage"
import { PollData, PollOption, PollSettings, StorageKeys } from "@types"
import Logger from "@services/logger"
import { ThemeContext } from "@contexts/ThemeContext"
import { ModalName } from "@components/Drawer/content"
import {
    getRequestPollOptions,
    getRequestPollSettings,
} from "@helpers/getRequestPoll"

interface callbackProps {
    title: PollData["title"]
    optionsList: PollOption[]
    pollSettings: PollSettings
}

export const useLaunchPollFlow = (pollDispatch: PollAction) => {
    const {
        userState: { deviceId },
    } = useContext(UserContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { screenShotDispatch } = useContext(ScreenShotContext)
    const { theme } = useContext(ThemeContext)

    return useCallback(
        async ({ title, optionsList, pollSettings }: callbackProps) => {
            Logger.info("FLOW: launchPoll initialized")

            const { isSuccess, data } = await launchPoll({
                deviceId,
                optionsList: getRequestPollOptions(optionsList),
                title,
                pollSettings: getRequestPollSettings(pollSettings),
            })

            if (isSuccess && data) {
                pollDispatch({
                    type: PollActionType.SET_DATA,
                    payload: { data, pollTitle: "" },
                })

                pollDispatch({
                    type: PollActionType.SET_POLL_ID,
                    payload: data.id,
                })

                pollDispatch({
                    type: PollActionType.SET_LAUNCHED,
                    payload: {
                        poll: data,
                    },
                })

                pollDispatch({
                    type: PollActionType.CREATE_PREVIEW_IMAGE,
                })

                storageService.setItem(StorageKeys.POLL_OPTIONS, "")
                storageService.setItem(StorageKeys.POLL_TITLE, "")
            } else {
                drawerDispatch({
                    type: DrawerActionType.DISMISS_DRAWER,
                })

                drawerDispatch({
                    type: DrawerActionType.SHOW_MODAL,
                    payload: {
                        identifier: ModalName.FAILED_TO_LAUNCH_POLL,
                        title: t("oops"),
                        message:
                            t("launchPoll.couldntLaunchThePoll") +
                            " " +
                            t("launchPoll.checkConnection"),
                        image: theme.image.launchPollFailedGraphic,
                        imageSize: {
                            height: 105,
                            width: 131,
                        },
                        buttonTitle: t("okay"),
                        delay: 0,
                    },
                })
            }

            pollDispatch({
                type: PollActionType.LAUNCH_POLL_DATA_READY,
            })
        },
        [deviceId, drawerDispatch, pollDispatch, screenShotDispatch, t],
    )
}
