import { createIconSetFromIcoMoon } from "@expo/vector-icons"
import selection from "./selection.json"

type IconSet = {
    pencil: string
    lock: string
    phone: string
    eye: string
    checkSingle: string
    checkDouble: string
    checkboxChecked: string
    checkboxUnchecked: string
    send: string
    home: string
    profile: string
    share: string
    edit: string
    linkOut: string
    dismiss: string
    discard: string
    back: string
    optionRemove: string
    optionLocked: string
    group: string
    info: string
    copy: string
    list: string
}
export const Icons: IconSet = {
    pencil: "pencil",
    lock: "lock",
    eye: "eye",
    phone: "phone",
    checkSingle: "check-single",
    checkDouble: "check-double",
    checkboxChecked: "checkbox-checked",
    checkboxUnchecked: "checkbox-unchecked",
    send: "send",
    home: "home",
    profile: "profile",
    share: "share",
    edit: "edit",
    linkOut: "link-out",
    dismiss: "dismiss",
    discard: "discard",
    back: "back",
    optionRemove: "option-remove",
    optionLocked: "option-locked",
    group: "group",
    info: "info",
    copy: "copy",
    list: "list",
}

export const Icon = createIconSetFromIcoMoon(
    selection,
    "IcoMoon",
    "icomoon.ttf",
)
