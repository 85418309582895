import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface ScreenLayoutStyles extends Styles {
    container: ViewStyle
    screenContainer: ViewStyle
    contentCenter: ViewStyle
    input: ViewStyle
    fakeInputBottom: ViewStyle
    fakeInputTop: ViewStyle
    rowFlex: ViewStyle
}

export const commonStyles: StyleFunction<ScreenLayoutStyles> = ({ theme }) => {
    return {
        container: {
            flex: 1,
            width: "100%",
            height: "100%",
        },
        screenContainer: {
            flex: 1,
            width: "100%",
            height: "100%",
        },
        input: {
            width: 200,
        },
        contentCenter: {
            alignItems: "center",
        },
        fakeInputTop: {
            position: "absolute",
            top: 0,
            color: "transparent",
            height: 0.03,
            zIndex: -10,
        },
        fakeInputBottom: {
            position: "absolute",
            bottom: 200,
            color: "transparent",
            height: 0.03,
            zIndex: -10,
            backgroundColor: "green",
        },
        rowFlex: {
            flex: 1,
            flexDirection: "row",
        },
        MOBILE: {
            screenContainer: {
                paddingHorizontal: 12,
            },
            fakeInputBottom: {
                outlineStyle: "none",
            },
            fakeInputTop: {
                outlineStyle: "none",
            },
        },
        WEB: {
            screenContainer: {
                paddingHorizontal: 110,
            },
            fakeInputBottom: {
                outlineStyle: "none",
            },
            fakeInputTop: {
                outlineStyle: "none",
            },
        },
    }
}
