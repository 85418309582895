import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ConfirmLogoutModalStyles extends Styles {
    container: ViewStyle
    subheader: TextStyle
    card: ViewStyle
    divider: ViewStyle
    buttons: ViewStyle
    title: TextStyle
    buttonLogout: ViewStyle
    buttonLogoutText: TextStyle
    buttonCancel: ViewStyle
    buttonCancelText: TextStyle
}

export const ConfirmLogoutModalStyles: StyleFunction<
    ConfirmLogoutModalStyles
> = ({ theme }) => {
    return {
        container: {
            alignItems: "center",
            paddingHorizontal: 10,
            paddingVertical: 10,
        },
        buttons: {
            flex: 1,
        },
        title: {
            ...theme.font.logoutModalTitle, //perhaps notificationPrimaryText
            marginBottom: 10,
        },
        buttonLogout: {},
        buttonLogoutText: {
            ...theme.font.logoutModalLogoutButtonText,
        },
        buttonCancel: {},
        buttonCancelText: {
            ...theme.font.logoutModalCancelButtonText,
        },
        subheader: {
            ...theme.font.logoutModalSubtile,
            paddingBottom: 10,
            marginVertical: 10,
        },
        card: {
            width: "100%",
            padding: 20,
            borderRadius: theme.cornerRadius.modal,
        },
        divider: {
            width: "100%",
            height: 1,
            backgroundColor: theme.color.modalDivider,
        },
        NATIVE: {
            container: {
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
            },
            card: {
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 20,
                padding: 10,
                backgroundColor: theme.color.modalBackground,
                ...theme.shadow.modal.native,
            },
        },
        MOBILE: {
            container: {
                flex: 1,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
            },
            card: {
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                margin: 10,
                padding: 10,
                backgroundColor: theme.color.modalBackground,
                ...theme.shadow.modal.web,
            },
            buttonLogout: { marginTop: 10 },
        },
        WEB: {
            container: {
                padding: 0,
            },
            card: {
                backgroundColor: theme.color.modalBackground,
                ...theme.shadow.modal.native,
                padding: 20,
            },
            subheader: {
                ...theme.font.logoutModalSubtileDesktop,
                marginBottom: 40,
            },
            buttons: {
                flexDirection: "row-reverse",
            },
            buttonLogout: {
                marginLeft: 10,
                marginRight: 10,
            },
            buttonLogoutText: {
                ...theme.font.logoutModalSmallLogoutButtonText,
            },
            buttonCancel: {
                marginLeft: 10,
                marginRight: 10,
            },
            buttonCancelText: {
                ...theme.font.logoutModalSmallCancelButtonText,
            },
        },
    }
}
