import { Text } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { Dispatch, SetStateAction, useContext, useState } from "react"
import { ResendCodeStyles } from "./ResendCode.styles"
import { LottieAnimation } from "@components"
import { AnalyticsActionType, AnalyticsContext, UserContext } from "@contexts"
import { useTranslation } from "react-i18next"
import { authenticateUser } from "@services/amplify"
import { useCodeResendAction } from "@helpers/hooks/useAnalyticsActions"

interface ResendCodeProps {
    setErrorMessage: Dispatch<SetStateAction<string>>
}

const ResendCode = ({ setErrorMessage }: ResendCodeProps) => {
    const { t } = useTranslation()
    const { styles, theme } = useStyles(ResendCodeStyles)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {
        userState: { phoneNumber },
        userDispatch,
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const getResendCodeAction = useCodeResendAction()

    const resendCode = async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: getResendCodeAction(),
            },
        })

        if (!phoneNumber) return
        setIsLoading(true)
        await authenticateUser({
            phoneNumber,
            userDispatch,
            setErrorMessage,
        })
        setIsLoading(false)
    }

    return !isLoading ? (
        <Text style={styles.resendText} onPress={resendCode}>
            {t("verificationCodeModal.resendCode")}
        </Text>
    ) : (
        <LottieAnimation
            style={styles.spinner}
            source={theme.lottie.spinner}
            autoPlay
            loop
        />
    )
}

export default ResendCode
