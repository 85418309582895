import React from "react"
import { t } from "i18next"
import { CARD_HEIGHT, EDIT_SECTION_EXTRA_HEIGHT } from "@config/pollList"
import { View } from "react-native"
import { ListItem, PollOption } from "@types"
import PollListItem from "@components/PollList/PollListItem"
import { ListEditSection } from "@components"
import { DoneTransition, ItemEdit } from "@components/PollList/PollListView"
import { Vote } from "@contexts/PollContext/PollReducer"
import { AnalyticsListActions } from "@contexts/AnalyticsProvider/AnalyticsReducer"
import { UserState } from "@contexts/UserDataProvider/UserReducer"

interface PollListItemsRender {
    testID?: string
    localData: ListItem[]
    itemEdit: ItemEdit
    currentSessionVotesByUser: Vote[]
    userId: string
    userName: UserState["userName"]
    pollOwnerId: string
    editMode: boolean
    editVoteMode: boolean
    draftMode: boolean
    totalVoteCount: number
    doneTransition: DoneTransition
    handleOnItemPress: (index: number) => void
    handleOnVote: (index: number) => void
    onOpenOptionLink: (id: string, index: number, url: string) => void
    handleSwapIndex: (callerId: string, from: number, to: number) => void
    handleRemove: (id: string) => void
    onItemStartDrag: (index: number) => void
    onItemStopDrag: (id: string) => void
    handleSeeWhoVoted: (data: PollOption, checked: boolean) => void
    handleLinkOptionParsed: (linkData: any, id: PollOption["id"]) => void
    logListAnalytic: (listActionId: AnalyticsListActions) => void
    isAddButtonVisible: boolean
    isHelpTextVisible: boolean
    handleAddOne: () => void
    displayAddOption: boolean
    onItemChanged: () => void
}

const PollListItemsRender = React.memo(
    ({
        localData,
        testID,
        itemEdit,
        currentSessionVotesByUser,
        userId,
        pollOwnerId,
        editMode,
        editVoteMode,
        draftMode,
        totalVoteCount,
        doneTransition,
        handleOnItemPress,
        handleOnVote,
        onOpenOptionLink,
        handleSwapIndex,
        handleRemove,
        onItemStartDrag,
        onItemStopDrag,
        handleSeeWhoVoted,
        handleLinkOptionParsed,
        logListAnalytic,
        isAddButtonVisible,
        isHelpTextVisible,
        handleAddOne,
        displayAddOption,
        onItemChanged,
    }: PollListItemsRender) => {
        let editSectionTitle
        let editSectionSubTitle = ""

        if (localData.length === 0) {
            editSectionTitle = t("addOption")
            editSectionSubTitle = t("whatCanWeVote")
        } else if (localData.length === 25) {
            editSectionTitle = t("maxOptions")
        } else {
            editSectionTitle = t("addTwoOptions")
            editSectionSubTitle = t("youCantVoteOne")
        }

        const editSectionOffset = CARD_HEIGHT * localData.length + 1
        const containerHeight =
            localData.length * CARD_HEIGHT + EDIT_SECTION_EXTRA_HEIGHT

        return (
            <View
                testID={`${testID}-view`}
                style={{
                    height: containerHeight,
                }}
            >
                {localData.map((item: ListItem) => {
                    const { on, index: eIndex } = itemEdit
                    const isVisible = !on || eIndex >= item.index
                    const votedOn = currentSessionVotesByUser.some(
                        vote => vote.optionId === item.data.id,
                    )
                    const isTransitionDone =
                        doneTransition.transition &&
                        doneTransition.index === item.index

                    const canUserRemove =
                        item.data.ownerId === userId || pollOwnerId === userId

                    const onSeeWhoVoted = () =>
                        handleSeeWhoVoted(item.data, votedOn)

                    return (
                        <PollListItem
                            id={item.id}
                            testID={`item-${item.id}`}
                            key={item.id}
                            data={item.data}
                            index={item.index}
                            itemEdit={itemEdit}
                            visible={isVisible}
                            editMode={editMode}
                            editVoteMode={editVoteMode}
                            draftMode={draftMode}
                            votedOn={votedOn}
                            totalVoteCount={totalVoteCount}
                            doneTransition={isTransitionDone}
                            canUserRemove={canUserRemove}
                            onPress={handleOnItemPress}
                            onVote={handleOnVote}
                            onOpenOptionLink={onOpenOptionLink}
                            onDelete={handleRemove}
                            onStartDrag={onItemStartDrag}
                            onStopDrag={onItemStopDrag}
                            onSwapIndex={handleSwapIndex}
                            onValueChanged={onItemChanged}
                            onSeeWhoVoted={onSeeWhoVoted}
                            onLinkOptionParsed={handleLinkOptionParsed}
                            logListEvent={logListAnalytic}
                        />
                    )
                })}
                {displayAddOption && (
                    <ListEditSection
                        style={{ top: editSectionOffset }}
                        buttonVisible={isAddButtonVisible}
                        textVisible={isHelpTextVisible}
                        title={editSectionTitle}
                        subtitle={editSectionSubTitle}
                        logListEvent={logListAnalytic}
                        onAddOption={handleAddOne}
                    />
                )}
            </View>
        )
    },
    (prev, next) => {
        return (
            prev.itemEdit.index === next.itemEdit.index &&
            prev.isHelpTextVisible === next.isHelpTextVisible &&
            prev.isAddButtonVisible === next.isAddButtonVisible &&
            prev.localData === next.localData &&
            prev.editMode === next.editMode &&
            prev.currentSessionVotesByUser.length ===
                next.currentSessionVotesByUser.length &&
            prev.doneTransition === next.doneTransition &&
            prev.userId === next.userId &&
            prev.userName === next.userName
        )
    },
)

PollListItemsRender.displayName = "PollListItemRender"

export default PollListItemsRender
