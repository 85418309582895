import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface TextCardTemplateStyles extends Styles {
    input: TextStyle
    itemText: TextStyle
    counterText: TextStyle
    inputContainer: ViewStyle
    contentColumn: ViewStyle
    votesContainer: ViewStyle
    flexStart: ViewStyle
    skeletonImage: ImageStyle
    skeletonContentContainer: ViewStyle
}

export const TextCardTemplateStyles: StyleFunction<TextCardTemplateStyles> = ({
    theme,
}) => {
    return {
        input: {
            ...theme.font.optionCardInputText,
            paddingVertical: 2,
            minHeight: 50,
            marginHorizontal: 6,
            marginVertical: 6,
            borderRadius: 0,
            shadowColor: "transparent",
            borderColor: "transparent",
        },
        inputContainer: {
            shadowColor: "transparent",
            minWidth: "100%",
        },
        contentColumn: {
            flex: 1,
            marginTop: 13,
        },
        itemText: {
            ...theme.font.optionCardInputText,
            flex: 1,
        },
        counterText: {
            ...theme.font.optionCardTextVoteCount,
        },
        votesContainer: {
            flexDirection: "row",
            alignItems: "center",
            marginTop: 13,
            height: 18,
        },
        flexStart: {
            alignItems: "flex-start",
            justifyContent: "flex-start",
        },
        skeletonImage: {
            height: 70,
            width: 102,
            marginRight: 10,
        },
        skeletonContentContainer: {
            flex: 1,
            height: 70,
            justifyContent: "center",
        },
        NATIVE: {
            input: {
                marginVertical: 0,
                textAlignVertical: "top",
                height: "100%",
            },
        },
        WEB: {
            itemText: {
                userSelect: "none",
            },
            counterText: { userSelect: "none" },
        },
        MOBILE: {
            itemText: {
                userSelect: "none",
            },
            counterText: { userSelect: "none" },
        },
    }
}
