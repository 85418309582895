import {
    AnalyticsActionType,
    AnalyticsContext,
    PollActionType,
    PollContext,
} from "@contexts"
import { AppContext } from "@contexts/AppProvider"
import { DrawerActionType, DrawerContext } from "@contexts/DrawerProvider"
import { DrawerAction } from "@contexts/DrawerProvider/DrawerProvider"
import {
    UserAction,
    UserActionType,
    UserContext,
} from "@contexts/UserDataProvider"
import { ScreenParamList } from "@navigation/LinkingConfiguration"
import { useNavigation, useNavigationState } from "@react-navigation/core"
import { StackActions } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { ScreenName } from "@screens"
import Logger from "@services/logger"
import { openURL } from "expo-linking"
import { noop } from "lodash"
import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react"
import { BackHandler, Platform } from "react-native"
import { ModalName } from "@components/Drawer/content"
import { getAppConfig } from "@services/appConfig"
import { ThemeContext } from "@contexts/ThemeContext"
import { getThemeByQueryParam } from "@theme/Theme"
import { AnalyticsAction } from "@contexts/AnalyticsProvider"
import { useCodeBackAction } from "@helpers/hooks/useAnalyticsActions"
import { PollData } from "@types"
import {
    AppActionType,
    EmbeddedState,
    OpenPollFlow,
} from "@contexts/AppProvider/AppReducer"
import { setEmbeddedState, setStoreId } from "@services/analytics"
import { getTrackedLink } from "@helpers/getTrackedLink"

export type NavigationHandlerProps<T extends keyof ScreenParamList> = {
    screen: T
    transition?: string
    params?: ScreenParamList[T]
}

export interface NavigationProviderContextInterface {
    currentScreen: keyof ScreenParamList | undefined
    screenTransition: string
    canGoBack: boolean
    navigate: <T extends keyof ScreenParamList>(
        props: NavigationHandlerProps<T>,
    ) => void
    push: (screen: ScreenName) => void
    goBack: () => void
}

export const NavigationContext =
    createContext<NavigationProviderContextInterface>({
        currentScreen: undefined,
        screenTransition: "initial",
        canGoBack: true,
        navigate: noop,
        push: noop,
        goBack: noop,
    })

interface NavigationProviderViewProps {
    navigation: StackNavigationProp<ScreenParamList, keyof ScreenParamList>
    children: ReactNode
    navReady: boolean
    isWeb: boolean
    isPhoneNumberEntered: boolean
    userDispatch: UserAction
    isDrawerVisible: boolean
    isDrawerActive: boolean
    isDrawerDismissible: boolean
    drawerDispatch: DrawerAction
    analyticsDispatch: AnalyticsAction
    pollParams: {
        isLaunched: boolean
        pollId: string
        state: PollData["state"]
    }
    toggleSharePoll: () => void
    handleSetTenantOverride: (val: string) => void
    handleSetEmbeddedState: (val: string) => void
    handleSetStoreId: (val: string) => void
    handleSetOpenPollFlow: (flowParam: string, pollId: string) => void
    handlePollPending: () => void
    navState: any
}

const NavigationProviderView = React.memo(
    ({
        navigation,
        children,
        navReady,
        isWeb,
        isPhoneNumberEntered,
        userDispatch,
        isDrawerActive,
        isDrawerDismissible,
        isDrawerVisible,
        drawerDispatch,
        analyticsDispatch,
        pollParams,
        toggleSharePoll,
        handleSetTenantOverride,
        handleSetStoreId,
        handleSetEmbeddedState,
        handleSetOpenPollFlow,
        handlePollPending,
        navState,
    }: NavigationProviderViewProps) => {
        const [screenTransition, setScreenTransition] = useState("initial")
        const [currentScreen, setCurrentScreen] = useState<
            keyof ScreenParamList | undefined
        >(undefined)
        const { tenantConfig } = getAppConfig()
        const getCodeBackAction = useCodeBackAction(currentScreen)

        const exitApp = () => {
            drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
            BackHandler.exitApp()
        }

        const handleExit = useCallback(() => {
            drawerDispatch({
                type: DrawerActionType.SHOW_MODAL,
                payload: {
                    title: "Exit App",
                    message: "Do you really want to exit app?",
                    buttonTitle: "Exit",
                    onAction: exitApp,
                    identifier: ModalName.EXIT_APP,
                },
            })
        }, [])

        const [canGoBack, setCanGoBack] = useState(false)

        const getCurrentRouteName = useCallback(() => {
            if (navigation) {
                const navState = navigation.getState()
                if (navState) {
                    const { index, routes } = navState
                    const currentIndex = index | 0
                    const childState = routes[currentIndex].state

                    if (childState && childState?.routes && childState.index) {
                        const childRoute = childState.routes[childState.index]

                        setCurrentScreen(
                            childRoute.name as keyof ScreenParamList,
                        )
                        return
                    }
                    const themeName = routes[currentIndex].params?.theme
                    const share = routes[currentIndex].params?.share
                    const flow = routes[currentIndex].params?.flow
                    const embeddedState =
                        routes[currentIndex].params?.embeddedState
                    const pending = routes[currentIndex].params?.pending
                    const storeId = routes[currentIndex].params?.storeId

                    if (themeName) {
                        handleSetTenantOverride(themeName)
                    }

                    if (storeId) {
                        handleSetStoreId(storeId)
                    }

                    if (tenantConfig.type === "customer" && share) {
                        toggleSharePoll()
                        navigation.setParams({
                            ...routes[currentIndex].params,
                            share: undefined,
                        })
                    }

                    if (flow) {
                        const pollId = routes[currentIndex].params?.pollId
                        if (pollId) {
                            handleSetOpenPollFlow(flow, pollId)
                        }
                        navigation.setParams({
                            ...routes[currentIndex].params,
                            flow: undefined,
                        })
                    }

                    if (pending) {
                        handlePollPending()
                        navigation.setParams({
                            ...routes[currentIndex].params,
                            pending: undefined,
                        })
                    }

                    if (embeddedState) {
                        handleSetEmbeddedState(embeddedState)
                    }

                    setCurrentScreen(routes[currentIndex].name)
                }
            }
        }, [navigation, pollParams.isLaunched])

        const checkCanGoBack = useCallback(
            routeName => {
                Logger.info(JSON.stringify(`canGoBack ${routeName}`))
                if (!routeName) {
                    setCanGoBack(true)
                    return
                }

                if (isDrawerActive) {
                    setCanGoBack(false)
                    return
                }

                switch (routeName) {
                    case ScreenName.VOTE_SCREEN:
                        setCanGoBack(!isWeb)
                        return
                    case ScreenName.PROFILE_SCREEN:
                        setCanGoBack(true)
                        setScreenTransition("forward")
                        return
                    case ScreenName.CREATE_POLL_SCREEN:
                    case ScreenName.SIGN_IN_SCREEN_CONFIRM:
                        setCanGoBack(true)
                        return
                    case ScreenName.SELECT_POLL_SCREEN:
                    case ScreenName.GET_APP_SCREEN:
                    case ScreenName.HOME_SCREEN:
                    case ScreenName.SIGN_IN_SCREEN:
                    case ScreenName.CONSENT_SCREEN:
                    case "HomeStack":
                        setCanGoBack(false)
                        return
                    default:
                        setCanGoBack(true)
                        return true
                }
            },
            [isDrawerActive, isWeb],
        )

        useEffect(() => {
            if (navReady && currentScreen) {
                checkCanGoBack(currentScreen)
            }
        }, [checkCanGoBack, currentScreen, navReady])

        useEffect(() => {
            getCurrentRouteName()
        }, [navReady, navState, navigation])

        const navigate = useCallback(
            ({
                screen,
                params,
                transition,
            }: NavigationHandlerProps<keyof ScreenParamList>) => {
                setScreenTransition(transition ?? "")
                navigation.navigate(screen, params)
            },
            [navigation],
        )

        const push = useCallback(
            (screen: ScreenName) => {
                navigation.dispatch(StackActions.push(screen))
            },
            [navigation],
        )

        const handleModalException = useCallback(() => {
            if (isDrawerActive) {
                if (isPhoneNumberEntered) {
                    userDispatch({
                        type: UserActionType.SET_IS_PHONE_NUMBER_ENTERED,
                        payload: { isPhoneNumberEntered: false },
                    })
                    return true
                }

                if (isDrawerDismissible) {
                    drawerDispatch({
                        type: DrawerActionType.DISMISS_DRAWER,
                    })
                }

                return true
            }

            if (isDrawerVisible) {
                if (isDrawerDismissible) {
                    drawerDispatch({
                        type: DrawerActionType.DISMISS_DRAWER,
                    })
                }

                return true
            }
            return false
        }, [
            isDrawerActive,
            isDrawerDismissible,
            isPhoneNumberEntered,
            isDrawerVisible,
        ])

        // back button interceptor
        const goBack = useCallback(() => {
            Logger.info("=====> Going back")
            setCanGoBack(false)
            if (handleModalException()) {
                return true
            }

            logCodeBackEvent()

            if (isWeb && !isDrawerVisible) {
                if (currentScreen === ScreenName.CREATE_POLL_SCREEN) {
                    openURL(getTrackedLink(tenantConfig.homeUrl))
                } else {
                    setScreenTransition("back")
                    setTimeout(navigateBack, 260)
                }
            } else {
                navigateBack()
            }

            return true
        }, [currentScreen, handleModalException, isDrawerVisible, isWeb])

        const navigateBack = useCallback(() => {
            if (!currentScreen) {
                openURL(getTrackedLink(tenantConfig.homeUrl))
                return
            }

            setScreenTransition("bringBack")

            switch (currentScreen) {
                case ScreenName.PROFILE_SCREEN:
                    if (isWeb) {
                        navigation.canGoBack()
                            ? navigation.goBack()
                            : navigation.navigate(ScreenName.CREATE_POLL_SCREEN)
                    } else {
                        navigation.goBack()
                    }
                    return true
                case ScreenName.VOTE_SCREEN:
                    if (isWeb) {
                        openURL(getTrackedLink(tenantConfig.homeUrl))
                    } else {
                        navigation.navigate(ScreenName.HOME_SCREEN)
                    }
                    return true
                case ScreenName.SIGN_IN_SCREEN:
                    handleExit()
                    return true
                case ScreenName.SIGN_IN_SCREEN_CONFIRM:
                    userDispatch({
                        type: UserActionType.SET_IS_PHONE_NUMBER_ENTERED,
                        payload: { isPhoneNumberEntered: false },
                    })
                    navigation.navigate(ScreenName.SIGN_IN_SCREEN)
                    return true
                case ScreenName.CONSENT_SCREEN:
                    handleExit()
                    return true
                case ScreenName.HOME_SCREEN:
                    handleExit()
                    return true
                case ScreenName.THEME_SCREEN:
                    navigation.goBack()
                    return true
                default:
                    navigation.goBack()
                    return true
            }
        }, [
            navReady,
            currentScreen,
            navigation,
            screenTransition,
            isWeb,
            isDrawerVisible,
        ])

        const logCodeBackEvent = useCallback(() => {
            if (currentScreen !== ScreenName.SIGN_IN_SCREEN_CONFIRM) return

            analyticsDispatch({
                type: AnalyticsActionType.LOG_ANALYTIC,
                payload: {
                    analyticId: getCodeBackAction(),
                },
            })
        }, [analyticsDispatch, getCodeBackAction])

        useEffect(() => {
            if (navReady && currentScreen) {
                const backHandler = BackHandler.addEventListener(
                    "hardwareBackPress",
                    goBack,
                )

                return () => {
                    backHandler.remove()
                }
            }
        }, [navReady, currentScreen, goBack])

        useEffect(() => {
            if (pollParams.pollId && pollParams.isLaunched) {
                navigate({
                    screen: ScreenName.VOTE_SCREEN,
                    params: {
                        pollId: pollParams.pollId,
                    },
                })
            }
        }, [pollParams.pollId, pollParams.isLaunched, navigate])

        useEffect(() => {
            if (
                pollParams.state === "live" &&
                currentScreen === ScreenName.SELECT_POLL_SCREEN
            ) {
                navigate({
                    screen: ScreenName.VOTE_SCREEN,
                    params: {
                        pollId: pollParams.pollId,
                    },
                })
            }

            if (
                pollParams.state === "draft" &&
                currentScreen === ScreenName.VOTE_SCREEN
            ) {
                navigate({
                    screen: ScreenName.SELECT_POLL_SCREEN,
                    params: {
                        pollId: pollParams.pollId,
                    },
                })
            }
        }, [pollParams.state, currentScreen])

        return (
            <NavigationContext.Provider
                value={{
                    currentScreen,
                    canGoBack: canGoBack,
                    screenTransition,
                    navigate,
                    push,
                    goBack,
                }}
            >
                {children}
            </NavigationContext.Provider>
        )
    },
    (prev, next) => {
        return (
            prev.navigation === next.navigation &&
            prev.navReady === next.navReady &&
            prev.isWeb === next.isWeb &&
            prev.isPhoneNumberEntered === next.isPhoneNumberEntered &&
            prev.isDrawerVisible === next.isDrawerVisible &&
            prev.isDrawerActive === next.isDrawerActive &&
            prev.isDrawerDismissible === next.isDrawerDismissible &&
            prev.pollParams.isLaunched === next.pollParams.isLaunched &&
            prev.pollParams.state === next.pollParams.state &&
            prev.navState === next.navState
        )
    },
)

NavigationProviderView.displayName = "NavigationProviderView"

interface NavigationProviderProps {
    children: ReactNode
}

const NavigationProvider = ({ children }: NavigationProviderProps) => {
    const {
        appState: { navReady },
        appDispatch,
    } = useContext(AppContext)
    const isWeb = Platform.OS === "web"
    const navigation = useNavigation<StackNavigationProp<ScreenParamList>>()
    const {
        userState: { isPhoneNumberEntered },
        userDispatch,
    } = useContext(UserContext)
    const {
        drawerState: {
            visible: isDrawerVisible,
            isActive: isDrawerActive,
            dismissible: isDrawerDismissible,
        },
        drawerDispatch,
    } = useContext(DrawerContext)
    const {
        pollState: {
            isLaunched,
            pollId,
            data: { state },
        },
        pollDispatch,
    } = useContext(PollContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)
    const navState = useNavigationState(state => state)

    const { tenantConfig } = getAppConfig()
    const { changeTheme } = useContext(ThemeContext)

    const handleSetStoreId = (param: string) => {
        // TODO: this may not work on react native,
        // but we should not be using shopify store id on react native anyways
        const storeId = decodeURIComponent(param)

        // update analytics
        setStoreId(storeId)

        // udpate app provider for later use
        appDispatch({
            type: AppActionType.SET_STORE_ID,
            payload: {
                storeId: storeId,
            },
        })
    }

    const handleSetTenantOverride = (param: string) => {
        if (tenantConfig.shouldSupportDynamicTheme) {
            const theme = getThemeByQueryParam(param)
            changeTheme(theme)

            appDispatch({
                type: AppActionType.SET_TENANT_OVERRIDE,
                payload: {
                    tenantKey: param,
                },
            })
        }
    }

    const handleSetOpenPollFlow = (flowParam: string, pollId: string) => {
        const openPollFlow = Object.values<string>(OpenPollFlow).includes(
            flowParam,
        )
            ? (flowParam as OpenPollFlow)
            : OpenPollFlow.openPoll
        appDispatch({
            type: AppActionType.SET_OPEN_POLL_FLOW,
            payload: {
                openPollFlow: openPollFlow,
                openPollId: pollId,
            },
        })
    }

    const handleSetEmbeddedState = (param: string) => {
        if (Object.values<string>(EmbeddedState).includes(param)) {
            setEmbeddedState(param as EmbeddedState)

            appDispatch({
                type: AppActionType.SET_EMBEDDED_STATE,
                payload: {
                    embeddedState: param as EmbeddedState,
                },
            })
        }
    }

    const toggleSharePoll = () => {
        pollDispatch({
            type: PollActionType.SET_CUSTOMER_SHARE_POLL,
            payload: true,
        })
    }

    const handlePollPending = () => {
        pollDispatch({
            type: PollActionType.SET_POLL_PENDING,
        })
    }

    return (
        <NavigationProviderView
            navigation={navigation}
            navReady={navReady}
            isWeb={isWeb}
            isPhoneNumberEntered={isPhoneNumberEntered}
            isDrawerVisible={isDrawerVisible}
            isDrawerActive={isDrawerActive}
            isDrawerDismissible={isDrawerDismissible}
            userDispatch={userDispatch}
            drawerDispatch={drawerDispatch}
            analyticsDispatch={analyticsDispatch}
            pollParams={{ isLaunched, pollId, state }}
            toggleSharePoll={toggleSharePoll}
            handlePollPending={handlePollPending}
            handleSetTenantOverride={handleSetTenantOverride}
            handleSetStoreId={handleSetStoreId}
            handleSetEmbeddedState={handleSetEmbeddedState}
            handleSetOpenPollFlow={handleSetOpenPollFlow}
            navState={navState}
        >
            {children}
        </NavigationProviderView>
    )
}

export default NavigationProvider
