import React from "react"
import { View } from "react-native"
import { NavHeader, PoweredByPolls, WebScreenCard } from "@components"
import { screenStyles } from "./ScreenLayout.styles"
import useStyles from "@helpers/hooks/useStyles"
import { SafeAreaView } from "react-native-safe-area-context"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { getAppConfig } from "@services/appConfig"

interface ScreenLayoutProps {
    height: number
    children: React.ReactNode
}

const ScreenLayout = React.memo(
    ({ children }: ScreenLayoutProps) => {
        const { styles } = useStyles(screenStyles)
        const { isMobileView } = useScreenDimensions()
        const isNative = isPlatformNative()
        const { tenantConfig } = getAppConfig()

        return (
            <SafeAreaView style={styles.container}>
                <View style={styles.bg}>
                    <NavHeader />
                    {!isMobileView &&
                        !isNative &&
                        tenantConfig.type === "customer" && <PoweredByPolls />}
                    <WebScreenCard>{children}</WebScreenCard>
                </View>
            </SafeAreaView>
        )
    },
    (prev, next) => {
        return prev.children === next.children && prev.height === next.height
    },
)

ScreenLayout.displayName = "ScreenLayout"

interface ScreenLayoutWrapperProps {
    children: React.ReactNode
}

const ScreenLayoutWrapper = (props: ScreenLayoutWrapperProps) => {
    const { height } = useScreenDimensions()
    return <ScreenLayout {...props} height={height} />
}

export default ScreenLayoutWrapper
