import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react"
import { Animated, GestureResponderEvent, Platform } from "react-native"
import { Card } from "@components"
import { PollOption } from "@types"
import PollListCardTemplate from "@components/PollList/PollListCardTemplate"
import { CARD_HEIGHT } from "@config/pollList"
import {
    AnalyticsActionType,
    AnalyticsContext,
    AnalyticsListActions,
} from "@contexts/AnalyticsProvider/AnalyticsReducer"
import { linkOptionInfo, Response } from "@services/poll/linkOptionInfo"
import { AnalyticsEvent } from "@services/analytics"
import { getHostnameFromUrl } from "@helpers/getHostnameFromUrl"

interface LinkInfoResponse {
    isSuccess: boolean
    data: Response["data"]["optionInfo"] | null
}

interface PollListItemProps {
    id: string
    testID?: string
    index: number
    data: PollOption
    visible: boolean
    editMode: boolean
    editVoteMode: boolean
    draftMode: boolean
    votedOn: boolean
    totalVoteCount: number
    itemEdit: {
        on: boolean
        index: number
    }
    doneTransition: boolean
    canUserRemove: boolean
    onPress: (index: number) => void
    onVote: (index: number) => void
    onOpenOptionLink: (id: string, index: number, url: string) => void
    onDelete?: (id: string) => void
    onStartDrag?: (index: number) => void
    onStopDrag?: (id: string, index: number) => void
    onSwapIndex: (id: string, index: number, nextIndex: number) => void
    onValueChanged: (id: string) => void
    onSeeWhoVoted: () => void
    onLinkOptionParsed: (linkData: any, id: PollOption["id"]) => void
    logListEvent: (action: AnalyticsListActions) => void
}

const PollListItem = ({
    id,
    testID,
    index,
    data,
    visible,
    editMode,
    editVoteMode,
    draftMode,
    votedOn,
    totalVoteCount,
    itemEdit,
    doneTransition,
    canUserRemove,
    onPress,
    onVote,
    onOpenOptionLink,
    onDelete,
    onStartDrag,
    onStopDrag,
    onSwapIndex,
    onValueChanged,
    onSeeWhoVoted,
    onLinkOptionParsed,
    logListEvent,
}: PollListItemProps) => {
    const [isLoadingLink, setLoadingLink] = useState(false)
    const [active, setActive] = useState(false)
    const [pos, setPos] = useState({ y: 0, offset: 0 })
    const [linkParseResponse, setLinkParseResponse] = useState<
        LinkInfoResponse | undefined
    >(undefined)
    const vIndex = useRef(index)
    const posY = useRef(new Animated.Value(index * CARD_HEIGHT)).current
    const alpha = useRef(new Animated.Value(0)).current
    const useNativeDriver = Platform.OS !== "web"

    const { analyticsDispatch } = useContext(AnalyticsContext)

    const animatePosition = () => {
        Animated.timing(posY, {
            toValue: index * CARD_HEIGHT + pos.y - pos.offset,
            duration: active ? 1 : 100,
            useNativeDriver,
        }).start()
    }

    const animateAlpha = () => {
        Animated.timing(alpha, {
            toValue: visible ? 1 : 0,
            duration: 300,
            useNativeDriver,
        }).start()
    }

    useEffect(() => {
        animatePosition()
        animateAlpha()
    }, [])

    useEffect(() => {
        animatePosition()
    }, [pos, index])

    useEffect(() => {
        animateAlpha()
    }, [visible])

    const itemPressIn = (index: any) => (e: GestureResponderEvent) => {
        setActive(true)
        setPos({ y: e.nativeEvent.pageY, offset: e.nativeEvent.pageY })
        onStartDrag?.(index)
    }

    const itemPressOut = useCallback(
        (e: GestureResponderEvent) => {
            setActive(false)
            setPos({ y: e.nativeEvent.pageY, offset: e.nativeEvent.pageY })
            onStopDrag?.(id, index)
        },
        [id, index, onStopDrag],
    )

    const handleOnDelete = () => {
        if (itemEdit.index === index) {
            logListEvent(AnalyticsListActions.CANCEL)
        } else if ((!draftMode && editMode) || draftMode) {
            logListEvent(AnalyticsListActions.REMOVE)
        }

        onDelete?.(id)
    }

    const handleOnPress = (index: number) => {
        logListEvent(AnalyticsListActions.EDIT)

        onPress(index)
    }

    const calculateIndex = (y: number) => {
        const predictedIndex = Math.floor(
            index + (y + CARD_HEIGHT * 0.5) / CARD_HEIGHT,
        )
        if (predictedIndex < 0) {
            return 0
        }
        return predictedIndex
    }

    const onItemMove = (e: GestureResponderEvent) => {
        setPos({ y: e.nativeEvent.pageY, offset: pos.offset })
        const relativeDistY = pos.y - pos.offset
        const predictedIndex = calculateIndex(relativeDistY)

        if (predictedIndex !== vIndex.current) {
            onSwapIndex(id, predictedIndex, vIndex.current)
            vIndex.current = predictedIndex
        }
    }

    const handleLinkParsed = async (link: string) => {
        setLoadingLink(true)

        const hostname = getHostnameFromUrl(link)

        onLinkOptionParsed(
            {
                title: link,
                subtitle: hostname,
                siteName: hostname,
                url: link,
            },
            id,
        )

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.linkOptionDetected,
                additionalData: {
                    url: link,
                },
            },
        })

        const response = await linkOptionInfo({ link })

        setLinkParseResponse(response)
    }

    useEffect(() => {
        if (!linkParseResponse) return

        const { data, isSuccess } = linkParseResponse

        if (data && isSuccess) {
            onLinkOptionParsed(data, id)
        }

        setLoadingLink(false)
    }, [linkParseResponse, itemEdit.index])

    const onTerminate = useCallback(
        (e: GestureResponderEvent) => {
            itemPressOut(e)
        },
        [itemPressOut],
    )

    const enabler = (e: GestureResponderEvent) => true

    const panResponderConfig = {
        onStartShouldSetResponder: enabler,
        onMoveShouldSetResponder: enabler,
        onMoveShouldSetResponderCapture: enabler,
        onStartShouldSetResponderCapture: enabler,
        onResponderMove: onItemMove,
        onResponderStart: itemPressIn(index),
        onResponderTerminate: onTerminate,
        onResponderRelease: itemPressOut,
    }

    return (
        <Animated.View
            style={{
                position: "absolute",
                width: "100%",
                zIndex: active ? 1 : 0,
                transform: [{ translateY: posY }],
            }}
        >
            <Animated.View
                needsOffscreenAlphaCompositing={true}
                style={{ opacity: alpha }}
            >
                <Card
                    testID={testID}
                    style={{
                        minHeight: CARD_HEIGHT - 10,
                        maxHeight: CARD_HEIGHT - 10,
                    }}
                >
                    <PollListCardTemplate
                        isLoadingLink={isLoadingLink}
                        index={index}
                        data={data}
                        optionID={data.id}
                        itemEdit={itemEdit}
                        itemEditMode={itemEdit.on}
                        editMode={editMode}
                        editVoteMode={editVoteMode}
                        draftMode={draftMode}
                        checked={votedOn}
                        canUserRemove={canUserRemove}
                        totalVoteCount={totalVoteCount}
                        handleLinkParsed={handleLinkParsed}
                        onPress={handleOnPress}
                        onItemCreated={onPress}
                        onVote={onVote}
                        onOpenOptionLink={onOpenOptionLink}
                        onValueChanged={onValueChanged}
                        onSeeWhoVoted={onSeeWhoVoted}
                        onDelete={handleOnDelete}
                        doneTransition={doneTransition}
                        panResponderCallbacks={panResponderConfig}
                    />
                </Card>
            </Animated.View>
        </Animated.View>
    )
}

export default PollListItem
