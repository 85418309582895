import { Image, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { ModalStyles } from "./Modal.styles"
import { Button, LottieAnimation } from "@components"
import { base64Image } from "@assets/theme/Theme"

interface ModalProps {
    image?: base64Image
    imageSize?: {
        height: number
        width: number
    }
    animation?: any
    animationSize?: number
    title: string
    message: string
    buttonTitle: string
    onAction?: () => void
}

const Modal = ({
    image,
    imageSize,
    animation,
    animationSize,
    title,
    message,
    buttonTitle,
    onAction,
}: ModalProps) => {
    const { styles } = useStyles(ModalStyles)

    return (
        <View style={styles.container}>
            {image && (
                <Image
                    source={{
                        uri: image,
                    }}
                    resizeMode={"contain"}
                    style={[styles.image, imageSize]}
                />
            )}
            {animation && (
                <View
                    style={
                        animationSize ? styles.animationContainer : undefined
                    }
                >
                    <LottieAnimation
                        source={animation}
                        style={[
                            styles.animation,
                            animationSize
                                ? {
                                      height: animationSize,
                                      margin: 0,
                                  }
                                : undefined,
                        ]}
                        autoPlay
                        loop={false}
                    />
                </View>
            )}
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.message}>{message}</Text>
            {!!buttonTitle && onAction && (
                <Button
                    onPress={onAction}
                    title={buttonTitle}
                    style={styles.buttonContainer}
                />
            )}
        </View>
    )
}

export default Modal
