import { PollActionType } from "@contexts"
import { useCallback } from "react"
import { PollAction } from "@contexts/PollContext/PollReducer"
import { storageService } from "@services/storage"
import { PollOption, PollSettings, StorageKeys } from "@types"
import Logger from "@services/logger"

export const useGetDraftPollFlow = (pollDispatch: PollAction) => {
    return useCallback(async () => {
        Logger.info("FLOW: getDraftPoll initialized")

        const storageTitle = await storageService.getItem<string>(
            StorageKeys.POLL_TITLE,
        )
        const storagePollOptions = await storageService.getItem<string>(
            StorageKeys.POLL_OPTIONS,
        )

        const storagePollSettings = await storageService.getItem<PollSettings>(
            StorageKeys.POLL_SETTINGS,
        )

        const pollOptions = storagePollOptions
            ? (JSON.parse(storagePollOptions) as PollOption[])
            : []

        pollDispatch({
            type: PollActionType.SET_DATA_FROM_DRAFT,
            payload: {
                options: pollOptions,
                pollTitle: storageTitle || "",
                settings: storagePollSettings as PollSettings,
            },
        })
    }, [pollDispatch])
}
