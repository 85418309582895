import { useContext, useMemo } from "react"
import { merge } from "lodash"
import { Platform, StyleSheet } from "react-native"
import { ThemeContext } from "@contexts/ThemeContext"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import { Theme } from "@assets/theme/Theme"
export interface DynamicStyle {
    width: number | 0
    height: number | 0
    theme: Theme
    props?: { [key: string]: any }
}

export interface Styles {
    NATIVE?: Record<string, unknown>
    WEB?: Record<string, unknown>
    MOBILE?: Record<string, unknown>
}

export type StyleFunction<T extends Styles> = (arg: DynamicStyle) => T

const useStyles = <T extends Styles>(
    style: StyleFunction<T>,
    forceStyle?: "WEB" | "MOBILE" | "NATIVE",
) => {
    const { theme } = useContext(ThemeContext)
    const { width, height, isMobileView, isSdkWebDesktop } =
        useScreenDimensions()

    const mergedStyles = useMemo(() => {
        const styles = style({ width, height, theme })
        const { WEB, MOBILE, NATIVE, ...defaultStyle } = styles

        let platformStyle

        if (forceStyle) {
            platformStyle = styles[forceStyle]
        } else if (isSdkWebDesktop) {
            platformStyle = MOBILE
        } else if (Platform.OS === "web") {
            platformStyle = isMobileView ? MOBILE : WEB
        } else {
            platformStyle = NATIVE
        }

        return merge(defaultStyle, platformStyle)
    }, [style, width, height, theme, isSdkWebDesktop, isMobileView, forceStyle])

    return { styles: StyleSheet.create(mergedStyles) as T, theme }
}

export default useStyles
