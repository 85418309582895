import { AnalyticsListActions } from "@contexts/AnalyticsProvider/AnalyticsReducer"
import { AnalyticsEvent, logEvent, logEventWithPoll } from "@services/analytics"
import { useContext } from "react"
import { DrawerContext, NavigationContext, PollContext } from "@contexts"
import { Parameters } from "@services/analytics/AnalyticsTypes"
import { ScreenName } from "@screens"
import { SignInFlow } from "@types"

const useLogListAnalytics = () => {
    const {
        pollState: { data, isCreateDraftMode, editMode },
    } = useContext(PollContext)

    const logAction = (actionName: string) => {
        const listState = isCreateDraftMode
            ? "Draft"
            : editMode
            ? "Edit"
            : "Vote"
        const analyticsName = `tap${listState}${actionName}` as AnalyticsEvent

        return logEventWithPoll(analyticsName, data)
    }

    return (action: AnalyticsListActions) => {
        switch (action) {
            case AnalyticsListActions.ADD_OPTION:
                logAction("AddOption")
                break
            case AnalyticsListActions.DONE:
                logAction("OptionDone")
                break
            case AnalyticsListActions.ANOTHER:
                logAction("OptionAnother")
                break
            case AnalyticsListActions.REMOVE:
                logAction("OptionRemove")
                break
            case AnalyticsListActions.CANCEL:
                logAction("OptionCancel")
                break
            case AnalyticsListActions.EDIT:
                logAction("OptionEdit")
                break
        }
    }
}

const useLogAnalytic = (additionalData?: Parameters) => {
    const {
        pollState: { data },
    } = useContext(PollContext)
    const {
        drawerState: { identifier: drawerIdentifier },
    } = useContext(DrawerContext)

    const logPollEvent = (
        action: AnalyticsEvent,
        customAdditionalData?: Parameters,
    ) => {
        data.options.length
            ? logEventWithPoll(
                  action,
                  data,
                  customAdditionalData ?? additionalData,
              )
            : logEvent(action, customAdditionalData ?? additionalData)
    }

    return (action: AnalyticsEvent) => {
        switch (action) {
            case AnalyticsEvent.dismissModal:
            case AnalyticsEvent.viewModal:
                logPollEvent(action, {
                    modalName: drawerIdentifier ?? additionalData?.modalName,
                })
                break
            default:
                logPollEvent(action)
        }
    }
}

const useCodeBackAction = (currentScreen?: ScreenName) => {
    return (): AnalyticsEvent => {
        switch (currentScreen) {
            case ScreenName.VOTE_SCREEN:
                return AnalyticsEvent.tapEditCodeBack
            case ScreenName.CREATE_POLL_SCREEN:
                return AnalyticsEvent.tapLaunchCodeBack
            case ScreenName.PROFILE_SCREEN:
                return AnalyticsEvent.tapProfileCodeBack
            case ScreenName.SIGN_IN_SCREEN_CONFIRM:
                return AnalyticsEvent.tapOnboardCodeBack
            default:
                return AnalyticsEvent.tapEditCodeBack
        }
    }
}

const useCodeResendAction = () => {
    const { currentScreen } = useContext(NavigationContext)

    return (): AnalyticsEvent => {
        switch (currentScreen) {
            case ScreenName.SIGN_IN_SCREEN_CONFIRM:
                return AnalyticsEvent.tapOnboardCodeResend
            case ScreenName.PROFILE_SCREEN:
                return AnalyticsEvent.tapProfileCodeResend
            case ScreenName.VOTE_SCREEN:
                return AnalyticsEvent.tapEditCodeResend
            case ScreenName.CREATE_POLL_SCREEN:
                return AnalyticsEvent.tapLaunchCodeResend
            default:
                return AnalyticsEvent.tapEditCodeResend
        }
    }
}

const useSignInAnalytic = () => {
    return (signInFlow: SignInFlow) => {
        switch (signInFlow) {
            case SignInFlow.onboarding:
                logEvent(AnalyticsEvent.onboardSignInSuccess)
                break
            case SignInFlow.profile:
                logEvent(AnalyticsEvent.profileSignInSuccess)
                break
            case SignInFlow.editPoll:
                logEvent(AnalyticsEvent.editSignInSuccess)
                break
            case SignInFlow.launchPoll:
                logEvent(AnalyticsEvent.launchSignInSuccess)
                break
            default:
                break
        }
    }
}

const useScreenAnalytic = () => {
    const {
        pollState: { data },
    } = useContext(PollContext)

    const screenNameAnalytic = (routeName: ScreenName | "HomeStack") => {
        switch (routeName) {
            case ScreenName.PROFILE_SCREEN:
                return "profile"
            case ScreenName.VOTE_SCREEN:
                return "vote"
            case ScreenName.CREATE_POLL_SCREEN:
                return "createPoll"
            case ScreenName.THEME_SCREEN:
                return "theme"
            case ScreenName.CONSENT_SCREEN:
                return "agree"
            case ScreenName.HOME_SCREEN:
                return "home"
            case "HomeStack":
                return "home"
            case ScreenName.SIGN_IN_SCREEN:
                return "signIn"
            case ScreenName.SIGN_IN_SCREEN_CONFIRM:
                return "verifyCode"
            case ScreenName.SELECT_POLL_SCREEN:
                return "draftFilter"
        }
    }

    const logPollEvent = (action: AnalyticsEvent, screenName: ScreenName) => {
        data.options.length
            ? logEventWithPoll(action, data, {
                  screenName: screenNameAnalytic(screenName),
              })
            : logEvent(action, {
                  screenName: screenNameAnalytic(screenName),
              })
    }

    return (screenName: ScreenName) => {
        logPollEvent(AnalyticsEvent.viewScreen, screenName)
    }
}

export {
    useLogAnalytic,
    useLogListAnalytics,
    useCodeBackAction,
    useCodeResendAction,
    useSignInAnalytic,
    useScreenAnalytic,
}
