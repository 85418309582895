import { View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { CustomerInfoModalStyles } from "./CustomerInfoModal.styles"
import { Title } from "@components"
import { InfoLink } from "./InfoLink"
import { getAppConfig } from "@services/appConfig"
import { useTranslation } from "react-i18next"
import { getTrackedLink } from "@helpers/getTrackedLink"

const CustomerInfoModal = () => {
    const { t } = useTranslation()
    const { styles } = useStyles(CustomerInfoModalStyles)
    const { tenantConfig } = getAppConfig()

    return (
        <View style={styles.container}>
            <Title title={t("resources")} style={styles.header} />
            <View style={styles.divider} />
            {tenantConfig.infoModalLinks.map((item, index) => (
                <InfoLink 
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    url={getTrackedLink(item.url)}
                />
            ))}
            {/* <Pressable onPress={downloadPolls}>
                <Image
                    source={{
                        uri: theme.image.pollsAdvertise,
                    }}
                    style={styles.image}
                />
            </Pressable> */}
        </View>
    )
}

export default CustomerInfoModal
