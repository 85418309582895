import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface PollsCardWrapperStyles extends Styles {
    container: ViewStyle
    dragIcon: TextStyle
    deleteIcon: TextStyle
    iconClose: ViewStyle
    iconContainer: ViewStyle
    dragIconContainer: ViewStyle
    doneAnim: ViewStyle
}

export const PollsCardWrapperStyles: StyleFunction<PollsCardWrapperStyles> = ({
    theme,
}) => {
    return {
        container: {
            flex: 1,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingTop: 13,
            paddingRight: 20,
            flexDirection: "row",
        },
        dragIcon: {
            color: theme.color.optionCardGroupIcon,
            textAlign: "left",
        },
        deleteIcon: {
            color: theme.color.optionCardDeleteIcon,
            textAlign: "right",
        },
        dragIconContainer: {
            paddingVertical: 12,
            paddingLeft: 12,
            width: 40,
            height: 50,
        },
        iconContainer: {
            marginTop: 13,
            marginRight: 17,
        },
        iconClose: {
            marginTop: 3,
            paddingLeft: 15,
        },
        doneAnim: {
            position: "absolute",
            width: 28,
            height: 28,
        },
        WEB: {
            dragIcon: {
                userSelect: "none",
            },
            deleteIcon: {
                userSelect: "none",
            },
            doneAnim: {
                position: "absolute",
                left: 0,
                top: 0,
                width: 40,
                height: 40,
            },
        },
        MOBILE: {
            dragIcon: {
                userSelect: "none",
            },
            deleteIcon: {
                userSelect: "none",
            },
            doneAnim: {
                position: "absolute",
                left: 0,
                top: 0,
                width: 40,
                height: 40,
            },
        },
    }
}
