import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface LinkCardTemplateStyles extends Styles {
    container: ViewStyle
    draftSpacing: ViewStyle
    topRow: ViewStyle
    rowCenter: ViewStyle
    rowImage: ImageStyle
    rowTextColumn: ViewStyle
    textStart: ViewStyle
    itemTitle: TextStyle
    itemSubtitle: TextStyle
    counterText: TextStyle
    bottomRow: ViewStyle
    linkText: TextStyle
    favicon: ImageStyle
    linkIcon: TextStyle
}

export const LinkCardTemplateStyles: StyleFunction<LinkCardTemplateStyles> = ({
    theme,
}) => {
    return {
        container: {
            flex: 1,
        },
        draftSpacing: {
            marginBottom: 20,
        },
        topRow: {
            flexDirection: "row",
            width: "100%",
            flex: 1,
            alignItems: "center",
        },
        rowCenter: {
            flexDirection: "row",
            alignItems: "center",
        },
        rowImage: {
            width: 102,
            height: 70,
            borderRadius: 6,
            marginRight: 9,
        },
        rowTextColumn: {
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
        },
        textStart: {
            justifyContent: "flex-start",
        },
        itemTitle: {
            ...theme.font.optionCardLinkTitle,
            flexWrap: "wrap",
        },
        itemSubtitle: {
            ...theme.font.optionCardLinkSubtitle,
            flexWrap: "wrap",
            marginTop: 2,
        },
        counterText: {
            ...theme.font.optionCardTextVoteCount,
        },
        bottomRow: {
            flexDirection: "row",
            alignItems: "center",
            marginTop: 7,
            height: 18,
            width: "100%",
            justifyContent: "space-between",
        },
        linkText: {
            ...theme.font.optionCardLink,
            marginRight: 5,
        },
        favicon: {
            width: 19,
            height: 19,
            marginRight: 5,
        },
        linkIcon: {
            textAlign: "center",
            fontSize: 11,
        },
        WEB: {
            itemTitle: {
                userSelect: "none",
            },
            counterText: { userSelect: "none" },
        },
        MOBILE: {
            itemTitle: {
                userSelect: "none",
            },
            counterText: { userSelect: "none" },
        },
    }
}
