import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface PollSelectListStyles extends Styles {
    container: ViewStyle
    listBanner: ViewStyle
    scrollContainer: ViewStyle
}

export const PollSelectListStyles: StyleFunction<PollSelectListStyles> = () => {
    return {
        container: { flex: 1, width: "100%" },
        listBanner: { marginTop: 20 },
        scrollContainer: {},
        WEB: {
            scrollContainer: {
                paddingHorizontal: 120,
            },
        },
        MOBILE: {
            scrollContainer: {
                paddingHorizontal: 20,
            },
        },
    }
}
