import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { HeaderLogoStyles } from "./HeaderLogo.styles"
import { isPlatformMobile } from "@helpers/isPlatformMobile"

const HeaderLogo = () => {
    const { theme } = useStyles(HeaderLogoStyles)
    const isMobile = isPlatformMobile()
    const logoHeight = isMobile ? 22 : 43

    return (
        <img
            // @ts-ignore
            src={theme.image.appLogo}
            style={{ height: logoHeight, marginRight: 5, userSelect: "none" }}
        />
    )
}

export default HeaderLogo
