import React, { FC } from "react"
import { View, Text, Pressable, Image, ImageSourcePropType } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { imageWithTextStyles } from "./ImageWithText.styles"

interface ImageWithTextProps {
    source: ImageSourcePropType
    title: string
    onPress?: () => void
}

const ImageWithText: FC<ImageWithTextProps> = ({ source, onPress, title }) => {
    const { styles } = useStyles(imageWithTextStyles)
    return (
        <View style={styles.content}>
            <Pressable onPress={onPress}>
                <View style={styles.imageBackgroundShadow}>
                    <View style={styles.imageBackground}>
                        <Image style={styles.image} source={source} />
                    </View>
                </View>
            </Pressable>
            <Text style={styles.text}>{title}</Text>
        </View>
    )
}

export default ImageWithText
