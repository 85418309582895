import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface DrawerStyles extends Styles {
    view: ViewStyle
    animated: ViewStyle
    overlay: ViewStyle
    outerContainer: ViewStyle
    container: ViewStyle
    content: ViewStyle
    background: ViewStyle
    closeButtonContainerModal: ViewStyle
    closeButtonContainer: ViewStyle
    closeButton: ViewStyle
    closeIcon: TextStyle
    dummy: ViewStyle
    backgroundModal: ViewStyle
    outerContainerModal: ViewStyle
    containerModal: ViewStyle
}

export const drawerStyles: StyleFunction<DrawerStyles> = ({ theme, width }) => {
    return {
        view: {
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 10,
        },
        overlay: {
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: theme.color.modalOverlay,
            opacity: 0.75,
        },
        outerContainer: {
            flex: 1,
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
        },
        container: {
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
        },
        animated: {
            width: "100%",
        },
        content: {},
        background: {
            overflow: "hidden",
            backgroundColor: theme.color.modalBackground,
        },
        closeButtonContainer: {
            zIndex: 100,
            alignSelf: "flex-start",
        },
        closeButtonContainerModal: {
            zIndex: 100,
            alignSelf: "flex-start",
        },
        closeButton: {
            alignSelf: "flex-start",
        },
        closeIcon: {
            ...theme.font.modalCloseIcon,
        },
        dummy: {
            height: 0,
        },
        backgroundModal: {
            overflow: "hidden",
            borderRadius: theme.cornerRadius.modal,
            backgroundColor: theme.color.modalBackground,
            alignSelf: "center",
        },
        outerContainerModal: {
            flex: 1,
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
        },
        containerModal: {
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
        },
        WEB: {
            outerContainer: {
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
            },
            container: {
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                overflow: "hidden",
                padding: 10,
            },
            background: {
                overflow: "hidden",
                borderRadius: theme.cornerRadius.modal,
                ...theme.shadow.modal.web,
            },
        },
        MOBILE: {
            view: {
                alignContent: "flex-end",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "100%",
            },
            outerContainer: {
                marginTop: 10,
                alignContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
                bottom: 0,
            },
            container: {
                width: "100%",
            },
            animated: {
                width: width,
            },
            backgroundModal: {
                ...theme.shadow.modal.web,
                overflow: "hidden",
                borderRadius: theme.cornerRadius.modal,
                borderTopLeftRadius: theme.cornerRadius.modal,
                borderTopRightRadius: theme.cornerRadius.modal,
                justifyContent: "center",
            },
            content: {
                alignItems: "center",
                flex: 1,
            },
            background: {
                borderRadius: 0,
                overflow: "hidden",
                borderTopLeftRadius: theme.cornerRadius.drawer,
                borderTopRightRadius: theme.cornerRadius.drawer,
                ...theme.shadow.modal.web,
            },
            closeButtonContainerModal: {},
            closeButtonContainer: {},
        },
        NATIVE: {
            view: {
                paddingLeft: 0,
                width: "100%",
            },
            outerContainer: {
                paddingLeft: 0,
                marginTop: 10,
                alignContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
            },
            container: {
                alignItems: "center",
            },
            animated: {
                width: width,
            },
            content: {
                alignItems: "center",
            },
            background: {
                alignItems: "center",
                paddingBottom: 20,
                borderRadius: 0,
                overflow: "hidden",
                borderTopLeftRadius: theme.cornerRadius.drawer,
                borderTopRightRadius: theme.cornerRadius.drawer,
                ...theme.shadow.modal.native,
            },
            backgroundModal: {
                ...theme.shadow.modal.native,
                borderRadius: theme.cornerRadius.modal,
                overflow: "hidden",
                borderTopLeftRadius: theme.cornerRadius.modal,
                borderTopRightRadius: theme.cornerRadius.modal,
                justifyContent: "center",
                alignItems: "center",
            },
            closeButtonContainer: {
                elevation: 10,
            },
            closeButton: {
                marginLeft: 0,
                marginRight: 0,
            },
        },
    }
}
