import { UserContext } from "@contexts/UserDataProvider/UserReducer"
import { useCallback, useContext } from "react"
import { PollAction, PollActionType } from "@contexts/PollContext/PollReducer"
import { PollData, PollOption } from "@types"
import Logger from "@services/logger"
import { publishPoll } from "@services/poll/publishPoll"
import {
    getRequestPollOptions,
    getRequestPollSettings,
} from "@helpers/getRequestPoll"
import { DrawerActionType, DrawerContext } from "@contexts"
import { t } from "i18next"
import { ModalName } from "@components/Drawer/content"
import { ThemeContext } from "@contexts/ThemeContext"

interface callbackProps {
    poll: PollData
    optionsToPublish: PollOption[]
}

export const usePublishPollFlow = (pollDispatch: PollAction) => {
    const {
        userState: { deviceId },
    } = useContext(UserContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { theme } = useContext(ThemeContext)

    const getPollData = ({ poll, optionsToPublish }: callbackProps) => {
        return {
            id: poll.id,
            version: poll.version,
            minCompatibleVersion: poll.minCompatibleVersion,
            settings: getRequestPollSettings(poll.settings),
            timeZoneId: poll.timeZoneId,
            title: poll.title,
            options: getRequestPollOptions(optionsToPublish),
        }
    }

    return useCallback(
        async ({ poll, optionsToPublish }: callbackProps) => {
            Logger.info("FLOW: publishPoll initialized")

            const { isSuccess, data } = await publishPoll({
                deviceId,
                poll: getPollData({ poll, optionsToPublish }),
            })

            if (isSuccess && data) {
                pollDispatch({
                    type: PollActionType.SET_LAUNCHED,
                    payload: {
                        poll: data,
                    },
                })

                pollDispatch({
                    type: PollActionType.CREATE_PREVIEW_IMAGE,
                })
            } else {
                drawerDispatch({
                    type: DrawerActionType.SHOW_MODAL,
                    payload: {
                        identifier: ModalName.FAILED_TO_CREATE_POLL,
                        title: t("oops"),
                        message:
                            t("launchPoll.couldntLaunchThePoll") +
                            " " +
                            t("launchPoll.checkConnection"),
                        image: theme.image.launchPollFailedGraphic,
                        imageSize: {
                            height: 105,
                            width: 131,
                        },
                        buttonTitle: t("okay"),
                        delay: 0,
                    },
                })
            }

            pollDispatch({
                type: PollActionType.POLL_PUBLISHED,
            })
        },
        [deviceId, drawerDispatch],
    )
}
