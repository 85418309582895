import {
    Button,
    CheckboxAnimated,
    PollOptionCard,
    ProgressBar,
    Title,
} from "@components"
import { DrawerActionType, DrawerContext } from "@contexts/DrawerProvider"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext, useState } from "react"
import { Text, View } from "react-native"
import { seeWhoVotedModalStyles } from "./SeeWhoVotedModal.styles"
import { VotersList } from "./VotersList"
import { PollOption } from "@types"

interface SeeWhoVotedDrawerProps {
    optionId: string
    checked: boolean
    totalCounter: number
    title: PollOption["title"]
    voteCount: number
}

const SeeWhoVotedModal = ({
    optionId,
    checked,
    totalCounter,
    title,
    voteCount,
}: SeeWhoVotedDrawerProps) => {
    const [isScrolling, setIsScrolling] = useState(false)
    const { drawerDispatch } = useContext(DrawerContext)
    const { styles } = useStyles(seeWhoVotedModalStyles)

    const dismissDrawer = () => {
        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
    }

    return (
        <View style={styles.outerContainer}>
            <PollOptionCard
                iconContent={<CheckboxAnimated checked={checked} size={36} />}
            >
                <Text style={styles.title}>{title}</Text>
                <View style={styles.progressVotesContainer}>
                    <ProgressBar
                        votes={voteCount ?? 0}
                        totalVotes={totalCounter}
                    />
                    <View style={styles.votesContainer}>
                        <Text style={styles.counterText}>
                            {`${voteCount} ${
                                voteCount === 1 ? "vote" : "votes"
                            }`}
                        </Text>
                    </View>
                </View>
            </PollOptionCard>
            <View style={styles.shadowWrapper}>
                <View
                    style={[
                        styles.sectionHeaderContainer,
                        isScrolling && styles.shadowContainer,
                    ]}
                >
                    <Title title={"Who Voted"} style={styles.sectionHeader} />
                </View>
            </View>

            <VotersList
                optionId={optionId}
                handleScrollingState={setIsScrolling}
                checked={checked}
            />
            <Button onPress={dismissDrawer} title={"Done"} />
        </View>
    )
}

export default SeeWhoVotedModal
