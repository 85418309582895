import { Image, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { PollSelectListSkeletonStyles } from "./PollSelectListSkeleton.styles"
import { commonStyles } from "@styles/Main.styles"
import { Card, CheckboxAnimated } from "@components"
import SkeletonLoader from "expo-skeleton-loader"

const PollSelectListSkeleton = () => {
    const { styles, theme } = useStyles(PollSelectListSkeletonStyles)
    const { styles: defaultStyles } = useStyles(commonStyles)

    return (
        <View style={styles.container}>
            <View style={styles.checkboxColumn}>
                <CheckboxAnimated checked={false} size={30} square={true} />
            </View>
            <Card style={styles.card}>
                <View style={defaultStyles.rowFlex}>
                    <Image
                        source={{ uri: theme.image.imagePlaceholder }}
                        style={styles.skeletonImage}
                    />
                    <View style={styles.skeletonContentContainer}>
                        <SkeletonLoader
                            boneColor={theme.color.step2SkeletonCardTitle}
                            highlightColor={theme.color.skeletonHighlight}
                            duration={800}
                        >
                            <SkeletonLoader.Container style={styles.flexStart}>
                                <SkeletonLoader.Item
                                    style={{
                                        width: 150,
                                        height: 9,
                                        borderRadius: 4.5,
                                        marginBottom: 10,
                                    }}
                                ></SkeletonLoader.Item>
                                <SkeletonLoader.Item
                                    style={{
                                        width: 120,
                                        height: 9,
                                        borderRadius: 4.5,
                                        marginBottom: 10,
                                    }}
                                ></SkeletonLoader.Item>
                            </SkeletonLoader.Container>
                        </SkeletonLoader>
                        <SkeletonLoader
                            boneColor={theme.color.step2SkeletonCardSubtitle}
                            highlightColor={theme.color.skeletonHighlight}
                            duration={800}
                        >
                            <SkeletonLoader.Container style={styles.flexStart}>
                                <SkeletonLoader.Item
                                    style={{
                                        width: 120,
                                        height: 7,
                                        borderRadius: 3.5,
                                    }}
                                ></SkeletonLoader.Item>
                            </SkeletonLoader.Container>
                        </SkeletonLoader>
                    </View>
                </View>
            </Card>
        </View>
    )
}

export default PollSelectListSkeleton
