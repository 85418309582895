import React, { ReactNode } from "react"
import { Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { PollOptionCardStyles } from "./PollOptionCard.styles"
import { Button, Card } from "@components"
import { CARD_HEIGHT } from "@config/pollList"

interface PollOptionCardProps {
    iconContent: ReactNode
    children: ReactNode
    onPress?: () => void
    clickDisabled?: boolean
    onDismiss?: () => void
    onContentPress?: () => void
}

const PollOptionCard = ({
    onPress,
    clickDisabled,
    iconContent,
    children,
    onDismiss,
    onContentPress,
}: PollOptionCardProps) => {
    const { styles, theme } = useStyles(PollOptionCardStyles)

    return (
        <Pressable
            style={{ height: CARD_HEIGHT }}
            onPress={onPress}
            disabled={clickDisabled}
        >
            <Card>
                <View style={styles.container}>
                    <View style={styles.iconContainer}>{iconContent}</View>
                    <Pressable
                        style={styles.textContainer}
                        onPress={onContentPress}
                        disabled={clickDisabled}
                    >
                        {children}
                    </Pressable>
                    {onDismiss && (
                        <Button
                            variant="text"
                            align="left"
                            iconStyle={styles.closeIcon}
                            style={styles.closeButton}
                            iconLeft={theme.image.closeX}
                            onPress={onDismiss}
                        />
                    )}
                </View>
            </Card>
        </Pressable>
    )
}

export default PollOptionCard
