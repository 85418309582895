import { AppContext } from "@contexts"
import { EmbeddedState } from "@contexts/AppProvider/AppReducer"
import { useCallback, useContext, useEffect, useState } from "react"
import { EmitterSubscription, Keyboard, Platform } from "react-native"

export const useVisualViewport = () => {
    const isNative = Platform.OS !== "web"

    const [isKeyboardShown, setIsKeyboardShown] = useState(false)

    const getViewport = () => {
        if (isNative) {
            return {
                viewport: {
                    width: 0,
                    height: 0,
                },
                visualViewport: {
                    left: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                },
            }
        }

        return {
            viewport: {
                width: Math.max(
                    document.documentElement.clientWidth || 0,
                    window.innerWidth || 0,
                ),
                height: Math.max(
                    document.documentElement.clientHeight || 0,
                    window.innerHeight || 0,
                ),
            },
            visualViewport: {
                left: visualViewport.pageLeft,
                top: visualViewport.pageTop,
                width: window.visualViewport.width,
                height: window.visualViewport.height,
            },
        }
    }

    const [state, setState] = useState(getViewport)
    const [initialState, setInitialState] = useState(getViewport)

    // app state
    const {
        appState: { embeddedState },
    } = useContext(AppContext)

    const getIsKeyboardVisible = useCallback(() => {
        return initialState.visualViewport.height - state.visualViewport.height > 150
    }, [initialState.visualViewport.height, state.visualViewport.height])

    useEffect(() => {
        setInitialState(getViewport)
        const handleResize = () => setState(getViewport)

        if (!isNative)
            window.visualViewport.addEventListener("resize", handleResize)

        return () => {
            if (!isNative)
                window.visualViewport.removeEventListener(
                    "resize",
                    handleResize,
                )
        }
    }, [])

    useEffect(() => {
        const handleKeyboardShow = () => setIsKeyboardShown(true)
        const handleKeyboardHide = () => setIsKeyboardShown(false)

        let subscriptions: EmitterSubscription[]

        if (Platform.OS === "ios") {
            subscriptions = [
                Keyboard.addListener("keyboardWillShow", handleKeyboardShow),
                Keyboard.addListener("keyboardWillHide", handleKeyboardHide),
            ]
        } else {
            subscriptions = [
                Keyboard.addListener("keyboardDidShow", handleKeyboardShow),
                Keyboard.addListener("keyboardDidHide", handleKeyboardHide),
            ]
        }

        return () => {
            subscriptions.forEach(s => s.remove())
        }
    }, [])

    return {
        ...state,
        isMobileView:
            Platform.OS !== "web" ||
            (Platform.OS === "web" && state.viewport.width < 600),
        isMobileWeb: Platform.OS === "web" && state.viewport.width < 600,
        isSdkWebDesktop: embeddedState === EmbeddedState.SDK_WEB_DESKTOP,
        isKeyboardVisible: isNative ? isKeyboardShown : getIsKeyboardVisible(),
    }
}
