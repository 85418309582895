import useStyles from "@helpers/hooks/useStyles"
import React, { useEffect, useMemo } from "react"
import {
    KeyboardTypeOptions,
    NativeSyntheticEvent,
    NativeTouchEvent,
    StyleProp,
    TextInput,
    TextInputChangeEventData,
    TextInputFocusEventData,
    TextStyle,
    View,
    ViewStyle,
} from "react-native"
import { inputStyles } from "./BaseInput.styles"
import { isPlatformNative } from "@helpers/isPlatformNative"
import Logger from "@services/logger"
import { captureMessage } from "@services/sentry"

export interface BaseInputProps {
    variant?: "plain" | "box"
    placeholder?: string
    autoCorrect?: boolean
    autoCapitalize?: "none" | "sentences" | "words" | "characters"
    textAlign?: "center" | "left" | "right"
    value?: string
    multiline?: boolean
    numberOfLines?: number
    style?: StyleProp<ViewStyle>
    inputStyle?: StyleProp<TextStyle>
    keyboardType?: KeyboardTypeOptions
    editable?: boolean
    onChange?: (value: string) => void
    onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    onContentSizeChange?: (height: number) => void
    onInputReference?: (ref: TextInput) => void
    onPressIn?: (e: NativeSyntheticEvent<NativeTouchEvent>) => void
    onPressOut?: (e: NativeSyntheticEvent<NativeTouchEvent>) => void
    fontSize?: number
    importantForAutofill?: boolean
}

const BaseInput = React.forwardRef<TextInput, BaseInputProps>(
    (props: BaseInputProps, ref) => {
        const {
            variant = "plain",
            placeholder = "",
            value = undefined,
            numberOfLines = 1,
            multiline = false,
            autoCapitalize = "none",
            autoCorrect = false,
            textAlign = "center",
            editable = true,
            fontSize,
            style,
            inputStyle,
            keyboardType,
            onChange,
            onFocus,
            onBlur,
            onContentSizeChange,
            onPressIn,
            onPressOut,
            importantForAutofill,
        } = props

        const { styles, theme } = useStyles(inputStyles)
        const variantStyle = styles[variant]
        const isNative = isPlatformNative()

        const handleOnChange = (
            e: NativeSyntheticEvent<TextInputChangeEventData>,
        ) => {
            onChange && onChange(e.nativeEvent.text)
        }

        const inputFontSize = useMemo(() => {
            return fontSize ? { fontSize, lineHeight: fontSize } : null
        }, [fontSize])

        const noEditStyle = useMemo(() => {
            return editable ? null : styles.noEdit
        }, [editable, styles.noEdit])

        useEffect(() => {
            if (!isNative && importantForAutofill) {
                const ac = new AbortController()

                if (navigator.credentials) {
                    navigator.credentials
                        .get({
                            // @ts-ignore
                            otp: { transport: ["sms"] },
                            signal: ac.signal,
                        })
                        .then(otp => {
                            // @ts-ignore
                            onChange?.(otp.code)
                        })
                        .catch(err => {
                            captureMessage(err)
                            Logger.error(err)
                        })
                }
            }
        }, [])

        return (
            <View style={[variant === "box" && styles.inputBox, style]}>
                <TextInput
                    ref={ref}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onPressIn={onPressIn}
                    onPressOut={onPressOut}
                    style={[
                        styles.inputText,
                        inputStyle,
                        variantStyle,
                        inputFontSize,
                        noEditStyle,
                    ]}
                    editable={editable}
                    textAlign={textAlign}
                    autoCorrect={autoCorrect}
                    placeholderTextColor={theme.color.inputPlaceholderColor}
                    placeholder={placeholder}
                    value={value}
                    numberOfLines={numberOfLines}
                    multiline={multiline}
                    autoCapitalize={autoCapitalize}
                    keyboardType={keyboardType}
                    onChange={handleOnChange}
                    rejectResponderTermination={true}
                    selectTextOnFocus={false}
                    underlineColorAndroid="transparent"
                    importantForAutofill={importantForAutofill ? "yes" : "auto"}
                    textContentType={
                        importantForAutofill ? "oneTimeCode" : "none"
                    }
                    // @ts-ignore
                    autoComplete={importantForAutofill ? "one-time-code" : "on"}
                    onContentSizeChange={({
                        nativeEvent: {
                            contentSize: { height },
                        },
                    }) => onContentSizeChange?.(height)}
                />
            </View>
        )
    },
)

BaseInput.displayName = "BaseInput"

export default BaseInput
