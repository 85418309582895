// Available input options: {
//  placeholder - string
//  keyboardType - string - available options {"default" | "number-pad" | "decimal-pad" | "numeric" | "email-address" | "phone-pad"}
//  maxLength - number
//  minLength - number
//  autoCorrect - boolean
//  textAlign - string - available options {"left" | "right" | "center"}
//  autoCapitalize - string - available options {"none" | "sentences" | "words" | "characters"}
//  numberOfLines - number
//  multiline - boolean
// }

import { InputConfig } from "@helpers/hooks/useInputConfig"

interface Inputs {
    [key: string]: InputConfig
}

export const Inputs: Inputs = {
    autocorrectInput: {
        placeholder: "Some placeholder",
        maxLength: 20,
        minLength: 2,
        autoCorrect: true,
        textAlign: "left",
    },
    nameInput: {
        placeholder: "Name",
        maxLength: 30,
        minLength: 1,
        autoCorrect: false,
        autoCapitalize: "sentences",
    },
    pollTitleInput: {
        placeholder: "Poll Title",
        multiline: true,
        maxLength: 60,
        minLength: 1,
        numberOfLines: 2,
        autoCapitalize: "sentences",
        autoCorrect: true,
        textAlign: "center",
    },
    optionText: {
        placeholder: "option input",
        maxLength: 80,
        numberOfLines: 2,
        textAlign: "left",
    },
    pollOptionInput: {
        minLength: 1,
        maxLength: 60,
        fontSize: 14.5,
        shrunkenFontSize: 12,
        numberOfLines: 2,
        multiline: true,
        textAlign: "left",
        autoCapitalize: "sentences",
        autoCorrect: true,
    },
}
