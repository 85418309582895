import React from "react"
import { View } from "react-native"
import { Button } from "@components/Button"
import useStyles from "@helpers/hooks/useStyles"
import { pollListStyles } from "./PollList.styles"
import { t } from "i18next"
import { AnalyticsListActions } from "@contexts/AnalyticsProvider/AnalyticsReducer"

interface PollListToolBar {
    onDone: () => void
    onAddOne: () => void
    isAddOneVisible: boolean
    logListEvent: (action: AnalyticsListActions) => void
}

const PollListToolBar = ({
    onDone,
    onAddOne,
    isAddOneVisible,
    logListEvent,
}: PollListToolBar) => {
    const { styles } = useStyles(pollListStyles)

    const handleDonePress = () => {
        logListEvent(AnalyticsListActions.DONE)
        onDone()
    }

    const handleAddOnePress = () => {
        logListEvent(AnalyticsListActions.ANOTHER)
        onAddOne()
    }

    return (
        <View style={styles.toolbarButtons}>
            <Button
                testID="done"
                style={styles.doneButton}
                title={t("done")}
                onPress={handleDonePress}
            />
            {isAddOneVisible && (
                <Button
                    testID="add-one"
                    variant="secondary"
                    style={styles.addOneButton}
                    title={t("addAnother")}
                    onPress={handleAddOnePress}
                />
            )}
        </View>
    )
}

export default PollListToolBar
