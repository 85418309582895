import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface SectionStyles extends Styles {
    sectionTitle: TextStyle
    linkButton: ViewStyle
    infoLinkText: TextStyle
    infoLinkIcon: TextStyle
    logoutButton: ViewStyle
    logoutText: TextStyle
    versionText: TextStyle
}

const buttonLeftAligned: ViewStyle = {
    alignItems: "flex-start",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    marginLeft: 0,
    marginVertical: 0,
    paddingHorizontal: 0,
    marginTop: 0,
    paddingTop: 0,
    marginBottom: 0,
}

export const sectionStyles: StyleFunction<SectionStyles> = ({ theme }) => {
    return {
        sectionTitle: {
            ...theme.font.profileSectionTitle,
            marginTop: theme.layout.margin.sectionTitle.top,
            marginBottom: theme.layout.margin.sectionTitle.bottom,
        },
        linkButton: {
            ...buttonLeftAligned,
            minHeight: 22,
        },
        infoLinkText: {
            ...theme.font.profileLinkText,
            alignItems: "flex-start",
        },
        infoLinkIcon: {
            ...theme.font.profileLinkIcon,
        },
        logoutButton: {
            ...buttonLeftAligned,
        },
        logoutText: {
            ...theme.font.profileLogOutText,
        },
        versionText: {
            marginTop: 40,
            marginBottom: 12,
            ...theme.font.versionText,
        },
    }
}
