import {
    AnalyticsActionType,
    AnalyticsContext,
    DrawerActionType,
    DrawerContext,
    PollContext,
} from "@contexts"
import { useCanEditPoll } from "@helpers/hooks/useCanEditPoll"
import { useTranslation } from "react-i18next"
import { ReactNode, useCallback, useContext } from "react"
import { ThemeContext } from "@contexts/ThemeContext"
import { ModalName } from "@components/Drawer/content"
import { useFocus } from "./useFocus"
import { AnalyticsEvent } from "@services/analytics"

interface usePollInteractionCheckProps {
    authModalContent: ReactNode
}

export const usePollPermissionsCheck = ({
    authModalContent,
}: usePollInteractionCheckProps) => {
    const { t } = useTranslation()
    const { ui: canEditResponseUI } = useCanEditPoll()
    const { setFocusBottom } = useFocus()

    const { drawerDispatch } = useContext(DrawerContext)
    const { theme } = useContext(ThemeContext)
    const {
        pollState: { isCreateDraftMode },
    } = useContext(PollContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const handleAuthorize = useCallback(() => {
        const event =
            canEditResponseUI === "sign-in"
                ? AnalyticsEvent.tapEditSignInToEditSignIn
                : AnalyticsEvent.tapEditIsThisYourPollSignIn

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: event,
            },
        })

        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })

        setFocusBottom()

        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: authModalContent,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: true,
                identifier: ModalName.SIGN_IN_EDIT,
            },
        })
    }, [authModalContent, drawerDispatch])

    return useCallback(() => {
        if (isCreateDraftMode) return true

        switch (canEditResponseUI) {
            case "poll-locked":
                drawerDispatch({
                    type: DrawerActionType.SHOW_MODAL,
                    payload: {
                        title: t("pollLocked"),
                        message: t("voteScreen.pollLocked"),
                        image: theme.image.pollLockedGraphic,
                        imageSize: {
                            height: 140,
                            width: 160,
                        },
                        buttonTitle: t("okay"),
                        dismissible: true,
                        identifier: ModalName.POLL_LOCKED,
                    },
                })

                return false
            case "sign-in":
                drawerDispatch({
                    type: DrawerActionType.SHOW_MODAL,
                    payload: {
                        title: t("signIn"),
                        message: t("voteScreen.signInRequired"),
                        image: theme.image.pollLockedGraphic,
                        imageSize: {
                            height: 140,
                            width: 160,
                        },
                        buttonTitle: t("signIn"),
                        onAction: handleAuthorize,
                        dismissible: true,
                        identifier: ModalName.SIGN_IN_TO_EDIT_POLL,
                    },
                })

                return false
            case "verify-ownership":
                drawerDispatch({
                    type: DrawerActionType.SHOW_MODAL,
                    payload: {
                        title: t("isYourPoll"),
                        message: t("voteScreen.verifyOwnership"),
                        image: theme.image.pollLockedGraphic,
                        imageSize: {
                            height: 140,
                            width: 160,
                        },
                        buttonTitle: t("signIn"),
                        onAction: handleAuthorize,
                        dismissible: true,
                        identifier: ModalName.IS_THIS_YOUR_POLL,
                    },
                })

                return false
            case "allow":
                return true
            default:
                break
        }
    }, [
        canEditResponseUI,
        drawerDispatch,
        handleAuthorize,
        isCreateDraftMode,
        t,
        theme.image.pollLockedGraphic,
    ])
}
