import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { Dimensions, ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface HomeScreenStyles extends Styles {
    horizontal: ViewStyle
    pageTitle: TextStyle
    pageSubtitle: TextStyle
    createPollButton: ViewStyle
    name: TextStyle
    greeting: TextStyle
    center: ViewStyle
    avatarContainer: ViewStyle
    imageContainer: ViewStyle
    image: ImageStyle
    container: ViewStyle
    fixedContainerUpper: ViewStyle
    fixedContainerLower: ViewStyle
}

export const homeScreenStyles: StyleFunction<HomeScreenStyles> = ({
    theme,
    width,
}) => {
    return {
        center: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        horizontal: {
            display: "flex",
            flexDirection: "row",
        },
        avatarContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
        },
        pageTitle: {
            ...theme.font.homePageTitle,
        },
        pageSubtitle: {
            ...theme.font.homePageSubtitle,
        },
        name: {
            ...theme.font.homeName,
        },
        greeting: {
            ...theme.font.homeGreeting,
        },
        imageContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
            flexGrow: 1,
        },
        image: {
            flex: 1,
            resizeMode: "contain",
            maxWidth: width - 80,
        },
        createPollButton: {
            marginTop: 20,
            width: 292,
        },
        container: {
            height:
                Dimensions.get("screen").height -
                (Dimensions.get("screen").height / 10 + 100),
        },
        fixedContainerUpper: {
            minHeight: 150,
        },
        fixedContainerLower: {
            paddingTop: 10,
            minHeight: 200,
        },
    }
}
