import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface NavHeaderStyles extends Styles {
    card: ViewStyle
    cardContent: ViewStyle
    cardActions: ViewStyle
    cardTitleContainer: ViewStyle
    cardTitle: TextStyle
    cardTitleIcon: TextStyle
    cardSubTitle: TextStyle
    checkboxContainer: ViewStyle
    checkBox: TextStyle
}

export const cardButtonStyles: StyleFunction<NavHeaderStyles> = ({ theme }) => {
    return {
        card: {
            flex: 1,
            flexGrow: 1,
            flexDirection: "row",
            margin: 5,
            backgroundColor: theme.color.profileSettingsCardBackground,
            borderRadius: theme.cornerRadius.profileSettingsCard,
        },
        cardContent: {
            flex: 1,
            paddingLeft: 18,
            paddingTop: 14,
            minHeight: theme.layout.defaultOptionCardHeight,
            maxHeight: theme.layout.defaultOptionCardHeight,
        },
        cardActions: {
            marginLeft: "auto",
            alignSelf: "center",
            height: theme.layout.defaultOptionCardHeight,
        },
        cardTitleContainer: {
            flex: 1,
            flexGrow: 1,
            flexDirection: "row",
            maxHeight: 20,
        },
        cardTitle: {
            ...theme.font.profileSettingsCardPrimaryText,
        },
        cardTitleIcon: {
            ...theme.font.profileSettingsCardPrimaryIcon,
            paddingRight: 10,
        },
        cardSubTitle: {
            ...theme.font.profileSettingsCardSecondaryText,
        },
        checkboxContainer: {
            paddingVertical: 20,
            paddingRight: 20,
        },
        checkBox: {
            color: theme.color.checkBox,
        },
        NATIVE: {
            card: {
                backfaceVisibility: "hidden",
                ...theme.shadow.profileSettingsCard.native,
            },
        },
        WEB: {
            card: {
                ...theme.shadow.profileSettingsCard.web,
            },
            cardTitle: {
                paddingBottom: 4,
            },
        },
        MOBILE: {
            card: {
                ...theme.shadow.profileSettingsCard.web,
            },
        },
    }
}
