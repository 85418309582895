import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle } from "react-native"

export interface ShareImageStyles extends Styles {
    container: ImageStyle
}

export const shareImageStyles: StyleFunction<ShareImageStyles> = ({
    width,
    theme,
}) => {
    return {
        container: {
            marginVertical: 24,
            height: 200,
            width: 339,
        },
    }
}
