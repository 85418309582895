import { AuthModal, ProfileAvatar, ScreenLayout } from "@components"
import { DrawerActionType, DrawerContext } from "@contexts/DrawerProvider"
import { UserContext } from "@contexts/UserDataProvider"
import { SignInFlow } from "@types"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import useStyles from "@helpers/hooks/useStyles"
import { commonStyles } from "@styles/Main.styles"
import React, { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView } from "react-native"
import {
    DefaultSettingsSection,
    InfoSection,
    LogoutSection,
    PhoneButton,
    PhoneNumberText,
    VersionSection,
} from "./components"
import { useRoute } from "@react-navigation/native"
import { ScreenName } from "@screens"
import { ModalName } from "@components/Drawer/content"
import { useFocus } from "@helpers/hooks/useFocus"

const ProfileScreen = () => {
    const { t } = useTranslation()
    const { styles: defaultStyles } = useStyles(commonStyles)
    const { setFocusBottom } = useFocus()
    const { drawerDispatch } = useContext(DrawerContext)
    const {
        userState: {
            phoneNumber,
            authStatus,
            amplifyInitialized,
            isAuthenticating,
        },
    } = useContext(UserContext)

    const route = useRoute()
    const isAuthenticated = authStatus === AuthenticationState.AUTHENTICATED

    const shouldSignIn = useMemo(() => {
        return route.name === ScreenName.PROFILE_SCREEN_SIGN_IN
    }, [route])

    useEffect(() => {
        if (
            shouldSignIn &&
            !isAuthenticated &&
            amplifyInitialized &&
            !isAuthenticating
        ) {
            handleSignInAction()
        }
    }, [amplifyInitialized, isAuthenticated, isAuthenticating])

    const handleSignInAction = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <AuthModal signInFlow={SignInFlow.profile} />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: true,
                identifier: ModalName.SIGN_IN_PROFILE,
            },
        })

        setFocusBottom()
    }

    return (
        <ScreenLayout>
            <ScrollView
                testID="scroll"
                keyboardDismissMode="none"
                keyboardShouldPersistTaps="handled"
                pinchGestureEnabled={false}
                style={defaultStyles.screenContainer}
                showsVerticalScrollIndicator={false}
            >
                <ProfileAvatar />
                {phoneNumber && isAuthenticated ? (
                    <PhoneNumberText phoneNumber={phoneNumber} />
                ) : (
                    <PhoneButton
                        title={t("signInPhone")}
                        onPress={handleSignInAction}
                    />
                )}
                <DefaultSettingsSection />
                <InfoSection />
                {isAuthenticated && <LogoutSection />}
                <VersionSection />
            </ScrollView>
        </ScreenLayout>
    )
}

export default ProfileScreen
