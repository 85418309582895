import { httpService } from "@services/http"
import Logger from "@services/logger"
import { PollSettings } from "@types"
import { captureMessage } from "@services/sentry"
interface SuccessResult {
    type: "success"
    data: RetrievedUser
}

interface ErrorResult {
    type: "error"
    error?: Error
}

interface RetrievedUser {
    id: string
    cognitoId: string
    name: string
    signInMethod: string
    phoneNumber: string
    userDefaults: { pollSettings: PollSettings }
}

interface EndpointResponse {
    user: RetrievedUser
}

export const getUser = async (
    deviceId: string,
): Promise<SuccessResult | ErrorResult> => {
    try {
        const response = await httpService.get<EndpointResponse>("/me", {
            headers: { "polls-device-id": deviceId },
        })

        if (response.status >= 200 && response.status < 300) {
            Logger.info(`updated user. response status: ${response.status}`)
            return { type: "success", data: response.data.user }
        }
    } catch (error) {
        captureMessage(error)
        Logger.error("could not get user info")
        return { type: "error", error: error as Error }
    }
    return { type: "error" }
}
