import { Vote } from "@contexts/PollContext/PollReducer"
import { httpService } from "@services/http"
import { PollData } from "@types"
import { AxiosError, AxiosResponse } from "axios"
import { captureMessage } from "@services/sentry"
import { RequestPollOption } from "@helpers/getRequestPoll"

interface EndpointPayload {
    resultingVotes: Vote[]
    name?: string
    sequentialUpdates?: LogEntry[]
}

interface EndpointResponse {
    poll: PollData
}

export type LogEntry =
    | {
          type: "removeOption"
          optionId: string
          value?: "true"
      }
    | {
          type: "addOption"
          option: RequestPollOption
      }

export interface ResultingVotes {
    optionId: string
    value: "true"
}

interface UpdatePollProps {
    userName?: string
    pollId: string
    deviceId: string
    sequentialUpdates: LogEntry[] | []
    resultingVotes: Vote[]
    isAuthenticated: boolean
}

export const updatePoll = async ({
    userName,
    pollId,
    deviceId,
    sequentialUpdates,
    resultingVotes,
    isAuthenticated,
}: UpdatePollProps) => {
    const requestUrl = isAuthenticated
        ? `polls/${pollId}`
        : `polls/${pollId}/vote`

    const requestConfig = {
        headers: {
            "polls-device-id": deviceId || "",
        },
    }

    let requestData: EndpointPayload = userName
        ? {
              name: userName,
              resultingVotes,
          }
        : { resultingVotes }

    if (isAuthenticated) {
        requestData = {
            ...requestData,
            sequentialUpdates,
        }
    }

    try {
        const {
            status,
            data: { poll },
        } = await httpService.put<
            EndpointPayload,
            AxiosResponse<EndpointResponse>
        >(requestUrl, requestData, requestConfig)

        return { isSuccess: status >= 200 && status < 300, data: poll }
    } catch (e) {
        captureMessage(e)
        return { isSuccess: false, error: e as AxiosError }
    }
}
