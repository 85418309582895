import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface OuterLayoutStyles extends Styles {
    backgroundTransparent: ViewStyle
    absoluteContainer: ViewStyle
}

export const OuterLayoutStyles: StyleFunction<OuterLayoutStyles> = () => {
    return {
        backgroundTransparent: {
            backgroundColor: "transparent",
        },
        absoluteContainer: {
            position: "absolute",
            height: "100%",
        },
        WEB: {
            backgroundTransparent: {
                boxShadow: "none",
            },
        },
    }
}
