import { View } from "react-native"
import React, { useContext } from "react"
import { Title, Button } from "@components"
import { DrawerActionType, DrawerContext } from "@contexts/DrawerProvider"
import { ConfirmLogoutModal } from "../"
import { useTranslation } from "react-i18next"
import useStyles from "@helpers/hooks/useStyles"
import { sectionStyles } from "../../ProfileScreen.styles"
import { AnalyticsEvent } from "@services/analytics"
import { ModalName } from "@components/Drawer/content"

const LogoutSection = () => {
    const { styles } = useStyles(sectionStyles)

    const { t } = useTranslation()
    const { drawerDispatch } = useContext(DrawerContext)

    const showConfirmModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <ConfirmLogoutModal />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: false,
                identifier: ModalName.CONFIRM_LOGOUT_MODAL,
            },
        })
    }

    return (
        <View>
            <Title style={styles.sectionTitle} title={t("account")} />
            <Button
                variant="text"
                align={"left"}
                style={styles.logoutButton}
                titleStyle={styles.logoutText}
                onPress={showConfirmModal}
                title={t("logout")}
                analyticsEvent={AnalyticsEvent.tapProfileLogout}
            />
        </View>
    )
}

export default LogoutSection
