import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface ShareLinkBarStyles extends Styles {
    container: ImageStyle
    shareLink: TextStyle
    shareButton: ViewStyle
    shareLinkContainer: ViewStyle
    shareLinkShadow: ViewStyle
}

export const shareLinkBarStyles: StyleFunction<ShareLinkBarStyles> = ({
    theme,
}) => {
    return {
        NATIVE: {
            container: {
                alignItems: "flex-start",
                justifyContent: "space-between",
            },
            shareLinkContainer: {
                ...theme.shadow.shareLinkShadow.native,
                marginRight: 20,
            },
            shareButton: {
                width: 110,
            },
        },
        WEB: {
            container: {
                flexDirection: "row",
            },
            shareLinkContainer: {
                ...theme.shadow.shareLinkShadow.web,
            },
            shareLink: {
                lineHeight: "unset",
            },
        },
        MOBILE: {
            shareLinkContainer: {
                ...theme.shadow.shareLinkShadow.web,
            },
            shareLink: {
                lineHeight: "unset",
            },
        },
        container: {
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-start",
        },
        shareLinkContainer: {
            width: 220,
            flex: 1,
            borderRadius: 10,
            paddingHorizontal: 4,
            marginRight: 12,
            minHeight: 34,
            overflow: "hidden",
            justifyContent: "center",
            marginVertical: 5,
            backgroundColor: theme.color.notificationPollUrlCardBackground,
        },
        shareLink: {
            ...theme.font.notificationPollUrlText,
        },
        shareButton: {},
        shareLinkShadow: {},
    }
}
