import { Amplify, Auth } from "aws-amplify"
import { getAppConfig } from "@services/appConfig"

const { amplifyAuth } = getAppConfig()
const { region, userPoolId, userPoolWebClientId } = amplifyAuth

const configureAmplify = (deviceId: string) => {
    Amplify.configure({
        Auth: {
            // REQUIRED - Amazon Cognito Region
            region: region,
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: userPoolId,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: userPoolWebClientId,
            authenticationFlowType: "CUSTOM_AUTH",
            clientMetadata: { "polls-device-id": deviceId },
        },
    })

    Auth.configure()
}

export default configureAmplify
