import React, { useContext } from "react"
import { FormError } from "@components"
import { PollActionType, PollContext } from "@contexts"

const BottomErrorMessage = () => {
    const {
        pollState: { errorMessage },
        pollDispatch,
    } = useContext(PollContext)

    const resetMessage = () => {
        pollDispatch({
            type: PollActionType.SET_ERROR_MESSAGE,
            payload: { message: "" },
        })
    }

    if (!errorMessage) return null

    return (
        <FormError
            message={errorMessage}
            disableShadow={true}
            onErrorDismissed={resetMessage}
        />
    )
}

export default BottomErrorMessage
