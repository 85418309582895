import { View } from "react-native"
import { Title } from "@components"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "@helpers/hooks/useStyles"
import { sectionStyles } from "../../ProfileScreen.styles"
import * as Manifest from "@helpers/manifest"

const VersionSection = () => {
    const { styles } = useStyles(sectionStyles)

    const { t } = useTranslation()

    const versionString = useMemo(() => {
        const env = Manifest.appEnv()
        const envPrefix = env === "prod" ? "" : `${env}-`
        const jsVersion = Manifest.jsVersion()
        const appVersion = Manifest.appVersion()
        const runtimeVersion = Manifest.runtimeVersion()

        return `${envPrefix}J${jsVersion}-A${appVersion}-${runtimeVersion}`
    }, [
        Manifest.appEnv,
        Manifest.jsVersion,
        Manifest.appVersion,
        Manifest.runtimeVersion,
    ])

    return (
        <View>
            <Title
                title={`${t("profileScreen.version")}: ${versionString}`}
                style={styles.versionText}
            />
        </View>
    )
}

export default VersionSection
