import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { Platform, TextStyle } from "react-native"
import { ViewStyle } from "react-native"

export interface ScreenLayoutStyles extends Styles {
    inputContainer: ViewStyle
    title: TextStyle
    titleActive: TextStyle
    input: ViewStyle
}

export const CreatePollScreenTitleInputStyles: StyleFunction<
    ScreenLayoutStyles
> = ({ theme }) => {
    return {
        inputContainer: {
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 0,
            width: "100%",
            marginTop: 9,
            marginBottom: 9,
        },
        input: {
            textAlign: "center",
            width: "100%",
            margin: 0,
            padding: 0,
        },
        title: {
            ...theme.font.createPollTitleMobile,
        },
        titleActive: {
            ...theme.font.pollTitleActiveMobile,
        },
        WEB: {
            title: {
                ...theme.font.createPollTitleWeb,
            },
            titleActive: {
                ...theme.font.pollTitleActiveWeb,
            },
            input: {
                overflow: "hidden",
                outlineStyle: "none",
                width: 375,
            },
        },
        NATIVE: {
            input: {
                height: Platform.select({ android: 50, ios: 42.9 }),
            },
        },
        MOBILE: {
            input: {
                width: "90%",
                height: 42.9,
                outlineStyle: "none",
                overflow: "hidden",
            },
            inputContainer: {
                marginVertical: 8,
            },
        },
    }
}
