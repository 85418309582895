import { createStackNavigator } from "@react-navigation/stack"
import {
    CreatePollScreen,
    GetAppScreen,
    ProfileScreen,
    ScreenName,
    SelectPollScreen,
    // ThemeScreen,
    VoteScreen,
} from "@screens"
import React from "react"
import { getAppConfig } from "@services/appConfig"

const Stack = createStackNavigator()

const RootNavigator = () => {
    const { tenantConfig } = getAppConfig()

    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                animationEnabled: false,
            }}
        >
            {tenantConfig.type === "customer" ? (
                <Stack.Group>
                    <Stack.Screen
                        name={ScreenName.VOTE_SCREEN}
                        component={VoteScreen}
                        options={{
                            title: "Vote",
                        }}
                        initialParams={{
                            pollId: "404",
                        }}
                    />
                    <Stack.Screen
                        name={ScreenName.SELECT_POLL_SCREEN}
                        component={SelectPollScreen}
                        options={{ title: "Draft Poll" }}
                    />
                </Stack.Group>
            ) : (
                <Stack.Group>
                    <Stack.Screen
                        name={ScreenName.CREATE_POLL_SCREEN}
                        component={CreatePollScreen}
                        options={{ title: "New Poll" }}
                    />
                    <Stack.Screen
                        name={ScreenName.VOTE_SCREEN}
                        component={VoteScreen}
                        options={{
                            title: "Vote",
                        }}
                        initialParams={{
                            pollId: "404",
                        }}
                    />
                    <Stack.Screen
                        name={ScreenName.PROFILE_SCREEN}
                        component={ProfileScreen}
                        options={{ title: "Profile" }}
                    />
                    <Stack.Screen
                        name={ScreenName.PROFILE_SCREEN_SIGN_IN}
                        component={ProfileScreen}
                        options={{ title: "Profile" }}
                    />
                    {/* <Stack.Screen
                        name={ScreenName.THEME_SCREEN}
                        component={ThemeScreen}
                        options={{ title: "Theme" }}
                    /> */}
                    <Stack.Screen
                        name={ScreenName.GET_APP_SCREEN}
                        component={GetAppScreen}
                        options={{ title: "Get The App" }}
                    />
                    <Stack.Screen
                        name={ScreenName.SELECT_POLL_SCREEN}
                        component={SelectPollScreen}
                        options={{ title: "Draft Poll" }}
                    />
                </Stack.Group>
            )}
        </Stack.Navigator>
    )
}

export default RootNavigator
