import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, ViewStyle } from "react-native"

export interface PostVoteImageModalStyles extends Styles {
    container: ViewStyle
    pressable: ViewStyle
    image: ImageStyle
}

export const PostVoteImageModalStyles: StyleFunction<
    PostVoteImageModalStyles
> = () => {
    return {
        container: {
            height: 400,
            width: 400,
        },
        pressable: {
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        },
        image: {
            width: "100%",
            height: "100%",
            resizeMode: "contain",
        },
    }
}
