import React, { useCallback, useContext } from "react"
import { AppAction, AppContext } from "@contexts/AppProvider"
import { AppActionType } from "@contexts/AppProvider/AppReducer"
import { NavigationProvider } from "@contexts/NavigationProvider"
import {
    DefaultTheme,
    NavigationContainer,
    useNavigationContainerRef,
} from "@react-navigation/native"
import Logger from "@services/logger"
import { linking, ScreenParamList } from "./LinkingConfiguration"
import { RootNavigator } from "./RootNavigator"
import { OuterLayout, SplashScreen } from "@components"
import {
    AnalyticsContextProvider,
    DrawerActionType,
    DrawerContext,
    PollActionType,
    PollContext,
} from "@contexts"

const TransparentApp = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: "transparent",
    },
}

interface NavigationProps {
    appDispatch: AppAction
    handleSetPollId: (id: string) => void
}

const Navigation = React.memo(
    ({ appDispatch, handleSetPollId }: NavigationProps) => {
        const navigationRef = useNavigationContainerRef<ScreenParamList>()

        const getPollId = () => {
            const params = navigationRef.getCurrentRoute()?.params as
                | { pollId: string }
                | undefined

            if (params?.pollId) {
                handleSetPollId(params?.pollId)
            }
        }

        const handleNavigationReady = () => {
            Logger.info("navigation ready")
            appDispatch({
                type: AppActionType.NAV_READY,
                payload: { navReady: true },
            })

            getPollId()
        }

        const handleStateChange = () => {
            getPollId()
        }

        return (
            <NavigationContainer
                ref={navigationRef}
                onReady={handleNavigationReady}
                linking={linking}
                theme={TransparentApp}
                fallback={<SplashScreen />}
                onStateChange={handleStateChange}
            >
                <NavigationProvider>
                    <AnalyticsContextProvider>
                        <RootNavigator />
                        <OuterLayout />
                    </AnalyticsContextProvider>
                </NavigationProvider>
            </NavigationContainer>
        )
    },
    (prev, next) => {
        return (
            prev.appDispatch === next.appDispatch &&
            prev.handleSetPollId === next.handleSetPollId
        )
    },
)

Navigation.displayName = "Navigation"

const NavigationWrapper = () => {
    const { appDispatch } = useContext(AppContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const {
        pollState: { isLaunched },
        pollDispatch,
    } = useContext(PollContext)

    const handleSetPollId = useCallback(
        (pollId: string) => {
            if (!isLaunched)
                drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })

            pollDispatch({
                type: PollActionType.SET_POLL_ID,
                payload: pollId,
            })
        },
        [isLaunched],
    )

    return (
        <Navigation
            appDispatch={appDispatch}
            handleSetPollId={handleSetPollId}
        />
    )
}

export default NavigationWrapper
