import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface VotersListStyle extends Styles {
    loader: ViewStyle
    flatList: ViewStyle
    lottieContainer: ViewStyle
}

export const VotersListStyle: StyleFunction<VotersListStyle> = ({ height }) => {
    return {
        loader: {
            width: 33,
            height: 33,
            alignSelf: "center",
            marginTop: 10,
        },
        lottieContainer: {
            height: height - 375,
        },
        flatList: {
            height: height - 375,
            marginTop: -2,
        },
        WEB: {
            flatList: {
                height: 180,
            },
            lottieContainer: {
                height: 180,
            },
        },
    }
}
