import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ResendCodeStyles extends Styles {
    spinner: ViewStyle
    resendText: TextStyle
}

export const ResendCodeStyles: StyleFunction<ResendCodeStyles> = ({
    theme,
}) => {
    return {
        resendText: {
            ...theme.font.verificationCodeResendCode,
        },
        spinner: {
            height: 25,
            padding: 0,
        },
    }
}
