import { Auth } from "aws-amplify"
import { CognitoUser } from "@types"
import { captureMessage } from "@services/sentry"

const getAuthenticatedUser = async (): Promise<CognitoUser | undefined> => {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        return cognitoUser as CognitoUser
    } catch (e) {
        captureMessage(e)
        return undefined
    }
}

export default getAuthenticatedUser
