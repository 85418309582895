import React, { Dispatch, Reducer } from "react"
import { AnalyticsEvent } from "@services/analytics"
import { Parameters } from "@services/analytics/AnalyticsTypes"

export enum AnalyticsActionType {
    LOG_ANALYTIC = "logAnalytic",
    LOG_LIST_ANALYTIC = "logListAnalytic",
}

export enum AnalyticsListActions {
    DONE = "done",
    ANOTHER = "another",
    REMOVE = "remove",
    CANCEL = "cancel",
    EDIT = "edit",
    ADD_OPTION = "addOption",
}

export type Action =
    | {
          type: AnalyticsActionType.LOG_ANALYTIC
          payload: {
              analyticId: AnalyticsEvent
              additionalData?: Parameters
          }
      }
    | {
          type: AnalyticsActionType.LOG_LIST_ANALYTIC
          payload: { listActionId: AnalyticsListActions }
      }

interface AnalyticsState {
    analyticId?: AnalyticsEvent
    analyticTrigger: boolean
    listActionId?: AnalyticsListActions
    listActionTrigger: boolean
    additionalData?: Parameters
}

interface AnalyticsContextContent {
    analyticsState: AnalyticsState
    analyticsDispatch: AnalyticsAction
}

type AnalyticsAction = Dispatch<Action>

const initialState: AnalyticsState = {
    analyticId: undefined,
    analyticTrigger: false,
    listActionId: undefined,
    listActionTrigger: false,
    additionalData: undefined,
}

const AnalyticsContext = React.createContext<AnalyticsContextContent>({
    analyticsState: initialState,
    analyticsDispatch: () => initialState,
})

const AnalyticsReducer: Reducer<AnalyticsState, Action> = (
    state: AnalyticsState,
    action: Action,
) => {
    switch (action.type) {
        case AnalyticsActionType.LOG_ANALYTIC: {
            const { analyticId, additionalData } = action.payload

            return {
                ...state,
                analyticId,
                additionalData: additionalData ?? initialState.additionalData,
                analyticTrigger: !state.analyticTrigger,
            }
        }
        case AnalyticsActionType.LOG_LIST_ANALYTIC: {
            const { listActionId } = action.payload

            return {
                ...state,
                listActionId,
                listActionTrigger: !state.listActionTrigger,
            }
        }

        default:
            return state
    }
}

export {
    AnalyticsAction,
    AnalyticsContext,
    AnalyticsReducer as analyticsReducer,
    AnalyticsState,
    initialState,
}
