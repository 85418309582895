import Cookies from "universal-cookie"

interface ICookie {
    name: string
    value: string
}
const cookies = new Cookies()

export const setCookie = ({ name, value }: ICookie): void => {
    cookies.set(name, value, {
        path: "/",
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        domain: ".pollsplatform.com",
    })
}

export const getCookie = (name: string): string => cookies.get(name)

export const destroyCookie = (name: string): void => {
    cookies.remove(name, {
        path: "/",
    })
}
