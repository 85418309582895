import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface GetAppScreenStyles extends Styles {
    container: ViewStyle
    image: ImageStyle
    titlesContainer: ViewStyle
    title: TextStyle
    subtitle: TextStyle
    input: ViewStyle
    errorMessage: ViewStyle
    errorMessageText: TextStyle
    button: ViewStyle
    buttonTitle: TextStyle
    qrContainer: ViewStyle
    asterisk: TextStyle
    qrTitle: TextStyle
}

export const getAppScreenStyles: StyleFunction<GetAppScreenStyles> = ({
    theme,
}) => {
    return {
        container: {
            paddingHorizontal: 10,
            alignItems: "center",
            flex: 1,
        },
        image: {
            width: 200,
            height: 148,
            marginVertical: 15,
        },
        titlesContainer: {
            alignItems: "center",
        },
        title: {
            ...theme.font.getAppTitle,
        },
        subtitle: {
            ...theme.font.getAppSubtitle,
        },
        input: {
            ...theme.font.phoneNumberCardTextActive,
            textAlign: "center",
            height: 48,
            marginHorizontal: "auto",
            marginTop: 5,
            marginBottom: 5,
            borderWidth: 1,
            borderColor: "#ddd",
            width: 300,
            borderRadius: 10,
            paddingHorizontal: 10,
            backgroundColor: "#fff",
            outlineStyle: "none",
            ...theme.shadow.phoneNumberInputShadow.web,
        },
        errorMessage: {
            marginTop: 0,
            marginBottom: 0,
        },
        errorMessageText: {
            fontSize: 16,
        },
        button: {
            width: 197,
            minHeight: 40,
            maxHeight: 40,
            marginBottom: 10,
        },
        buttonTitle: {
            fontSize: 24,
        },
        qrContainer: {
            alignItems: "center",
            marginTop: "auto",
            marginBottom: 56,
        },
        asterisk: {
            ...theme.font.phoneNumberCardTextBottom,
            textAlign: "center",
        },
        qrTitle: {
            ...theme.font.getAppQrTitle,
            marginBottom: 20,
        },
    }
}
