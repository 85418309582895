import { Image, ImageBackground, Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { HintModalStyles } from "./HintModal.styles"
import { EncodedImages } from "@assets/images/encodedImages"
import { AnimatedType, AnimatedView, Button, Title } from "@components"
import {
    AnalyticsActionType,
    AnalyticsContext,
    PollActionType,
    PollContext,
} from "@contexts"
import { t } from "i18next"
import { storageService } from "@services/storage"
import { StorageKeys } from "@types"
import { AnalyticsEvent } from "@services/analytics"

const HintModal = () => {
    const { styles, theme } = useStyles(HintModalStyles)
    const { analyticsDispatch } = useContext(AnalyticsContext)
    const { pollDispatch } = useContext(PollContext)

    const [isVisible, setVisible] = useState<boolean>(false)

    const {
        pollState: { isHintVisible },
    } = useContext(PollContext)

    const handleDismiss = () => {
        storageService.setItem(StorageKeys.REMEMBER_SUBMIT_SHOWN, true)
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.dismissModal,
                additionalData: {
                    modalName: "tooltipSubmitVote",
                },
            },
        })
    }

    const dismissModal = () => {
        pollDispatch({
            type: PollActionType.TOGGLE_HINT_MODAL,
            payload: { visible: false },
        })
    }

    const initialAnim = useMemo(() => {
        if (isHintVisible) {
            return {
                type: AnimatedType.SLIDE_V,
                speed: 20,
                from: 300,
                to: 0,
            }
        } else {
            return {
                type: AnimatedType.SLIDE_V,
                speed: 10,
                from: 0,
                to: 700,
            }
        }
    }, [isHintVisible])

    const overlayAnim = useMemo(() => {
        if (isHintVisible) {
            return { type: AnimatedType.FADE, from: 0, to: 0.7, duration: 300 }
        } else {
            return { type: AnimatedType.FADE, from: 0.7, to: 0 }
        }
    }, [isHintVisible])

    const handleAnimationComplete = useCallback(() => {
        if (!isHintVisible) setVisible(false)
    }, [isHintVisible])

    useEffect(() => {
        if (isHintVisible === isVisible) return

        const showModal = async () => {
            const modalShown = await storageService.getItem(
                StorageKeys.REMEMBER_SUBMIT_SHOWN,
            )
            analyticsDispatch({
                type: AnalyticsActionType.LOG_ANALYTIC,
                payload: {
                    analyticId: AnalyticsEvent.viewModal,
                    additionalData: {
                        modalName: "tooltipSubmitVote",
                    },
                },
            })

            setVisible(!modalShown)
        }
        isHintVisible ? showModal() : handleDismiss()
    }, [isHintVisible])

    if (!isVisible) return null

    return (
        <View style={styles.outerContainer}>
            <Pressable onPress={dismissModal} style={styles.overlayContainer}>
                <AnimatedView animProps={overlayAnim} style={styles.overlay} />
            </Pressable>
            <AnimatedView
                style={styles.animationContainer}
                animProps={initialAnim}
                onAnimationCompleted={handleAnimationComplete}
            >
                <ImageBackground
                    source={{ uri: theme.image.rememberSubmitBackground }}
                    style={styles.imageBackground}
                >
                    <Button
                        variant="text"
                        align="left"
                        iconStyle={theme.font.modalCloseIcon}
                        iconLeft={theme.image.closeX}
                        style={styles.closeIcon}
                        onPress={dismissModal}
                    />
                    <View style={styles.container}>
                        <Image
                            source={{ uri: theme.image.rememberSubmit }}
                            style={styles.image}
                        />
                        <Title title={t("allDone")} style={styles.title} />
                        <Title
                            title={t("youNeedToSubmit")}
                            style={styles.subtitle}
                            numberOfLines={2}
                        />
                    </View>
                </ImageBackground>
            </AnimatedView>
        </View>
    )
}

export default HintModal
