import React, { FC } from "react"
import useStyles from "@helpers/hooks/useStyles"
import { statusButtonStyles } from "./StatusButton.styles"
import { Button } from "@components"
import { vibrate } from "@services/vibration"
import { ViewStyle } from "react-native"

interface StatusButtonProps {
    title: string
    completedTitle: string
    onPress: () => void
    complete?: boolean
    isLoading?: boolean
    disabled?: boolean
    style?: ViewStyle
    titleStyle?: ViewStyle
}

const StatusButton: FC<StatusButtonProps> = ({
    title,
    completedTitle,
    onPress,
    complete,
    isLoading,
    disabled,
    style: propStyles,
    titleStyle: propTitleStyles,
}) => {
    const { styles, theme } = useStyles(statusButtonStyles)

    const handleOnPress = () => {
        vibrate("normalButtonPress")
        onPress()
    }

    if (!complete) {
        return (
            <Button
                title={title}
                titleStyle={[styles.buttonTitle, propTitleStyles]}
                iconStyle={styles.icon}
                onPress={handleOnPress}
                isLoading={isLoading}
                disabled={disabled}
                style={[
                    styles.backgroundNormal,
                    styles.noVerticalMargin,
                    propStyles,
                ]}
            />
        )
    } else {
        return (
            <Button
                title={completedTitle}
                titleStyle={[styles.buttonTitle, propTitleStyles]}
                iconLeft={theme.image.shareCopyButtonCheckmark}
                iconStyle={styles.icon}
                onPress={() => {
                    //noop
                }}
                style={[
                    styles.backgroundSuccess,
                    styles.noVerticalMargin,
                    propStyles,
                ]}
            />
        )
    }
}

export default StatusButton
