import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface ScreenLayoutStyles extends Styles {
    bg: ViewStyle
    container: ViewStyle
}

export const screenStyles: StyleFunction<ScreenLayoutStyles> = ({ theme }) => {
    return {
        bg: {
            flex: 1,
            backgroundColor: theme.color.appBackgroundMobile,
        },
        container: {
            flex: 1,
        },
        WEB: {
            container: {
                touchAction: "pan-y",
            },
            bg: {
                backgroundColor: theme.color.appBackgroundDesktop,
                backgroundImage: theme.image.appBackgroundDesktop,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            },
        },
        MOBILE: {
            container: {
                touchAction: "pan-y",
            },
            bg: {
                backgroundColor: theme.color.appBackgroundMobile,
            },
        },
        NATIVE: {
            bg: {
                backgroundColor: theme.color.appBackgroundMobile,
            },
        },
    }
}
