import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { Text, View } from "react-native"
import { shareVoteTitleStyles } from "./ShareVoteTitle.styles"

export const ShareVoteTitle = () => {
    const { styles } = useStyles(shareVoteTitleStyles)
    return (
        <View style={styles.container}>
            <Text style={styles.step}>Step 2:</Text>
            <Text style={styles.shareVote}>Share your vote</Text>
        </View>
    )
}
