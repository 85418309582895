import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface PollOptionCardStyles extends Styles {
    container: ViewStyle
    iconContainer: ViewStyle
    textContainer: ViewStyle
    dismissIconContainer: ViewStyle
    closeIcon: TextStyle
    closeButton: ViewStyle
}

export const PollOptionCardStyles: StyleFunction<PollOptionCardStyles> = ({
    theme,
}) => {
    return {
        container: {
            flex: 1,
            padding: 15,
            flexDirection: "row",
            justifyContent: "space-between",
        },
        iconContainer: {
            paddingRight: 15,
            paddingTop: 9,
        },
        textContainer: {
            flex: 1,
            justifyContent: "space-between",
            paddingTop: 11,
        },
        dismissIconContainer: {
            padding: 5,
        },
        closeIcon: {
            ...theme.font.modalCloseIcon,
        },
        closeButton: {
            paddingHorizontal: 0,
            marginTop: -10,
            minWidth: 10,
        },
        NATIVE: {
            closeButton: {
                marginRight: 0,
                marginLeft: 0,
            },
        },
    }
}
