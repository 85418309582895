import { Image, Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { PostVoteImageModalStyles } from "./PostVoteImageModal.styles"
import { AnalyticsContext, AppContext, PollContext } from "@contexts"
import {
    getPostVoteAction,
    getPostVoteActionImagePayload,
} from "@helpers/postVoteAction"
import { AnalyticsActionType } from "@contexts/AnalyticsProvider/AnalyticsReducer"
import { AnalyticsEvent, Parameter } from "@services/analytics"
import { useRedirect } from "@helpers/hooks/useRedirect"
import { getAppConfig } from "@services/appConfig"

const PostVoteImageModal = () => {
    const { styles } = useStyles(PostVoteImageModalStyles)

    // app state

    const {
        appState: { embeddedState, tenantOverrideKey },
    } = useContext(AppContext)

    // poll state

    const {
        pollState: {
            data: { settings },
        },
    } = useContext(PollContext)

    // analytics state

    const { analyticsDispatch } = useContext(AnalyticsContext)

    // handle platform specific routing

    const redirect = useRedirect()

    // get post vote action

    const postVoteAction = getPostVoteAction({
        pollSettings: settings,
        embeddedState,
    })

    // only pass post vote image override for demo
    const { tenantConfig } = getAppConfig()
    let postVoteImageTenantOverrideKey: string | undefined = undefined
    if (tenantConfig.backendTenantName === "demo") {
        postVoteImageTenantOverrideKey = tenantOverrideKey
    }

    // get image payload

    const { imageUrl, url } = getPostVoteActionImagePayload({
        postVoteAction,
        tenantOverrideKey: postVoteImageTenantOverrideKey,
    })

    // did press modal

    const didPress = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapPostVoteImage,
                additionalData: { [Parameter.url]: url },
            },
        })
        if (url) {
            redirect(url)
        }
    }

    return (
        <View style={styles.container}>
            {imageUrl && (
                <Pressable style={styles.pressable} onPress={didPress}>
                    <Image
                        source={{
                            uri: imageUrl,
                        }}
                        style={styles.image}
                    />
                </Pressable>
            )}
        </View>
    )
}

export default PostVoteImageModal
