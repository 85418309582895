// USER PROPERTIES
export const enum UserProperty {
    pollsUserId = "pollsUserId",
    pollsDeviceId = "pollsDeviceId",
    userName = "userName",
    appVersion = "appVersion",
    runtimeVersion = "runtimeVersion",
    jsVersion = "jsVersion",
    stage = "stage",
    pollSendCount = "pollSendCount",
    submitVoteCount = "submitVoteCount",
    tenant = "tenant",
    embeddedState = "embeddedState",
    storeId = "storeId",
}

// PARAMETERS

// we want to use consistent parameters across events
// this enum will help reduce the unique parameters we send
export const enum Parameter {
    pollId = "pollId",
    title = "title",
    version = "version",
    minCompatibleVersion = "minCompatibleVersion",
    ownerId = "ownerId",
    declareWinner = "declareWinner",
    editPoll = "editPoll",
    seeWhoVoted = "seeWhoVoted",
    multipleVotes = "multipleVotes",
    sendType = "sendType",
    postVoteAction = "postVoteAction",
    timeZoneId = "timeZoneId",
    modalName = "modalName",
    pollSettings = "pollSettings",
    screenName = "screenName",
    setting = "setting",
    value = "value",
    url = "url",
    optionUrl = "optionUrl",
}

// EVENTS
export enum AnalyticsEvent {
    // onboarding

    tapOnboardAgree = "tapOnboardAgree",
    tapOnboardTerms = "tapOnboardTerms",
    tapOnboardPrivacy = "tapOnboardPrivacy",

    tapOnboardSubmitPhone = "tapOnboardSubmitPhone",
    tapOnboardCodeBack = "tapOnboardCodeBack",
    tapOnboardCodeResend = "tapOnboardCodeResend",
    tapOnboardSubmitCode = "tapOnboardSubmitCode",
    onboardSignInSuccess = "onboardSignInSuccess",

    // home screen
    tapHomeCreatePoll = "tapHomeCreatePoll",
    tapHomeProfile = "tapHomeProfile",
    tapHomePollHistoryPoll = "tapHomePollHistoryPoll",

    // profile screen
    tapProfileAddName = "tapProfileAddName",
    tapProfileSignIn = "tapProfileSignIn",
    tapProfilePollSetting = "tapProfilePollSetting",
    tapProfileTerms = "tapProfileTerms",
    tapProfilePrivacy = "tapProfilePrivacy",
    tapProfileFeedback = "tapProfileFeedback",
    tapProfileLogout = "tapProfileLogout",
    tapProfileSubmitPhone = "tapProfileSubmitPhone",
    tapProfileSubmitCode = "tapProfileSubmitCode",
    tapProfileCodeBack = "tapProfileCodeBack",
    tapProfileCodeResend = "tapProfileCodeResend",
    tapProfileSaveName = "tapProfileSaveName",
    tapProfileLogoutConfirm = "tapProfileLogoutConfirm",
    profileSignInSuccess = "profileSignInSuccess",

    // create screen
    tapDraftSetTitle = "tapDraftSetTitle",
    tapDraftSetTitleNext = "tapDraftSetTitleNext",
    tapDraftEditTitle = "tapDraftEditTitle",
    tapDraftBack = "tapDraftBack",
    tapDraftEditTitleNext = "tapDraftEditTitleNext",
    tapDraftAddOption = "tapDraftAddOption",
    tapDraftOptionDone = "tapDraftOptionDone",
    tapDraftOptionAnother = "tapDraftOptionAnother",
    tapDraftOptionCancel = "tapDraftOptionCancel",
    tapDraftOptionEdit = "tapDraftOptionEdit",
    tapDraftOptionRemove = "tapDraftOptionRemove",
    draftOptionReorder = "draftOptionReorder",

    tapDraftDiscard = "tapDraftDiscard",
    tapDraftDiscardConfirm = "tapDraftDiscardConfirm",
    tapDraftProfile = "tapDraftProfile",

    tapDraftNextSignIn = "tapDraftNextSignIn",
    tapDraftNextSendSettings = "tapDraftNextSendSettings",

    tapLaunchSubmitPhone = "tapLaunchSubmitPhone",
    tapLaunchSubmitCode = "tapLaunchSubmitCode",
    tapLaunchCodeBack = "tapLaunchCodeBack",
    tapLaunchCodeResend = "tapLaunchCodeResend",
    launchSignInSuccess = "launchSignInSuccess",

    tapLaunchPollSetting = "tapLaunchPollSetting",
    tapLaunchSaveDefaults = "tapLaunchSaveDefaults",
    tapLaunchPoll = "tapLaunchPoll",
    createPollSuccess = "createPollSuccess",

    // vote screen
    tapVoteSelectOption = "tapVoteSelectOption",
    tapVoteUnselectOption = "tapVoteUnselectOption",
    tapVoteAddOption = "tapVoteAddOption",
    tapVoteOptionDone = "tapVoteOptionDone",
    tapVoteOptionAnother = "tapVoteOptionAnother",
    tapVoteOptionCancel = "tapVoteOptionCancel",

    tapVoteSaveName = "tapVoteSaveName",
    tapVoteSeeWho = "tapVoteSeeWho",
    tapVoteOpenLink = "tapVoteOpenLink",

    tapVoteHome = "tapVoteHome",
    tapVoteEdit = "tapVoteEdit",
    tapVoteSharePoll = "tapVoteSharePoll",
    tapVoteProfile = "tapVoteProfile",
    tapVoteInfo = "tapVoteInfo",

    tapVotePartnerLogo = "tapVotePartnerLogo",
    tapVotePoweredByPolls = "tapVotePoweredByPolls",

    voteSuccess = "voteSuccess",
    updatePollSuccess = "updatePollSuccess",

    tapVoteSubmitVote = "tapVoteSubmitVote",
    tapVoteSubmitUpdate = "tapVoteSubmitUpdate",

    // edit screen
    tapEditSubmitPhone = "tapEditSubmitPhone",
    tapEditSubmitCode = "tapEditSubmitCode",
    tapEditCodeBack = "tapEditCodeBack",
    tapEditCodeResend = "tapEditCodeResend",
    editSignInSuccess = "editSignInSuccess",

    tapEditRemove = "tapEditRemove",
    tapEditAddOption = "tapEditAddOption",
    tapEditOptionDone = "tapEditOptionDone",
    tapEditOptionAnother = "tapEditOptionAnother",
    tapEditOptionCancel = "tapEditOptionCancel",
    tapEditDone = "tapEditDone",

    // share vote
    tapShareVoteCopy = "tapShareVoteCopy",

    // share poll
    tapSharePollSms = "tapSharePollSms",
    tapSharePollEmail = "tapSharePollEmail",
    tapSharePollWhatsApp = "tapSharePollWhatsApp",
    tapSharePollMessenger = "tapSharePollMessenger",
    tapSharePollCopy = "tapSharePollCopy",
    tapSharePollTextMe = "tapSharePollTextMe",

    tapEditIsThisYourPollSignIn = "tapEditIsThisYourPollSignIn",
    tapEditSignInToEditSignIn = "tapEditSignInToEditSignIn",

    tapPostVoteDownloadPollsDownload = "tapPostVoteDownloadPollsDownload",
    tapPostVoteImage = "tapPostVoteImage",

    tapDraftFilterAll = "tapDraftFilterAll",
    tapDraftFilterNone = "tapDraftFilterNone",
    tapDraftFilterSelect = "tapDraftFilterSelect",
    tapDraftFilterUnselect = "tapDraftFilterUnselect",
    tapDraftFilterLaunch = "tapDraftFilterLaunch",

    linkOptionDetected = "linkOptionDetected",

    embeddedState = "embeddedState",

    // info modal
    tapInfoModalLink = "tapInfoModalLink",

    // modals and screens
    viewScreen = "viewScreen",
    viewModal = "viewModal",
    dismissModal = "dismissModal",
}
