import { createContext, Dispatch, Reducer } from "react"
import {
    AuthenticatedUserInfo,
    PollSettings,
    SignInFlow,
} from "../../types/index"
import { CognitoUser } from "@types"
import Logger from "@services/logger"

import { PropsToUpdate } from "@services/user/updateUser"
import {
    logout,
    setAuthenticatedUserIdentity,
    setUnauthenticatedUserIdentity,
    setUserName,
} from "@services/analytics"

export enum AuthenticationState {
    AUTHENTICATED = "authenticated",
    UNAUTHENTICATED = "unauthenticated",
    UNKNOWN = "unknown",
    VERIFY = "verify",
}

export enum UserActionType {
    LOG_IN = "logIn",
    LOG_OUT = "logOut",
    SET_COGNITO_USER = "setCognitoUser",
    SET_AUTHENTICATED_USER_INFO = "setAuthenticatedUserInfo",
    SET_UNAUTHENTICATED_USER = "setUnAuthenticatedUser",
    SET_POLL_SETTINGS = "setPollSettings",
    SET_NAME = "setName",
    SET_PHONE_NUMBER = "setPhoneNumber",
    SET_IS_PHONE_NUMBER_ENTERED = "setIsPhoneNumberEntered",
    SET_NO_LOGOUT_ACTION = "setNoLogoutAction",
    SET_CONSENT = "setConsent",
    SET_LOCAL_STORAGE_DATA = "setLocalStorageData",
    GET_FINGERPRINT_DATA = "getFingerprintData",
    INITIALIZE_AMPLIFY = "initializeAmplify",
    UPDATE_USER_DATA = "updateUserData",
    USER_DATA_UPDATED = "userDataUpdated",
}

type Action =
    | {
          type: UserActionType.LOG_IN
          payload: {
              signInFlow: SignInFlow
          }
      }
    | {
          type: UserActionType.LOG_OUT
      }
    | {
          type: UserActionType.SET_COGNITO_USER
          payload: {
              cognitoUser: CognitoUser | undefined
              isUserConfirmed: boolean
          }
      }
    | {
          type: UserActionType.SET_UNAUTHENTICATED_USER
          payload: {
              userName: string | undefined
              userId: string
              deviceId: string
              isLogout: boolean
          }
      }
    | {
          type: UserActionType.SET_AUTHENTICATED_USER_INFO
          payload: {
              authenticatedUserInfo: AuthenticatedUserInfo | undefined
              phoneNumber: string | undefined
              userName: string | undefined
              userId: string
          }
      }
    | {
          type: UserActionType.SET_POLL_SETTINGS
          payload: { pollSettings: PollSettings }
      }
    | {
          type: UserActionType.SET_NAME
          payload: { userName: string }
      }
    | {
          type: UserActionType.SET_PHONE_NUMBER
          payload: { phoneNumber: string }
      }
    | {
          type: UserActionType.SET_IS_PHONE_NUMBER_ENTERED
          payload: { isPhoneNumberEntered: boolean }
      }
    | {
          type: UserActionType.SET_NO_LOGOUT_ACTION
      }
    | {
          type: UserActionType.SET_CONSENT
          payload: { hasConsented: boolean }
      }
    | {
          type: UserActionType.SET_LOCAL_STORAGE_DATA
          payload: {
              hasConsented: boolean
              userId: string
              userName: string
              deviceId: string
          }
      }
    | {
          type: UserActionType.GET_FINGERPRINT_DATA
          payload: {
              forceCreateNew: boolean
          }
      }
    | {
          type: UserActionType.INITIALIZE_AMPLIFY
      }
    | {
          type: UserActionType.UPDATE_USER_DATA
          payload: { userDataToUpdate: PropsToUpdate }
      }
    | { type: UserActionType.USER_DATA_UPDATED }

type UserState = {
    userId: string
    authStatus: AuthenticationState
    amplifyInitialized: boolean
    loadingFingerPrint: boolean
    isUserConfirmed: boolean
    phoneNumber: string | undefined
    isPhoneNumberEntered: boolean
    userName: string | undefined
    deviceId: string
    cognitoUser: CognitoUser | undefined
    authenticatedUserInfo: AuthenticatedUserInfo | undefined
    pollSettings: PollSettings
    hasConsented: boolean
    isAuthenticating: boolean
    userDataToUpdate: PropsToUpdate | undefined
    forceCreateNew: boolean
    signInFlow?: SignInFlow
}

interface UserContextContent {
    userState: UserState
    userDispatch: UserAction
}

type UserAction = Dispatch<Action>

const initialState: UserState = {
    userId: "",
    authStatus: AuthenticationState.UNKNOWN,
    amplifyInitialized: false,
    loadingFingerPrint: false,
    isUserConfirmed: false,
    isPhoneNumberEntered: false,
    phoneNumber: "",
    userName: "",
    deviceId: "",
    cognitoUser: undefined,
    authenticatedUserInfo: undefined,
    hasConsented: false,
    isAuthenticating: true,
    userDataToUpdate: undefined,
    pollSettings: {
        seeWhoVoted: true,
        multipleVotes: true,
        editPoll: true,
        sendType: "",
        declareWinner: false,
    },
    forceCreateNew: false,
    signInFlow: undefined,
}

const UserContext = createContext<UserContextContent>({
    userState: initialState,
    userDispatch: () => initialState,
})

// reducer
const userReducer: Reducer<UserState, Action> = (
    state: UserState,
    action: Action,
) => {
    Logger.info(`ACTION UserReducer: ${action.type}`)
    switch (action.type) {
        case UserActionType.LOG_IN: {
            const { signInFlow } = action.payload

            return {
                ...state,
                authStatus: AuthenticationState.AUTHENTICATED,
                signInFlow,
            }
        }
        case UserActionType.LOG_OUT: {
            return {
                ...state,
                authStatus: AuthenticationState.UNAUTHENTICATED,
                loadingFingerPrint: false,
                cognitoUser: undefined,
                isUserConfirmed: false,
                authenticatedUserInfo: undefined,
                userName: "",
                phoneNumber: "",
            }
        }
        case UserActionType.SET_COGNITO_USER: {
            const { cognitoUser, isUserConfirmed } = action.payload
            return {
                ...state,
                cognitoUser,
                isUserConfirmed,
                // authStatus: AuthenticationState.AUTHENTICATED,
            }
        }
        case UserActionType.SET_AUTHENTICATED_USER_INFO: {
            const { authenticatedUserInfo, phoneNumber, userName, userId } =
                action.payload
            setAuthenticatedUserIdentity(state.deviceId, userId)
            setUserName(userName)
            Logger.info(
                `Authenticated user info: ${authenticatedUserInfo} ${phoneNumber} ${userName}`,
            )
            return {
                ...state,
                authStatus: AuthenticationState.AUTHENTICATED,
                authenticatedUserInfo,
                phoneNumber,
                userName,
                userId,
                isAuthenticating: false,
            }
        }
        case UserActionType.SET_UNAUTHENTICATED_USER: {
            const { userName, userId, deviceId, isLogout } = action.payload
            if (isLogout) {
                logout()
            } else {
                setUnauthenticatedUserIdentity(deviceId)
            }
            setUserName(userName)
            Logger.info(`UnAuthenticated user info: ${deviceId} ${userName}`)
            return {
                ...state,
                userName,
                userId,
                deviceId,
                isAuthenticating: false,
                loadingFingerPrint: false,
            }
        }
        case UserActionType.SET_NAME: {
            const { userName } = action.payload
            setUserName(userName)
            return {
                ...state,
                userName,
            }
        }
        case UserActionType.SET_PHONE_NUMBER: {
            const { phoneNumber } = action.payload
            return {
                ...state,
                phoneNumber,
            }
        }
        case UserActionType.SET_IS_PHONE_NUMBER_ENTERED: {
            const { isPhoneNumberEntered } = action.payload
            return {
                ...state,
                isPhoneNumberEntered,
            }
        }
        case UserActionType.SET_POLL_SETTINGS: {
            const { pollSettings } = action.payload
            return {
                ...state,
                pollSettings,
            }
        }
        case UserActionType.SET_CONSENT: {
            const { hasConsented } = action.payload
            return {
                ...state,
                hasConsented,
            }
        }
        case UserActionType.SET_LOCAL_STORAGE_DATA: {
            const { hasConsented, userId, userName, deviceId } = action.payload
            return {
                ...state,
                hasConsented,
                userId,
                userName,
                deviceId,
                authStatus: AuthenticationState.VERIFY,
            }
        }
        case UserActionType.GET_FINGERPRINT_DATA: {
            const { forceCreateNew } = action.payload
            return {
                ...state,
                loadingFingerPrint: true,
                authStatus: AuthenticationState.UNAUTHENTICATED,
                forceCreateNew,
            }
        }
        case UserActionType.INITIALIZE_AMPLIFY: {
            return {
                ...state,
                amplifyInitialized: true,
            }
        }
        case UserActionType.UPDATE_USER_DATA: {
            const { userDataToUpdate } = action.payload

            return {
                ...state,
                userName: userDataToUpdate.name ?? state.userName,
                pollSettings:
                    userDataToUpdate.userDefaults?.pollSettings ??
                    state.pollSettings,
                userDataToUpdate,
            }
        }
        case UserActionType.USER_DATA_UPDATED: {
            return {
                ...state,
                userDataToUpdate: undefined,
            }
        }
        default: {
            return state
        }
    }
}

export { UserAction, UserContext, userReducer, UserState, initialState }
