import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface PoweredByPollsStyles extends Styles {
    container: ViewStyle
    logo: ImageStyle
    title: TextStyle
    subtitle: TextStyle
}

export const PoweredByPollsStyles: StyleFunction<PoweredByPollsStyles> = ({
    theme,
}) => {
    return {
        container: {
            position: "absolute",
            flexDirection: "row",
            left: 25,
            bottom: 32,
        },
        logo: {
            width: 57,
            height: 43,
            marginLeft: 15,
            marginRight: 10,
        },
        title: {
            ...theme.font.poweredByHeader,
            marginTop: 1,
        },
        subtitle: {
            ...theme.font.poweredBySubheader,
            marginBottom: 1,
            marginLeft: 3,
        },
    }
}
