import React from "react"
// @ts-ignore
import LottieView from "react-native-web-lottie/src/index"
import { AnimatedLottieViewProps } from "lottie-react-native"

const LottieAnimation: React.FC<AnimatedLottieViewProps> = (
    props: AnimatedLottieViewProps,
) => {
    return <LottieView {...props} />
}

export default LottieAnimation
