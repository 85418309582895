import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
    Animated,
    Image,
    KeyboardTypeOptions,
    Text,
    TextInput,
    View,
} from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { sectionStyles } from "./EnterPhoneNumber.styles"
import { isValidPhoneNumber as checkIsValidPhoneNumber } from "react-phone-number-input"
import { Button, FormError } from "@components"
import PhoneInput, {
    Value as PhoneNumberType,
} from "react-phone-number-input/react-native-input"
import { useTranslation } from "react-i18next"
import { UserContext } from "@contexts"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { authenticateUser } from "@services/amplify"
import { AnalyticsEvent } from "@services/analytics"
import { SignInFlow } from "@types"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import { useFocus } from "@helpers/hooks/useFocus"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"

interface EnterPhoneNumberProps {
    signInFlow: SignInFlow
    onFocusLost?: (keyboardType: KeyboardTypeOptions) => void
}

const EnterPhoneNumber = ({ signInFlow }: EnterPhoneNumberProps) => {
    const { styles, theme } = useStyles(sectionStyles)
    const { t } = useTranslation()
    const {
        userState: { authStatus, isPhoneNumberEntered },
        userDispatch,
    } = useContext(UserContext)

    const isAuthenticated = authStatus === AuthenticationState.AUTHENTICATED
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumberType>()
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
    const phoneInputRef = useRef<TextInput>()
    const startPos = isAuthenticated ? -180 : 180
    const slideInAnim = useRef(new Animated.Value(startPos)).current
    const { isMobileView } = useScreenDimensions()

    const { setFocusTarget, setFocus, setFocusBottom } = useFocus()

    const focusPhoneInput = () => {
        if (!phoneInputRef.current) return

        setFocusTarget("phoneNo", phoneInputRef.current, "phone-pad")

        setFocus("phoneNo")
    }

    const slideIn = () => {
        Animated.timing(slideInAnim, {
            toValue: startPos,
            duration: 200,
            useNativeDriver: isPlatformNative(),
        }).start(focusPhoneInput)
    }

    useEffect(() => {
        if (!phoneInputRef.current) return

        slideIn()
    }, [phoneInputRef])

    useEffect(() => {
        if (!isPhoneNumberEntered && phoneNumber && phoneInputRef.current) {
            setTimeout(focusPhoneInput, 300)
        }
    }, [isPhoneNumberEntered, phoneInputRef])

    const handlePhoneInputChange = (newPhoneNumber: PhoneNumberType) => {
        if (newPhoneNumber) {
            setPhoneNumber(newPhoneNumber)
            setIsValidPhoneNumber(checkIsValidPhoneNumber(newPhoneNumber))
        }
    }

    const handleAuthenticateUser = async () => {
        if (!phoneNumber) return

        if (!isMobileView) setFocusBottom()

        setIsLoading(true)
        await authenticateUser({
            phoneNumber,
            userDispatch,
            setErrorMessage,
        })
        setIsLoading(false)
    }

    const submitPhoneAnalyticsEvent = useMemo(() => {
        switch (signInFlow) {
            case SignInFlow.onboarding:
                return AnalyticsEvent.tapOnboardSubmitPhone
            case SignInFlow.launchPoll:
                return AnalyticsEvent.tapLaunchSubmitPhone
            case SignInFlow.profile:
                return AnalyticsEvent.tapProfileSubmitPhone
            case SignInFlow.editPoll:
                return AnalyticsEvent.tapEditSubmitPhone
            default:
                return undefined
        }
    }, [signInFlow])

    return (
        <>
            <Image
                source={{
                    uri: theme.image.userAuthenticateGraphic,
                }}
                resizeMode={"contain"}
                style={styles.image}
            />
            <Text style={styles.title}>
                {t("phoneNumberModal.verifyPhoneNumber")}
            </Text>
            <Text style={styles.subtitle}>
                {t("phoneNumberModal.enterPhoneNumberBelow")}
            </Text>
            <View>
                <PhoneInput
                    ref={phoneInputRef}
                    disabled={isLoading}
                    placeholder={t("phoneNumberModal.phoneNumberPlaceholder")}
                    value={phoneNumber}
                    onChange={handlePhoneInputChange}
                    defaultCountry="US"
                    style={styles.input}
                />
            </View>
            {!!errorMessage && <FormError message={errorMessage} />}
            <Button
                disabled={!isValidPhoneNumber}
                onPress={handleAuthenticateUser}
                isLoading={isLoading}
                title={t("send")}
                variant={"primary"}
                analyticsEvent={submitPhoneAnalyticsEvent}
            />
        </>
    )
}

export default EnterPhoneNumber
