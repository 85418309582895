/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react"

export const useDebounce = (
    callback: () => void,
    timeout: number,
    deps: any[],
) => {
    const timeoutId = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        if (timeoutId.current) clearTimeout(timeoutId.current)
        timeoutId.current = setTimeout(callback, timeout)

        return () => {
            if (timeoutId.current) clearTimeout(timeoutId.current)
        }
    }, deps)
}
