import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface SeeWhoVotedModalStyles extends Styles {
    outerContainer: ViewStyle
    progressVotesContainer: ViewStyle
    votesContainer: ViewStyle
    counterText: TextStyle
    title: TextStyle
    sectionHeader: TextStyle
    sectionHeaderContainer: ViewStyle
    shadowContainer: ViewStyle
    shadowWrapper: ViewStyle
}

export const seeWhoVotedModalStyles: StyleFunction<SeeWhoVotedModalStyles> = ({
    theme,
}) => {
    return {
        outerContainer: {
            padding: 10,
            width: "100%",
        },
        progressVotesContainer: {
            justifyContent: "flex-end",
        },
        votesContainer: {
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
            height: 18,
        },
        counterText: {
            ...theme.font.voterListNumbersText,
        },
        title: {
            width: "100%",
            textAlign: "left",
            ...theme.font.voterListTitle,
        },
        sectionHeader: {
            ...theme.font.voterListSectionHeader,
            marginLeft: 8,
        },
        sectionHeaderContainer: {
            alignItems: "flex-start",
            paddingBottom: 10,
        },
        shadowContainer: {},
        shadowWrapper: {
            overflow: "hidden",
            marginTop: 10,
            paddingBottom: 5,
        },
        WEB: {
            shadowContainer: {
                ...theme.shadow.seeWhoVoted.web,
            },
            outerContainer: {
                width: 400,
                paddingHorizontal: 5,
                paddingVertical: 10,
            },
        },
        MOBILE: {
            shadowContainer: {
                ...theme.shadow.seeWhoVoted.web,
            },
            outerContainer: {
                paddingTop: 20,
                width: "100%",
            },
        },
        NATIVE: {
            shadowContainer: {
                ...theme.shadow.seeWhoVoted.native,
            },
            shadowWrapper: {
                paddingBottom: 2,
            },
        },
    }
}
