import React, { useContext } from "react"
import { Pressable, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { profileStyles } from "./ProfileAvatar.styles"
import { Icon } from "@assets/icons"
import {
    AnalyticsActionType,
    AnalyticsContext,
    DrawerActionType,
    DrawerContext,
    UserContext,
} from "@contexts"
import { EditNameModal } from "@components"
import { t } from "i18next"
import Avatar from "./Avatar"
import { AnalyticsEvent } from "@services/analytics"
import { ModalName } from "@components/Drawer/content"
import { useFocus } from "@helpers/hooks/useFocus"

const ProfileAvatar = () => {
    const { styles, theme } = useStyles(profileStyles)
    const { drawerDispatch } = useContext(DrawerContext)
    const {
        userState: { userName },
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const { setFocusBottom } = useFocus()

    const showOverlay = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapProfileAddName,
            },
        })

        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <EditNameModal />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: true,
                identifier: ModalName.PROFILE_ENTER_NAME,
            },
        })
        setFocusBottom()
    }

    return (
        <View style={styles.container}>
            <Avatar letter={userName?.charAt(0).toUpperCase() || ""} />
            <View style={styles.userNameContainer}>
                <Text
                    style={styles.userName}
                    numberOfLines={1}
                    ellipsizeMode={"tail"}
                >
                    {userName ? userName : t("addName")}
                </Text>
                <Pressable onPress={showOverlay}>
                    <Icon
                        style={styles.userNameIcon}
                        name={theme.image.userNameEdit}
                    />
                </Pressable>
            </View>
        </View>
    )
}

export default ProfileAvatar
