import { httpService } from "@services/http"
import { captureMessage } from "@services/sentry"

export interface Response {
    status: number
    data: {
        optionInfo: {
            title: string
            subtitle: string
            url: string
            imageUrl: string
            faviconUrl: string
            siteName: string
        }
    }
}

interface linkOptionInfoProps {
    link: string
}

export const linkOptionInfo = async ({ link }: linkOptionInfoProps) => {
    const requestUrl = "links/optionInfo"

    try {
        const response = await httpService.post<linkOptionInfoProps, Response>(
            requestUrl,
            {
                url: link,
            },
        )

        return {
            isSuccess: response.status >= 200 && response.status < 300,
            data: response.data.optionInfo,
        }
    } catch (e) {
        captureMessage(e)
        return { isSuccess: false, data: null }
    }
}
