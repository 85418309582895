import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface StatusButtonStyles extends Styles {
    backgroundNormal: ViewStyle
    backgroundSuccess: ViewStyle
    noVerticalMargin: ViewStyle
    icon: TextStyle
    buttonTitle: TextStyle
}

export const statusButtonStyles: StyleFunction<StatusButtonStyles> = ({
    theme,
}) => {
    return {
        backgroundNormal: {
            width: 132,
        },
        backgroundSuccess: {
            backgroundColor: theme.color.ctaPrimarySuccessBackground,
            width: 132,
        },
        noVerticalMargin: {
            marginVertical: 0,
            minHeight: 34,
        },
        icon: {
            ...theme.font.voteShareCopyIcon,
            paddingRight: 8,
            paddingLeft: 0,
        },
        buttonTitle: {
            fontSize: 18,
            lineHeight: 18,
        },
        NATIVE: {
            icon: {
                lineHeight: 15,
            },
        },
    }
}
