import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface ListSkeletonItemStyles extends Styles {
    skeletonContent: ViewStyle
}

export const listSkeletonItemStyles: StyleFunction<
    ListSkeletonItemStyles
> = () => {
    return {
        skeletonContent: {
            marginRight: 20,
        },
    }
}
