import Logger from "@services/logger"
import Amplitude, { Config } from "amplitude-js"

import {
    AmplitudeServiceType,
    EventProperties,
    LogLevel,
    UserProperties,
    ValidPropertyType,
    ValidPropertyTypeToAppend,
} from "../AmplitudeTypes"

const ampInstance = Amplitude.getInstance()

// init

const init = async (options: {
    apiKey: string
    deviceId?: string
    appVersion: string
    logLevel: LogLevel
}): Promise<boolean> => {
    const { apiKey, deviceId, appVersion, logLevel } = options

    let logLevelString: "DISABLE" | "ERROR" | "WARN" | "INFO" | undefined =
        undefined

    switch (logLevel) {
        case LogLevel.None: {
            logLevelString = "DISABLE"
            break
        }
        case LogLevel.Warn: {
            logLevelString = "WARN"
            break
        }
        case LogLevel.Error: {
            logLevelString = "ERROR"
            break
        }
        case LogLevel.Verbose: {
            logLevelString = "INFO"
            break
        }
    }

    const config: Config = {
        logLevel: logLevelString,
        includeUtm: true,
        includeGclid: true,
        includeFbclid: true,
        includeReferrer: true,
    }

    if (deviceId) {
        config.deviceId = deviceId
    }

    ampInstance.init(apiKey, undefined, config)

    return true
}

// user id

const getDeviceIdTimeout = async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

const getDeviceId = async () => {
    const maxNumberOfAttemps = 8
    const iterationDelayInMs = 500

    let deviceId: string | null = null
    let attemptsSoFar = 0

    while (!deviceId && attemptsSoFar < maxNumberOfAttemps) {
        await getDeviceIdTimeout(iterationDelayInMs)
        deviceId = await ampInstance.getDeviceId()
        attemptsSoFar += 1
        if (deviceId) {
            const message = `got Amplitude Device ID on attempt ${attemptsSoFar}: ${deviceId}`
            Logger.info(message)
        } else {
            const message = `failed to get Amplitude Device ID on attempt ${attemptsSoFar}`
            Logger.warning(message)
        }
    }

    return deviceId
}

const setDeviceId = (deviceId: string) => {
    ampInstance.setDeviceId(deviceId)
}

const setUserId = (userId: string) => {
    ampInstance.setUserId(userId)
}

const logout = () => {
    ampInstance.setUserId(null)
}

// set user property

const setUserProperty = (
    property: string,
    value: ValidPropertyType,
    setOnce = false,
) => {
    const identify = new Amplitude.Identify()
    if (setOnce) {
        identify.setOnce(property, value)
    } else {
        identify.set(property, value)
    }
    ampInstance.identify(identify)
}

const setUserProperties = (properties: UserProperties) => {
    let identify = new Amplitude.Identify()

    for (const [property, value] of Object.entries(properties)) {
        identify = identify.set(property, value)
    }

    ampInstance.identify(identify)
}

const unsetUserPropery = (property: string) => {
    const identify = new Amplitude.Identify()
    identify.unset(property)
    ampInstance.identify(identify)
}

// convenience for user properties

const incrementUserProperty = (property: string, incrementAmount?: number) => {
    incrementAmount = incrementAmount ?? 1

    const identify = new Amplitude.Identify()
    identify.add(property, incrementAmount)
    ampInstance.identify(identify)
}

const appendToUserPropertyArray = (
    property: string,
    value: ValidPropertyTypeToAppend,
) => {
    const identify = new Amplitude.Identify()
    identify.append(property, value)
    ampInstance.identify(identify)
}

// log event

const logEvent = (event: string, eventProperties?: EventProperties) => {
    ampInstance.logEvent(event, eventProperties)
}

const forceUploadEvents = () => {
    // no op
    // events are uploaded automatically
}

const AmplitudeService: AmplitudeServiceType | null = {
    // initialization

    init,

    // user id

    getDeviceId,
    setDeviceId,
    setUserId,
    logout,

    // user properties

    setUserProperty,
    setUserProperties,
    unsetUserPropery,
    incrementUserProperty,
    appendToUserPropertyArray,

    // events

    logEvent,
    forceUploadEvents,
}

export default AmplitudeService
