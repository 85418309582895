import { Text } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { TextCardTemplateStyles } from "./TextCardTemplate.styles"
import { PollOption, PollOptionText } from "@types"
import { PollsCardWrapper } from "@components/PreviewImage/templates/PollsCardWrapper"

interface TextCardTemplateItemProps {
    seeWhoVoted: boolean
    option: PollOptionText
    totalCounter: number
    onLoaded: (id: PollOption["id"]) => void
    isChecked: boolean
}

const TextCardTemplate = ({
    seeWhoVoted,
    option,
    totalCounter,
    onLoaded,
    isChecked,
}: TextCardTemplateItemProps) => {
    const { styles } = useStyles(TextCardTemplateStyles)

    return (
        <PollsCardWrapper
            seeWhoVoted={seeWhoVoted}
            option={option}
            isChecked={isChecked}
            totalCounter={totalCounter}
            onLoaded={onLoaded}
        >
            <Text style={styles.title} ellipsizeMode={"tail"} numberOfLines={2}>
                {option.title}
            </Text>
        </PollsCardWrapper>
    )
}

export default TextCardTemplate
