import { StatusButton } from "@components"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import useStyles from "@helpers/hooks/useStyles"
import { t } from "i18next"
import React from "react"
import { View, Text, Platform } from "react-native"
import { shareLinkBarStyles } from "./ShareLinkBar.styles"

interface ShareLinkBarProps {
    onPress: () => void
    shareLink: string
}

export const ShareLinkBar = ({ onPress, shareLink }: ShareLinkBarProps) => {
    const { styles } = useStyles(shareLinkBarStyles)
    const [complete, setComplete] = React.useState(false)

    const handlePress = () => {
        onPress()
        setComplete(true)

        setTimeout(() => {
            setComplete(false)
        }, 3000)
    }

    const { isMobileView } = useScreenDimensions()

    return (
        <View style={styles.container}>
            <View style={styles.shareLinkContainer}>
                <Text
                    style={styles.shareLink}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                >
                    {shareLink}
                </Text>
            </View>
            <View style={styles.shareButton}>
                <StatusButton
                    complete={complete}
                    title={
                        isMobileView || Platform.OS !== "web"
                            ? t("copy")
                            : t("copyLink")
                    }
                    onPress={handlePress}
                    completedTitle={t("copied")}
                />
            </View>
        </View>
    )
}
