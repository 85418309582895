import {
    AnalyticsActionType,
    AnalyticsContext,
    DrawerActionType,
    DrawerContext,
    NavigationContext,
    PollActionType,
    PollContext,
} from "@contexts"
import { useCallback, useContext } from "react"
import { storageService } from "@services/storage"
import { StorageKeys } from "@types"
import Logger from "@services/logger"
import { AnalyticsEvent } from "@services/analytics"
import { ScreenName } from "@screens"
import { ThemeContext } from "@contexts/ThemeContext"
import { t } from "i18next"

export const useDiscardPollFlow = () => {
    const { pollDispatch } = useContext(PollContext)
    const { navigate } = useContext(NavigationContext)
    const { theme } = useContext(ThemeContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    return useCallback(async () => {
        Logger.info("FLOW: discardPollFlow initialized")

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapDraftDiscardConfirm,
            },
        })

        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })

        storageService.setItem(StorageKeys.POLL_OPTIONS, "")
        storageService.setItem(StorageKeys.POLL_TITLE, "")

        pollDispatch({ type: PollActionType.RESET_POLL })

        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: {
                title: t("pollDeleted"),
                message: t("startPoll"),
                animation: theme.lottie.trashCan,
            },
        })

        navigate({ screen: ScreenName.CREATE_POLL_SCREEN })
    }, [drawerDispatch, pollDispatch])
}
