import { AuthModal } from "./AuthModal"
import { ConfirmLogoutModal } from "./ConfirmLogoutModal"
import { DiscardModal } from "./DiscardModal"
import { EditNameModal } from "./EditNameModal"
import { SeeWhoVotedModal } from "./SeeWhoVotedModal"
import { SendPollSettingsModal } from "./SendPollSettingsModal"
import { ShareVoteModal } from "./ShareVoteModal"
import { VoterAddNameModal } from "./VoterAddNameModal"
import { SharePollModal } from "./SharePollModal"
import { CustomerInfoModal } from "./CustomerInfoModal"
import { CreateOwnPolls } from "./CreateOwnPolls"
import { PostVoteImageModal } from "./PostVoteImageModal"

export enum ModalName {
    CONFIRM_LOGOUT_MODAL = "confirmLogout",
    CONFIRM_DISCARD_MODAL = "confirmDiscardPoll",
    EDIT_NAME_MODAL = "editNameModal",
    SEE_WHO_VOTED_MODAL = "seeWhoVotedModal",
    INVALID_POLL_MODAL = "invalidPollModal",
    SIGN_IN_LAUNCH = "signInLaunch",
    SIGN_IN_PROFILE = "signInProfile",
    SIGN_IN_EDIT = "signInEdit",
    FAILED_TO_LAUNCH_POLL = "failedToLaunchPoll",
    FAILED_TO_LOAD_POLL = "failedToLoadPoll",
    FAILED_TO_SUBMIT_VOTE = "failedToSubmitVote",
    FAILED_TO_CREATE_POLL = "failedToCreatePoll",
    POLL_LOCKED = "pollLocked",
    IS_THIS_YOUR_POLL = "isThisYourPoll",
    SIGN_IN_TO_EDIT_POLL = "signInToEditPoll",
    ALMOST_THERE = "almostThere",
    WIRES_CROSSED = "wiresCrossed",
    PROFILE_ENTER_NAME = "profileEnterName",
    VOTE_ENTER_NAME = "voteEnterName",
    VOTER_LIST = "voterList",
    PARTNER_INFO_MODAL = "partnerInfoModal",
    POST_VOTE_IMAGE_MODAL = "postVoteImage",
    SHARE_POLL = "sharePoll",
    SHARE_VOTE = "shareVote",
    SEND_POLL_SETTINGS = "sendPollSettings",
    EXIT_APP = "exitApp",
    DELAYED_MODAL = "delayedModal",
    CREATE_OWN_POLL = "createOwnPoll",
    POST_VOTE_DOWNLOAD_POLLS = "postVoteDownloadPolls",
}

export {
    AuthModal,
    ConfirmLogoutModal,
    DiscardModal,
    EditNameModal,
    SeeWhoVotedModal,
    ShareVoteModal,
    SendPollSettingsModal,
    VoterAddNameModal,
    SharePollModal,
    CustomerInfoModal,
    CreateOwnPolls,
    PostVoteImageModal,
}
