import {
    parsePhoneNumber,
    formatPhoneNumberIntl,
} from "react-phone-number-input"

// supply the phone number in international format with no spaces
// example: +12133734253
const formatPhoneNumber = (phoneNumber: string): string => {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
    if (!parsedPhoneNumber) {
        // fallback to international formatting
        return formatPhoneNumberIntl(phoneNumber)
    }

    if (parsedPhoneNumber.country === "US") {
        // use local formatter
        return parsedPhoneNumber.formatNational()
    } else {
        // use international formatting
        return parsedPhoneNumber.formatInternational()
    }
}

export default formatPhoneNumber
