import { httpService } from "@services/http"
import { PollData } from "@types"
import { captureMessage } from "@services/sentry"

interface Response {
    status: number
    data: {
        poll: PollData
    }
}

interface getPollProps {
    pollId: string
    deviceId: string
}
export const getPoll = async ({ pollId, deviceId }: getPollProps) => {
    const requestUrl = `polls/${pollId}`
    const requestConfig = {
        headers: { "polls-device-id": deviceId || "" },
    }

    try {
        const response = await httpService.get<getPollProps, Response>(
            requestUrl,
            requestConfig,
        )

        return {
            isSuccess: response.status >= 200 && response.status < 300,
            data: response.data,
        }
    } catch (e) {
        captureMessage(e)
        return { isSuccess: false, data: null }
    }
}
