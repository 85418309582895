import i18next from "i18next"

export const formatLinkWithLanguage = (baseUrl: string, suffix?: string) => {
    const language = i18next.language
    if (suffix) {
        return encodeURI(baseUrl + "/" + language + "/" + suffix)
    } else {
        return encodeURI(baseUrl + "/" + language)
    }
}
