import {
    configureAmplify,
    getAuthenticatedUser,
    isSessionValid,
} from "@services/amplify"
import { fingerprintService } from "@services/fingerprint"
import Logger from "@services/logger"
import * as Sentry from "@services/sentry"
import { storageService } from "@services/storage"
import { getUser } from "@services/user/getUser"
import { CookieKeys, StorageKeys } from "@types"
import React, { ReactNode, useEffect, useReducer } from "react"
import { UserContext } from "./"
import * as Analytics from "@services/analytics"
import {
    AuthenticationState,
    initialState,
    UserActionType,
    userReducer,
} from "./UserReducer"
import { setCookie } from "@services/cookies"
import { useUpdateUserFlow } from "@flows/useUpdateUserFlow"

interface UserProviderProps {
    children: ReactNode
}

export const UserDataProvider = ({ children }: UserProviderProps) => {
    const [userState, userDispatch] = useReducer(userReducer, initialState)

    const {
        deviceId,
        loadingFingerPrint,
        authStatus,
        amplifyInitialized,
        isUserConfirmed,
        forceCreateNew,
        userDataToUpdate,
    } = userState

    const updateUser = useUpdateUserFlow(userDispatch)

    const checkSession = async () => {
        const isValid = await isSessionValid()
        if (isValid) {
            const cognitoUser = await getAuthenticatedUser()
            if (cognitoUser) {
                userDispatch({
                    type: UserActionType.SET_COGNITO_USER,
                    payload: {
                        cognitoUser: cognitoUser,
                        isUserConfirmed: true,
                    },
                })
            }
        } else {
            userDispatch({
                type: UserActionType.GET_FINGERPRINT_DATA,
                payload: {
                    forceCreateNew: false,
                },
            })
        }
    }

    useEffect(() => {
        if (userDataToUpdate && Object.keys(userDataToUpdate).length)
            updateUser({
                isAuthenticated:
                    authStatus === AuthenticationState.AUTHENTICATED,
                propsToUpdate: userDataToUpdate,
                deviceId,
            })
    }, [userDataToUpdate])

    useEffect(() => {
        // update user info with sentry
        Sentry.updateUser(userState.userId, userState.deviceId)
    }, [userState.userId, userState.deviceId])

    useEffect(() => {
        const checkLocalStorage = async () => {
            Logger.info("Checking local storage user data")
            const consented = await storageService.getItem<string>(
                StorageKeys.HAS_CONSENTED,
            )
            const hasConsented = !!(consented && consented === "true")
            const deviceId = await storageService.getItem<string>(
                StorageKeys.DEVICE_ID,
            )
            const hasDeviceId = !!(deviceId && deviceId?.length > 1)
            const userId = await storageService.getItem<string>(
                StorageKeys.USER_ID,
            )
            const userName = await storageService.getItem<string>(
                StorageKeys.USER_NAME,
            )

            if (hasDeviceId) {
                userDispatch({
                    type: UserActionType.SET_LOCAL_STORAGE_DATA,
                    payload: {
                        hasConsented,
                        userId: userId || "",
                        userName: userName || "",
                        deviceId: deviceId || "",
                    },
                })
            } else {
                userDispatch({
                    type: UserActionType.GET_FINGERPRINT_DATA,
                    payload: {
                        forceCreateNew: false,
                    },
                })
            }
        }

        Sentry.initializeSentry()
        checkLocalStorage()
    }, [])

    useEffect(() => {
        if (
            loadingFingerPrint &&
            authStatus === AuthenticationState.UNAUTHENTICATED
        ) {
            Logger.info(`loadingFingerPrint ${loadingFingerPrint}`)
            fingerprintService({
                userDispatch,
                isForceCreateNew: forceCreateNew,
            })
        }
    }, [loadingFingerPrint, authStatus])

    useEffect(() => {
        const initializeAmplify = async () => {
            Logger.info(`initializeAmplify ${deviceId}`)
            configureAmplify(deviceId)
            userDispatch({
                type: UserActionType.INITIALIZE_AMPLIFY,
            })
        }
        if (
            deviceId &&
            (authStatus === AuthenticationState.VERIFY ||
                authStatus === AuthenticationState.UNAUTHENTICATED)
        )
            initializeAmplify()
    }, [deviceId, authStatus])

    useEffect(() => {
        if (amplifyInitialized && authStatus === AuthenticationState.VERIFY) {
            checkSession()
        }
    }, [amplifyInitialized, authStatus])

    useEffect(() => {
        const executeGetUser = async () => {
            const getUserResult = await getUser(deviceId)

            if (getUserResult.type === "success") {
                const {
                    name: userName,
                    id,
                    cognitoId,
                    signInMethod,
                    phoneNumber,
                    userDefaults: { pollSettings },
                } = getUserResult.data

                userDispatch({
                    type: UserActionType.SET_AUTHENTICATED_USER_INFO,
                    payload: {
                        authenticatedUserInfo: {
                            cognitoId,
                            signInMethod,
                        },
                        phoneNumber,
                        userName,
                        userId: id,
                    },
                })

                userDispatch({
                    type: UserActionType.SET_POLL_SETTINGS,
                    payload: { pollSettings },
                })

                storageService.setItem(StorageKeys.USER_NAME, userName)
                storageService.setItem(StorageKeys.POLL_SETTINGS, pollSettings)
                storageService.setItem(StorageKeys.USER_ID, id)
                setCookie({ name: CookieKeys.IS_AUTHENTICATED, value: "true" })
                setCookie({ name: CookieKeys.USER_NAME, value: userName })
            }
        }
        if (isUserConfirmed) {
            executeGetUser()
        }
    }, [isUserConfirmed])

    return (
        <UserContext.Provider
            value={{
                userState,
                userDispatch,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}
