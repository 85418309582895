import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface OptionCardStyles extends Styles {
    container: ViewStyle
    internalContainer: ViewStyle
}

export const ProgressBarStyles: StyleFunction<OptionCardStyles> = ({
    theme,
}) => {
    return {
        container: {
            height: 7,
            backgroundColor: theme.color.optionCardProgressBarBackground,
            borderRadius: 10,
            width: "100%",
        },
        internalContainer: {
            height: 7,
            backgroundColor: theme.color.optionCardProgressBarFill,
            borderRadius: 10,
            width: "100%",
        },
    }
}
