import { httpService } from "@services/http"
import { PollData, PollOption, PollOptionLink, PollSettings } from "@types"
import * as Localization from "expo-localization"
import uuid from "react-native-uuid"
import { captureMessage } from "@services/sentry"
import { RequestPollOption } from "@helpers/getRequestPoll"

interface Poll {
    poll: PollData
}

export interface LaunchPollData {
    id: PollData["id"]
    version: PollData["version"]
    minCompatibleVersion: PollData["minCompatibleVersion"]
    timeZoneId: PollData["timeZoneId"]
    settings: PollData["settings"]
    title: PollData["title"]
    options: RequestPollOption[]
}

interface LaunchPollProps {
    deviceId: string
    optionsList: RequestPollOption[]
    title: string
    pollSettings: PollSettings
}

export const launchPoll = async ({
    deviceId,
    optionsList,
    title,
    pollSettings,
}: LaunchPollProps) => {
    const settings = {
        ...pollSettings,
        declareWinner: true, // TODO
        sendType: "web", //TODO
    }

    const poll: LaunchPollData = {
        id: String(uuid.v4()),
        version: "3.3",
        minCompatibleVersion: "3.3",
        timeZoneId: Localization.timezone,
        settings,
        title,
        options: optionsList,
    }

    const requestConfig = {
        headers: {
            "polls-device-id": deviceId || "",
        },
    }

    try {
        const pollResponse = await httpService.post<Poll>(
            "polls",
            { poll },
            requestConfig,
        )

        return {
            isSuccess: pollResponse.status >= 200 && pollResponse.status < 300,
            data: pollResponse.data.poll,
        }
    } catch (e) {
        const error = e as Error
        captureMessage(error.message)
        return { isSuccess: false }
    }
}
