import useStyles from "@helpers/hooks/useStyles"
import { PollRecentVotes } from "@types"
import React, { useContext } from "react"
import { Text, View } from "react-native"
import { SeeWhoVotedIconsStyle } from "./SeeWhoVotedIcons.styles"
import { PollContext } from "@contexts/PollContext"
import { hash } from "@components/Drawer/content/SeeWhoVotedModal/VotersList/hashColors"

interface UserVoteIconParams {
    item: PollRecentVotes
}
const UserVoteIcon = ({ item }: UserVoteIconParams) => {
    const {
        pollState: { pollId },
    } = useContext(PollContext)
    const { styles, theme } = useStyles(SeeWhoVotedIconsStyle)
    return (
        <View
            style={[
                styles.avatar,
                {
                    backgroundColor: hash({
                        pollId,
                        userId: item.voter.id,
                        colors: theme.layout.palette,
                    }),
                },
            ]}
        >
            <Text style={styles.avatarIcon}>
                {item.voter.name.charAt(0).toLocaleUpperCase()}
            </Text>
        </View>
    )
}

export default UserVoteIcon
