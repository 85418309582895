import { useEffect, useState } from "react"
import { Keyboard, KeyboardEvent } from "react-native"

export const useKeyboardHeight = () => {
    const [keyboardHeight, setKeyboardHeight] = useState<number>(0)

    useEffect(() => {
        const onChange = (e: KeyboardEvent) => {
            if (
                e.endCoordinates.height !== keyboardHeight &&
                e.endCoordinates.height > 0
            ) {
                setKeyboardHeight(e.endCoordinates.height)
            }
        }

        const keyboardShowListener = Keyboard.addListener(
            "keyboardDidShow",
            onChange,
        )

        return () => {
            keyboardShowListener.remove()
        }
    }, [])

    return keyboardHeight
}
