import { isPlatformNative } from "@helpers/isPlatformNative"
import Bowser from "bowser"
import { modelName, osBuildFingerprint } from "expo-device" // Android only
import { osName, osVersion } from "react-device-detect"
import { Platform } from "react-native"

export const getOSBuildFingerprint = (): string => {
    const buildFingerprint = osBuildFingerprint || ""
    return isPlatformNative() ? buildFingerprint : ""
}

export const getDeviceModel = (): string => (modelName ? modelName : "")

export const getBrowser = (): string => {
    if (Platform.OS !== "web") return ""
    const browser = Bowser.getParser(navigator.userAgent)
    return browser.getBrowserName() + browser.getBrowserVersion()
}

export const getDeviceOS = (): string => {
    return osName !== "none" ? osName + " " + osVersion : ""
}
