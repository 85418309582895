import { Icon } from "@assets/icons"
import { LottieAnimation } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import {
    NativeSyntheticEvent,
    NativeTouchEvent,
    Pressable,
    StyleProp,
    Text,
    TextStyle,
    View,
    ViewStyle,
} from "react-native"
import { buttonStyles } from "./Button.styles"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"
import { AnalyticsEvent } from "@services/analytics"

interface ButtonProps {
    testID?: string
    variant?: "primary" | "secondary" | "text"
    style?: StyleProp<ViewStyle>
    titleStyle?: StyleProp<TextStyle>
    title?: string
    iconLeft?: string
    iconRight?: string
    iconStyle?: StyleProp<TextStyle>
    disabled?: boolean
    isLoading?: boolean
    align?: string
    onPress: (e: NativeSyntheticEvent<NativeTouchEvent>) => void
    analyticsEvent?: AnalyticsEvent
}

const Button = ({
    testID,
    variant = "primary",
    style,
    titleStyle,
    title,
    iconLeft,
    iconStyle,
    iconRight,
    disabled,
    isLoading,
    align,
    onPress,
    analyticsEvent,
}: ButtonProps) => {
    const { styles, theme } = useStyles(buttonStyles)
    const variantStyle = styles[variant]

    const { analyticsDispatch } = useContext(AnalyticsContext)

    const handleOnPress = (e: NativeSyntheticEvent<NativeTouchEvent>) => {
        if (disabled) return

        if (analyticsEvent)
            analyticsDispatch({
                type: AnalyticsActionType.LOG_ANALYTIC,
                payload: {
                    analyticId: analyticsEvent,
                },
            })

        onPress(e)
    }

    const getBackgroundColor = () => {
        switch (variant) {
            case "primary":
                return {
                    backgroundColor: disabled
                        ? theme.color.ctaPrimaryInactiveBackground
                        : theme.color.ctaPrimaryActiveBackground,
                }
            case "secondary":
                return {
                    backgroundColor: disabled
                        ? theme.color.ctaSecondaryInactiveBackground
                        : theme.color.ctaSecondaryActiveBackground,
                }
        }
    }

    const getFontColor = () => {
        switch (variant) {
            case "primary":
                return {
                    color: disabled
                        ? theme.color.ctaPrimaryDisabledText
                        : theme.color.ctaPrimaryText,
                }
            case "secondary":
                return {
                    color: disabled
                        ? theme.color.ctaSecondaryDisabledText
                        : theme.color.ctaSecondaryText,
                }
            case "text":
                return {
                    color: disabled
                        ? theme.color.ctaFloatingDisabledText
                        : theme.color.ctaFloatingText,
                }
        }
    }

    const getIconColor = () => {
        switch (variant) {
            case "primary":
                return {
                    color: disabled
                        ? theme.color.ctaPrimaryDisabledText
                        : theme.color.ctaPrimaryText,
                }
            case "secondary":
                return {
                    color: disabled
                        ? theme.color.ctaSecondaryDisabledText
                        : theme.color.ctaSecondaryText,
                }
            case "text":
                return {
                    color: disabled
                        ? theme.color.ctaFloatingDisabledText
                        : theme.color.ctaFloatingText,
                }
        }
    }

    const renderIcon = (iconName: string) => {
        return (
            <Icon
                style={[styles.btnIcon, getIconColor(), iconStyle]}
                name={iconName}
            />
        )
    }

    const getAlignment = () => {
        switch (align) {
            case "left":
                return "flex-start"
            case "center":
                return "center"
            case "right":
                return "flex-end"
            default:
                return "center"
        }
    }

    return (
        <View style={[styles.ctn, { alignItems: getAlignment() }]}>
            <Pressable
                needsOffscreenAlphaCompositing={true}
                style={[
                    styles.container,
                    variantStyle,
                    getBackgroundColor(),
                    style,
                ]}
                onPress={handleOnPress}
            >
                {isLoading ? (
                    <LottieAnimation
                        style={styles.spinner}
                        source={theme.lottie.whiteSpinner}
                        autoPlay
                        loop
                    />
                ) : (
                    <View style={styles.btnContainer}>
                        {iconLeft && renderIcon(iconLeft)}
                        {title && (
                            <Text
                                style={[
                                    styles.btnTitle,
                                    getFontColor(),
                                    titleStyle,
                                ]}
                            >
                                {title}
                            </Text>
                        )}
                        {iconRight && renderIcon(iconRight)}
                    </View>
                )}
            </Pressable>
        </View>
    )
}

export default Button
