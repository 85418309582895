import AsyncStorage from "@react-native-async-storage/async-storage"
import Logger from "@services/logger"
import { captureMessage } from "@services/sentry"

export const storageService = {
    getItem: async <T>(key: string): Promise<T | string | null> => {
        try {
            const value = await AsyncStorage.getItem(key)
            if (!value) return null
            if (isStringifiedObject(value)) return JSON.parse(value)
            return value
        } catch (e) {
            captureMessage(JSON.stringify(e))
            Logger.error(JSON.stringify(e))
            return null
        }
    },
    setItem: async (key: string, value: unknown): Promise<void> => {
        try {
            await AsyncStorage.setItem(
                key,
                typeof value === "string" ? value : JSON.stringify(value),
            )
        } catch (e) {
            const stringifiedError = JSON.stringify(e)
            captureMessage(stringifiedError)
            Logger.error(stringifiedError)
        }
    },
    clear: async (): Promise<void> => {
        try {
            await AsyncStorage.clear()
        } catch (e) {
            captureMessage(e)
            Logger.error("Error clearing storage")
        }
    },
}

const isStringifiedObject = (value: string) =>
    value.charAt(0) === "{" && value.charAt(value.length - 1) === "}"
