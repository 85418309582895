import useStyles from "@helpers/hooks/useStyles"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { FormError, ScreenLayout, StatusButton, Title } from "@components"
import { getAppScreenStyles } from "./GetAppScreen.styles"
import { Image, ScrollView, Text, View } from "react-native"
import { t } from "i18next"
import PhoneInput, {
    Value as PhoneNumberType,
} from "react-phone-number-input/react-native-input"
import QRCode from "react-native-qrcode-svg"
import { AnalyticsEvent } from "@services/analytics"
import { isValidPhoneNumber as checkIsValidPhoneNumber } from "libphonenumber-js/max"
import { textMeTheApp } from "@services/poll/textMeTheApp"
import links from "@config/links"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"

const GetAppScreen = () => {
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const { styles, theme } = useStyles(getAppScreenStyles)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumberType>("")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false)
    const [messageSent, setMessageSent] = useState<boolean>(false)

    const handleSendLink = useCallback(async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapSharePollTextMe,
            },
        })

        setIsLoading(true)

        const { isSuccess, error } = await textMeTheApp({ phoneNumber })

        if (!isSuccess && error) {
            setErrorMessage("Failed to send")
        } else {
            setMessageSent(true)
        }

        setIsLoading(false)
    }, [phoneNumber])

    const handlePhoneInputChange = useCallback(
        (newPhoneNumber: PhoneNumberType) => {
            const phoneNumber = newPhoneNumber ?? ""
            setPhoneNumber(phoneNumber)
            setIsValidPhoneNumber(checkIsValidPhoneNumber(phoneNumber))
        },
        [],
    )

    useEffect(() => {
        if (messageSent) {
            const timeOutHandler = setTimeout(() => {
                setMessageSent(false)
            }, 3000)

            return () => {
                clearTimeout(timeOutHandler)
            }
        }
    }, [messageSent])

    return (
        <ScreenLayout>
            <ScrollView
                contentContainerStyle={styles.container}
                showsHorizontalScrollIndicator={false}
            >
                <Image
                    source={{ uri: theme.image.getAppScreenImage }}
                    style={styles.image}
                />
                <View style={styles.titlesContainer}>
                    <Title
                        title={t("getAppScreen.getTheApp")}
                        style={styles.title}
                    />
                    <Title
                        title={t("getAppScreen.sendADownloadLink")}
                        style={styles.subtitle}
                    />
                    <PhoneInput
                        placeholder={t(
                            "phoneNumberModal.phoneNumberPlaceholder",
                        )}
                        value={phoneNumber}
                        onChange={handlePhoneInputChange}
                        defaultCountry="US"
                        style={styles.input}
                    />
                </View>
                <FormError
                    message={errorMessage}
                    styles={styles.errorMessage}
                    textStyles={styles.errorMessageText}
                    disableShadow={true}
                />
                <StatusButton
                    onPress={handleSendLink}
                    title={t("getAppScreen.sendLink")}
                    completedTitle={t("sent")}
                    style={styles.button}
                    titleStyle={styles.buttonTitle}
                    disabled={!isValidPhoneNumber}
                    isLoading={isLoading}
                    complete={messageSent}
                />
                <Text style={styles.asterisk}>
                    {t("phoneNumberModal.standardsRatesMayApply")}
                </Text>
                <View style={styles.qrContainer}>
                    <Title
                        title={t("getAppScreen.orScan")}
                        style={styles.qrTitle}
                    />
                    <QRCode value={links.getPolls} size={178} />
                </View>
            </ScrollView>
        </ScreenLayout>
    )
}

export default GetAppScreen
