// a presets prop, e.g., "lightButtonPress", expects a number value or an array of numbers
// if a number is set, it determines the duration of vibration in ms
// if a number array is set, it determines a vibration pattern, also in ms, where:
// values assigned to odd indexes denote separation intervals,
// and values assigned to even indexes denote vibration duration

// undefined and nulls (prohibited at static type check but will go into runtime)
// are handled with a default duration of 400ms;

// cancellable vibration can be implemented as needed

export const presets = {
    lightButtonPress: 20,
    normalButtonPress: 40,
    significantButtonPress: [50, 80, 80, 80],
    modalAppear: 20,
    drawerAppear: 20,
}
