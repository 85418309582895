import Logger from "@services/logger"
import React, {
    ReactNode,
    useReducer,
    Dispatch,
    createContext,
    Reducer,
    useEffect,
    useRef,
} from "react"
import { SuccessModal } from "@components/Drawer/content/SuccessModal"
import { Modal } from "@components/Drawer/content/Modal"

import { ModalName } from "@components/Drawer/content"
import { base64Image } from "@assets/theme/Theme"

enum DrawerActionType {
    SHOW_DRAWER = "showDrawer",
    SHOW_MODAL = "showModal",
    HIDE_DRAWER = "hideDrawer",
    DISMISS_DRAWER = "dismissDrawer",
    SET_ACTIVE = "setActive",
    DISPLAY_DRAWER = "displayDrawer",
    SHOW_SUCCESS_MODAL = "showSuccessModal",
}

type Action =
    | {
          type: DrawerActionType.SHOW_DRAWER
          payload: {
              content: ReactNode
              dismissible: boolean
              hasCloseButton: boolean
              hasBackground: boolean
              delay?: number
              onDismiss?: (props?: any) => void
              identifier: ModalName
              isModal?: boolean
          }
      }
    | {
          type: DrawerActionType.SHOW_MODAL
          payload: {
              title: string
              message?: string
              autoDismiss?: boolean
              dismissible?: boolean
              buttonTitle?: string
              image?: base64Image
              imageSize?: { height: number; width: number }
              animation?: any
              animationSize?: number
              onDismiss?: () => void
              onAction?: () => void
              preventDismiss?: boolean
              delay?: number
              hasCloseButton?: boolean
              identifier: ModalName
          }
      }
    | {
          type: DrawerActionType.HIDE_DRAWER
      }
    | {
          type: DrawerActionType.DISMISS_DRAWER
          payload?: {
              onDismiss?: () => void
          }
      }
    | {
          type: DrawerActionType.SET_ACTIVE
      }
    | {
          type: DrawerActionType.DISPLAY_DRAWER
      }
    | {
          type: DrawerActionType.SHOW_SUCCESS_MODAL
          payload: {
              title: string
              message?: string
              delay?: number
              duration?: number
              animationStart?: number
              animationEnd?: number
              animationScale?: number
              onDismiss?: (props?: any) => void
              animation?: any
          }
      }

type DrawerState = {
    isShowing: boolean
    visible: boolean
    isActive: boolean
    dismissed: boolean
    dismissible: boolean
    hasCloseButton: boolean
    hasBackground?: boolean
    delay?: number
    autoDismiss: boolean
    autoDismissDelay: number
    onDismiss?: () => void
    content: ReactNode
    isModal?: boolean
    identifier?: ModalName
}

const initialState: DrawerState = {
    isShowing: false,
    visible: false,
    isActive: false,
    dismissed: true,
    dismissible: true,
    hasCloseButton: true,
    hasBackground: true,
    autoDismiss: false,
    autoDismissDelay: 2000,
    onDismiss: undefined,
    delay: 0,
    content: null,
    isModal: false,
    identifier: undefined,
}

interface DrawerProviderProps {
    children: ReactNode
}

type DrawerAction = Dispatch<Action>

interface DrawerContext {
    drawerState: DrawerState
    drawerDispatch: DrawerAction
}

const DrawerContext = createContext<DrawerContext>({
    drawerState: initialState,
    drawerDispatch: () => initialState,
})

// reducer
const drawerReducer: Reducer<DrawerState, Action> = (state, action) => {
    Logger.info(`Action: ${action.type}`)
    switch (action.type) {
        case DrawerActionType.SHOW_DRAWER: {
            const {
                content,
                delay = 100,
                onDismiss,
                dismissible,
                hasCloseButton,
                hasBackground,
                identifier,
                isModal = false,
            } = action.payload

            return {
                ...state,
                isShowing: true,
                dismissed: false,
                content,
                delay,
                onDismiss,
                dismissible,
                hasCloseButton,
                hasBackground,
                identifier,
                isModal,
            }
        }
        case DrawerActionType.DISMISS_DRAWER: {
            const onDismiss = action?.payload?.onDismiss || state.onDismiss
            return {
                ...state,
                isActive: false,
                dismissed: true,
                autoDismiss: false,
                autoDismissDelay: initialState.autoDismissDelay,
                onDismiss,
            }
        }
        case DrawerActionType.HIDE_DRAWER: {
            return {
                ...state,
                visible: false,
                content: null,
                isActive: false,
                isModal: false,
                hasBackground: true,
                pollData: undefined,
                identifier: undefined,
                alignContentEnd: false,
            }
        }
        case DrawerActionType.SET_ACTIVE: {
            return {
                ...state,
                isActive: true,
            }
        }
        case DrawerActionType.DISPLAY_DRAWER: {
            return {
                ...state,
                visible: true,
                isShowing: false,
            }
        }
        case DrawerActionType.SHOW_SUCCESS_MODAL: {
            const {
                title,
                message,
                onDismiss,
                delay,
                animation,
                duration,
                animationStart,
                animationEnd,
                animationScale,
            } = action.payload
            return {
                ...state,
                isShowing: true,
                dismissed: false,
                content: (
                    <SuccessModal
                        title={title}
                        message={message}
                        animation={animation}
                        duration={duration}
                        animationStart={animationStart}
                        animationEnd={animationEnd}
                        animationScale={animationScale}
                    />
                ),
                delay,
                onDismiss,
                autoDismissDelay: duration ?? state.autoDismissDelay,
                dismissible: false,
                hasCloseButton: false,
                hasBackground: true,
                isModal: true,
                autoDismiss: true,
            }
        }
        case DrawerActionType.SHOW_MODAL: {
            const {
                title,
                message = "",
                dismissible = true,
                image = undefined,
                imageSize = { height: 60, width: 60 },
                buttonTitle = "",
                animation = undefined,
                animationSize = undefined,
                onDismiss = undefined,
                onAction = undefined,
                autoDismiss = false,
                delay = 0,
                hasCloseButton = true,
                identifier,
            } = action.payload

            return {
                ...state,
                isShowing: true,
                dismissed: false,
                content: (
                    <Modal
                        title={title}
                        message={message}
                        buttonTitle={buttonTitle}
                        onAction={onAction}
                        image={image}
                        imageSize={imageSize}
                        animation={animation}
                        animationSize={animationSize}
                    />
                ),
                dismissible,
                autoDismiss,
                delay,
                onDismiss,
                hasCloseButton,
                isModal: true,
                identifier,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}

// provider
const DrawerProvider = ({ children }: DrawerProviderProps) => {
    const [drawerState, drawerDispatch] = useReducer(
        drawerReducer,
        initialState,
    )
    const runner = useRef<any>(null)
    const {
        isShowing,
        delay,
        autoDismiss,
        autoDismissDelay,
        dismissed,
        onDismiss,
        visible,
    } = drawerState

    const onDismissTimerComplete = () => {
        clearTimeout(runner.current)
        runner.current = null
        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
    }

    const onShowTimerComplete = () => {
        clearTimeout(runner.current)
        runner.current = null
        drawerDispatch({ type: DrawerActionType.DISPLAY_DRAWER })
        if (autoDismiss) {
            runner.current = setTimeout(
                onDismissTimerComplete,
                autoDismissDelay,
            )
        }
    }

    useEffect(() => {
        if (isShowing) {
            runner.current = setTimeout(onShowTimerComplete, delay)
        }
    }, [isShowing])

    useEffect(() => {
        if (dismissed && !visible) {
            clearTimeout(runner.current)
            runner.current = null
            onDismiss?.()
        }
    }, [dismissed, visible, onDismiss])

    return (
        <DrawerContext.Provider value={{ drawerState, drawerDispatch }}>
            {children}
        </DrawerContext.Provider>
    )
}

export { DrawerContext, DrawerProvider, DrawerAction, DrawerActionType }
