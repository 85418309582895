import { Title } from "@components"
import { UserActionType, UserContext } from "@contexts/UserDataProvider"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { sectionStyles } from "../../ProfileScreen.styles"
import { ToggleCardButton } from "../ToggleCardButton"
import { AnalyticsEvent } from "@services/analytics"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"

type Setting = "seeWhoVoted" | "editPoll" | "multipleVotes"

const DefaultSettingsSection = () => {
    const { styles, theme } = useStyles(sectionStyles)
    const { t } = useTranslation()
    const {
        userState: {
            pollSettings,
            pollSettings: { editPoll, seeWhoVoted, multipleVotes },
        },
        userDispatch,
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const toggleSetting = (setting: Setting) => () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapProfilePollSetting,
                additionalData: {
                    setting: setting,
                    value: String(!pollSettings[setting]),
                },
            },
        })

        const updatedSetting = {
            [setting]: !pollSettings[setting],
        }

        const propsToUpdate = {
            userDefaults: {
                pollSettings: {
                    ...pollSettings,
                    ...updatedSetting,
                },
            },
        }

        userDispatch({
            type: UserActionType.UPDATE_USER_DATA,
            payload: {
                userDataToUpdate: propsToUpdate,
            },
        })
    }

    return (
        <View>
            <Title
                style={styles.sectionTitle}
                title={t("profileScreen.defaultPollSettings")}
            />
            <ToggleCardButton
                title={t("profileScreen.seeWhoVoted.title")}
                subtitle={t("profileScreen.seeWhoVoted.description")}
                icon={theme.image.userSettingsSeeWhoVoted}
                isChecked={seeWhoVoted || false}
                onPress={toggleSetting("seeWhoVoted")}
            />
            <ToggleCardButton
                title={t("profileScreen.editPoll.title")}
                subtitle={t("profileScreen.editPoll.description")}
                icon={theme.image.userSettingsEditPoll}
                isChecked={editPoll || false}
                onPress={toggleSetting("editPoll")}
            />
            <ToggleCardButton
                title={t("profileScreen.multiVote.title")}
                subtitle={t("profileScreen.multiVote.description")}
                icon={theme.image.userSettingsMultiVote}
                isChecked={multipleVotes || false}
                onPress={toggleSetting("multipleVotes")}
            />
        </View>
    )
}

export default DefaultSettingsSection
