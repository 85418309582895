import React, { useContext, useMemo } from "react"
import PollListView from "./PollListView"
import {
    AnalyticsActionType,
    AnalyticsContext,
    AppContext,
    DrawerActionType,
    DrawerContext,
    PollActionType,
    PollContext,
    UserContext,
} from "@contexts"
import {
    PollOption,
    PollOptionLink,
    PollOptionType,
    PollOptionValueType,
} from "@types"
import {
    ModalName,
    SeeWhoVotedModal,
    VoterAddNameModal,
} from "@components/Drawer/content"
import { useFocus } from "@helpers/hooks/useFocus"
import { AnalyticsListActions } from "@contexts/AnalyticsProvider/AnalyticsReducer"
import { AnalyticsEvent } from "@services/analytics"
import { getRequestPollOptions } from "@helpers/getRequestPoll"
import {
    EmbeddedMessageEvent,
    sendWrapperMessage,
} from "@helpers/sendWrapperMessage"
import { useRedirect } from "@helpers/hooks/useRedirect"
import { useToggleHintModal } from "@helpers/hooks/useToggleHintModal"
import { getTrackedLink } from "@helpers/getTrackedLink"

const PollList = () => {
    const {
        pollState: {
            currentSessionVotesByUser,
            data: pollData,
            isTitleSet,
            pollTitle,
            pollTitleEdit,
            editMode,
            editVoteMode,
            exitEditMode,
            isCreateDraftMode,
            optionsAdded,
            isScrollTop,
            customerSharePoll,
        },
        pollDispatch,
    } = useContext(PollContext)
    const {
        userState: { userId, userName },
    } = useContext(UserContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)
    const {
        appState: { embeddedState },
    } = useContext(AppContext)

    const options = useMemo(() => pollData.options, [pollData.options])
    const totalVoteCount = useMemo(
        () => pollData.totalVoteCount,
        [pollData.totalVoteCount],
    )
    const settings = useMemo(() => pollData.settings, [pollData.settings])

    const { setFocus, setFocusBottom } = useFocus()
    const redirect = useRedirect()
    const toggleHintModal = useToggleHintModal()

    const addOption = (
        type: PollOptionType,
        valueType: PollOptionValueType,
    ) => {
        pollDispatch({
            type: PollActionType.ADD_OPTION,
            payload: {
                ownerId: userId,
                type,
                valueType,
                editVoteMode: !isCreateDraftMode,
            },
        })
    }

    const removeOption = (id: string) => {
        pollDispatch({ type: PollActionType.REMOVE_OPTION, payload: { id } })
    }

    const setOptions = (options: PollOption[]) => {
        pollDispatch({ type: PollActionType.SET_OPTIONS, payload: { options } })
    }

    const handleOnVote = (id: string, valueType: PollOptionValueType) => {
        if (customerSharePoll)
            pollDispatch({
                type: PollActionType.SET_CUSTOMER_SHARE_POLL,
                payload: false,
            })

        pollDispatch({
            type: PollActionType.VOTE,
            payload: { id, valueType, userId },
        })

        const showHintModal = () => toggleHintModal(true)

        if (!userName?.length && settings.seeWhoVoted) {
            setFocusBottom()

            drawerDispatch({
                type: DrawerActionType.SHOW_DRAWER,
                payload: {
                    identifier: ModalName.VOTE_ENTER_NAME,
                    content: <VoterAddNameModal />,
                    hasBackground: true,
                    hasCloseButton: false,
                    dismissible: false,
                    onDismiss: showHintModal,
                },
            })
            return
        }

        showHintModal()
    }

    const handleOnOpenOptionLink = (id: string, index: number, url: string) => {
        const trackedUrl = getTrackedLink(url)

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapVoteOpenLink,
                additionalData: { optionUrl: trackedUrl },
            },
        })
        const option = [...pollData.options].find(i => i.id === id)

        if (embeddedState && option) {
            const optionPayload = getRequestPollOptions([option])[0]

            const optionUrl = (optionPayload as { url?: string }).url
            if (optionUrl) {
                //@ts-ignore
                optionPayload.url = getTrackedLink(optionUrl)
            }

            sendWrapperMessage({
                event: EmbeddedMessageEvent.VIEW_OPTION_DETAILS,
                payload: {
                    option: optionPayload,
                },
            })
            return
        }

        redirect(trackedUrl)
    }

    const handleSetScrollTop = (top: boolean) => {
        if (top !== isScrollTop)
            pollDispatch({ type: PollActionType.SET_SCROLL_TOP, payload: top })
    }

    const handleSeeWhoVoted = (data: PollOption, checked: boolean) => {
        if (!settings?.seeWhoVoted || data.recentVotes.length < 1) return

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapVoteSeeWho,
            },
        })

        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: (
                    <SeeWhoVotedModal
                        optionId={data.id}
                        checked={checked}
                        totalCounter={totalVoteCount}
                        title={data.title}
                        voteCount={data.voteCount[0].count}
                    />
                ),
                dismissible: false,
                hasBackground: true,
                hasCloseButton: false,
                identifier: ModalName.VOTER_LIST,
            },
        })
    }

    const handleSetTitle = () => {
        pollDispatch({ type: PollActionType.SET_POLL_TITLE })
    }

    const setEditMode = (enabled: boolean) => {
        pollDispatch({
            type: PollActionType.SET_EDIT_MODE,
            payload: { enabled },
        })
    }

    const setEditVoteMode = (enabled: boolean) => {
        pollDispatch({
            type: PollActionType.SET_EDIT_VOTE_MODE,
            payload: { enabled },
        })
    }

    const logListAnalytic = (listActionId: AnalyticsListActions) => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_LIST_ANALYTIC,
            payload: { listActionId },
        })
    }

    const logAnalytic = (analyticId: AnalyticsEvent) => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: analyticId,
            },
        })
    }

    const onLinkOptionParsed = (option: PollOptionLink) => {
        pollDispatch({
            type: PollActionType.LINK_OPTION_PARSED,
            payload: {
                option,
            },
        })
    }

    return (
        <PollListView
            testID={"create-poll-list"}
            editMode={editMode}
            editVoteMode={editVoteMode}
            exitEditMode={exitEditMode}
            draftMode={isCreateDraftMode}
            isTitleSet={isTitleSet}
            pollTitleEdit={pollTitleEdit}
            pollTitle={pollTitle}
            pollOwnerId={pollData.ownerId}
            userId={userId}
            userName={userName}
            options={options}
            currentSessionVotesByUser={currentSessionVotesByUser}
            totalVoteCount={totalVoteCount}
            optionsAdded={optionsAdded}
            setFocus={setFocus}
            setEditMode={setEditMode}
            setEditVoteMode={setEditVoteMode}
            addOption={addOption}
            removeOption={removeOption}
            setOptions={setOptions}
            onVote={handleOnVote}
            onOpenOptionLink={handleOnOpenOptionLink}
            onLinkOptionParsed={onLinkOptionParsed}
            setScrollTop={handleSetScrollTop}
            seeWhoVoted={handleSeeWhoVoted}
            setTitle={handleSetTitle}
            logListAnalytic={logListAnalytic}
            logAnalytic={logAnalytic}
        />
    )
}

export default PollList
