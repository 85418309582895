import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface EditNameModalStyles extends Styles {
    container: ViewStyle
    buttonsContainer: ViewStyle
    cancelButton: TextStyle
}

export const editNameModalStyles: StyleFunction<EditNameModalStyles> = () => {
    return {
        container: {
            alignItems: "center",
            paddingHorizontal: 10,
            paddingVertical: 10,
        },
        buttonsContainer: {
            marginTop: 20,
        },
        cancelButton: {
            fontSize: 14,
        },
    }
}
