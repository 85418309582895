import { Button, Title } from "@components"
import { DrawerActionType, DrawerContext } from "@contexts"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { t } from "i18next"
import { Text, View } from "react-native"
import { discardModalStyles } from "./DiscardModal.styles"
import { useDiscardPollFlow } from "@flows/useDiscardPollFlow"
import { isPlatformMobile } from "@helpers/isPlatformMobile"

const DiscardModal = () => {
    const { styles } = useStyles(discardModalStyles)
    const { drawerDispatch } = useContext(DrawerContext)
    const isMobile = isPlatformMobile()

    const discardPoll = useDiscardPollFlow()

    const handleCancel = () => {
        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
    }

    return isMobile ? (
        <View style={styles.container}>
            <View style={styles.card}>
                <Text style={styles.subheader}>{t("confirmDiscard")}</Text>
                <Button
                    style={styles.buttonDiscard}
                    titleStyle={styles.buttonDiscardText}
                    variant="text"
                    onPress={discardPoll}
                    title={t("discard")}
                />
            </View>
            <View style={styles.card}>
                <Button
                    titleStyle={styles.buttonCancelText}
                    variant="text"
                    onPress={handleCancel}
                    title={t("cancel")}
                />
            </View>
        </View>
    ) : (
        <View style={styles.card}>
            <Title style={styles.title} title={t("discard")} />
            <Text style={styles.subheader}>{t("confirmDiscard")}</Text>
            <View style={styles.buttons}>
                <Button
                    style={styles.buttonDiscard}
                    titleStyle={styles.buttonDiscardText}
                    variant="secondary"
                    onPress={discardPoll}
                    title={t("discard")}
                />
                <Button
                    style={styles.buttonCancel}
                    titleStyle={styles.buttonCancelText}
                    variant="secondary"
                    onPress={handleCancel}
                    title={t("cancel")}
                />
            </View>
        </View>
    )
}

export default DiscardModal
