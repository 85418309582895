import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { PixelRatio, TextStyle } from "react-native"

export interface LinkCardTemplateStyles extends Styles {
    title: TextStyle
}

export const LinkCardTemplateStyles: StyleFunction<LinkCardTemplateStyles> = ({
    theme,
}) => {
    const ratio = PixelRatio.get()

    return {
        title: {
            ...theme.font.previewImageOptionTitle,
            fontSize: theme.font.previewImageOptionTitle.fontSize / ratio,
            lineHeight: theme.font.previewImageOptionTitle.lineHeight / ratio,
            flexWrap: "wrap",
            marginBottom: "auto",
            flex: 1,
        },
    }
}
