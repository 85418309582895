import Logger from "@services/logger"
import { TenantTheme } from "@theme/Theme"
import * as Font from "expo-font"
import * as SplashScreen from "expo-splash-screen"
import { useEffect, useState } from "react"
import { captureMessage } from "@services/sentry"

export default function useCachedResources() {
    const [isLoadingComplete, setLoadingComplete] = useState(false)

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            try {
                await SplashScreen.preventAutoHideAsync()
                await Font.loadAsync(TenantTheme.fontFiles)

                setLoadingComplete(true)
                await SplashScreen.hideAsync()
            } catch (e) {
                captureMessage(JSON.stringify(e))
                Logger.warning(JSON.stringify(e))
            }
        }

        loadResourcesAndDataAsync()
    }, [])

    return isLoadingComplete
}
