import React, { useCallback, useContext, useEffect } from "react"
import {
    AuthModal,
    Button,
    DiscardModal,
    PollList,
    ScreenLayout,
    SendPollSettingsModal,
} from "@components"
import {
    AnalyticsActionType,
    AnalyticsContext,
    DrawerActionType,
    DrawerContext,
    NavigationContext,
    PollActionType,
    PollContext,
    UserContext,
} from "@contexts"

import { Platform } from "react-native"
import { useFocusEffect, useRoute } from "@react-navigation/native"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import useStyles from "@helpers/hooks/useStyles"
import { vibrate } from "@services/vibration"
import { BottomBarOption } from "@components/BottomNavigator/config"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import { SignInFlow } from "@types"
import { ScreenName } from "@screens"
import { ScreenParamList } from "@navigation/LinkingConfiguration"
import { bottomNavigatorStyles } from "@components/BottomNavigator/BottomNavigator.styles"
import { useTranslation } from "react-i18next"
import { AnalyticsEvent } from "@services/analytics"
import Logger from "@services/logger"
import { BottomNavigator } from "@components/BottomNavigator"
import { getAppConfig } from "@services/appConfig"
import { ModalName } from "@components/Drawer/content"
import { useFocus } from "@helpers/hooks/useFocus"

const CreatePollScreen = () => {
    const { t } = useTranslation()
    const { styles: bottomBarStyles, theme } = useStyles(bottomNavigatorStyles)

    const {
        pollState: {
            pollTitle,
            pollTitleEdit,
            data: { options },
            isTitleSet,
            isDataRestored,
        },
        pollDispatch,
    } = useContext(PollContext)
    const { navigate } = useContext(NavigationContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const {
        userState: { authStatus },
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const route = useRoute()

    useEffect(() => {
        if (!route) return
        const setPollTitleFromRoute = () => {
            const params = route.params as { [key: string]: string }
            if (params && params["title"]) {
                const { title } = params
                pollDispatch({
                    type: PollActionType.SET_POLL_TITLE_FROM_PARAMS,
                    payload: { title },
                })
            }
        }

        if (isDataRestored && !pollTitle.length) setPollTitleFromRoute()
    }, [route, isDataRestored])

    const {
        tenantConfig: { bottomBarConfig: cfg },
    } = getAppConfig()
    const { isMobileView } = useScreenDimensions()

    const isMobile = isMobileView || Platform.OS !== "web"

    const redirect = (screenName: keyof ScreenParamList) => {
        navigate({ screen: screenName })
        vibrate("normalButtonPress")
    }

    const filterValidateOptionList = useCallback((): boolean => {
        if (options.filter(item => item.title.length).length >= 2) {
            pollDispatch({ type: PollActionType.FILTER_EMPTY_POLL_OPTIONS })
        }

        return options.filter(item => item.title.length).length >= 2
    }, [options, pollDispatch])

    const { setFocusBottom } = useFocus()

    const handleSubmitPoll = useCallback(() => {
        pollDispatch({
            type: PollActionType.EXIT_EDIT_MODE,
        })

        if (!pollTitle) return

        const isValid = filterValidateOptionList()

        if (!isValid) {
            drawerDispatch({
                type: DrawerActionType.SHOW_MODAL,
                payload: {
                    title: t("createPollScreen.invalidPollModal.almostThere"),
                    message: t(
                        "createPollScreen.invalidPollModal.pollNeedsTwo",
                    ),
                    buttonTitle: t("okay"),
                    image: theme.image.missingOptionsGraphic,
                    imageSize: {
                        width: 160,
                        height: 160,
                    },
                    identifier: ModalName.ALMOST_THERE,
                },
            })
            return
        }

        if (authStatus !== AuthenticationState.AUTHENTICATED) {
            analyticsDispatch({
                type: AnalyticsActionType.LOG_ANALYTIC,
                payload: {
                    analyticId: AnalyticsEvent.tapDraftNextSignIn,
                },
            })

            setFocusBottom()
            drawerDispatch({
                type: DrawerActionType.SHOW_DRAWER,
                payload: {
                    content: <AuthModal signInFlow={SignInFlow.launchPoll} />,
                    dismissible: true,
                    hasCloseButton: false,
                    hasBackground: true,
                    identifier: ModalName.SIGN_IN_LAUNCH,
                },
            })

            return
        }

        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapDraftNextSendSettings,
            },
        })

        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <SendPollSettingsModal />,
                dismissible: true,
                hasCloseButton: true,
                hasBackground: true,
                identifier: ModalName.SEND_POLL_SETTINGS,
            },
        })
    }, [pollTitle, authStatus, filterValidateOptionList])

    const handleDiscard = () => {
        Logger.info("opening discard poll modal")
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <DiscardModal />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: !isMobile,
                identifier: ModalName.CONFIRM_DISCARD_MODAL,
            },
        })
    }

    const handleOnAction = (id: BottomBarOption) => {
        switch (id) {
            case BottomBarOption.DISCARD:
                handleDiscard()
                break
            case BottomBarOption.PROFILE:
                redirect(ScreenName.PROFILE_SCREEN)
                break
        }
    }

    useFocusEffect(
        useCallback(() => {
            pollDispatch({
                type: PollActionType.SET_CREATE_DRAFT_MODE,
            })
        }, [pollDispatch]),
    )

    const isDisabled =
        !pollTitle || pollTitle.length === 0 || options.length < 2

    const showNavbar = isTitleSet && !pollTitleEdit

    return (
        <ScreenLayout>
            <PollList />
            {showNavbar && (
                <BottomNavigator
                    config={cfg.create}
                    canSubmitPoll={false}
                    onAction={handleOnAction}
                >
                    <Button
                        title={t("next")}
                        disabled={isDisabled}
                        onPress={handleSubmitPoll}
                        style={bottomBarStyles.submitButton}
                    />
                </BottomNavigator>
            )}
        </ScreenLayout>
    )
}

export default CreatePollScreen
