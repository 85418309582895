import { Image, View } from "react-native"
import React from "react"
import useStyles from "@helpers/hooks/useStyles"
import { TextCardTemplateStyles } from "@components/PollList/Items/TextCardTemplate/TextCardTemplate.styles"
import { commonStyles } from "@styles/Main.styles"
import SkeletonLoader from "expo-skeleton-loader"

const TextCardSkeleton = () => {
    const { styles, theme } = useStyles(TextCardTemplateStyles)
    const { styles: defaultStyles } = useStyles(commonStyles)

    return (
        <View style={defaultStyles.rowFlex}>
            <Image
                source={{ uri: theme.image.imagePlaceholder }}
                style={styles.skeletonImage}
            />
            <View style={styles.skeletonContentContainer}>
                <SkeletonLoader
                    boneColor={theme.color.skeletonDark}
                    highlightColor={theme.color.skeletonHighlight}
                    duration={800}
                >
                    <SkeletonLoader.Container style={styles.flexStart}>
                        <SkeletonLoader.Item
                            style={{
                                width: 135,
                                height: 9,
                                borderRadius: 4.5,
                                marginBottom: 10,
                            }}
                        ></SkeletonLoader.Item>
                        <SkeletonLoader.Item
                            style={{
                                width: 65,
                                height: 9,
                                borderRadius: 4.5,
                                marginBottom: 10,
                            }}
                        ></SkeletonLoader.Item>
                    </SkeletonLoader.Container>
                </SkeletonLoader>
                <SkeletonLoader
                    boneColor={theme.color.skeletonLight}
                    highlightColor={theme.color.skeletonHighlight}
                    duration={800}
                >
                    <SkeletonLoader.Container style={styles.flexStart}>
                        <SkeletonLoader.Item
                            style={{
                                width: 115,
                                height: 7,
                                borderRadius: 3.5,
                            }}
                        ></SkeletonLoader.Item>
                    </SkeletonLoader.Container>
                </SkeletonLoader>
            </View>
        </View>
    )
}

export default TextCardSkeleton
