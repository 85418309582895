import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { PollListSubmitButtonStyles } from "./PollListSubmitButton.styles"
import { Button } from "@components"
import { BottomErrorMessage } from "@components/BottomNavigator/BottomErrorMessage"

interface PollListSubmitButtonProps {
    isVisible: boolean
    buttonTitle: string
    onPress: () => void
}

const PollListSubmitButton = ({
    isVisible,
    buttonTitle,
    onPress,
}: PollListSubmitButtonProps) => {
    const { styles } = useStyles(PollListSubmitButtonStyles)

    if (!isVisible) return null

    return (
        <>
            <BottomErrorMessage />
            <Button
                title={buttonTitle}
                onPress={onPress}
                style={styles.button}
            />
        </>
    )
}

export default PollListSubmitButton
