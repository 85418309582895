import { useContext } from "react"
import { KeyboardTypeOptions, TextInput } from "react-native"
import { FocusContext } from "@contexts"
import { FocusActionType } from "@contexts/FocusProvider"
import { isPlatformAndroid } from "@helpers/isPlatformAndroid"

interface FocusHandler {
    setFocusTarget: (
        id: string,
        input: TextInput,
        keyboardType?: KeyboardTypeOptions,
        overwrite?: boolean,
    ) => void
    setFocus: (id: string) => void
    setFocusTop: () => void
    setFocusBottom: () => void
}

export const useFocus = (): FocusHandler => {
    const { focusDispatch, focusState } = useContext(FocusContext)
    const isAndroid = isPlatformAndroid()
    const setFocusTarget = (
        id: string,
        input: TextInput,
        keyboardType?: KeyboardTypeOptions,
        overwrite?: boolean,
    ) => {
        if (isAndroid) {
            input.focus()
        } else {
            focusDispatch({
                type: FocusActionType.SET_FOCUS_TARGET,
                payload: { id, input, keyboardType, overwrite },
            })
        }
    }

    const setFocus = (id: string) => {
        if (isAndroid) return
        focusDispatch({
            type: FocusActionType.SET_FOCUS,
            payload: { id },
        })
    }

    const setFocusTop = () => {
        if (isAndroid) return
        const inputRef = focusState.list["top"].input
        if (inputRef) {
            inputRef.focus()
            focusDispatch({
                type: FocusActionType.SET_FOCUS,
                payload: { id: "top" },
            })
        }
    }

    const setFocusBottom = () => {
        if (isAndroid) return
        const inputRef = focusState.list["bottom"].input
        if (inputRef) {
            inputRef.focus()
            focusDispatch({
                type: FocusActionType.SET_FOCUS,
                payload: { id: "bottom" },
            })
        }
    }

    return { setFocus, setFocusTarget, setFocusTop, setFocusBottom }
}
