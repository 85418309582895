import i18next, { i18n } from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "../config/translations/translationEN.json"
import translationDE from "../config/translations/translationDE.json"

const resources = {
    en: translationEN,
    de: translationDE,
}

let instance: i18n
const i18nextInstance = () => {
    if (!instance) {
        instance = i18next.use(initReactI18next)
        instance.init({
            lng: "en",
            fallbackLng: "en",
            interpolation: { escapeValue: false },
            compatibilityJSON: "v3",
            resources,
        })

        return instance
    }
}

export const switchLanguage = () => {
    const availableLanguages = Object.keys(resources)
    const currentLanguageIdx = availableLanguages.indexOf(i18next.language)

    const newLanguageIdx =
        currentLanguageIdx + 1 < availableLanguages.length
            ? currentLanguageIdx + 1
            : 0
    i18next.changeLanguage(availableLanguages[newLanguageIdx])
}

export default i18nextInstance
