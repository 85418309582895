import { Text } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useCallback, useEffect, useState } from "react"
import { SaveChangesBannerStyles } from "./SaveChangesBanner.styles"
import { AnimatedType, AnimatedView } from "@components"
import { useTranslation } from "react-i18next"

const SaveChangesBanner = ({ active }: { active?: boolean }) => {
    const { t } = useTranslation()
    const { styles } = useStyles(SaveChangesBannerStyles)

    const [visible, setVisible] = useState(false)

    const onAnimationCompleted = useCallback(() => {
        if (!active) setVisible(false)
    }, [active])

    useEffect(() => {
        if (active) setVisible(true)
    }, [active])

    if (!visible) return null

    return (
        <AnimatedView
            animProps={{
                type: AnimatedType.SLIDE_V,
                from: active ? 0 : -20,
                to: !active ? 0 : -20,
                bounciness: 0,
                duration: 200,
            }}
            style={styles.container}
            onAnimationCompleted={onAnimationCompleted}
        >
            <Text style={styles.text}>{t("submitToSave")}</Text>
        </AnimatedView>
    )
}

export default SaveChangesBanner
