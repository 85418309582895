import React, { useEffect, useRef, useState } from "react"
import { Title } from "@components/Title"
import useStyles from "@helpers/hooks/useStyles"
import { editSectionStyles } from "./ListEditSection.styles"
import { Animated, Platform, View, ViewStyle } from "react-native"
import { Button } from "@components/Button"
import { t } from "i18next"
import { usePollPermissionsCheck } from "@helpers/hooks/usePollPermissionsCheck"
import { AuthModal } from "@components"
import { SignInFlow } from "@types"
import { AnalyticsListActions } from "@contexts/AnalyticsProvider/AnalyticsReducer"

interface ListEditSectionProps {
    style: ViewStyle
    buttonVisible: boolean
    textVisible: boolean
    title: string
    subtitle: string
    logListEvent: (action: AnalyticsListActions) => void
    onAddOption: () => void
}

const ListEditSection = ({
    style,
    buttonVisible,
    textVisible,
    title,
    subtitle,
    logListEvent,
    onAddOption,
}: ListEditSectionProps) => {
    const { styles } = useStyles(editSectionStyles)

    const useNativeDriver = Platform.OS !== "web"
    const alphaButton = useRef(new Animated.Value(0)).current
    const alphaText = useRef(new Animated.Value(0)).current

    const [elementActive, setElementActive] = useState<boolean>(true)

    const deactivateElement = () => setElementActive(false)

    const checkPermissions = usePollPermissionsCheck({
        authModalContent: <AuthModal signInFlow={SignInFlow.editPoll} />,
    })

    const handleButtonPress = () => {
        logListEvent(AnalyticsListActions.ADD_OPTION)

        if (checkPermissions()) {
            onAddOption()
        }
    }

    const buttonTransition = (onAnimationFinished?: () => void) => {
        Animated.timing(alphaButton, {
            toValue: buttonVisible ? 1 : 0,
            duration: 200,
            useNativeDriver,
        }).start(onAnimationFinished)
    }

    const alphaTransition = () => {
        Animated.timing(alphaText, {
            toValue: textVisible ? 1 : 0,
            duration: 200,
            useNativeDriver,
        }).start()
    }

    useEffect(() => {
        if (buttonVisible) setElementActive(true)

        buttonTransition(!buttonVisible ? deactivateElement : undefined)
    }, [buttonVisible])

    useEffect(() => {
        alphaTransition()
    }, [textVisible])

    return (
        <View style={styles.section}>
            {elementActive && (
                <Animated.View
                    style={[
                        { opacity: alphaButton },
                        styles.buttonContent,
                        style,
                    ]}
                    needsOffscreenAlphaCompositing={true}
                >
                    <Button
                        variant="secondary"
                        title={t("addOption")}
                        onPress={handleButtonPress}
                    />
                </Animated.View>
            )}

            {textVisible && (
                <Animated.View
                    style={[{ opacity: alphaText }, styles.textContent, style]}
                >
                    <Title style={styles.helpTextPrimary} title={title} />
                    {!!subtitle && (
                        <Title
                            style={styles.helpTextSecondary}
                            title={subtitle}
                        />
                    )}
                </Animated.View>
            )}
        </View>
    )
}

export default ListEditSection
