import { config, ConfigProperties } from "@config"
import * as Manifest from "@helpers/manifest"

export const getAppConfig = (): ConfigProperties => {
    const env = Manifest.appEnv()
    const tenant = Manifest.tenant()
    const isQaTenant = Manifest.isQaTenant()
    return config({
        env: env,
        tenant: tenant,
        isQaTenant: isQaTenant,
    })
}
