import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface PollHistoryStyles extends Styles {
    container: ViewStyle
    headerContainer: ViewStyle
    headerImage: ImageStyle
    headerTitle: TextStyle
    cardBody: ViewStyle
    itemContainer: ViewStyle
    itemDivider: ViewStyle
    itemTitle: TextStyle
    itemIcon: ViewStyle
    emptyImage: ImageStyle
    emptyText: TextStyle
}

export const PollHistoryStyles: StyleFunction<PollHistoryStyles> = ({
    theme,
}) => {
    return {
        container: {
            paddingHorizontal: 12,
            marginBottom: 10,
            flexDirection: "column",
        },
        headerContainer: {
            flexDirection: "row",
            alignItems: "center",
        },
        headerImage: {
            width: 36,
            height: 36,
            marginRight: 12,
            marginVertical: 12,
        },
        headerTitle: {
            ...theme.font.pollHistoryHeader,
        },
        cardBody: {
            alignItems: "center",
        },
        itemContainer: {
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: 36,
            marginBottom: 24,
        },
        itemDivider: {
            width: 2,
            height: "100%",
            marginHorizontal: 10,
            backgroundColor: theme.color.pollHistoryDivider,
        },
        itemTitle: {
            ...theme.font.pollHistoryItem,
            height: "100%",
            textAlignVertical: "center",
            flexWrap: "wrap",
            flex: 1,
            marginRight: 25,
        },
        itemIcon: {
            transform: [{ rotateY: "180deg" }],
            color: theme.color.pollHistoryIcon,
            fontSize: 16,
            marginTop: 5,
            marginLeft: "auto",
        },
        emptyImage: {
            height: 115,
            width: "100%",
            marginBottom: 12,
        },
        emptyText: {
            textAlign: "center",
            paddingHorizontal: 70,
            marginBottom: 24,
            ...theme.font.pollHistoryEmptyTitle,
        },
    }
}
