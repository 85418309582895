import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface ModalStyles extends Styles {
    container: ViewStyle
    title: TextStyle
    message: TextStyle
    buttonContainer: ViewStyle
    image: ImageStyle
    animation: ViewStyle
    animationContainer: ViewStyle
}

export const ModalStyles: StyleFunction<ModalStyles> = ({ theme, width }) => {
    return {
        container: {
            marginBottom: 25,
            marginTop: 5,
            paddingHorizontal: 30,
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
        title: {
            ...theme.font.notificationPrimaryText,
        },
        message: {
            ...theme.font.notificationSecondaryText,
            marginTop: 10,
            textAlign: "center",
        },
        buttonContainer: {
            marginTop: 20,
            paddingHorizontal: 35,
        },
        image: {
            width: "100%",
            marginBottom: 20,
        },
        animation: {
            width: "100%",
            height: 80,
            marginBottom: 10,
        },
        animationContainer: {
            width: 170,
            height: 120,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        WEB: {
            container: {
                margin: "auto",
                width: 320,
            },
        },
        MOBILE: {
            container: {
                width: width - 70,
            },
        },
        NATIVE: {
            bg: {
                justifyContent: "center",
                alignItems: "center",
            },
            container: {
                width: width - 70,
                elevation: 10,
            },
        },
    }
}
