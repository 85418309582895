import { DrawerContext } from "@contexts/DrawerProvider"
import { PollContext } from "@contexts/PollContext"
import { UserContext } from "@contexts/UserDataProvider"
import useStyles from "@helpers/hooks/useStyles"
import { getVotesForOption } from "@services/poll/getVotesForOption"
import { PollRecentVotes } from "@types"
import remove from "lodash/remove"
import React, { useCallback, useContext, useEffect, useState } from "react"
import {
    FlatList,
    NativeScrollEvent,
    NativeSyntheticEvent,
    View,
} from "react-native"
import { FlatListItem } from "./FlatListItem"
import { FlatListLoader } from "./FlatListLoader/index"
import { VotersListStyle } from "./VotersList.styles"

interface VotersListProps {
    optionId: string
    handleScrollingState: (state: boolean) => void
    checked: boolean
}

const VotersList = ({
    optionId,
    handleScrollingState,
    checked,
}: VotersListProps) => {
    const { styles } = useStyles(VotersListStyle)
    const {
        pollState: { pollId },
    } = useContext(PollContext)

    const {
        drawerState: { isActive },
    } = useContext(DrawerContext)

    const {
        userState: { deviceId, userId, userName },
    } = useContext(UserContext)

    // Pagination states
    const [offset, setOffset] = useState(0)
    const [totalSize, setTotalSize] = useState(0)
    const PAGE_SIZE = 10
    const [votersLoading, setVotersLoading] = useState(false)
    const [fetchedAll, setFetchedAll] = useState(false)
    const [votesForOption, setVotesForOption] = useState<PollRecentVotes[]>([])

    const handleGetVotesForOption = useCallback(async () => {
        setVotersLoading(true)
        // get list of votes for option from backend in batches
        const { data, isSuccess } = await getVotesForOption(
            pollId,
            deviceId,
            optionId,
            offset,
            PAGE_SIZE,
        )

        if (isSuccess && data) {
            // do formatting
            setOffset(offset + data.meta.pagination.pageSize)
            setTotalSize(data.meta.pagination.total)

            // append the latest fetched votes to our previous fetched votes
            let allFetchedVotes = [...votesForOption, ...data.votes]

            // check if we've downloaded all the votes
            const fetchedAllTmp = allFetchedVotes.length >= totalSize

            const voteToAdd = {
                id: "",
                optionId: "",
                createdAt: "",
                voter: {
                    name: userName ?? "",
                    id: userId,
                },
            }

            // check if we are in the list of submitted votes
            const hasVoted = allFetchedVotes.some(
                vote => vote.voter.id === userId,
            )

            // I think this is removing duplicates
            allFetchedVotes = allFetchedVotes.filter(function (vote, index) {
                return (
                    allFetchedVotes.findIndex(
                        thisVote => thisVote.voter.id === vote.voter.id,
                    ) === index
                )
            })

            // if we previously submitted but have now unchecked, then remove us from the list
            if (!checked && hasVoted) {
                remove(allFetchedVotes, v => v.voter.id === userId)
            }

            // if we have checked but not submitted, add us to the end, only if we have fetched all votes
            if (fetchedAllTmp && checked && !hasVoted) {
                allFetchedVotes = [...allFetchedVotes, voteToAdd]
            }

            setVotesForOption([...allFetchedVotes])
            setFetchedAll(fetchedAllTmp)
        }
        setVotersLoading(false)
    }, [
        offset,
        optionId,
        pollId,
        checked,
        deviceId,
        userName,
        totalSize,
        userId,
        votesForOption,
    ])

    const displayShadow = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        if (e.nativeEvent.contentOffset.y === 0) {
            handleScrollingState(false)
        } else if (e.nativeEvent.contentOffset.y > 0) {
            handleScrollingState(true)
        }
    }

    useEffect(() => {
        if (!isActive) {
            setVotesForOption([])
            return
        }
        if (!fetchedAll) {
            handleGetVotesForOption()
        }
    }, [isActive, fetchedAll])

    const isEndReached = () => {
        if (totalSize > offset || !fetchedAll) {
            handleGetVotesForOption()
        }
    }

    const renderLoader = () => {
        return <View>{votersLoading && <FlatListLoader />}</View>
    }

    const renderFlatListItems = ({
        index,
        item,
    }: {
        index: number
        item: PollRecentVotes
    }) => {
        return (
            <FlatListItem
                index={index}
                item={item}
                authenticatedUserId={userId}
                pollID={pollId}
            />
        )
    }

    if (!votesForOption.length) return <FlatListLoader />

    return (
        <FlatList
            data={votesForOption}
            renderItem={renderFlatListItems}
            style={styles.flatList}
            onEndReached={isEndReached}
            onEndReachedThreshold={0.1}
            ListFooterComponent={renderLoader}
            onScroll={displayShadow}
            keyExtractor={(item, index) => index.toString()}
            showsVerticalScrollIndicator={false}
        />
    )
}

export default VotersList
