import { Animated, Platform } from "react-native"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import useStyles from "@helpers/hooks/useStyles"
import { pollListStyles } from "@components/PollList/PollList.styles"
import { CARD_HEIGHT } from "@config/pollList"

interface PollListAvoidingContainer {
    visible: boolean
    offsetY: number
    children: ReactNode
}

const PollListAvoidingContainer = ({
    visible,
    offsetY,
    children,
}: PollListAvoidingContainer) => {
    const { styles } = useStyles(pollListStyles)

    const useNativeDriver = Platform.OS !== "web"
    const toolBarY = useRef(new Animated.Value(220)).current
    const toolBarAlpha = useRef(new Animated.Value(0)).current

    const [elementActive, setElementActive] = useState<boolean>(false)

    const moveToolbarTransition = (
        positionY: number,
        alpha: number,
        delay: number,
        onAnimationComplete?: () => void,
    ) => {
        Animated.timing(toolBarY, {
            toValue: positionY,
            duration: 20,
            delay,
            useNativeDriver,
        }).start()

        Animated.timing(toolBarAlpha, {
            toValue: alpha,
            duration: 200,
            delay,
            useNativeDriver,
        }).start(onAnimationComplete)
    }

    const deactivateElement = () => setElementActive(false)

    useEffect(() => {
        if (!visible && !elementActive) return

        if (visible) {
            setElementActive(true)
            moveToolbarTransition(offsetY + CARD_HEIGHT, 1, 200)
        } else {
            moveToolbarTransition(
                offsetY + CARD_HEIGHT,
                0,
                0,
                deactivateElement,
            )
        }
    }, [visible, offsetY])

    if (!elementActive) return null

    return (
        <Animated.View
            needsOffscreenAlphaCompositing={true}
            style={[
                styles.toolbarContainer,
                {
                    zIndex: visible ? 2 : 0,
                    opacity: toolBarAlpha,
                    transform: [{ translateY: toolBarY }],
                },
            ]}
        >
            {children}
        </Animated.View>
    )
}

export default PollListAvoidingContainer
