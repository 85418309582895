export enum BottomBarOption {
    HOME = "home",
    EDIT = "edit",
    SHARE = "share",
    PROFILE = "profile",
    INFO = "info",
    DISCARD = "discard",
}

export interface BottomBarColumnsProps {
    leftColumn: BottomBarOption[] | []
    rightColumn: BottomBarOption[] | []
}

export type BottomBarConfig = {
    create: BottomBarColumnsProps
    vote: BottomBarColumnsProps
    selectPoll: BottomBarColumnsProps
    sharePoll: BottomBarColumnsProps
}

export const defaultConfig = {
    create: {
        leftColumn: [BottomBarOption.DISCARD],
        rightColumn: [BottomBarOption.PROFILE],
    },
    vote: {
        leftColumn: [BottomBarOption.HOME, BottomBarOption.EDIT],
        rightColumn: [BottomBarOption.SHARE, BottomBarOption.PROFILE],
    },
    selectPoll: {
        leftColumn: [],
        rightColumn: [],
    },
    sharePoll: {
        leftColumn: [],
        rightColumn: [],
    },
}

export const customersConfig = {
    vote: {
        leftColumn: [BottomBarOption.SHARE],
        rightColumn: [BottomBarOption.INFO],
    },
    create: { leftColumn: [], rightColumn: [] },
    selectPoll: {
        leftColumn: [],
        rightColumn: [],
    },
    sharePoll: {
        leftColumn: [],
        rightColumn: [],
    },
}
