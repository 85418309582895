import React, { useContext, useEffect, useMemo, useState } from "react"
import { Keyboard, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { authModalStyles } from "./AuthModal.styles"
import { EnterPhoneNumber } from "./EnterPhoneNumber"
import { EnterSMSCode } from "./EnterSMSCode"
import { useTranslation } from "react-i18next"
import {
    AnalyticsActionType,
    AnalyticsContext,
    DrawerActionType,
    DrawerContext,
    NavigationContext,
    UserActionType,
    UserContext,
} from "@contexts"
import { AnimatedType, AnimatedView, BackButton, Button } from "@components"
import { useVisualViewport } from "@helpers/hooks/useVisualViewport"
import { SignInFlow } from "@types"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import { useFocus } from "@helpers/hooks/useFocus"
import { useCodeBackAction } from "@helpers/hooks/useAnalyticsActions"

interface AuthModalProps {
    signInFlow: SignInFlow
}

const AuthModal = ({ signInFlow }: AuthModalProps) => {
    const { styles, theme } = useStyles(authModalStyles)
    const {
        visualViewport: { width },
        isMobileView,
    } = useVisualViewport()
    const { t } = useTranslation()
    const { setFocusBottom } = useFocus()

    const [offsetX, setOffset] = useState(0)

    const {
        userState: { isPhoneNumberEntered, phoneNumber, authStatus },
        userDispatch,
    } = useContext(UserContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)
    const { currentScreen } = useContext(NavigationContext)

    const getCodeBackAction = useCodeBackAction(currentScreen)

    const isAuthenticated = authStatus === AuthenticationState.AUTHENTICATED
    const w = isMobileView ? width : 360

    const secondStep = useMemo(() => {
        return isPhoneNumberEntered && phoneNumber && phoneNumber?.length > 0
    }, [isPhoneNumberEntered, phoneNumber?.length])

    useEffect(() => {
        return () => {
            if (!isAuthenticated) {
                userDispatch({
                    type: UserActionType.SET_PHONE_NUMBER,
                    payload: { phoneNumber: "" },
                })
            }
        }
    }, [isAuthenticated])

    useEffect(() => {
        setOffset(secondStep ? -w : 0)
    }, [secondStep, w])

    const handleClose = () => {
        drawerDispatch({
            type: DrawerActionType.DISMISS_DRAWER,
        })
    }

    const handleBackToPhone = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: getCodeBackAction(),
            },
        })

        setFocusBottom()
        userDispatch({
            type: UserActionType.SET_IS_PHONE_NUMBER_ENTERED,
            payload: {
                isPhoneNumberEntered: false,
            },
        })
    }

    useEffect(() => {
        return () => {
            Keyboard.dismiss()
        }
    }, [])

    const slideAnim = {
        type: AnimatedType.SLIDE_H,
        from: -offsetX,
        to: offsetX,
        speed: 20,
        bounciness: 2,
    }

    return (
        <View>
            <View style={styles.backButton}>
                {secondStep ? (
                    <View style={styles.backButtonWrapper}>
                        <BackButton onPress={handleBackToPhone} />
                    </View>
                ) : (
                    <Button
                        variant="text"
                        iconLeft={theme.image.closeX}
                        style={styles.closeButton}
                        iconStyle={styles.closeIcon}
                        onPress={handleClose}
                    />
                )}
            </View>
            <View style={[styles.content, { width: w }]}>
                <AnimatedView
                    id="auth_enter_phone"
                    style={[styles.section, { width: w }]}
                    animProps={slideAnim}
                >
                    <EnterPhoneNumber signInFlow={signInFlow} />
                </AnimatedView>
                <AnimatedView
                    id="auth_sms_code"
                    style={[styles.section, { width: w }]}
                    animProps={slideAnim}
                >
                    <EnterSMSCode signInFlow={signInFlow} />
                </AnimatedView>
            </View>
            <Text style={styles.asterisk}>
                {t("phoneNumberModal.standardsRatesMayApply")}
            </Text>
        </View>
    )
}

export default AuthModal
