import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface ButtonStyles extends Styles {
    button: ViewStyle
    title: TextStyle
    icon: TextStyle
}

export const buttonStyles: StyleFunction<ButtonStyles> = ({ theme }) => {
    return {
        button: {
            paddingLeft: 10,
            maxHeight: 20,
            minHeight: 20,
        },
        title: {
            ...theme.font.backButtonText,
        },
        icon: {
            ...theme.font.backButtonIcon,
            fontSize: 13,
        },
    }
}
