import { Linking, Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext } from "react"
import { InfoLinkStyles } from "./InfoLink.styles"
import { Title } from "@components"
import { Icon, Icons } from "@assets/icons"
import { AnalyticsEvent } from "@services/analytics"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"

interface InfoLinkProps {
    title: string
    subtitle: string
    url: string
}

const InfoLink = ({ title, subtitle, url }: InfoLinkProps) => {
    const { styles } = useStyles(InfoLinkStyles)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const redirect = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapInfoModalLink,
                additionalData: {
                    url: url,
                },
            },
        })

        Linking.openURL(url)
    }

    return (
        <Pressable style={styles.container} onPress={redirect}>
            <View style={styles.iconContainer}>
                <Icon name={Icons.linkOut} style={styles.icon} size={18} />
            </View>
            <View style={styles.titleContainer}>
                <Title title={title} style={styles.title} />
                <Title title={subtitle} style={styles.subTitle} />
            </View>
        </Pressable>
    )
}

export default InfoLink
