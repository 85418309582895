import React, { useContext, useEffect } from "react"
import { View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import { signInScreenStyles } from "./SignInScreen.styles"
import { ScreenLayout } from "@components"
import { EnterPhoneNumber } from "@components/Drawer/content/AuthModal/EnterPhoneNumber"
import { SignInFlow } from "@types"
import { NavigationContext, UserContext } from "@contexts"
import { ScreenName } from "@screens"
import { useKeyboardHeight } from "@helpers/hooks/useKeyboardHeight"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"

const SignInScreen = () => {
    const { styles } = useStyles(signInScreenStyles)
    const { navigate } = useContext(NavigationContext)
    const { height } = useScreenDimensions()
    const keyboardHeight = useKeyboardHeight()

    const {
        userState: { isPhoneNumberEntered },
    } = useContext(UserContext)

    useEffect(() => {
        if (isPhoneNumberEntered)
            navigate({
                screen: ScreenName.SIGN_IN_SCREEN_CONFIRM,
            })
    }, [isPhoneNumberEntered, navigate])

    return (
        <ScreenLayout>
            <View
                style={[
                    styles.center,
                    styles.phoneContainer,
                    { maxHeight: height - keyboardHeight - 100 },
                ]}
            >
                <EnterPhoneNumber signInFlow={SignInFlow.onboarding} />
            </View>
        </ScreenLayout>
    )
}

export default React.memo(SignInScreen)
