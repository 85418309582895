import React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { CardStyles } from "./Card.styles"
import useStyles from "@helpers/hooks/useStyles"

interface CardProps {
    testID?: string
    children: React.ReactNode
    style?: StyleProp<ViewStyle>
}

const Card = ({ testID, children, style }: CardProps) => {
    const { styles } = useStyles(CardStyles)
    return (
        <View testID={`${testID}-card`} style={[styles.card, style]}>
            {children}
        </View>
    )
}

export default Card
