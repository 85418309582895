import { View } from "react-native"
import React, { useContext, useEffect, useRef, useState } from "react"
import useStyles from "@helpers/hooks/useStyles"
import { editNameModalStyles } from "./EditNameModal.styles"
import { Button, Input } from "@components"
import {
    AnalyticsActionType,
    AnalyticsContext,
    DrawerActionType,
    DrawerContext,
    UserActionType,
    UserContext,
} from "@contexts"
import { t } from "i18next"
import { useFocus } from "@helpers/hooks/useFocus"
import Avatar from "../../../ProfileAvatar/Avatar"
import Logger from "@services/logger"
import { useInputConfig } from "@helpers/hooks/useInputConfig"
import { AnalyticsEvent } from "@services/analytics"
import { captureMessage } from "@services/sentry"
import { TextInput } from "react-native-gesture-handler"

const EditNameModal = () => {
    const { styles } = useStyles(editNameModalStyles)
    const {
        drawerDispatch,
        drawerState: { isActive },
    } = useContext(DrawerContext)
    const { setFocusTop, setFocus, setFocusTarget } = useFocus()
    const {
        userState: { userName: nameFromUserContext, deviceId },
        userDispatch,
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const inputRef = useRef<TextInput>(null)
    const [userName, setName] = useState(nameFromUserContext || "")
    const isValid = userName && userName.length > 1 // to be replaced by validation schema once input is ready

    const inputConfig = useInputConfig("nameInput")

    const handleOnChange = (value: string) => {
        if (value.length < 31) {
            setName(value)
        }
    }

    const dismissDrawer = () => {
        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
    }

    const submitForm = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapProfileSaveName,
            },
        })

        if (!deviceId) return

        try {
            userDispatch({
                type: UserActionType.UPDATE_USER_DATA,
                payload: {
                    userDataToUpdate: {
                        name: userName,
                    },
                },
            })

            drawerDispatch({
                type: DrawerActionType.SHOW_SUCCESS_MODAL,
                payload: {
                    title: t("nameSaved"),
                    onDismiss: dismissDrawer,
                },
            })
        } catch (e: any) {
            // if user name update unsuccessful
            captureMessage(e)
            Logger.error("failed to update user's name")
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            setFocusTarget("editName", inputRef.current)
        }
    }, [inputRef])

    useEffect(() => {
        if (isActive) {
            setFocus("editName")
        }
    }, [isActive])

    return (
        <View style={styles.container}>
            <Avatar letter={userName?.charAt(0).toUpperCase() || ""} />
            <Input
                {...inputConfig}
                ref={inputRef as any}
                variant="box"
                value={userName}
                onChange={handleOnChange}
            />
            <View style={styles.buttonsContainer}>
                <Button
                    title={t("save")}
                    onPress={submitForm}
                    disabled={!isValid}
                />
                <Button
                    variant="text"
                    titleStyle={styles.cancelButton}
                    title={t("cancel")}
                    onPress={dismissDrawer}
                />
            </View>
        </View>
    )
}

export default EditNameModal
