import React from "react"
import { NativeSyntheticEvent, NativeTouchEvent, ViewStyle } from "react-native"
import { Button } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { buttonStyles } from "./BackButton.styles"

interface BackButtonProps {
    onPress: (e: NativeSyntheticEvent<NativeTouchEvent>) => void
    align?: string
    styles?: ViewStyle
}

const BackButton = ({ onPress, styles: propStyles }: BackButtonProps) => {
    const { styles, theme } = useStyles(buttonStyles)

    return (
        <Button
            align={"left"}
            variant={"text"}
            title={"Back"}
            style={[styles.button, propStyles]}
            titleStyle={styles.title}
            iconStyle={styles.icon}
            iconLeft={theme.image.backIcon}
            onPress={onPress}
        />
    )
}

export default BackButton
