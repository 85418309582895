import React, {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    useEffect,
    useReducer,
} from "react"
import {
    AppState as NativeAppState,
    AppStateStatus as NativeAppStateStatus,
} from "react-native"
import {
    Action,
    AppActionType,
    appReducer,
    AppState,
    initialState,
    OpenPollFlow,
} from "./AppReducer"
import Logger from "@services/logger"
import * as Analytics from "@services/analytics"

interface AppProviderProps {
    children: ReactNode
}

type AppAction = Dispatch<Action>

interface AppContextType {
    appState: AppState
    appDispatch: AppAction
}

const AppContext = createContext<AppContextType>({
    appState: initialState,
    appDispatch: () => initialState,
})

const AppProvider: FC<AppProviderProps> = ({ children }) => {
    const [appState, appDispatch] = useReducer(appReducer, initialState)

    const { openPollFlow } = appState

    const handleStateChange = (status: NativeAppStateStatus) => {
        Logger.info(`APP STATE: ${status}`)
        appDispatch({
            type: AppActionType.APP_STATE,
            payload: { appState: status },
        })
    }

    useEffect(() => {
        Logger.info("APP STARTUP")
        const subscription = NativeAppState.addEventListener(
            "change",
            handleStateChange,
        )
        return () => {
            subscription.remove()
        }
    }, [])

    // create poll analytics trigger
    const createPollAnalyticsTrigger = `${openPollFlow.flow} - ${
        openPollFlow.openPollId ?? "undefined"
    }`
    useEffect(() => {
        if (
            openPollFlow.flow === OpenPollFlow.createPoll &&
            openPollFlow.openPollId
        ) {
            Analytics.logEvent(Analytics.AnalyticsEvent.createPollSuccess, {
                pollId: openPollFlow.openPollId,
            })
        }
    }, [createPollAnalyticsTrigger])

    return (
        <AppContext.Provider value={{ appState, appDispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export { AppContext, AppProvider, AppAction }
