import { httpService } from "@services/http"
import { PollHistoryItem } from "@types"
import { captureMessage } from "@services/sentry"

interface Response {
    status: number
    data: {
        polls: PollHistoryItem[]
    }
}

interface getPollProps {
    deviceId: string
}
export const getPollsForUser = async ({ deviceId }: getPollProps) => {
    const requestConfig = {
        headers: { "polls-device-id": deviceId || "" },
    }

    try {
        const response = await httpService.get<getPollProps, Response>(
            "me/polls",
            requestConfig,
        )

        return {
            isSuccess: response.status >= 200 && response.status < 300,
            data: response.data,
        }
    } catch (e) {
        captureMessage(e)
        return { isSuccess: false, data: null }
    }
}
