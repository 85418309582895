import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface NavHeaderStyles extends Styles {
    container: ViewStyle
    content: ViewStyle
    backButtonContainer: ViewStyle
    backButton: ViewStyle
    title: TextStyle
    subtitle: TextStyle
}

export const navHeaderStyles: StyleFunction<NavHeaderStyles> = ({ theme }) => {
    return {
        container: {
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 0,
            marginLeft: 0,
            paddingVertical: 0,
            backgroundColor: theme.color.navHeaderBackground,
        },
        content: {
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        },
        backButtonContainer: {
            position: "absolute",
            left: 0,
        },
        backButton: {
            marginLeft: 0,
        },
        title: {
            ...theme.font.logoHeader,
            marginBottom: 0,
        },
        subtitle: {
            ...theme.font.logoSubheader,
            marginVertical: 3,
        },
        WEB: {
            container: {
                paddingTop: 16,
                paddingBottom: 16,
                alignItems: "flex-start",
                justifyContent: "flex-start",
            },
            title: {
                userSelect: "none",
            },
            content: {
                justifyContent: "flex-start",
                marginLeft: 25,
            },
            backButtonContainer: {
                display: "none",
            },
        },
        MOBILE: {
            container: {
                marginTop: 10,
            },
            title: {
                ...theme.font.logoHeaderMobile,
                marginTop: 0,
                userSelect: "none",
            },
        },
        NATIVE: {
            container: {
                marginTop: 10,
                height: 30,
            },
            content: {
                position: "absolute",
            },
            title: {
                ...theme.font.logoHeaderMobile,
            },
            backButtonContainer: {
                position: "absolute",
                top: 0,
                left: 0,
                minHeight: 20,
                maxHeight: 20,
            },
        },
    }
}
