import { LogLevel } from "../../config"
import { getAppConfig } from "@services/appConfig"
import * as Manifest from "@helpers/manifest"

const config = getAppConfig()

export default class Logger {
    public static error = (message: string) => {
        if (config.logLevel >= LogLevel.Error)
            console.error(
                `[${Manifest.appEnv()} | ${
                    LogLevel[LogLevel.Error]
                }] ${message}`,
            )
    }
    public static warning(message: string) {
        if (config.logLevel >= LogLevel.Warning)
            console.warn(
                `[${Manifest.appEnv()} | ${
                    LogLevel[LogLevel.Warning]
                }] ${message}`,
            )
    }
    public static analytics(message: string) {
        if (config.logLevel >= LogLevel.Analytics)
            console.log(
                `[${Manifest.appEnv()} | ${
                    LogLevel[LogLevel.Analytics]
                }] ${message}`,
            )
    }
    public static info(message: string) {
        if (config.logLevel >= LogLevel.Info)
            console.info(
                `[${Manifest.appEnv()} | ${
                    LogLevel[LogLevel.Info]
                }] ${message}`,
            )
    }
    public static debug(message: string) {
        if (config.logLevel >= LogLevel.Debug) {
            console.debug(
                `[${Manifest.appEnv()} | ${
                    LogLevel[LogLevel.Debug]
                }] ${message}`,
            )
            console.log(
                `[${Manifest.appEnv()} | ${
                    LogLevel[LogLevel.Debug]
                }] ${message}`,
            )
        }
    }
    public static verbose(message: string) {
        if (config.logLevel >= LogLevel.Verbose)
            console.warn(
                `[${Manifest.appEnv()} | ${
                    LogLevel[LogLevel.Verbose]
                }] ${message}`,
            )
    }
}
