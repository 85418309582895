import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface AuthModalStyles extends Styles {
    content: ViewStyle
    section: ViewStyle
    asterisk: TextStyle
    backButton: ViewStyle
    backButtonWrapper: ViewStyle
    closeButton: ViewStyle
    closeIcon: TextStyle
}

export const authModalStyles: StyleFunction<AuthModalStyles> = ({ theme }) => {
    return {
        backButton: {
            position: "absolute",
            left: -30,
            top: 0,
            zIndex: 100,
        },
        backButtonWrapper: {
            marginLeft: 22,
        },
        content: {
            flex: 1,
            flexDirection: "row",
            maxHeight: 340,
        },
        section: {
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
        asterisk: {
            ...theme.font.phoneNumberCardTextBottom,
            textAlign: "center",
            marginTop: 0,
            marginBottom: 30,
        },
        closeButton: { left: 18 },
        closeIcon: { ...theme.font.modalCloseIcon },
        WEB: {
            section: {
                width: 360,
                padding: 10,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            },
            title: {
                fontKerning: "none",
            },
        },
        MOBILE: {
            closeButton: { left: 25, top: 18 },
            backButtonWrapper: {
                marginLeft: 15,
                marginTop: 15,
            },
            backButton: {
                position: "absolute",
                left: -10,
                top: 0,
                zIndex: 100,
            },
        },
        NATIVE: {
            content: {
                position: "relative",
                overflow: "hidden",
                marginHorizontal: 20,
            },
            section: {
                width: 360,
            },
            backButton: {
                position: "absolute",
                left: -60,
                top: 5,
                zIndex: 100,
            },
            closeButton: { left: 12, top: 5 },
        },
    }
}
