import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface FlatListLoaderStyles extends Styles {
    loader: ViewStyle
    lottieContainer: ViewStyle
}

export const FlatListLoaderStyles: StyleFunction<FlatListLoaderStyles> = () => {
    return {
        lottieContainer: {
            height: 100,
        },
        loader: {
            width: 33,
            height: 33,
            alignSelf: "center",
            marginTop: 10,
        },
    }
}
