import { View } from "react-native"
import React, { ReactNode, useMemo } from "react"
import { useVisualViewport } from "@helpers/hooks/useVisualViewport"
import { isPlatformMobile } from "@helpers/isPlatformMobile"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import { useKeyboardHeight } from "@helpers/hooks/useKeyboardHeight"
import useStyles from "@helpers/hooks/useStyles"
import { pollListStyles } from "@components/PollList/PollList.styles"

const PollListKeyboardAvoidingContainer = ({
    children,
}: {
    children: ReactNode
}) => {
    const { styles } = useStyles(pollListStyles)
    const { visualViewport } = useVisualViewport()
    const isMobile = isPlatformMobile()
    const isNative = isPlatformNative()
    const { height } = useScreenDimensions()
    const keyboardHeight = useKeyboardHeight()

    const getHeight = useMemo(() => {
        if (isNative) return height - keyboardHeight - 100
        return isMobile ? visualViewport.height - 25 : "100%"
    }, [height, keyboardHeight, visualViewport.height])

    return (
        <View
            style={[{ height: getHeight }, styles.keyboardAvoidingContainer]}
            pointerEvents={"box-none"}
        >
            {children}
        </View>
    )
}

export default PollListKeyboardAvoidingContainer
