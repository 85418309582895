import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { PixelRatio, ViewStyle } from "react-native"

export interface OptionCardStyles extends Styles {
    container: ViewStyle
    internalContainer: ViewStyle
}

export const PreviewImageProgressBarStyles: StyleFunction<OptionCardStyles> = ({
    theme,
}) => {
    const ratio = PixelRatio.get()
    return {
        container: {
            height: 14 / ratio,
            backgroundColor: theme.color.optionCardProgressBarBackground,
            borderRadius: 10,
            width: "100%",
        },
        internalContainer: {
            height: 14 / ratio,
            backgroundColor: theme.color.optionCardProgressBarFill,
            borderRadius: 10,
            width: "100%",
        },
    }
}
