import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface HintModalStyles extends Styles {
    outerContainer: ViewStyle
    animationContainer: ViewStyle
    overlayContainer: ViewStyle
    overlay: ViewStyle
    imageBackground: ViewStyle
    closeIcon: ViewStyle
    container: ViewStyle
    image: ImageStyle
    title: TextStyle
    subtitle: TextStyle
}

export const HintModalStyles: StyleFunction<HintModalStyles> = ({ theme }) => {
    return {
        outerContainer: {
            position: "absolute",
            width: "100%",
            height: "100%",
            alignItems: "center",
            alignSelf: "center",
            zIndex: 10,
        },
        animationContainer: {
            flex: 1,
            position: "relative",
            zIndex: 10,
            justifyContent: "flex-end",
        },
        overlayContainer: {
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            zIndex: 10,
        },
        overlay: {
            flex: 1,
            backgroundColor: theme.color.modalBackground,
            opacity: 0.75,
        },
        imageBackground: {
            width: 275,
            height: 305,
            position: "relative",
            marginBottom: 100,
            zIndex: 10,
        },
        closeIcon: {
            alignSelf: "flex-start",
            paddingHorizontal: 0,
            position: "absolute",
            left: 20,
            top: 10,
            zIndex: 100,
            marginLeft: 0,
        },
        container: {
            alignItems: "center",
            marginTop: 30,
            paddingHorizontal: 33,
        },
        image: {
            width: 204,
            height: 123,
        },
        title: {
            ...theme.font.toolTipSubmitTitleText,
            textAlign: "center",
        },
        subtitle: {
            ...theme.font.toolTipSubmitSubtitleText,
            textAlign: "center",
        },
        NATIVE: {
            container: {
                marginTop: 10,
            },
        },
    }
}
