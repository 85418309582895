import uuid from "react-native-uuid"
import { Auth } from "aws-amplify"
import { CognitoUser } from "@types"
import { UserAction, UserActionType } from "@contexts/UserDataProvider"
import Logger from "@services/logger"
import { captureMessage } from "@services/sentry"

const signUp = async (phoneNumber: string) => {
    try {
        const { user, userConfirmed } = await Auth.signUp({
            username: phoneNumber,
            password: uuid.v4().toString(),
            attributes: {
                phone_number: phoneNumber,
            },
        })
        Logger.info("Sign up success")

        return { isSuccess: true, user, userConfirmed, errorMessage: "" }
    } catch (error) {
        captureMessage(JSON.stringify(error))
        Logger.info(`Sign up error ${JSON.stringify(error)}`)
        return {
            isSuccess: false,
            user: null,
            userConfirmed: false,
            errorMessage: error,
        }
    }
}

const signIn = async (phoneNumber: string) => {
    try {
        const user = await Auth.signIn(phoneNumber)
        const cognitoUser = user as CognitoUser | undefined

        return { isSuccess: true, cognitoUser }
    } catch (error) {
        captureMessage(JSON.stringify(error))

        return { isSuccess: false, errorMessage: error }
    }
}

interface AuthenticateUserProps {
    phoneNumber: string
    userDispatch: UserAction
    setErrorMessage: (message: string) => void
}

const authenticateUser = async ({
    phoneNumber,
    userDispatch,
    setErrorMessage,
}: AuthenticateUserProps) => {
    const signInResponse = await signUp(phoneNumber)
    if (signInResponse.isSuccess) {
        Logger.info(`isSuccess signUp ${signInResponse}`)
    }

    const { isSuccess, cognitoUser, errorMessage } = await signIn(phoneNumber)
    if (isSuccess) {
        Logger.info("isSuccess signIn")
        if (cognitoUser) {
            userDispatch({
                type: UserActionType.SET_COGNITO_USER,
                payload: {
                    cognitoUser: cognitoUser,
                    isUserConfirmed: signInResponse.userConfirmed,
                },
            })
        }
        userDispatch({
            type: UserActionType.SET_PHONE_NUMBER,
            payload: {
                phoneNumber,
            },
        })
        userDispatch({
            type: UserActionType.SET_IS_PHONE_NUMBER_ENTERED,
            payload: {
                isPhoneNumberEntered: true,
            },
        })
    } else {
        captureMessage(JSON.stringify(errorMessage))
        Logger.error(`${JSON.stringify(errorMessage)}`)
        setErrorMessage("Failed to send")
    }
}

export default authenticateUser
