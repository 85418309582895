import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface SaveChangesBannerStyles extends Styles {
    container: ViewStyle
    text: TextStyle
}

export const SaveChangesBannerStyles: StyleFunction<
    SaveChangesBannerStyles
> = ({ theme }) => {
    return {
        container: {
            position: "absolute",
            alignSelf: "center",
            textAlign: "center",
            justifyContent: "center",
            width: 200,
            height: 20,
            backgroundColor: theme.color.bottomNavigationFlagBackground,
            borderTopLeftRadius:
                theme.cornerRadius.bottomNavigationFlagBackground,
            borderTopRightRadius:
                theme.cornerRadius.bottomNavigationFlagBackground,
        },
        text: {
            alignSelf: "center",
            ...theme.font.bottomNavigationFlagText,
        },
        WEB: {
            container: {
                ...theme.shadow.bottomNavigationFlagBackground.web,
            },
        },
        NATIVE: {
            container: {
                ...theme.shadow.bottomNavigationFlagBackground.native,
            },
        },
    }
}
