import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface SendPollSettingsModalStyles extends Styles {
    title: TextStyle
    sectionTitle: TextStyle
    container: ViewStyle
    optionsRow: ViewStyle
    mockCard: ViewStyle
    image: ImageStyle
    floatingButton: TextStyle
    floatingButtonContainer: ViewStyle
    mockButtonContainer: ViewStyle
    subtitle: TextStyle
    checkIcon: TextStyle
    settingsLine: ViewStyle
}

export const sendPollSettingsModalStyles: StyleFunction<
    SendPollSettingsModalStyles
> = ({ theme }) => {
    return {
        title: {
            ...theme.font.notificationPrimaryText,
        },
        sectionTitle: {
            ...theme.font.notificationSectionTitle,
        },
        container: {
            paddingHorizontal: 20,
            marginVertical: 20,
            alignItems: "center",
        },
        settingsLine: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingHorizontal: 5,
        },
        optionsRow: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
        },
        mockCard: {
            minWidth: 175,
            maxWidth: 175,
        },
        floatingButtonContainer: {
            paddingRight: 0,
            marginRight: 0,
            marginLeft: 0,
        },
        pressableContainer: {
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        },
        mockButtonContainer: {
            paddingRight: 0,
            marginRight: 0,
            marginLeft: 0,
        },
        floatingButton: {
            ...theme.font.ctaFloatingText,
        },
        subtitle: {
            ...theme.font.createPollSendSettingsSubtitle,
            marginTop: 0,
            marginBottom: 15,
        },
        image: {
            height: 104,
            width: 150,
            marginBottom: 5,
        },
        checkIcon: {
            ...theme.font.createPollSendSettingsCheckIcon,
        },
        NATIVE: {
            settingsLine: {
                minWidth: "100%",
                maxWidth: "100%",
            },
            container: {
                alignItems: "center",
            },
            mockCard: {
                minWidth: 175,
            },
        },
    }
}
