import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { Image } from "react-native"
import { SplashScreenStyles } from "./SplashScreen.styles"

const SplashScreen = () => {
    const { styles, theme } = useStyles(SplashScreenStyles)

    return (
        <Image
            source={theme.image.splashImage}
            resizeMode={"cover"}
            style={styles.container}
        />
    )
}

export default SplashScreen
