import React from "react"
import { TextCardTemplate } from "./Items/TextCardTemplate"
import { PollOption } from "@types"
import { GestureResponderHandlers } from "react-native"
import { DefaultCardTemplate } from "./Items/DefaultCardTemplate"
import { LinkCardTemplate } from "./Items/LinkCardTemplate"

interface PollListCardTemplateProps {
    isLoadingLink: boolean
    index: number
    data: PollOption
    optionID: string
    itemEdit: {
        on: boolean
        index: number
    }
    itemEditMode: boolean
    editMode: boolean
    editVoteMode: boolean
    draftMode: boolean
    totalVoteCount: number
    checked: boolean
    canUserRemove: boolean
    doneTransition: boolean
    handleLinkParsed: (link: string) => void
    onPress: (index: number) => void
    onItemCreated: (index: number) => void
    onVote: (index: number) => void
    onOpenOptionLink: (id: string, index: number, url: string) => void
    onValueChanged: (id: string) => void
    onSeeWhoVoted: () => void
    onDelete: () => void
    panResponderCallbacks: GestureResponderHandlers
}

const PollListCardTemplate = ({
    isLoadingLink,
    index,
    itemEdit,
    itemEditMode,
    editMode,
    editVoteMode,
    draftMode,
    data,
    checked,
    canUserRemove,
    totalVoteCount,
    doneTransition,
    handleLinkParsed,
    onVote,
    onOpenOptionLink,
    onPress,
    onItemCreated,
    onDelete,
    onValueChanged,
    onSeeWhoVoted,
    panResponderCallbacks,
}: PollListCardTemplateProps) => {
    switch (data.type) {
        case "defaultTemplate":
            return (
                <DefaultCardTemplate
                    data={data}
                    index={index}
                    checked={checked}
                    totalVoteCount={totalVoteCount}
                    onVote={onVote}
                    onOpenOptionLink={onOpenOptionLink}
                />
            )
        case "link":
            return (
                <LinkCardTemplate
                    isLoadingLink={isLoadingLink}
                    optionID={data.id}
                    index={index}
                    itemEdit={itemEdit}
                    itemEditMode={itemEditMode}
                    editMode={editMode}
                    editVoteMode={editVoteMode}
                    draftMode={draftMode}
                    data={data}
                    checked={checked}
                    totalVoteCount={totalVoteCount}
                    doneTransition={doneTransition}
                    canUserRemove={canUserRemove}
                    onPress={onPress}
                    onItemCreated={onItemCreated}
                    onValueChanged={onValueChanged}
                    onSeeWhoVoted={onSeeWhoVoted}
                    onDelete={onDelete}
                    onVote={onVote}
                    onOpenOptionLink={onOpenOptionLink}
                    panResponderCallbacks={panResponderCallbacks}
                />
            )
        case "text":
            return (
                <TextCardTemplate
                    isLoadingLink={isLoadingLink}
                    index={index}
                    itemEdit={itemEdit}
                    itemEditMode={itemEditMode}
                    editMode={editMode}
                    editVoteMode={editVoteMode}
                    draftMode={draftMode}
                    data={data}
                    checked={checked}
                    totalVoteCount={totalVoteCount}
                    doneTransition={doneTransition}
                    canUserRemove={canUserRemove}
                    handleLinkParsed={handleLinkParsed}
                    onPress={onPress}
                    onItemCreated={onItemCreated}
                    onValueChanged={onValueChanged}
                    onSeeWhoVoted={onSeeWhoVoted}
                    onDelete={onDelete}
                    onVote={onVote}
                    panResponderCallbacks={panResponderCallbacks}
                />
            )
        default:
            return null
    }
}

export default PollListCardTemplate
