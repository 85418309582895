import React, { useContext } from "react"
import { Button, Title } from "@components"
import { View } from "react-native"
import { configureFeedbackMessage } from "@helpers/feedbackMessage"
import links from "@config/links"
import { formatLinkWithLanguage } from "@helpers/linkLang"
import { useTranslation } from "react-i18next"
import Logger from "@services/logger"
import { UserContext } from "@contexts/UserDataProvider"
import * as WebBrowser from "expo-web-browser"
import { sectionStyles } from "../../ProfileScreen.styles"
import useStyles from "@helpers/hooks/useStyles"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { AnalyticsEvent } from "@services/analytics"
import { captureMessage } from "@services/sentry"

const InfoSection = () => {
    const { styles, theme } = useStyles(sectionStyles)
    const { t } = useTranslation()
    const {
        userState: { deviceId },
    } = useContext(UserContext)

    const handleIconPress = (id: string) => async () => {
        const feedbackMessage = await configureFeedbackMessage(
            links.feedbackAddress,
            t,
            deviceId,
        )

        switch (id) {
            case "terms":
                openUrl(formatLinkWithLanguage(links.baseUrl, links.terms))
                break
            case "policy":
                openUrl(formatLinkWithLanguage(links.baseUrl, links.privacy))
                break
            case "share":
                openUrl(feedbackMessage)
                break
        }
    }

    const openUrl = (url: string) => {
        if (isPlatformNative()) {
            try {
                WebBrowser.openBrowserAsync(url)
            } catch (e) {
                const error = e as Error
                captureMessage(error.message)
                Logger.error(error.message)
            }
        } else {
            window.open(url, "_blank")
        }
    }

    return (
        <View>
            <Title style={styles.sectionTitle} title="Info" />
            <Button
                style={styles.linkButton}
                title="Terms and Conditions"
                titleStyle={styles.infoLinkText}
                variant={"text"}
                align={"left"}
                iconRight={theme.image.profileLinkOutIcon}
                iconStyle={styles.infoLinkIcon}
                onPress={handleIconPress("terms")}
                analyticsEvent={AnalyticsEvent.tapProfileTerms}
            />
            <Button
                style={styles.linkButton}
                title="Privacy Policy"
                titleStyle={styles.infoLinkText}
                variant={"text"}
                align={"left"}
                iconRight={theme.image.profileLinkOutIcon}
                iconStyle={styles.infoLinkIcon}
                onPress={handleIconPress("policy")}
                analyticsEvent={AnalyticsEvent.tapProfilePrivacy}
            />
            <Button
                style={styles.linkButton}
                title="Share Feedback"
                titleStyle={styles.infoLinkText}
                variant={"text"}
                align={"left"}
                iconRight={theme.image.profileShareFeedbackIcon}
                iconStyle={styles.infoLinkIcon}
                onPress={handleIconPress("share")}
                analyticsEvent={AnalyticsEvent.tapProfileFeedback}
            />
        </View>
    )
}

export default InfoSection
