import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface PhoneNumberTextStyles extends Styles {
    container: ViewStyle
    icon: TextStyle
    number: TextStyle
}

export const PhoneNumberTextStyles: StyleFunction<PhoneNumberTextStyles> = ({
    theme,
}) => {
    return {
        container: {
            justifyContent: "center",
            flexDirection: "row",
        },
        number: {
            ...theme.font.profilePhoneText,
        },
        icon: {
            ...theme.font.profilePhoneIcon,
            paddingRight: 5,
        },
    }
}
