import React, { ReactElement, useEffect } from "react"
import { StatusBar } from "expo-status-bar"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { Navigation } from "@navigation"
import useCachedResources from "@helpers/hooks/useCachedResources"
import {
    AppProvider,
    DrawerProvider,
    FocusContextProvider,
    PollContextProvider,
    ScreenShotProvider,
    ThemeContextProvider,
    UserDataProvider,
} from "@contexts"
import smoothscroll from "smoothscroll-polyfill"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { ScreenShotContainer } from "@components/PreviewImage"
import { SplashScreen } from "@components"
import { initialize as AnalyticsInitialize } from "@services/analytics"

AnalyticsInitialize()

export const App: React.FC = (): ReactElement | null => {
    const isLoaded = useCachedResources()
    const isNative = isPlatformNative()

    useEffect(() => {
        // Safari scroll animation fix
        if (!isNative) {
            smoothscroll.polyfill()
        }
    }, [])

    if (!isLoaded) {
        if (isNative) {
            return <SplashScreen />
        }

        return null
    }

    return (
        <SafeAreaProvider>
            <ThemeContextProvider>
                <AppProvider>
                    <UserDataProvider>
                        <DrawerProvider>
                            <PollContextProvider>
                                <ScreenShotProvider>
                                    <FocusContextProvider>
                                        <Navigation />
                                        <StatusBar />
                                        <ScreenShotContainer />
                                    </FocusContextProvider>
                                </ScreenShotProvider>
                            </PollContextProvider>
                        </DrawerProvider>
                    </UserDataProvider>
                </AppProvider>
            </ThemeContextProvider>
        </SafeAreaProvider>
    )
}
