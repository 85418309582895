import { Button, Title } from "@components"
import { PollActionType, PollContext } from "@contexts/PollContext"
import { UserActionType, UserContext } from "@contexts/UserDataProvider"
import useStyles from "@helpers/hooks/useStyles"
import { commonStyles } from "@styles/Main.styles"
import React, { useContext, useState } from "react"
import { t } from "i18next"
import { Image, View } from "react-native"
import { SettingsOptionCard } from "./components"
import { sendPollSettingsModalStyles } from "./SendPollSettingsModal.styles"
import { PollMutableSettings } from "@types"
import { AnalyticsEvent } from "@services/analytics"
import { AnalyticsActionType, AnalyticsContext } from "@contexts"

const SendPollSettingsModal = () => {
    const [settingsSaved, setSettingsSaved] = useState(false)
    const [settingsChanged, setSettingsChanged] = useState(false)

    const { styles: defaultStyles } = useStyles(commonStyles)
    const { styles, theme } = useStyles(sendPollSettingsModalStyles)
    const {
        pollState: {
            data,
            data: { settings: settingsFromPollContext },
            loading,
        },
        pollDispatch,
    } = useContext(PollContext)
    const { userDispatch } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const toggleSetting = (setting: PollMutableSettings) => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapLaunchPollSetting,
                additionalData: {
                    setting: setting,
                    value: String(!settingsFromPollContext[setting]),
                },
            },
        })

        pollDispatch({
            type: PollActionType.TOGGLE_POLL_SETTING,
            payload: setting,
        })

        setSettingsChanged(true)
        setSettingsSaved(false)
    }

    const handleLaunch = async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapLaunchPoll,
            },
        })

        pollDispatch({
            type: PollActionType.LAUNCH_POLL,
        })
    }

    const handleSaveDefaults = () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapLaunchSaveDefaults,
            },
        })

        const propsToUpdate = {
            userDefaults: {
                pollSettings: settingsFromPollContext,
            },
        }

        userDispatch({
            type: UserActionType.UPDATE_USER_DATA,
            payload: {
                userDataToUpdate: propsToUpdate,
            },
        })

        setSettingsSaved(true)
    }

    return (
        <View style={styles.container}>
            <Image
                source={{
                    uri: theme.image.pollSendSettingsGraphic,
                }}
                resizeMode={"contain"}
                style={styles.image}
            />
            <Title
                title={t("createPollScreen.settingsDrawer.readyToSend")}
                style={styles.title}
            />
            <Title
                title={t("createPollScreen.settingsDrawer.checkPermissions")}
                style={styles.subtitle}
            />
            <View style={styles.settingsLine}>
                <Title
                    title={t("createPollScreen.settingsDrawer.allowVoters")}
                    style={styles.sectionTitle}
                />
                {settingsChanged ? (
                    // TODO: swap out for custom pressable with lottie checkmark
                    <Button
                        variant={"text"}
                        onPress={handleSaveDefaults}
                        title={t(
                            "createPollScreen.settingsDrawer.saveAsDefault",
                        )}
                        iconLeft={
                            settingsSaved
                                ? theme.image.checkboxChecked
                                : undefined
                        }
                        iconStyle={styles.checkIcon}
                        titleStyle={styles.floatingButton}
                        style={styles.floatingButtonContainer}
                    />
                ) : (
                    <View style={styles.mockButtonContainer}></View>
                )}
            </View>
            <View style={styles.optionsRow}>
                <SettingsOptionCard
                    title={t("profileScreen.seeWhoVoted.title")}
                    checked={!!data.settings?.seeWhoVoted}
                    onPress={() => toggleSetting("seeWhoVoted")}
                />
                <SettingsOptionCard
                    title={t("profileScreen.editPoll.title")}
                    checked={!!data.settings?.editPoll}
                    onPress={() => toggleSetting("editPoll")}
                />
            </View>
            <View style={styles.optionsRow}>
                <SettingsOptionCard
                    title={t("profileScreen.multiVote.title")}
                    checked={!!data.settings?.multipleVotes}
                    onPress={() => toggleSetting("multipleVotes")}
                />
                <View style={styles.mockCard} />
            </View>
            <Button
                onPress={handleLaunch}
                title={t("createPollScreen.settingsDrawer.launchPoll")}
                isLoading={loading}
            />
        </View>
    )
}

export default SendPollSettingsModal
