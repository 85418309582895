import { UserContext } from "@contexts/UserDataProvider"
import React, { useContext } from "react"
import { PollContext } from "@contexts/PollContext"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import { getAppConfig } from "@services/appConfig"

interface CanEditPollResponse {
    canEdit: boolean
    ui: "sign-in" | "poll-locked" | "verify-ownership" | "allow"
}

export const useCanEditPoll = (): CanEditPollResponse => {
    const {
        pollState: { data: poll },
    } = useContext(PollContext)
    const {
        userState: { authStatus, userId },
    } = React.useContext(UserContext)
    const { tenantConfig } = getAppConfig()

    if (tenantConfig.type === "customer") {
        return { canEdit: false, ui: "poll-locked" }
    }

    if (authStatus === AuthenticationState.AUTHENTICATED) {
        if (poll.ownerId === userId) {
            return { canEdit: true, ui: "allow" }
        } else {
            if (poll.settings?.editPoll) {
                return { canEdit: true, ui: "allow" }
            } else {
                return { canEdit: false, ui: "poll-locked" }
            }
        }
    } else {
        if (poll.settings?.editPoll) {
            return { canEdit: true, ui: "sign-in" }
        } else {
            return { canEdit: true, ui: "verify-ownership" }
        }
    }
}
