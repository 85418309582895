export enum LogLevel {
    None = 0,
    Error = 1,
    Warn = 2,
    Verbose = 3,
}

export type ValidPropertyType =
    | number
    | string
    | boolean
    | Array<string | number>
    | { [key: string]: ValidPropertyType }

export type ValidPropertyTypeToAppend =
    | number
    | string
    | Array<string | number>
    | { [key: string]: ValidPropertyTypeToAppend }

export type UserProperties = Record<string, ValidPropertyType>

export type EventProperties = Record<string, ValidPropertyType>

export interface AmplitudeServiceType {
    // initialization

    init: (options: {
        apiKey: string
        deviceId?: string
        appVersion: string
        logLevel: LogLevel
    }) => Promise<boolean>

    // user id

    getDeviceId: () => Promise<string | null>
    setDeviceId: (deviceId: string) => void
    setUserId: (userId: string) => void
    logout: () => void

    // user properties

    setUserProperty: (
        property: string,
        value: ValidPropertyType,
        setOnce: boolean,
    ) => void
    setUserProperties: (properties: UserProperties) => void
    unsetUserPropery: (property: string) => void
    incrementUserProperty: (property: string, incrementAmount?: number) => void
    appendToUserPropertyArray: (
        property: string,
        value: ValidPropertyTypeToAppend,
    ) => void

    // events

    logEvent: (event: string, eventProperties?: EventProperties) => void
    forceUploadEvents: () => void
}
