import { Button, Input } from "@components"
import { DrawerActionType, DrawerContext } from "@contexts/DrawerProvider"
import { UserActionType, UserContext } from "@contexts/UserDataProvider"
import { useInputConfig } from "@helpers/hooks/useInputConfig"
import useStyles from "@helpers/hooks/useStyles"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Image, Text, TextInput, View } from "react-native"
import { ValidationError } from "yup"
import { voterAddNameDrawerStyles } from "./VoterAddNameModal.styles"
import { AnalyticsEvent } from "@services/analytics"
import { useFocus } from "@helpers/hooks/useFocus"

const VoterAddNameModal = () => {
    const { styles, theme } = useStyles(voterAddNameDrawerStyles)
    const [userName, setName] = useState("")
    const inputRef = useRef<TextInput>()
    const [isNameValid, setIsNameValid] = useState(false)
    const {
        drawerDispatch,
        drawerState: { isActive },
    } = useContext(DrawerContext)
    const { t } = useTranslation()
    const {
        userState: { deviceId },
        userDispatch,
    } = useContext(UserContext)

    const { setFocusTarget, setFocus, setFocusBottom } = useFocus()

    const handleChange = (value: string) => {
        setName(value)
    }

    const handleInvalidChange = (error?: ValidationError) => {
        if (!error) setIsNameValid(true)
        else setIsNameValid(false)
    }

    useEffect(() => {
        if (isActive) {
            setFocus("nameInput")
        }
    }, [isActive])

    useEffect(() => {
        if (inputRef.current) {
            setFocusTarget("nameInput", inputRef.current)
            setFocusBottom()
        }
    }, [inputRef])

    const submitForm = () => {
        if (!deviceId) return

        userDispatch({
            type: UserActionType.UPDATE_USER_DATA,
            payload: {
                userDataToUpdate: { name: userName },
            },
        })

        drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
    }

    const inputConfig = useInputConfig("nameInput")

    return (
        <View style={styles.container}>
            <Image
                source={{
                    uri: theme.image.addUserNameGraphic,
                }}
                style={styles.image}
                resizeMode={"contain"}
            />
            <Text style={styles.title}>{t("voterAddNameDrawer.addName")}</Text>
            <Text style={styles.subTitle}>
                ({t("voterAddNameDrawer.addNameReason")})
            </Text>
            <Input
                ref={inputRef as any}
                variant="box"
                value={userName}
                onChange={handleChange}
                onInvalidChange={handleInvalidChange}
                style={styles.input}
                {...inputConfig}
            />
            <View style={styles.buttonsContainer}>
                <Button
                    title={t("save")}
                    onPress={submitForm}
                    disabled={!isNameValid}
                    analyticsEvent={AnalyticsEvent.tapVoteSaveName}
                />
            </View>
        </View>
    )
}

export default VoterAddNameModal
