import { Pressable, View, Text } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { PollSelectListHeaderStyles } from "./PollSelectListHeader.styles"

interface PollSelectListHeaderProps {
    counter: number
    shadowVisible: boolean
    handleFilterAll: () => void
    handleFilterNone: () => void
}

const PollSelectListHeader = ({
    counter,
    shadowVisible,
    handleFilterNone,
    handleFilterAll,
}: PollSelectListHeaderProps) => {
    const { styles } = useStyles(PollSelectListHeaderStyles)

    return (
        <View style={styles.outerContainer}>
            <View
                style={[
                    styles.container,
                    shadowVisible ? styles.containerShadow : null,
                ]}
            >
                <View style={styles.leftColumn}>
                    <Pressable
                        style={styles.filterButton}
                        onPress={handleFilterAll}
                    >
                        <Text style={styles.filterTextAll}>All</Text>
                    </Pressable>
                    <View style={styles.divider} />
                    <Pressable
                        style={styles.filterButton}
                        onPress={handleFilterNone}
                    >
                        <Text style={styles.filterTextNone}>None</Text>
                    </Pressable>
                </View>
                <View style={styles.rightColumn}>
                    <View style={styles.filterButton}>
                        <Text
                            style={
                                counter < 2
                                    ? styles.counterError
                                    : styles.counter
                            }
                        >
                            {counter}
                        </Text>
                    </View>
                    <View style={styles.filterButton}>
                        <Text style={styles.counterText}>
                            {counter === 1 ? "OPTION" : "OPTIONS"}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default PollSelectListHeader
